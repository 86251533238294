import { To, createBrowserHistory } from 'history';
import { pathConstants, searchParamsContants } from 'src/constants/const';
import { EnumNoHeaderSearchParams } from 'src/constants/enum';

const historyInstance = createBrowserHistory();

export interface IHistoryOptions {
  additionalParams?: Object;
  clearedSearchParams?: string[];
}

const getPathName = (to: To, options?: IHistoryOptions) => {
  const params = new URLSearchParams(history.location.search);
  const noHeader = params.get(searchParamsContants.NO_HEADER);
  if (noHeader === EnumNoHeaderSearchParams.ONCE || noHeader === EnumNoHeaderSearchParams.BACK_ONCE) {
    params.delete(searchParamsContants.NO_HEADER);
  }
  if (!!options?.additionalParams) {
    for (const key in options.additionalParams) {
      params.set(key, options.additionalParams[key]);
    }
  }
  if (!!options?.clearedSearchParams) {
    for (const clearedParam of options.clearedSearchParams) {
      params.delete(clearedParam);
    }
  }
  let pathname = '';
  const search = !!params.toString() ? `?${params.toString()}` : '';
  if (typeof to == 'string') {
    pathname = to + search;
  } else {
    pathname = to.pathname + search;
  }
  return pathname;
};

const protype = {
  pop() {
    if (window.history?.state?.idx === 0 && window.location.pathname !== pathConstants.UPDATE_NUMBER) {
      historyInstance.replace(pathConstants.HOME_PAGE);
    } else {
      historyInstance.back();
    }
  },

  pushWithSearch(to: To, state?: any, options?: IHistoryOptions) {
    historyInstance.push(getPathName(to, options), state);
  },

  replaceWithSearch(to: To, state?: any, options?: IHistoryOptions) {
    historyInstance.replace(getPathName(to, options), state);
  },

  pushToLogin() {
    const redirectUrl = encodeURIComponent(history.location.pathname + history.location.search + history.location.hash);

    historyInstance.push({
      pathname: pathConstants.LOGIN,
      search: `?redirect_url=${redirectUrl}&idx=${window.history?.state?.idx}`,
    });
  },
};

export const history = Object.assign(historyInstance, protype);

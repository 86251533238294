import { EnumRequestCode } from 'src/constants/enum';
import { infoAccount } from 'src/requests/api/home';
import { ITrendingStore } from 'src/requests/api/home/prop-state.type';

export const GET_TRENDING_STORES = 'GET_TRENDING_STORES';
export const RESET_TRENDING_STORES = 'RESET_TRENDING_STORES';

export interface IUpdatingTrendingStoresState {
  data?: ITrendingStore[];
  loading?: boolean;
  loaded?: boolean;
}

export const trendingStoresActions = {
  get: (payload: IUpdatingTrendingStoresState) => ({
    type: GET_TRENDING_STORES,
    payload,
  }),
  reset: () => ({
    type: RESET_TRENDING_STORES,
  }),
};

const getTrendingStoresList = () => async (dispatch: any, getState: any) => {
  const loaded = getState().trendingStoresReducer.loaded;
  if (loaded) return;

  let data: ITrendingStore[] = [];
  try {
    dispatch(
      trendingStoresActions.get({
        loading: true,
      }),
    );
    const isLoggedIn = getState().authentication.isLoggedIn;
    const settingInfo = getState().settingInfoReducer.settingInfo;

    const params: any = {};
    if (!isLoggedIn) {
      params.guestCountryCode = settingInfo.countryCd;
    }

    const result = await infoAccount.getPageColumn(params);
    if (result.code === EnumRequestCode.FAILED) {
      throw new Error('get trending stores failed');
    }
    data = result.entities ?? [];
  } catch (error) {
  } finally {
    dispatch(
      trendingStoresActions.get({
        data,
        loading: false,
        loaded: true,
      }),
    );
  }
};

export const trendingStoresMiddleware = {
  getTrendingStoresList,
};

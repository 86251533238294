import { EnumDifficulty, EnumFavoriteClub, EnumTeeHeight, EnumTeePosition, EnumTypeClub } from 'src/constants/enum';
import { EnumGDRLevel } from 'src/constants/enum/profile.enum';

export const profileContants = {
  MAX_DRIVER_POWER: 300,
  MAX_PUTTING_COUNT: 5,
};

export const RANKING_SCORE_TYPE = {
  G_HCP_TYPE: 'G_HCP_TYPE',
  BEST_SCORE_TYPE: 'BEST_SCORE_TYPE',
  LONGEST_TYPE: 'LONGEST_TYPE',
};

export const GENDER_TYPE = {
  ALL_GENDER: 'ALL_GENDER',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const GDR_LEVEL_OPTIONS = [
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.BEGINNER', value: EnumGDRLevel.BEGINNER_LEVEL_1 },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.BEGINNER', value: EnumGDRLevel.BEGINNER_LEVEL_2 },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.BEGINNER', value: EnumGDRLevel.BEGINNER_LEVEL_3 },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.LAST_100_STROKES', value: EnumGDRLevel.LAST_100_STROKES },
  {
    title: 'pages.profile.gdr.compare_average.gdr_level_list.MIDDLE_100_STROKES',
    value: EnumGDRLevel.MIDDLE_100_STROKES,
  },
  {
    title: 'pages.profile.gdr.compare_average.gdr_level_list.FIRST_100_STROKES',
    value: EnumGDRLevel.FIRST_100_STROKES,
  },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.LAST_90_STROKES', value: EnumGDRLevel.LAST_90_STROKES },
  {
    title: 'pages.profile.gdr.compare_average.gdr_level_list.MIDDLE_90_STROKES',
    value: EnumGDRLevel.MIDDLE_90_STROKES,
  },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.FIRST_90_STROKES', value: EnumGDRLevel.FIRST_90_STROKES },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.LAST_80_STROKES', value: EnumGDRLevel.LAST_80_STROKES },
  {
    title: 'pages.profile.gdr.compare_average.gdr_level_list.MIDDLE_80_STROKES',
    value: EnumGDRLevel.MIDDLE_80_STROKES,
  },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.FIRST_80_STROKES', value: EnumGDRLevel.FIRST_80_STROKES },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.LAST_70_STROKES', value: EnumGDRLevel.LAST_70_STROKES },
  {
    title: 'pages.profile.gdr.compare_average.gdr_level_list.MIDDLE_70_STROKES',
    value: EnumGDRLevel.MIDDLE_70_STROKES,
  },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.FIRST_70_STROKES', value: EnumGDRLevel.FIRST_70_STROKES },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.SCRATCH', value: EnumGDRLevel.SCRATCH },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.SEMI_PRO', value: EnumGDRLevel.SEMI_PRO },
  { title: 'pages.profile.gdr.compare_average.gdr_level_list.TOUR_PRO', value: EnumGDRLevel.TOUR_PRO },
];

export const CLUB_INFO = [
  {
    title: 'pages.record.type_golf.DRIVER',
    type: EnumTypeClub.DRIVER,
  },
  {
    title: 'pages.record.type_golf.WOOD',
    type: EnumTypeClub.WOOD,
  },
  {
    title: 'pages.record.type_golf.UTILITY',
    type: EnumTypeClub.UTILITY,
  },
  {
    title: 'pages.record.type_golf.FLATIRON',
    type: EnumTypeClub.IRON,
  },
  {
    title: 'pages.record.type_golf.WEDGE',
    type: EnumTypeClub.WEDGES,
  },
  {
    title: 'pages.record.type_golf.PUTTER',
    type: EnumTypeClub.PUTTER,
  },
];

export const genderOptions = [
  { title: 'pages.home.my_ranking.ALL_GENDER', value: GENDER_TYPE.ALL_GENDER },
  { title: 'pages.home.my_ranking.MALE', value: GENDER_TYPE.MALE },
  { title: 'pages.home.my_ranking.FEMALE', value: GENDER_TYPE.FEMALE },
];

export const gHandyOptions = [
  { title: 'pages.home.my_ranking.G-HCP', value: RANKING_SCORE_TYPE.G_HCP_TYPE },
  { title: 'pages.home.my_ranking.BEST_SCORE', value: RANKING_SCORE_TYPE.BEST_SCORE_TYPE },
  { title: 'pages.home.my_ranking.LONGEST', value: RANKING_SCORE_TYPE.LONGEST_TYPE },
];

export const teePositionOptions = [
  { title: 'Black', value: EnumTeePosition.BLACK },
  { title: 'Blue', value: EnumTeePosition.BLUE },
  { title: 'White', value: EnumTeePosition.WHITE },
  { title: 'Yellow', value: EnumTeePosition.YELLOW },
  { title: 'Red', value: EnumTeePosition.RED },
];

export const teeHeightOptions = [
  { title: '1" (25mm)', value: EnumTeeHeight.h25mm },
  { title: '1.2" (30mm)', value: EnumTeeHeight.h30mm },
  { title: '1.4" (35mm)', value: EnumTeeHeight.h35mm },
  { title: '1.6" (40mm)', value: EnumTeeHeight.h40mm },
  { title: '1.8" (45mm)', value: EnumTeeHeight.h45mm },
  { title: '2" (50mm)', value: EnumTeeHeight.h50mm },
  { title: '2.2" (55mm)', value: EnumTeeHeight.h55mm },
  { title: '2.4" (60mm)', value: EnumTeeHeight.h60mm },
];

export const difficultyOptions = [
  { title: 'pages.setting.screen_settings.difficulty_type.BEGINNER', value: EnumDifficulty.BEGINNER },
  { title: 'pages.setting.screen_settings.difficulty_type.AMATEUR', value: EnumDifficulty.AMATEUR },
  { title: 'pages.setting.screen_settings.difficulty_type.PRO', value: EnumDifficulty.PRO },
  { title: 'pages.setting.screen_settings.difficulty_type.G_TOUR', value: EnumDifficulty.G_TOUR },
];

export const favoriteClubOptions = [
  { title: 'pages.setting.distance.type_golf.WOOD', value: EnumFavoriteClub.WOOD },
  { title: 'pages.setting.distance.type_golf.FLATIRON', value: EnumFavoriteClub.IRON },
  { title: 'pages.setting.distance.type_golf.WEDGE', value: EnumFavoriteClub.WEDGE },
];

export enum EnumStatusRanking {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
  UNCHANGED = 'UNCHANGED',
}

export enum EnumTypeTableRanking {
  SUB_COUNTRY,
  COUNTRY,
  GLOBAL,
  LEVEL,
  STORE,
}

export enum EnumRankLevel {
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
  DIAMOND = 'Diamond',
}

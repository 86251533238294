import { eventName } from './../../constants/const/firebase.constants';
import i18n from 'src/locale';
import { NotificationType } from 'src/models/notification';
import { notificationActions } from 'src/store/notification';
import { infoAccount } from 'src/requests/api/home';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import { EnumRequestCode, EnumScreenLoginMode, EnumValueBoolean } from 'src/constants/enum';
import { IEventBannerListResponse } from 'src/requests/api/home/prop-state.type';
import dayjs from 'dayjs';
import { formatDate } from 'src/constants/const';
import { firebaseInstance } from 'src/init-firebase';

export const MY_INFO = 'MY_INFO';
export const ADVERTISING_INFO = 'ADVERTISING_INFO';
export const SHOW_ADVERTISING_POPUP = 'SHOW_ADVERTISING_POPUP';
export const SET_SCREEN_LOGIN_INFO = 'SET_SCREEN_LOGIN_INFO';
export const SET_HAS_NEW_NOTIFICATION = 'SET_HAS_NEW_NOTIFICATION';
export const UPDATE_HOME_DATA = 'UPDATE_HOME_DATA';
export const SET_PAGE_OFFSET = 'SET_PAGE_OFFSET';
export const RESET_HOME_DATA = 'RESET_HOME_DATA';

export const account = {
  info: (dataUser: IMyInfoResponse | null) => ({
    type: MY_INFO,
    payload: {
      userInfo: dataUser,
    },
  }),
  setHasNewNotification: (value: boolean) => ({
    type: SET_HAS_NEW_NOTIFICATION,
    payload: {
      value,
    },
  }),
  updateHomeData: (value: any) => ({
    type: UPDATE_HOME_DATA,
    payload: value,
  }),
  setPageOffset: (value: number) => ({
    type: SET_PAGE_OFFSET,
    payload: value,
  }),
  resetHomeData: () => ({
    type: RESET_HOME_DATA,
  }),
};

export const updateAdvertisingAction = {
  getInfo: (advertisingInfo: IEventBannerListResponse[]) => ({
    type: ADVERTISING_INFO,
    payload: {
      advertisingInfo,
    },
  }),

  handleShowAdvertisingInfo: ({
    showAdvertPopup,
    advertIsHidden,
  }: {
    showAdvertPopup: boolean;
    advertIsHidden?: boolean;
  }) => ({
    type: SHOW_ADVERTISING_POPUP,
    payload: {
      showAdvertPopup,
      advertIsHidden,
    },
  }),
};

export const screenLoginAction = {
  setInfo: (screenLoginMode: EnumScreenLoginMode) => ({
    type: SET_SCREEN_LOGIN_INFO,
    payload: {
      screenLoginMode,
    },
  }),
};

let isLoading = false;
const getMyInformation = (params?: { isAfterLogin?: boolean }) => async (dispatch: any, getState: any) => {
  if (isLoading) return;
  isLoading = true;
  try {
    const response = await infoAccount.getMyInfo({ currentDatetime: dayjs().format(formatDate.DATE_TIME) });
    if (response.code === EnumRequestCode.SUCCESS) {
      dispatch(account.info(response.entities));
      const advertIsHidden = getState()?.mainInfoReducer?.advertIsHidden;
      if (!advertIsHidden) {
        dispatch(
          updateAdvertisingAction.handleShowAdvertisingInfo({
            showAdvertPopup: true,
            advertIsHidden: false,
          }),
        );
      }
      if (params?.isAfterLogin && response.entities?.smsAuthYn === EnumValueBoolean.TRUE) {
        firebaseInstance.trackEvent(eventName.LOGIN_SUCCESS);
      }
    } else {
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(error.message, NotificationType.DANGER));
  } finally {
    isLoading = false;
  }
};

const getAdvertising = () => async (dispatch: any, getState: any) => {
  try {
    const isLoggedIn = getState().authentication.isLoggedIn;
    const settingInfo = getState().settingInfoReducer.settingInfo;
    const params: any = { currentDatetime: dayjs().format(formatDate.DATE_TIME) };
    if (!isLoggedIn) {
      params.countryCode = settingInfo.countryCd;
    }
    const result = await infoAccount.getEventBannerList(params);
    if (result.entities) {
      dispatch(updateAdvertisingAction.getInfo(result.entities));
    } else {
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(error.message, NotificationType.DANGER));
  }
};

export const mainAction = {
  getMyInformation,
  getAdvertising,
};

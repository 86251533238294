import { NotificationType } from 'src/models/notification';
import { notificationActions } from 'src/store/notification';
import { EnumFilterField, EnumFaqFilterCode, EnumRequestCode, pageSize } from 'src/constants/enum';
import { setting } from 'src/requests/api/setting';
import { INewsListResponse } from 'src/requests/api/home/prop-state.type';
import { IListMore } from 'src/requests/api/common/prop-state.type';

export const FAQ_INFO = 'FAQ_INFO';
export const FAQ_RESET = 'FAQ_RESET';

export const faqDispatch = {
  info: (dataList: IListMore<INewsListResponse>, keyFilter: EnumFaqFilterCode) => ({
    type: FAQ_INFO,
    payload: {
      dataList,
      keyFilter,
    },
  }),
  resetInfo: () => ({
    type: FAQ_RESET,
  }),
};

const faqDataInfo = (typeData: EnumFaqFilterCode, isLoadingMore?: boolean) => async (dispatch: any, getState: any) => {
  const prevStateRedux = getState().faqReducer.dataFaq[typeData];
  if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
  try {
    const isLoggedIn = getState().authentication.isLoggedIn;
    const settingInfo = getState().settingInfoReducer.settingInfo;
    const currentPage = prevStateRedux.loaded + 1;
    const params: any = {
      page: currentPage,
      size: pageSize.NORMAL,
      ...(typeData !== EnumFilterField.ALL && { subCode: typeData }),
    };
    if (!isLoggedIn) {
      params.guestCountryCode = settingInfo.countryCd;
    }
    const result = await setting.getFaqList(params);
    if (result.code === EnumRequestCode.FAILED) throw new Error("Can't get list News Notice");
    if (result.entities.length < 1) {
      const dataObject = {
        ...prevStateRedux,
        loaded: currentPage,
        lastPage: true,
      };
      dispatch(faqDispatch.info(dataObject, typeData));
      return;
    }
    const dataList = [...prevStateRedux.values, ...result.entities];
    const isLast = result.totalCount <= dataList.length;
    const dataObject = {
      values: dataList,
      loaded: currentPage,
      lastPage: isLast,
    };
    dispatch(faqDispatch.info(dataObject, typeData));
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(error.message, NotificationType.DANGER));
  }
};
export const faqAction = {
  faqDataInfo,
};

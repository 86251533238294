import { ILocation } from 'src/store/global';

export const STORE_NEWS_ACTION_TYPES = {
  GET_LOCATION_CURRENT: 'GET_LOCATION_CURRENT',
};
export const UPDATE_MAIN_WIDTH = 'UPDATE_MAIN_WIDTH';
export const UPDATE_LOADING = 'UPDATE_LOADING';

const updateWebsiteLocation = (websiteLocation: ILocation | null) => ({
  type: STORE_NEWS_ACTION_TYPES.GET_LOCATION_CURRENT,
  payload: {
    websiteLocation,
  },
});

const updateMainWidth = (width: number | string) => ({
  type: UPDATE_MAIN_WIDTH,
  payload: width,
});

const updateLoading = (payload: boolean) => ({
  type: UPDATE_LOADING,
  payload,
});

export const globalActions = {
  updateWebsiteLocation,
  updateMainWidth,
  updateLoading,
};

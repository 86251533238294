import React, { useEffect } from 'react';
import { isMobile, isMacOs } from 'react-device-detect';
import { LOCAL_STORAGE_IS_NATIVE_APP, pathConstants } from 'src/constants/const';
import { history } from 'src/services/history';

export const IOS_APP_LINK = 'https://apps.apple.com/us/app/golfzon-global/id1484602591';
export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.golfzon.ggdr';

const LinkToApp = React.memo(() => {
  const isAndroid = Boolean(window.navigator.userAgent.match(/Android/i));
  const isNativeApp = !!localStorage.getItem(LOCAL_STORAGE_IS_NATIVE_APP);

  const openApp = () => {
    if (isNativeApp) history.replace(pathConstants.HOME_PAGE);
    else if (isMobile) {
      setTimeout(() => {
        if (isAndroid) {
          window.location.href = ANDROID_APP_LINK;
        } else {
          window.location.href = IOS_APP_LINK;
        }
      }, 500);
    } else {
      if (isMacOs) {
        window.location.href = IOS_APP_LINK;
      } else {
        window.location.href = ANDROID_APP_LINK;
      }
    }
  };
  useEffect(() => {
    openApp();
  }, []);

  return <p></p>;
});

export default LinkToApp;

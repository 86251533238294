import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { picIconTwoVision, picPlusItemsBanner } from 'src/assets/images';
import { IPlusItem } from 'src/models/plus-items';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import { plusItemsRequests } from 'src/requests/api/plus-items';
import { DateTime } from 'src/services/datetime';
import { RootState } from 'src/store';
import ImageCustom from 'src/view/commons/customs/Image';
import Footer from 'src/view/layouts/global/Footer';
import Header from 'src/view/layouts/global/Header';

export default function PlusItemsPage(): JSX.Element {
  const translate = useTranslation().t;
  const params = useParams();
  const [data, setData] = useState<IPlusItem>();
  const [loading, setLoading] = useState(true);

  const userInfo: IMyInfoResponse | null = useSelector((state: RootState) => state.mainInfoReducer.userInfo);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      if (!params.id) return;
      const result = await plusItemsRequests.getInfo({
        allocateSeq: params.id,
      });
      setData(result?.data?.entities);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getDurationText = useMemo(() => {
    if (!data?.duration) return '';
    return translate(`pages.plus_items.duration.${data?.duration}`);
  }, [data?.duration]);

  const getBallTypeText = useMemo(() => {
    if (!data?.duration) return '';
    return translate(`pages.plus_items.ball_type.${data?.itemTypeCode}`);
  }, [data?.itemTypeCode]);

  const noteText = translate('pages.plus_items.NOTE', {
    icon: `<img class="inline-block w-[20px] h-[20px] ml-[4px] align-sub rounded-full" src="${picIconTwoVision}" />`,
  });

  const skeleton = () => {
    return (
      <>
        <div className="mt-[8px]">
          <div className="h-[16px] w-1/2 animate-pulse rounded-[8px] bg-disable" />
        </div>
        <div className="mt-[20px] flex flex-col items-center">
          <div className="aspect-square w-full max-w-[327px] animate-pulse rounded-[8px] bg-disable" />
          <div className="mt-[16px] flex w-full flex-col items-center rounded-[8px] bg-[#FFF7EE] p-[16px_12px]">
            <div className="mt-[12px] h-[20px] w-[120px] animate-pulse rounded-[8px] bg-disable" />
            <div className="mt-[12px] h-[16px] w-1/2 animate-pulse rounded-[8px] bg-disable" />
            <div className="mt-[8px] h-[16px] w-2/3 animate-pulse rounded-[8px] bg-disable" />
            <div className="mt-[8px] h-[16px] w-2/3 animate-pulse rounded-[8px] bg-disable" />
          </div>
        </div>
        <div
          className="gz-text-xs mt-[16px]"
          dangerouslySetInnerHTML={{
            __html: noteText,
          }}
        />
        <div className="mt-[20px] font-bold text-title">{translate('pages.plus_items.OTHER')}</div>
        <div className="mt-[8px] rounded-[8px] border border-[#E7E7E7] p-[10px_12px]">
          <div className="mt-[8px] h-[96px] w-full animate-pulse rounded-[8px] bg-disable" />
        </div>
      </>
    );
  };

  const element = () => {
    return (
      <>
        <div className="mt-[8px]">{translate('pages.plus_items.RECEIVED_ITEM_TYPE', { type: getBallTypeText })}</div>
        <div className="mt-[20px] flex flex-col items-center">
          <ImageCustom
            className="w-full max-w-[327px] rounded-[8px]"
            imgPath={data?.imageUrl}
            imgNotFound={picPlusItemsBanner}
          />
          <div className="mt-[8px] w-full break-words rounded-[8px] bg-[#FFF7EE] p-[16px_12px]">
            <div className="gz-text-md text-center font-bold text-darkblue-60">{data?.title}</div>
            <div className="mt-[12px] text-center">
              {translate('pages.plus_items.ITEM_NAME')}: {data?.itemName}
            </div>
            <div className="mt-[8px] text-center">
              {translate('pages.plus_items.ITEM_RECEIVED_DATE')}:{' '}
              <span className="font-semibold text-darkblue-60">
                {data?.receivedDate && DateTime.formatDate(data.receivedDate, translate)}
              </span>
            </div>
            <div
              className="mt-[8px] text-center"
              dangerouslySetInnerHTML={{
                __html: translate('pages.plus_items.EXPIRATION_NOTE', { duration: getDurationText }),
              }}
            />
          </div>
        </div>
        <div
          className="gz-text-xs mt-[16px]"
          dangerouslySetInnerHTML={{
            __html: noteText,
          }}
        />
        {!!data?.description && (
          <>
            <div className="mt-[20px] font-bold text-title">{translate('pages.plus_items.OTHER')}</div>
            <div className="mt-[8px] rounded-[8px] border border-[#E7E7E7] p-[10px_12px]">{data?.description}</div>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Header classTitle="text-gz-white ml-0" />
      <div className="p-[24px_16px]">
        <div>{translate('pages.home.banner.GREETING', { name: userInfo?.usrNickname })}</div>
        <div>{loading ? skeleton() : element()}</div>
      </div>
      <Footer />
    </>
  );
}

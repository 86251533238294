import { useEffect } from 'react';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';

export const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  // Add setTimeout to run after mount DOM
  const scrollToTop = () =>
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1);

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('beforeunload', scrollToTop);
    return () => {
      window.removeEventListener('beforeunload', scrollToTop);
    };
  }, []);
};

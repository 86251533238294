import { useSearchParams } from 'react-router-dom';
import { searchParamsContants } from 'src/constants/const';
import { eventScreenType } from 'src/constants/const/firebase.constants';

export function useEventFirebase() {
  const [params] = useSearchParams();
  const typeSearchParam = params.get(searchParamsContants.PAGE_REFERRER);
  const isMenucard = typeSearchParam === eventScreenType.MENUCARD;
  const isSetting = typeSearchParam === eventScreenType.SETTING;
  const isMyPage = typeSearchParam === eventScreenType.MYPAGE;
  const isHome = typeSearchParam === eventScreenType.HOME;
  const isHomeRank = typeSearchParam === eventScreenType.HOME_RANKING;
  const isHomeTournament = typeSearchParam === eventScreenType.HOME_TOURNAMENT;
  const isHomeMoreCourses = typeSearchParam === eventScreenType.HOME_MORECOURSES;
  const isHomeNewCourses = typeSearchParam === eventScreenType.HOME_NEWCOURSES;
  const isHomePopularCourses = typeSearchParam === eventScreenType.HOME_POPULARCOURSES;

  return {
    isMenucard,
    isSetting,
    isMyPage,
    isHome,
    isHomeRank,
    isHomeTournament,
    isHomeMoreCourses,
    isHomeNewCourses,
    isHomePopularCourses,
  };
}

import Header from './Header';

interface Props {
  children?: JSX.Element;
}

function GuestLayout({ children }: Props): JSX.Element {
  return (
    <>
      <Header />
      <main className="mx-auto max-w-[768px] bg-gz-white px-4 md:!px-8">{children}</main>
    </>
  );
}

export default GuestLayout;

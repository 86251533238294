import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'src/hooks/use-modal';
import { IconChevronLeft } from 'src/assets/svg';
import BottomSheet from 'src/view/commons/elements/BottomSheet';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';
import Button from 'src/view/commons/elements/Button';
import _ from 'lodash';

interface IProps {
  mainWidth?: number | string;
  date?: dayjs.Dayjs;
  months?: dayjs.Dayjs[];
  onChange?: (date: dayjs.Dayjs) => void;
}

const MonthPicker: React.FC<IProps> = ({ mainWidth, date = dayjs(), months = [], onChange }: IProps) => {
  const translate = useTranslation().t;
  const { isOpen, onOpen, onClose } = useModal();
  const [prevSelectedDate, setPrevSelectedDate] = useState(date);
  const validYears = _.uniq(months.map((month: dayjs.Dayjs) => month.year()));
  const [currentYearIndex, setCurrentYearIndex] = useState(validYears.indexOf(date.year()));
  const [selectedDate, setSelectedDate] = useState(prevSelectedDate);

  useEffect(() => {
    setSelectedDate(prevSelectedDate);
    setCurrentYearIndex(validYears.indexOf(prevSelectedDate.year()));
  }, [isOpen]);

  const changeCurrentDate = (month: number) => {
    const currentDt = selectedDate.clone().year(validYears[currentYearIndex]).month(month);
    setSelectedDate(currentDt);
  };

  const selectDate = () => {
    onChange?.call(null, selectedDate);
    setPrevSelectedDate(selectedDate);
    onClose();
  };

  if (!months || months.length === 0) return <></>;

  return (
    <>
      <div className="mx-[24px] mt-[24px] flex w-fit cursor-pointer items-center" onClick={onOpen}>
        <div className="gz-text-xl mr-[12px] font-bold">
          {translate('date.TEXT_YEAR_AND_MONTH', {
            year: prevSelectedDate.year(),
            month: `${translate(`date.month.${prevSelectedDate.month() + 1}`)}`,
          })}
        </div>
        <IconChevronLeft className="-rotate-90 stroke-title" />
      </div>
      <BottomSheet className="z-[1000]" isOpen={isOpen} onClose={onClose}>
        <div className="mx-auto" style={{ width: mainWidth }}>
          <div className="p-[24px]">
            <div className="gz-text-md text-center font-bold">{translate('commons.customs.month_picker.TITLE')}</div>
            <div className="mt-[16px] flex px-[8px]">
              <div className="flex w-[100px] flex-col items-center justify-center pr-[16px]">
                <div
                  className={twMerge(
                    'cursor-pointer select-none p-[4px]',
                    currentYearIndex === 0 && 'pointer-events-none',
                  )}
                  onClick={() => setCurrentYearIndex(currentYearIndex - 1)}
                >
                  <IconChevronLeft
                    className={twMerge(
                      'h-[16px] w-[16px] rotate-90',
                      currentYearIndex === 0 ? 'stroke-disable' : 'stroke-title',
                    )}
                  />
                </div>
                <div className="gz-text-md py-[8px] font-medium">{validYears[currentYearIndex]}</div>
                <div
                  className={twMerge(
                    'cursor-pointer select-none p-[4px]',
                    currentYearIndex === validYears.length - 1 && 'pointer-events-none',
                  )}
                  onClick={() => setCurrentYearIndex(currentYearIndex + 1)}
                >
                  <IconChevronLeft
                    className={twMerge(
                      'h-[16px] w-[16px] -rotate-90',
                      currentYearIndex === validYears.length - 1 ? 'stroke-disable' : 'stroke-title',
                    )}
                  />
                </div>
              </div>
              <div className="gz-text-xs grid w-full grid-cols-3 gap-y-[12px]">
                {[...Array(12)].map((_, index) => (
                  <div key={index} className={twMerge('flex items-center justify-center')}>
                    <div
                      className={twMerge(
                        'w-full max-w-[76px] cursor-pointer rounded-[8px] p-[12px_8px] text-center text-title transition-all',
                        selectedDate.year() === validYears[currentYearIndex] &&
                          selectedDate.month() === index &&
                          'bg-primary-20 text-primary-75',
                        months.findIndex((month) =>
                          month.isSame(dayjs(`${validYears[currentYearIndex]}/${index + 1}`)),
                        ) === -1 && 'pointer-events-none bg-gz-white text-disable',
                      )}
                      onClick={() => changeCurrentDate(index)}
                    >
                      {translate('date.TEXT_MONTH', { month: translate(`date.month.${index + 1}`).substring(0, 3) })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Button className="mt-[24px]" onClick={selectDate}>
              {translate('commons.customs.month_picker.APPLY')}
            </Button>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  mainWidth: state.globalReducer.mainWidth,
});

export default connect(mapStateToProps, null)(MonthPicker);

import dayjs from 'dayjs';
import { uniq } from 'lodash';
import { TFunction } from 'react-i18next';
import { EnumTmBallPathCode, EnumTrainingModeSwingPosition, EnumTypeClub } from 'src/constants/enum';
import { ITmSwingVideoInfoResponse } from 'src/requests/api/record/prop-state.type';
import { Dayjs } from 'src/services/datetime';
import { ITmSwingVideosData } from 'src/store/records';

export const getTrainingModeTypeClub = (clubNo?: string): EnumTypeClub => {
  let type;
  switch (clubNo) {
    case '1':
      type = EnumTypeClub.DRIVER;
      break;
    case '2':
    case '3':
    case '4':
    case '5':
      type = EnumTypeClub.WOOD;
      break;
    case '6':
    case '7':
    case '8':
    case '9':
    case '10':
    case '11':
    case '12':
      type = EnumTypeClub.IRON;
      break;
    case '13':
    case '14':
    case '15':
      type = EnumTypeClub.WEDGES;
      break;
    case '16':
      type = EnumTypeClub.PUTTER;
      break;
    default:
      type = EnumTypeClub.PUTTER;
      break;
  }
  return type;
};

export const getBallPathBySwingPositon = (
  shotType?: EnumTmBallPathCode,
  swingPosition?: EnumTrainingModeSwingPosition,
): EnumTmBallPathCode => {
  if (shotType == null || swingPosition == null) return EnumTmBallPathCode.STRAIGHT;
  if (swingPosition === EnumTrainingModeSwingPosition.RIGHT) return shotType;
  if (shotType === EnumTmBallPathCode.PULL) return EnumTmBallPathCode.PUSH;
  if (shotType === EnumTmBallPathCode.PUSH) return EnumTmBallPathCode.PULL;
  if (shotType === EnumTmBallPathCode.SLICE) return EnumTmBallPathCode.HOOK;
  if (shotType === EnumTmBallPathCode.HOOK) return EnumTmBallPathCode.SLICE;
  if (shotType === EnumTmBallPathCode.DRAW) return EnumTmBallPathCode.FADE;
  if (shotType === EnumTmBallPathCode.FADE) return EnumTmBallPathCode.DRAW;
  if (shotType === EnumTmBallPathCode.PULL_SLICE) return EnumTmBallPathCode.PUSH_HOOK;
  if (shotType === EnumTmBallPathCode.PUSH_HOOK) return EnumTmBallPathCode.PULL_SLICE;
  return shotType;
};

export const reverseSwingVideoList = (insertData: ITmSwingVideoInfoResponse[], previousData: ITmSwingVideosData[]) => {
  if (!insertData || !Array.isArray(insertData) || insertData.length === 0)
    return {
      data: previousData,
      isLast: true,
    };
  const newData = [...previousData];
  for (let index = 0; index < insertData.length; index++) {
    const insertedDataIndex = newData.findIndex((prevItem) => prevItem.tsCode === insertData[index].tsCode);
    if (insertedDataIndex > -1) {
      newData[insertedDataIndex].swingVideos = [...newData[insertedDataIndex].swingVideos, insertData[index]];
    } else {
      newData.push({
        tsCode: insertData[index].tsCode,
        swingVideos: [insertData[index]],
      });
    }
  }
  return {
    data: newData,
    isLast: false,
  };
};

export const isDriverClub = (clubCode?: string) => {
  if (clubCode == null) return true;
  const clubType = getTrainingModeTypeClub(clubCode);
  return !(clubType === EnumTypeClub.IRON || clubType === EnumTypeClub.WEDGES || clubType === EnumTypeClub.PUTTER);
};

export const getTmClubName = (club?: string) => {
  return club ?? 'PT';
};

export const getTmAvailableMonths = (months: any[], format: string) => {
  const list = months.map((el: any) => Dayjs.convertEstToLocale(el.tsTimeStart).format(format));
  return uniq(list).map((month: string) => dayjs(month, format));
};

export const getFaceToPathName = (
  translate: TFunction<'translation', undefined>,
  faceToPath: string,
  swingPosition?: EnumTrainingModeSwingPosition,
) => {
  if (
    (faceToPath === '2' && swingPosition === EnumTrainingModeSwingPosition.RIGHT) ||
    (faceToPath === '3' && swingPosition === EnumTrainingModeSwingPosition.LEFT)
  ) {
    return translate('pages.record.type.training_mode.face_to_path.OPEN');
  }
  if (
    (faceToPath === '3' && swingPosition === EnumTrainingModeSwingPosition.RIGHT) ||
    (faceToPath === '2' && swingPosition === EnumTrainingModeSwingPosition.LEFT)
  ) {
    return translate('pages.record.type.training_mode.face_to_path.CLOSED');
  }
  return translate('pages.record.type.training_mode.face_to_path.SQUARE');
};

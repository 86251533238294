import { memo, SVGProps } from 'react';

interface IIconBookmarkProps extends SVGProps<SVGSVGElement> {
  className?: string;
  startColor?: string;
  endColor?: string;
  animation?: boolean;
}

function IconBookmark({
  className,
  startColor = '#E6E6E6',
  endColor = '#40ABFF',
  animation = false,
  ...rest
}: IIconBookmarkProps) {
  const key = rest.key ?? '';
  return (
    <svg
      {...rest}
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99613 2.5C2.81204 2.5 2.6628 2.64924 2.6628 2.83333V13.9812C2.6628 14.2239 2.91393 14.3852 3.1347 14.2843L7.85757 12.1258C7.94557 12.0856 8.0467 12.0856 8.1347 12.1258L12.8576 14.2843C13.0783 14.3852 13.3295 14.2239 13.3295 13.9812V2.83333C13.3295 2.64924 13.1802 2.5 12.9961 2.5H2.99613Z"
        fill={animation ? `url(#animation-bookmark)${key}` : endColor}
      />
      <defs>
        <linearGradient id={`animation-bookmark${key}`} x1="100%" x2="100%" y1="100%" y2="0">
          <stop offset="1" stopColor={startColor}>
            <animate dur="0.5s" attributeName="offset" fill="freeze" from="1" to="0" />
          </stop>
          <stop offset="1" stopColor={endColor}>
            <animate dur="0.5s" attributeName="offset" fill="freeze" from="1" to="0" />
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default memo(IconBookmark);

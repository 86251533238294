import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { EnumRequestCode } from 'src/constants/enum';
import { NotificationType } from 'src/models';
import { notificationActions } from 'src/store/notification';
import { allValid, validate } from 'src/services/validators';
import { findPasswordMiddleware } from 'src/requests/api/account/find-password';
import Input, { InputStatus } from 'src/view/commons/elements/Input';
import ButtonNext from 'src/view/commons/customs/ButtonNext';
import FindPasswordResult from 'src/view/pages/account/find-password/FindPasswordResult';
import { history } from 'src/services/history';
import { isMobile } from 'react-device-detect';
import { pathConstants } from 'src/constants/const';

interface ISetupNewPasswordProps {
  addNotification: (message: string, type: NotificationType) => void;
  entities: any;
  onNextStep: (entities: any) => void;
}

function SetupNewPassword({ addNotification, entities, onNextStep }: ISetupNewPasswordProps): JSX.Element {
  const translate = useTranslation().t;
  const [newPasswordForm, setNewPasswordForm] = useState({
    password: {
      value: '',
      status: InputStatus.Normal,
      rules: 'required|special_char|password',
      error: '',
    },
    confirmPassword: {
      value: '',
      status: InputStatus.Normal,
      rules: 'required|special_char|password',
      error: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const [isShowPopupSuccess, setIsShowPopupSuccess] = useState(false);

  const handleClosePopupSuccess = () => {
    if (isMobile) {
      history.pushWithSearch(pathConstants.LOGIN);
      return;
    }
    setIsShowPopupSuccess(false);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.name;
    const value = e.target.value;
    const error = validate(value, newPasswordForm[inputName].rules);
    const formData = {
      ...newPasswordForm,
      [inputName]: {
        ...newPasswordForm[inputName],
        status: error ? InputStatus.Error : InputStatus.Done,
        error: error ?? '',
        value: e.target.value,
      },
    };

    if (
      formData.confirmPassword.status === InputStatus.Done &&
      ((inputName === 'confirmPassword' && value !== newPasswordForm.password.value) ||
        (inputName === 'password' && value !== newPasswordForm.confirmPassword.value))
    ) {
      formData.confirmPassword = {
        ...formData.confirmPassword,
        status: InputStatus.Error,
        error: 'match_password',
      };
    } else if (
      formData.password.status === InputStatus.Done &&
      ((inputName === 'confirmPassword' && value === newPasswordForm.password.value) ||
        (inputName === 'password' && value === newPasswordForm.confirmPassword.value))
    ) {
      formData.confirmPassword = {
        ...formData.confirmPassword,
        status: InputStatus.Done,
        error: '',
      };
    }
    setNewPasswordForm(formData);
  };

  const handleNextStep = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await findPasswordMiddleware.changePasssword(
        {
          usrNo: entities.usrNo,
          usrId: entities.usrId,
          password: newPasswordForm.password.value,
        },
        entities.token,
      );
      if (result.code === EnumRequestCode.SUCCESS) {
        onNextStep({
          snsList: result.entities.snsList,
        });
        setIsShowPopupSuccess(true);
      } else {
        addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
      }
    } catch (error) {
      console.warn(error);
      addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="gz-text-xl mb-1 font-medium text-title">
        {translate('pages.account.find_password.change_password.TITLE_1')}
      </div>
      <div className="gz-text-xl mb-4 font-medium text-title">
        {translate('pages.account.find_password.change_password.TITLE_2')}
      </div>
      <div className="gz-text-xs mb-4">{translate('pages.account.find_password.change_password.DESCRIPTION')}</div>
      <div className="gz-text-sm mb-1 font-light">
        {translate('pages.account.find_password.change_password.LABEL_USER_ID')}
      </div>
      <div className="gz-text-xs mb-3 font-medium">{entities.usrId}</div>
      <form name="form" onSubmit={handleNextStep}>
        <Input
          name="password"
          type="password"
          autoComplete="off"
          containerClassName="mb-4"
          placeholder={translate('pages.account.find_password.change_password.PLACEHOLDER_PASSWORD')}
          value={newPasswordForm.password.value}
          status={newPasswordForm.password.status}
          error={newPasswordForm.password.error}
          onChange={handleChangeInput}
          errorMessages={{
            required: translate('error.INVALID_PASSWORD'),
            special_char: translate('error.CONTAIN_INVALID_SPECIAL_CHARACTERS'),
            password: translate('error.INVALID_PASSWORD'),
          }}
        />
        <Input
          name="confirmPassword"
          type="password"
          autoComplete="off"
          placeholder={translate('pages.account.find_password.change_password.PLACEHOLDER_CONFIRM_PASSWORD')}
          value={newPasswordForm.confirmPassword.value}
          status={newPasswordForm.confirmPassword.status}
          error={newPasswordForm.confirmPassword.error}
          onChange={handleChangeInput}
          errorMessages={{
            required: translate('error.INVALID_PASSWORD'),
            special_char: translate('error.CONTAIN_INVALID_SPECIAL_CHARACTERS'),
            password: translate('error.INVALID_PASSWORD'),
            match_password: translate('error.INVALID_CONFIRM_PASSWORD'),
          }}
        />
        <ButtonNext type="submit" onClick={handleNextStep} loading={loading} disabled={!allValid(newPasswordForm)} />
      </form>
      <FindPasswordResult
        isShow={isShowPopupSuccess}
        onClose={handleClosePopupSuccess}
        entities={entities}
        key="find-password-result"
      />
    </>
  );
}

const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};

export default connect(null, mapDispatchToProps)(SetupNewPassword);

import { useState, useEffect } from 'react';

const useVideoPlayer = (videoElement: any, playRepeat: boolean = false) => {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    isReplayed: false,
    progress: 0,
    speed: 1,
    isMuted: false,
  });

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isReplayed: true,
      isPlaying: !playerState.isPlaying,
    });
  };

  const autoPlayVideo = () => {
    const promise = videoElement.current.play();
    if (promise !== undefined) {
      promise.catch((error: any) => {
        // Autoplay was prevented.
        videoElement.current.muted = true;
        videoElement.current.play();
      });
    }
  };

  useEffect(() => {
    if (!videoElement?.current) return;
    playerState.isPlaying ? autoPlayVideo() : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  const handleOnTimeUpdate = () => {
    if (!videoElement?.current) return;
    let progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
    if (Number.isNaN(progress)) progress = 0;
    if (progress !== 100) {
      setPlayerState({
        ...playerState,
        progress,
      });
    } else {
      setPlayerState({
        isPlaying: playerState.isPlaying && playRepeat,
        isReplayed: true,
        progress: 0,
        speed: 1,
        isMuted: false,
      });
      if (playerState.isPlaying && playRepeat) {
        videoElement.current.play();
      }
    }
  };

  const handleVideoProgress = (manualChange: number) => {
    if (!videoElement?.current) return;
    videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  };

  useEffect(() => {
    if (!videoElement?.current) return;
    playerState.isMuted ? (videoElement.current.muted = true) : (videoElement.current.muted = false);
  }, [playerState.isMuted, videoElement]);

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
  };
};

export default useVideoPlayer;

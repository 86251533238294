import { useTranslation } from 'react-i18next';

interface IMarketingPolicyProps {
  className?: string;
}

function MarketingPolicy(props: IMarketingPolicyProps): JSX.Element {
  const translate = useTranslation().t;

  return (
    <div className={props.className}>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('pages.terms_and_privacy.marketing_policy.CONTENT'),
        }}
      />
    </div>
  );
}

export default MarketingPolicy;

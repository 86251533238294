import { forwardRef, ReactElement, ReactNode, useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import ScrollContainer from 'react-indiana-drag-scroll';
import debounce from 'lodash/debounce';
import { useForwardRef } from 'src/hooks/use-forward-ref';

interface IDraggableScrollCustomProps {
  className?: string;
  hideScrollbars?: boolean;
  sticky?: boolean;
  vertical?: boolean;
  horizontal?: boolean;
  children: ReactNode;
}

const DraggableScrollCustom = forwardRef(
  (
    { className, hideScrollbars = false, sticky = false, vertical, horizontal, children }: IDraggableScrollCustomProps,
    ref: any,
  ): ReactElement => {
    const innerRef = useForwardRef<HTMLElement>(ref, null);

    useEffect(() => {
      const notToggleOverflow = innerRef.current === null || !sticky || !isIOS || !isMobile;
      if (notToggleOverflow) return;

      const debouncedValue = debounce(() => {
        if (notToggleOverflow) return;
        innerRef.current.style['overflow'] = 'auto';
      }, 300);

      const handleScroll = () => {
        if (notToggleOverflow) return;
        innerRef.current.style['overflow'] = 'hidden';
        debouncedValue();
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
      <ScrollContainer
        innerRef={innerRef}
        vertical={vertical}
        horizontal={horizontal}
        hideScrollbars={hideScrollbars}
        className={className}
      >
        {children}
      </ScrollContainer>
    );
  },
);

export default DraggableScrollCustom;

import { useTranslation } from 'react-i18next';
import { EnumPlayMode, EnumRoundScorePlayType, EnumSocialGameModeType, EnumValueBoolean } from 'src/constants/enum';
import { getPlayMode } from 'src/services/gdr-and-gs';
import { booleanValue } from 'src/services/utils';

interface IPlayModeProps {
  className?: string;
  playMode?: number;
  playModeSub?: number;
  playType?: EnumRoundScorePlayType;
  scorecard?: boolean;
  gameMode?: string;
  networkPlay?: EnumValueBoolean;
}

function PlayMode({
  className,
  playMode,
  playModeSub,
  playType,
  scorecard,
  gameMode,
  networkPlay = EnumValueBoolean.FALSE,
}: IPlayModeProps): JSX.Element {
  const translate = useTranslation().t;

  if (playMode == null) return <></>;

  const name = () => {
    if (booleanValue(networkPlay)) {
      return translate(
        `pages.record.${scorecard ? 'score_card' : 'play_mode'}.${
          gameMode === EnumSocialGameModeType.TOURNAMENT ? 'NETWORK_PLAY_TOURNAMENT' : 'NETWORK_PLAY'
        }`,
      );
    }
    const indexOfGameMode = Object.values(EnumSocialGameModeType).indexOf(gameMode as any);
    if (gameMode && indexOfGameMode >= 0) {
      //check social's data
      const mode = Object.keys(EnumSocialGameModeType)[indexOfGameMode];
      return translate(`pages.record.${scorecard ? 'score_card' : 'play_mode'}.${mode}`);
    }
    const valueMode = getPlayMode(playMode, playModeSub);
    const indexOfMode = Object.values(EnumPlayMode).indexOf(valueMode);
    const mode = Object.keys(EnumPlayMode)[indexOfMode];
    if (playType != null && playType !== EnumRoundScorePlayType.NORMAL && valueMode === EnumPlayMode.STROKE) {
      return translate(`pages.record.${scorecard ? 'score_card' : 'play_mode'}.TOURNAMENT`);
    }
    if (mode == null) return <></>;
    return translate(`pages.record.${scorecard ? 'score_card' : 'play_mode'}.${mode}`);
  };

  return <span className={className}>{name()}</span>;
}

export default PlayMode;

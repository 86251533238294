import { getObjectLocalStorage, saveObjectLocalStorage } from 'src/services/storage';
import { LOCALSTORAGE_IP_INFO } from 'src/constants/const';
import { MY_IP_INFO } from 'src/store/ipinfo';

export interface IpInfoState {
  fetch?: boolean;
  countryCode?: string;
  countryCode3Digits?: string;
  latitude?: number;
  longitude?: number;
}

const initialState = (): IpInfoState | null => ({ ...getObjectLocalStorage(LOCALSTORAGE_IP_INFO) });

export function ipInfoReducer(state = initialState(), action: any) {
  switch (action.type) {
    case MY_IP_INFO:
      const newState = {
        ...state,
        ...action.payload,
      };
      saveObjectLocalStorage(LOCALSTORAGE_IP_INFO, {
        ...newState,
        fetch: undefined,
      });
      return newState;
    default:
      return state;
  }
}

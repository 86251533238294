import { useTranslation } from 'react-i18next';
import { MAX_PARTNER_IMAGES } from 'src/constants/const';
import { EnumNetworkPlayNetGameType, EnumValueBoolean } from 'src/constants/enum';
import { IPartner } from 'src/models/record';
import { booleanValue } from 'src/services/utils';
import ImageGroup from 'src/view/commons/customs/ImageGroup';

interface IPartnerListProps {
  partnerList?: IPartner[];
  partnerCnt?: number;
  networkPlay?: EnumValueBoolean;
  netGameType?: EnumNetworkPlayNetGameType;
  netGameTeamNum?: number;
}

function PartnerList({
  partnerList = [],
  partnerCnt = 0,
  networkPlay,
  netGameType,
  netGameTeamNum = 0,
}: IPartnerListProps) {
  const translate = useTranslation().t;

  if (booleanValue(networkPlay) && netGameType === EnumNetworkPlayNetGameType.TEAM) {
    return (
      <div className="gz-text-xsm mt-[16px] text-sub-body">
        {translate('pages.record.information.NETWORK_PLAY_WITH_OTHER_TEAMS', { count: netGameTeamNum })}
      </div>
    );
  }

  if (partnerCnt === 0) return <></>;

  const displayedImageCount = partnerCnt > MAX_PARTNER_IMAGES ? MAX_PARTNER_IMAGES - 1 : MAX_PARTNER_IMAGES;
  const displayedPartnerList = partnerList.slice(0, displayedImageCount);

  return (
    <div className="mt-[16px] flex items-start">
      <div className="flex-shrink-0">
        <ImageGroup
          images={partnerList.map((partner) => partner.usrNickImage)}
          count={partnerCnt}
          maxImage={MAX_PARTNER_IMAGES}
        />
      </div>
      <div className="ml-[4px] text-sub-body">
        <span className="gz-text-tiny font-bold">
          {displayedPartnerList.map((partner) => partner.usrNickname).join(', ')}
        </span>
        <span className="gz-text-xsm ml-[4px]">
          {partnerCnt > displayedPartnerList.length
            ? translate('pages.record.information.PLAY_WITH_OTHERS', {
                count: partnerCnt - displayedPartnerList.length,
              })
            : translate('pages.record.information.PLAY_WITH_PARTNERS')}
        </span>
      </div>
    </div>
  );
}

export default PartnerList;

/* eslint-disable no-console */
import { useEffect } from 'react';
import { useLocation, matchRoutes, useSearchParams, Location } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { countries } from 'country-data';
import { history } from 'src/services/history';
import {
  CT_ENV_SITE,
  locales,
  LOCAL_STORAGE_FCM_TOKEN,
  mobileAction,
  mobileMessage,
  mobilePermissionResults,
  pathConstants,
  LOCALSTORAGE_SETTING_INFO,
  searchParamsContants,
  mapGlobalLangToInappLang,
  LOCAL_STORAGE_IS_NATIVE_APP,
  LOCALSTORAGE_USER_INFO,
} from 'src/constants/const';
import { EnumSaveMediaType } from 'src/constants/enum';
import { GetMessageWebview, sendDataToApp } from 'src/hooks/webview';
import { webviewActions } from 'src/store/webview/action';
import { convertLangCodeToCountryCode, setFontFamily } from 'src/services/global';
import { EnumFeedType } from 'src/constants/enum/feed.enum';
import { settingAction, settingDispatch } from 'src/store/setting/action';
import { getObjectLocalStorage, saveObjectLocalStorage } from 'src/services/storage';
import { notificationActions } from 'src/store/notification';
import { NotificationType } from 'src/models';
import { account, mainAction } from 'src/store/home';
import { useScrollToTop } from 'src/hooks/scroll-to-top';
import { useCalculateFullScreen } from 'src/hooks/use-calculate-fullscreen';
import { useGeoLocation } from 'src/hooks/use-geo-location';
import { useFirebase } from 'src/hooks/use-firebase';
import { firebaseInstance } from 'src/init-firebase';
import { mapCategoryToPath } from 'src/constants/const/firebase.constants';
import { getSessionId, saveSessionId } from 'src/requests/token';
import { RootState, useAppDispatch } from 'src/store';
import { EnumNotificationPositionType } from 'src/constants/enum/notification.enum';
import { recordsAction } from 'src/store/records';

const unchangedLanguagePages = [pathConstants.ROULETTE];

export const useInitializeApp = () => {
  useScrollToTop();
  useCalculateFullScreen();
  useFirebase();
  const locationRoute: Location = useLocation();
  const dispatch = useAppDispatch();
  const { t: translate, i18n: configLang } = useTranslation();
  const { countryCode: geoCountryCode } = useGeoLocation();
  const [params] = useSearchParams();
  const langParam: string = params.get(searchParamsContants.LANGUAGE) ?? '';
  const sessionIdParam: string = params.get(searchParamsContants.SESSION_ID) ?? '';
  const isValidLangParam = !!mapGlobalLangToInappLang[langParam];
  const settingInfo = useSelector((state: RootState) => state.settingInfoReducer.settingInfo);
  const isLoggedIn = useSelector((state: RootState) => state.authentication.isLoggedIn);
  const userInfo = useSelector((state: RootState) => state.mainInfoReducer.userInfo);
  const webviewReducer = useSelector((state: RootState) => state.webviewReducer);

  const { language: appLanguage, isLoggedIn: isWebvewLoggedIn, isNativeApp } = webviewReducer;

  useEffect(() => {
    if (process.env.NODE_ENV !== CT_ENV_SITE.develop) {
      console.log = () => {};
      console.debug = () => {};
      console.info = () => {};
      console.warn = () => {};
    }
    const sessionId = getSessionId();
    const isNativeApp = !!localStorage.getItem(LOCAL_STORAGE_IS_NATIVE_APP);
    if (!!sessionIdParam && isNativeApp) {
      saveSessionId(sessionIdParam);
    } else if (!sessionId) {
      localStorage.removeItem(LOCALSTORAGE_USER_INFO);
    }

    const handleLoaded = () => sendDataToApp({ action: mobileAction.WEBVIEW_LOADED });
    if (document.readyState === 'complete') {
      //check case safari mobile
      handleLoaded();
    } else {
      window.addEventListener('load', handleLoaded);
    }
    return () => window.removeEventListener('load', handleLoaded);
  }, []);

  /*
    Fetching user's information on first load
  */
  useEffect(() => {
    if (!isLoggedIn) return;
    dispatch(mainAction.getMyInformation());
    dispatch(settingAction.settingInformation());
  }, []);

  useEffect(() => {
    if (!isLoggedIn && userInfo) {
      dispatch(account.info(null));
    }
  }, [isLoggedIn, userInfo]);

  useEffect(() => {
    if (mapCategoryToPath[locationRoute.pathname] != null) {
      firebaseInstance.trackPageView(mapCategoryToPath[locationRoute.pathname]);
    }
  }, [locationRoute.pathname]);

  useEffect(() => {
    if (unchangedLanguagePages.includes(locationRoute.pathname)) return;
    if (settingInfo.langCd) {
      sendDataToApp({ action: mobileAction.CHANGE_LANGUAGE, language: settingInfo.langCd });
      configLang.changeLanguage(settingInfo.langCd);
      // Update font family for language
      setFontFamily(settingInfo.langCd);
    }
  }, [settingInfo.langCd]);

  useEffect(() => {
    if (isValidLangParam) return;
    if (!!appLanguage && !isWebvewLoggedIn && !settingInfo.langCd) {
      dispatch(
        settingDispatch.info({
          langCd: appLanguage,
        }),
      );
    }
  }, [appLanguage, isWebvewLoggedIn]);

  /*
    Get user's language based on their Browser setting s or IP address if users opens app first
    After that, store the language in localstorage and we will display app by this language
    In case searchParam language is provided and user have NOT logged in, change current language to it
  */
  useEffect(() => {
    if (isLoggedIn) return;
    const localSettingInfo = getObjectLocalStorage(LOCALSTORAGE_SETTING_INFO);
    let countryCd;
    let langCd;
    if (isValidLangParam) {
      langCd = langParam;
    } else if (!isNativeApp && !localSettingInfo?.countryCd) {
      const langDefault =
        convertLangCodeToCountryCode(navigator.language.split('-')[0]) || geoCountryCode?.toLocaleLowerCase();
      const isHaveLang = locales.map((locale) => locale.value).includes(langDefault);
      langCd = isHaveLang ? langDefault : undefined;
    }
    if (geoCountryCode && !localSettingInfo?.countryCd) {
      countryCd = countries[geoCountryCode].alpha3;
    }

    // Update settingInfo as new data
    const newSetting: any = {};
    if (langCd) {
      newSetting.langCd = langCd;
    }
    if (countryCd) {
      newSetting.countryCd = countryCd;
    }
    if (langCd || countryCd) {
      dispatch(settingDispatch.info(newSetting));
    }
  }, [geoCountryCode, isNativeApp, isLoggedIn]);

  /*
      Firebase logevent function
      Track screen by "pathConstants key" when accessing a route
    */
  useEffect(() => {
    const routes = Object.keys(pathConstants).map((key) => {
      let path = pathConstants[key];
      if (typeof pathConstants[key] === 'function') path = pathConstants[key]();
      return { path };
    });
    const route = matchRoutes(routes, locationRoute);
    if (!route || !route[0]) return;
    const matchRoute = route[0];
    const pageKey = Object.keys(pathConstants).find((key) => {
      if (typeof pathConstants[key] === 'function') return pathConstants[key]() === matchRoute.route.path;
      return pathConstants[key] === matchRoute.route.path;
    });
    if (!pageKey) return;
  }, [locationRoute]);

  const detectNativeApp = (data: any) => {
    const dataMobile = {
      ...data,
      isNativeApp: true,
      typeNativeApp: data.platform,
    };
    if (!!data?.fcmToken) {
      saveObjectLocalStorage(LOCAL_STORAGE_FCM_TOKEN, data?.fcmToken);
    }
    dispatch(webviewActions.saveMobileInfo(dataMobile));
  };

  const updateMobilePermission = (data: any) => {
    const hasPermission = data?.info === mobilePermissionResults.GRANTED;
    dispatch(
      webviewActions.setMobilePermission({
        nativeAppPermission: hasPermission,
        isLocationEnabled: data?.isLocationEnabled,
      }),
    );
  };

  const updateLocationMobile = (data: any) => {
    if (!!data?.info) {
      dispatch(webviewActions.updateMobileLocation(data?.info));
    }
  };

  const handleCreateFeed = (data: any) => {
    if (!!data?.info?.listMedia) {
      history.push(pathConstants.FEED_CREATE_ITEM('1', EnumFeedType.SO), { mediaUpload: data?.info?.listMedia });
    } else if (data?.info?.videoData?.feedType === EnumFeedType.TRAINING_MODE) {
      const state = {
        ...data?.info?.videoData,
        info: {
          filePath: data?.info?.filePath,
          saveFileName: data?.info?.saveFileName,
          notEdited: data?.info?.notEdited,
        },
      };
      history.push(pathConstants.FEED_TRAINING_MODE_CREATE, state);
    } else {
      history.push(pathConstants.FEED_CREATE_ITEM(data?.info?.videoData?.nasmoId, data?.info?.videoData?.feedType), {
        info: data?.info,
      });
    }
  };

  const getMobileWifiInfo = (data: any) => {
    dispatch(settingDispatch?.updateWifiInfo(data?.wifiEnable));
  };

  const handleSaveVideoMessage = (data: any) => {
    if (data?.type === EnumSaveMediaType.VIDEO) {
      data?.success
        ? dispatch(notificationActions.addNotification(translate('mess.SAVE_VIDEO_SUCCESS'), NotificationType.SUCCESS))
        : dispatch(notificationActions.addNotification(translate('mess.SAVE_VIDEO_ERROR'), NotificationType.DANGER));
    } else {
      data?.success
        ? dispatch(
            notificationActions.addNotification(
              translate('pages.record.score_card.SAVE_SCORECARD_SUCCESSFULLY'),
              NotificationType.SUCCESS,
            ),
          )
        : dispatch(
            notificationActions.addNotification(
              translate('pages.record.score_card.SAVE_SCORECARD_FAILED'),
              NotificationType.DANGER,
            ),
          );
    }
  };

  const handleAfterAnalysisVideo = (data: any) => {
    if (data?.success) {
      const { type = '', analysisId = '' } = data;
      dispatch(recordsAction.updateAnalysisId(data));
      history.push(pathConstants.RECORD_SWING_AI_ANALYSIS(type, analysisId));
    } else
      dispatch(
        notificationActions.addNotification(
          translate('error.ANALYSIS_FAILED'),
          NotificationType.DANGER,
          undefined,
          EnumNotificationPositionType.TOP,
        ),
      );
  };

  GetMessageWebview(mobileMessage.DEVICE, detectNativeApp);
  GetMessageWebview(mobileMessage.NATIVEAPP_PERMISSION, updateMobilePermission);
  GetMessageWebview(mobileMessage.NATIVEAPP_LOCATION, updateLocationMobile);
  GetMessageWebview(mobileMessage.MOBILE_FILE_INFO, handleCreateFeed);
  GetMessageWebview(mobileMessage.WIFI_ENABLE, getMobileWifiInfo);
  GetMessageWebview(mobileMessage.SAVE_MEDIA_INFO, handleSaveVideoMessage);
  GetMessageWebview(mobileMessage.ON_RECEIVE_MESSAGE, () => dispatch(account.setHasNewNotification(true)));
  GetMessageWebview(mobileMessage.SHOW_TOAST_AFTER_SIGN_UP, () =>
    dispatch(notificationActions.addNotification(translate('mess.SETTING_COMPLETE_AFTER_SIGNUP'))),
  );
  GetMessageWebview(mobileMessage.ON_FINISH_AI_ANALYSIS, handleAfterAnalysisVideo);
};

import { ICourseIdParams, IInfoListParams, MyCourseParams } from './prop-state.type';
import axios from 'src/requests';
import { SearchListParams } from 'src/requests/api/course/prop-state.type';

const getNewCourseList = async (params?: IInfoListParams) => {
  const response = await axios.get('/course/getNewCourseList', { params });
  return response.data.data;
};

const getRecommendCourseList = async (params?: IInfoListParams) => {
  const response = await axios.get('/course/getRecommendCourseList', { params });
  return response.data.data;
};

const getRoundCourseList = async () => {
  const response = await axios.get('/course/getRoundCourseList');
  return response.data.data;
};

const getSearchListCourse = async (params: SearchListParams) => {
  const response = await axios.get('/course/getSearchList', { params });
  return response.data.data;
};

const getFavoriteCourse = async () => {
  const response = await axios.get('/course/getInterestList');
  return response.data.data;
};

const getMyCourse = async (params: MyCourseParams) => {
  const response = await axios.get('/course/getMyRoundCourseList', { params });
  return response.data.data;
};

const favoriteCourse = async (typeNo: string) => {
  const response = await axios.post('/course/regInterest', { typeNo });
  return response.data.data;
};

const getCourseIntro = async (params: ICourseIdParams) => {
  const response = await axios.get('/course/getDetailInfo', { params });
  return response.data.data;
};

const getHoleListInformation = async (params: ICourseIdParams) => {
  const response = await axios.get('/course/getHoleList', { params });
  return response.data.data;
};

const getCourseCompetition = async (params: ICourseIdParams) => {
  const response = await axios.get('/course/getGlfList', { params });
  return response.data.data;
};

const getCourseLeaderBoard = async (params: ICourseIdParams) => {
  const response = await axios.get('/course/getLeaderBoardList', { params });
  return response.data.data;
};

export const courseMiddleware = {
  getNewCourseList,
  getRecommendCourseList,
  getRoundCourseList,
  getSearchListCourse,
  favoriteCourse,
  getFavoriteCourse,
  getMyCourse,
  getCourseIntro,
  getHoleListInformation,
  getCourseCompetition,
  getCourseLeaderBoard,
};

import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { history } from 'src/services/history';
import { NotificationType } from 'src/models';
import { notificationActions } from 'src/store/notification';
import { pathConstants } from 'src/constants/const';
import { twMerge } from 'tailwind-merge';
import { useCallRequestForGuest } from 'src/hooks/use-call-request-for-guest';
import { useLocation } from 'react-router-dom';
import { firebaseInstance } from 'src/init-firebase';
import { pageViewName } from 'src/constants/const/firebase.constants';
import { RootState, useAppDispatch } from 'src/store';
import { ITrendingStoresState, trendingStoresMiddleware } from 'src/store/trending-stores';

interface IPageColumnProps {
  className?: string;
  addNotification: (message: string, type: NotificationType) => void;
}

function TrendingStores(props: IPageColumnProps): JSX.Element {
  const translate = useTranslation().t;
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const trendingStoresState: ITrendingStoresState = useSelector((state: RootState) => state.trendingStoresReducer);
  const sidebarDesktopRef = useRef<HTMLDivElement>(null);

  useCallRequestForGuest(fetchData, true);

  useEffect(() => {
    let desktopDisplay;
    if (sidebarDesktopRef.current) {
      desktopDisplay = getComputedStyle(sidebarDesktopRef.current).display;
    }
    if (desktopDisplay === 'block' && !!trendingStoresState.data?.length) {
      firebaseInstance.trackPageView(pageViewName.TRENDINGLOCATIONS);
    }
  }, [pathname, trendingStoresState.data?.length]);

  async function fetchData() {
    dispatch(trendingStoresMiddleware.getTrendingStoresList());
  }

  const skeleton = () => {
    return (
      <>
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="[&:not(:last-child)]:border-color-gray-20 p-[16px] last:-mb-[8px] [&:not(:last-child)]:border-b-[1px]"
          >
            <div className="h-[18px] w-full max-w-[300px] animate-pulse rounded-[4px] bg-disable" />
            <div className="mt-[8px] h-[14px] w-1/2 max-w-[150px] animate-pulse rounded-[4px] bg-disable" />
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div
        ref={sidebarDesktopRef}
        className={twMerge('sticky top-0 h-[100vh] flex-shrink-0 bg-gz-white', props.className)}
      >
        <div className="p-[16px]">
          <div className="rounded-[8px] bg-bg py-[16px]">
            <h2 className="p-[0_16px_4px] text-[17px] font-medium leading-[25px] text-black">
              {translate('layouts.auth.page_column.TITLE')}
            </h2>

            {!trendingStoresState.loading
              ? trendingStoresState.data.map((item) => (
                  <div
                    key={`page-colum-${item.rgnNo}`}
                    className={twMerge(
                      'min-w-0 p-[16px] last:-mb-[8px]',
                      '[&:not(:last-child)]:border-color-gray-20 [&:not(:last-child)]:border-b-[1px]',
                    )}
                  >
                    <div
                      className={twMerge(
                        'gz-text-md mb-[5px] cursor-pointer text-title',
                        'min-w-0 overflow-hidden text-ellipsis whitespace-nowrap',
                      )}
                      onClick={() => history.push(pathConstants.STORE_DETAIL(item.rgnNo?.toString()))}
                    >
                      {item.rgnNm}
                    </div>
                    <div className="text-[12px] leading-[18px] text-sub-body">
                      <div className="inline">
                        {translate('layouts.auth.page_column.POST', { value: item.feedCnt })}
                      </div>
                      <div className="ml-[10px] inline">
                        {translate('layouts.auth.page_column.CUSTOM', { value: item.mySiteCnt })}
                      </div>
                    </div>
                  </div>
                ))
              : skeleton()}
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};

export default connect(null, mapDispatchToProps)(TrendingStores);

import dayjs from 'dayjs';
import { groupBy, remove } from 'lodash';
import { API_TIMEZONE, formatDate } from 'src/constants/const';
import { EnumPlayMode, EnumTypeClub } from 'src/constants/enum';
import { IObjectData } from 'src/requests/api/common/prop-state.type';
import { DateTime } from 'src/services/datetime';
import { getKeyObjectByValue } from 'src/services/utils';

export const mappingClubCodeAndName: any = {
  DR: '1',
  W1: '1',
  W2: '2',
  W3: '3',
  W4: '4',
  W5: '5',
  W6: '6',
  W7: '7',
  I1: '8',
  I2: '9',
  I3: '10',
  I4: '11',
  I5: '12',
  I6: '13',
  I7: '14',
  I8: '15',
  I9: '16',
  PW: '17',
  AW: '18',
  SW: '19',
  PT: '20',
  PUTTER: '20',
  W8: '21',
  W9: '22',
  U1: '23',
  U2: '24',
  U3: '25',
  U4: '26',
  U5: '27',
  U6: '28',
  U7: '29',
  LW: '30',
};

export const getClubName = (clubCode: string): string => {
  return getKeyObjectByValue(mappingClubCodeAndName, clubCode);
};

export const getTypeClub = (clubNo: string): any => {
  let type;
  switch (clubNo) {
    case '1':
      type = EnumTypeClub.DRIVER;
      break;
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '7':
    case '21':
    case '22':
      type = EnumTypeClub.WOOD;
      break;
    case '23':
    case '24':
    case '25':
    case '26':
    case '27':
    case '28':
    case '29':
      type = EnumTypeClub.UTILITY;
      break;
    case '8':
    case '9':
    case '10':
    case '11':
    case '12':
    case '13':
    case '14':
    case '15':
    case '16':
      type = EnumTypeClub.IRON;
      break;
    case '17':
    case '18':
    case '19':
    case '30':
      type = EnumTypeClub.WEDGES;
      break;
    case '20':
      type = EnumTypeClub.PUTTER;
      break;
    default:
      break;
  }
  return type;
};

export const getPlayMode = (playMode: number, playModeSub?: number): EnumPlayMode => {
  const valueMode = playModeSub != null && playModeSub > 0 ? `${playMode}-${playModeSub}` : `${playMode}`;
  return valueMode as EnumPlayMode;
};

interface IReverseMonthOptions {
  formatDate?: string;
  monthDisplay?: number;
  dataTimezone?: string;
}

const initializedOptions: IReverseMonthOptions = {
  formatDate: formatDate.SHORT,
  dataTimezone: API_TIMEZONE,
};

export const reverseMonthObject = (
  respondData: any[],
  field: string,
  dataDefault: IObjectData<Record<string, any[]>>,
  defaultOptions: IReverseMonthOptions = initializedOptions,
) => {
  const dataInfo: any = [];
  const data = { ...dataDefault };
  const options = {
    ...initializedOptions,
    ...defaultOptions,
  };
  respondData.forEach((element: any, index: number) => {
    // don't collect data if it is more than N months
    const monthsAgo = dayjs().diff(element[field], 'month', true);
    if (options.monthDisplay && monthsAgo > options.monthDisplay) {
      return;
    }
    // Update Timezone
    dataInfo[index] = {
      ...respondData[index],
      [field]: DateTime.getLocaleDatetime(element[field], options.dataTimezone),
    };
  });
  // group response data follow YEAR-MONTH
  const itemGroup = groupBy(dataInfo, (item) => dayjs(item[field]).format(options.formatDate));
  const keyMonth = Object.keys(itemGroup);
  // Merge MONTH and "sort" when load more
  const listKey = Array.from(new Set([...dataDefault.keySort, ...keyMonth]));
  listKey.sort((a: string, b: string) => {
    return dayjs(b).diff(a, 'day');
  });
  // Merge data when load more
  keyMonth.forEach((key: string) => {
    if (data.values[key]) {
      data.values[key] = [...data.values[key], ...itemGroup[key]];
    } else {
      data.values = {
        ...data.values,
        [key]: [...itemGroup[key]],
      };
    }
  });
  return {
    ...data,
    keySort: listKey,
    length: data.length + respondData.length,
  };
};

export const removeItemMonthObject = (
  dataDefault: IObjectData<Record<string, any[]>>,
  videoInfo?: any,
  fieldId: string = 'nasmoId',
  field: string = 'statDate',
  formatDate: string = 'YYYY-M',
  isNextItem?: boolean,
) => {
  const dateItem = dayjs(videoInfo[field]).format(formatDate);
  // Remove Item
  remove(dataDefault?.values[dateItem], function (item: any) {
    return item[fieldId] === videoInfo[fieldId];
  });
  if (dataDefault?.values[dateItem].length === 0) {
    remove(dataDefault?.keySort, function (item: any) {
      return item === dateItem;
    });
  }
  // Update Item Active
  const newDate = !isNextItem ? dataDefault?.keySort[0] : dateItem;
  const idActive = dataDefault?.values[newDate][0][fieldId];
  return [dataDefault, idActive];
};

export const isBetterValue = (comparedValue: number, comparingValue: number, sign?: string) => {
  if (comparedValue == null || comparingValue == null || !sign) return false;
  if (sign === '>') {
    return comparedValue > comparingValue;
  } else if (sign === '<') {
    return comparedValue < comparingValue;
  }
  return false;
};

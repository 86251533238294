import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  classDialog?: string;
  threshold?: number;
  isOpen?: boolean;
  hideTouchBar?: boolean;
  children?: JSX.Element;
  onClose?: () => void;
}

function BottomSheet({
  className,
  classDialog,
  threshold,
  isOpen,
  hideTouchBar,
  children,
  onClose,
}: Props): JSX.Element {
  const [size, setSize] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const currentThreshold = threshold ?? 0.5;

  useEffect(() => {
    if (!isOpen) {
      setSize(0);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose?.call(null);
  };

  const handler = (mouseDownEvent: any) => {
    const startSize = size;
    const startPosition = mouseDownEvent.pageY;
    let distance = 0;

    function onMouseMove(mouseMoveEvent: any) {
      mouseMoveEvent.preventDefault();
      distance = startSize - startPosition + mouseMoveEvent.pageY;
      onMove(distance);
    }

    function onMouseUp() {
      document.body.removeEventListener('mousemove', onMouseMove);
      onMoveEnd(distance);
    }
    document.body.addEventListener('mousemove', onMouseMove, { passive: false });
    document.body.addEventListener('mouseup', onMouseUp, { once: true });
  };

  const mobileHandler = (touchStartEvent: any) => {
    const startSize = size;
    const startPosition = touchStartEvent.touches[0].pageY;
    let distance = 0;

    function onTouchMove(touchMoveEvent: any) {
      if (touchMoveEvent.cancelable) {
        touchMoveEvent.preventDefault();
      }
      distance = startSize - startPosition + touchMoveEvent.touches[0].pageY;
      onMove(distance);
    }

    function onTouchEnd() {
      document.body.removeEventListener('touchmove', onTouchMove);
      onMoveEnd(distance);
    }
    document.body.addEventListener('touchmove', onTouchMove, { passive: false });
    document.body.addEventListener('touchend', onTouchEnd, { once: true });
  };

  const onMove = (distance: number) => {
    setSize(() => (distance < 0 ? 0 : distance));
  };

  const onMoveEnd = (distance: number) => {
    const offsetHeight = ref.current?.offsetHeight ?? 0;
    if (distance > offsetHeight * currentThreshold) {
      onClose?.call(null);
    } else {
      setSize(0);
    }
  };

  return (
    <Transition show={isOpen}>
      <Dialog className={twMerge('relative z-10', className)} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0">
          <div className="flex min-h-full items-end justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="ease-out-in duration-300"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
            >
              <Dialog.Panel
                ref={ref}
                className={twMerge(
                  'max-h-full w-full transform overflow-hidden rounded-t-[24px]',
                  'text-left align-middle shadow-xl',
                  'bg-gz-white dark:bg-dark-16',
                  classDialog,
                )}
                style={{ marginBottom: -size }}
              >
                {!hideTouchBar && (
                  <div
                    className="flex items-center justify-center py-[8px]"
                    onMouseDown={handler}
                    onTouchStart={mobileHandler}
                  >
                    <div className="h-[4px] w-[64px] rounded-[100px] bg-gray-10 dark:bg-gray-50" />
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default BottomSheet;

export enum EnumAIResultSequence {
  TORSO = 'Torso',
  PELVIS = 'Pelvis',
  HANDS = 'Hands',
}

export enum EnumAIRecordType {
  GS = 'GS',
  GDR = 'GDR',
  PRACTICE = 'PRACTICE',
}

export enum EnumSwingAIClubType {
  DRIVER = 'Driver',
  IRON = 'Iron',
}

export enum EnumSwingAIGender {
  MALE = 'Male',
  FEMALE = 'FeMale',
}

export enum EnumBodyPart {
  PARIETAL,
  LEFT_EAR,
  RIGHT_EAR,
  LEFT_SHOULDER,
  RIGHT_SHOULDER,
  LEFT_ELBOW,
  RIGHT_ELBOW,
  LEFT_WRIST,
  RIGHT_WRIST,
  LEFT_HIP,
  RIGHT_HIP,
  LEFT_KNEE,
  RIGHT_KNEE,
  LEFT_ANKLE,
  RIGHT_ANKLE,
  LEFT_HEEL,
  RIGHT_HEEL,
  LEFT_LITTLE_TOE,
  RIGHT_LITTLE_TOE,
  SHAFT_START,
  SHAFT_MIDDLE,
  SHAFT_END,
}

import { useCallback, useMemo, useState } from 'react';

import CheckUserId from './CheckUserId';
import VerifyPhoneOrEmail from './VerifyPhoneOrEmail';
import SetupNewPassword from './SetupNewPassword';

function FindPassword(): JSX.Element {
  const [currentStep, setCurrentStep] = useState(0);
  const [entities, setEntities] = useState({
    usrNo: '',
    usrId: '',
    cellphone: '',
    email: '',
    token: '',
    snsList: [],
  });

  const handleNextStep = useCallback(
    (entitiesRes?: any) => {
      if (entitiesRes) {
        setEntities({ ...entities, ...entitiesRes });
      }
      if (currentStep === 2) return;
      setCurrentStep(currentStep + 1);
    },

    [currentStep, entities],
  );

  const steps = useMemo(
    () => [
      <CheckUserId onNextStep={handleNextStep} key="id-input" />,
      <VerifyPhoneOrEmail onNextStep={handleNextStep} entities={entities} key="verify-phone-or-email" />,
      <SetupNewPassword onNextStep={handleNextStep} entities={entities} key="setup-new-password" />,
    ],
    [handleNextStep, entities],
  );

  return <div className="mx-auto max-w-[576px] pt-[48px] md:pt-[12vh]">{steps[currentStep]}</div>;
}

export default FindPassword;

import { AnyAction } from '@reduxjs/toolkit';
import { IComment } from 'src/models/comment';
import {
  COMMENT_ACTION_ADD_COMMENT_LIST,
  COMMENT_ACTION_CLOSE_REPLY,
  COMMENT_ACTION_START_REPLY,
  COMMENT_ACTION_RESET_COMMENT_STATE,
  COMMENT_ACTION_NEW_ITEM,
} from 'src/store/comments';

export interface ICommentsState {
  comments: IComment[];
  newComment: IComment | null;
  commentNo: number;
  isReplying: boolean;
  replyToUsrnm: string;
  replyToUsrno: string;
}

const initialState: ICommentsState = {
  comments: [],
  newComment: null,
  commentNo: 0,
  isReplying: false,
  replyToUsrnm: '',
  replyToUsrno: '',
};

export const commentsReducer = (state: ICommentsState = initialState, action: AnyAction) => {
  switch (action.type) {
    case COMMENT_ACTION_ADD_COMMENT_LIST: {
      return {
        ...state,
        comments: action.payload,
      };
    }
    case COMMENT_ACTION_NEW_ITEM: {
      return {
        ...state,
        newComment: action.payload.newComment,
      };
    }
    case COMMENT_ACTION_START_REPLY: {
      return {
        ...state,
        commentNo: action.payload.commentNo,
        replyToUsrnm: action.payload.usrName,
        replyToUsrno: action.payload.usrNo,
        isReplying: true,
      };
    }
    case COMMENT_ACTION_CLOSE_REPLY: {
      return {
        ...state,
        isReplying: false,
        commentNo: 0,
        replyToUsrnm: '',
        replyToUsrno: '',
      };
    }
    case COMMENT_ACTION_RESET_COMMENT_STATE:
      return initialState;
    default:
      return state;
  }
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { IPuttingClubResponse, ITmResponse } from 'src/requests/api/record/prop-state.type';
import { constUnit, pathConstants } from 'src/constants/const';
import { history } from 'src/services/history';
import { fixDecimal } from 'src/services/utils';

interface IProps {
  classWrapper?: string;
  item?: ITmResponse;
  isDetail?: boolean;
}
const PuttingItem: React.FC<IProps> = React.memo(({ item, isDetail, classWrapper }) => {
  const translate = useTranslation().t;

  const renderItem = (arrayData: IPuttingClubResponse[]) => {
    const itemMax = Math.max(...arrayData.map((el: IPuttingClubResponse) => el.holeInRate));
    return arrayData.map((element: IPuttingClubResponse, index: number) => {
      return (
        <div
          key={`club-shot-${index}`}
          className="gz-text-xsm flex items-center justify-between [&:not(:last-child)]:mb-[12px]"
        >
          <div className="w-[56px] shrink-0">
            {fixDecimal(element.toPin, false, 1)} {item?.distanceUnit}
          </div>
          <div className="ml-[12px] mr-[14px] w-full">
            <div
              className={twMerge(
                'h-[8px] rounded-r-[8px]',
                itemMax === element.holeInRate ? 'bg-primary-75' : 'bg-gray-20',
              )}
              style={{ width: `${element.holeInRate}%` }}
            />
          </div>
          <div className="flex w-[70px] flex-shrink-0 items-center justify-end">
            <span>
              {element.holeInRate}
              {constUnit.PERCENT}
            </span>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className={twMerge('w-full rounded-[16px] p-[18px] shadow-xsm', !isDetail ? 'cursor-pointer' : '', classWrapper)}
      onClick={() => history.push(pathConstants.RECORD_DETAIL_TM_PUTTING(item?.tsCode))}
    >
      <div className="mb-[24px] flex justify-around">
        <div className="text-center">
          <div className="gz-text-md mb-[8px] font-bold">{item?.tsPutting?.practiceBallCount ?? 0}</div>
          <div className="gz-text-xsm text-sub-body">{translate('pages.record.type.training_mode.PRACTICE_BALLS')}</div>
        </div>
        <div className="text-center">
          <div className="gz-text-md mb-[8px] font-bold">
            {item?.tsPutting?.holeInRate ?? 0}
            {constUnit.PERCENT}
          </div>
          <div className="gz-text-xsm text-sub-body">{translate('pages.record.type.training_mode.HOLE_IN_RATE')}</div>
        </div>
      </div>
      {item?.tsPutting?.puttings && item?.tsPutting?.puttings.length > 0 && renderItem(item.tsPutting.puttings)}
    </div>
  );
});

export default PuttingItem;

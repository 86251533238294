import { EnumAIRecordType } from 'src/constants/enum';
import axios from 'src/requests';
import {
  IAIAnalysisResultBody,
  IDataRes,
  IAIVideoContentResponse,
  IAILessonContentResponse,
} from 'src/requests/api/AI/prop-state.type';

const getAIAnalysisResult = async (body: IAIAnalysisResultBody) => {
  const response = await axios.post('/ai-analysis/detail', body);
  return response.data.data;
};

const getAIVideoContent = async (
  analysisId: string,
  diagnoseId: string,
  content: string,
  type = EnumAIRecordType.GS,
) => {
  const response = await axios.post<IDataRes<IAIVideoContentResponse>>(`/ai-analysis/video/${content}`, {
    analysisId,
    diagnoseId,
    type,
  });
  return response.data.data;
};

const getAILessonContent = async (
  analysisId: string,
  diagnoseId: string,
  content: string,
  type: string = EnumAIRecordType.GS,
) => {
  const response = await axios.post<IDataRes<IAILessonContentResponse[]>>(`/ai-analysis/lesson/${content}`, {
    analysisId,
    diagnoseId,
    type,
  });
  return response.data.data;
};

export const AIRequest = {
  getAIAnalysisResult,
  getAIVideoContent,
  getAILessonContent,
};

import { useTranslation } from 'react-i18next';
import { pathConstants } from 'src/constants/const';
import { eventName } from 'src/constants/const/firebase.constants';
import { EnumRankLevel } from 'src/constants/enum';
import { firebaseInstance } from 'src/init-firebase';
import { IDataRankingDetailResponse } from 'src/requests/api/profile/prop-state.type';
import { history } from 'src/services/history';
import { fixDecimal, formatNumberAbbreviation, getFlagByCountryCd, numberWithSign } from 'src/services/utils';
import AvatarImage from 'src/view/commons/customs/AvatarImage';
import ImageCustom from 'src/view/commons/customs/Image';
import LeaderboardRank from 'src/view/commons/customs/LeaderboardRank';
import StatusChangeRanking from 'src/view/pages/home/commons/StatusChangeRanking';
import { twMerge } from 'tailwind-merge';

interface IRankingRowProps {
  data: IDataRankingDetailResponse;
  isMyDataRanking?: boolean;
  rankingField: number;
  sameRank?: boolean;
  isBestScore?: boolean;
}
export const RankingRow: React.FC<IRankingRowProps> = ({
  data,
  isMyDataRanking = false,
  rankingField,
  sameRank = false,
  isBestScore,
}) => {
  const translate = useTranslation().t;
  function findEnumFromGradeCode(str: string): EnumRankLevel | undefined {
    const enumValue = Object.values(EnumRankLevel).find((value) => value === str);
    return enumValue as EnumRankLevel | undefined;
  }

  return (
    <tr className={twMerge(isMyDataRanking && 'bg-lavender')}>
      <td className="mx-auto w-[50px] py-[8px] pl-[16px]">
        {isMyDataRanking ? (
          <div className="gz-text-tiny text-center font-bold">
            MY <div>{formatNumberAbbreviation(rankingField)}</div>
          </div>
        ) : (
          <LeaderboardRank
            className="gz-text-xs flex w-full justify-center text-center font-bold"
            rank={rankingField}
            sameRank={sameRank}
          />
        )}
      </td>
      <td className="py-[8px]">
        <div
          className="ml-[12px] flex cursor-pointer items-center"
          onClick={() => {
            if (!data?.usrNo) return;
            history.push(pathConstants.PROFILE(data?.usrNo?.toString()));

            firebaseInstance.trackEvent(eventName.RANKING_DETAIL_VIEW_PLAYER, true, {
              usr_nick_name: data?.usrNickName,
            });
          }}
        >
          <div>
            <AvatarImage src={data?.usrNickImage} gradetcode={findEnumFromGradeCode(data?.gradeCode!?.trim())} />
            <ImageCustom
              imgPath={getFlagByCountryCd(data?.usrCountry)}
              className="relative z-1 ml-[20px] mt-[-12px] h-[16px] w-[16px] rounded-full"
            />
          </div>
          <div className="ml-[8px]">
            <div className="gz-text-xsm font-bold">{data?.usrNickName}</div>
            {!!data?.gradetcode && (
              <div className="gz-text-tiny text-sub-body">
                {translate('pages.tournaments.detail.information_detail.grade_handy.' + data?.gradetcode)}
              </div>
            )}
          </div>
        </div>
      </td>
      <td>
        <StatusChangeRanking
          className="gz-text-sm justify-center py-[8px] text-center"
          classNameIcon="mr-[4px]"
          rankChange={data?.rankChange}
        />
      </td>
      <td className="gz-text-sm py-[8px] pr-[16px] text-right font-bold text-gz-primary">
        {isBestScore ? numberWithSign(data?.ugGHandy) : fixDecimal(data.ugGHandy, true)}
      </td>
    </tr>
  );
};

import { SET_PARAMS_FILTER } from './action';
import { EnumValueBoolean } from 'src/constants/enum';
import { IFeed } from 'src/models';
import { IFeedGdrSwing, IFeedGsSwing, IScoreList } from 'src/requests/api/feed/prop-state.type';
import { ITmSwingVideoInfoResponse } from 'src/requests/api/record/prop-state.type';
import {
  FEED_OFFSET,
  RESET_FEED,
  GET_RECENT_FEED,
  GET_STORE_FEED,
  GET_GDR_SWING_VIDEO_FEED,
  GET_GS_SWING_VIDEO_FEED,
  GET_SCORE_CARD_FEED,
  GET_INDIVIDUAL_FEED,
  GET_TM_SWING_VIDEO_FEED,
} from 'src/store/feed/action';

export interface IOffsetFeed {
  individualFeed: number;
  recentFeed: number;
  storeFeed: number;
  postScoreCard: number;
  postGsNasmo: number;
  postGdrNasmo: number;
  postTmNasmo: number;
}
export interface IFeedData {
  values: IFeed[];
  loaded: number;
  lastPage: boolean;
}
export interface IGsSwingVideoFeedData {
  values: IFeedGsSwing[];
  loaded: number;
  lastPage: boolean;
}
export interface IGdrSwingVideoFeedData {
  values: IFeedGdrSwing[];
  loaded: number;
  lastPage: boolean;
}

export interface ITmSwingVideoFeedData {
  values: ITmSwingVideoInfoResponse[];
  loaded: number;
  lastPage: boolean;
}

export interface IScoreCardFeedData {
  values: IScoreList[];
  loaded: number;
  lastPage: boolean;
}

export interface IStoreFeedData extends IFeedData {
  storeId: number;
}

export interface IIndividualFeedData extends IFeedData {
  userNo: string;
}

export interface IFeedCommentAndLikeCount {
  feedNo: number;
  commentCnt?: number;
  likeCnt?: number;
  myLikeYn?: EnumValueBoolean;
}

export interface IFeedsState {
  offsetListFeed: IOffsetFeed;
  dataRecentFeed: IFeedData;
  dataStoreFeed: IStoreFeedData;
  dataIndividualFeed: IIndividualFeedData;
  dataGsSwingVideoFeed: IGsSwingVideoFeedData;
  dataGdrSwingVideoFeed: IGdrSwingVideoFeedData;
  dataTmSwingVideoFeed: ITmSwingVideoFeedData;
  dataScoreCardFeed: IScoreCardFeedData;
  paramsFilter: IParamFilter;
}
export interface IParamFilter {
  sex?: string;
  countryType?: string;
  liked?: boolean;
  detailNo?: number;
  mostLiked?: boolean;
}

const initialState: IFeedsState = {
  offsetListFeed: {
    individualFeed: 0,
    recentFeed: 0,
    storeFeed: 0,
    postScoreCard: 0,
    postGsNasmo: 0,
    postGdrNasmo: 0,
    postTmNasmo: 0,
  },
  dataRecentFeed: {
    values: [],
    loaded: 0,
    lastPage: false,
  },
  dataStoreFeed: {
    storeId: 0,
    values: [],
    loaded: 0,
    lastPage: false,
  },
  dataIndividualFeed: {
    userNo: '0',
    values: [],
    loaded: 0,
    lastPage: false,
  },
  dataGsSwingVideoFeed: {
    values: [],
    loaded: 0,
    lastPage: false,
  },
  dataGdrSwingVideoFeed: {
    values: [],
    loaded: 0,
    lastPage: false,
  },
  dataTmSwingVideoFeed: {
    values: [],
    loaded: 0,
    lastPage: false,
  },
  dataScoreCardFeed: {
    values: [],
    loaded: 0,
    lastPage: false,
  },
  paramsFilter: {},
};

export function feedReducer(state: IFeedsState = initialState, action: any) {
  switch (action.type) {
    case FEED_OFFSET:
      return {
        ...state,
        offsetListFeed: action.payload.offsetListFeed,
      };
    case RESET_FEED:
      return initialState;
    case GET_RECENT_FEED:
      return {
        ...state,
        dataRecentFeed: action.payload.dataRecentFeed,
      };
    case GET_STORE_FEED:
      return {
        ...state,
        dataStoreFeed: action.payload.dataStoreFeed,
      };
    case GET_INDIVIDUAL_FEED:
      return {
        ...state,
        dataIndividualFeed: action.payload.dataIndividualFeed,
      };
    case GET_GS_SWING_VIDEO_FEED:
      return {
        ...state,
        dataGsSwingVideoFeed: action.payload.dataGsSwingVideoFeed,
      };
    case GET_GDR_SWING_VIDEO_FEED:
      return {
        ...state,
        dataGdrSwingVideoFeed: action.payload.dataGdrSwingVideoFeed,
      };
    case GET_TM_SWING_VIDEO_FEED:
      return {
        ...state,
        dataTmSwingVideoFeed: action.payload.dataTmSwingVideoFeed,
      };
    case GET_SCORE_CARD_FEED:
      return {
        ...state,
        dataScoreCardFeed: action.payload.dataScoreCardFeed,
      };
    case SET_PARAMS_FILTER:
      return {
        ...state,
        paramsFilter: action.payload.dataFilter,
      };
    default:
      return state;
  }
}

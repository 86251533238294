import {
  flagAlgeria,
  flagAndorra,
  flagAngola,
  flagChina,
  flagAustralia,
  flagAustria,
  flagAzerbaijan,
  flagBahrain,
  flagCanada,
  flagCyprus,
  flagDenmark,
  flagFinland,
  flagFrance,
  flagGabon,
  flagGermany,
  flagGreece,
  flagGuinea,
  flagHongKong,
  flagIndia,
  flagIndonesia,
  flagIraq,
  flagItaly,
  flagJapan,
  flagKazakhstan,
  flagKorea,
  flagKuwait,
  flagLibya,
  flagMalaysia,
  flagMauritania,
  flagMexico,
  flagNetherlands,
  flagOman,
  flagPhilippines,
  flagPoland,
  flagRussia,
  flagSaudiArabia,
  flagSingapore,
  flagSlovakia,
  flagSouthAfrica,
  flagSpain,
  flagSweden,
  flagSwitzerland,
  flagTaiwan,
  flagThailand,
  flagUnitedArabEmirates,
  flagUnitedKingdom,
  flagUSA,
  flagUzbekistan,
  flagVietnam,
  flagAfghanistan,
  flagAlandIslands,
  flagAlbania,
  flagAmericanSamoa,
  flagAnguilla,
  flagAntiguaAndBarbuda,
  flagLiberia,
  flagLiechtenstein,
  flagLithuania,
  flagLuxembourg,
  flagMacao,
  flagMadagascar,
  flagMalawi,
  flagArgentina,
  flagArmenia,
  flagAruba,
  flagBahamas,
  flagMaldives,
  flagMali,
  flagMalta,
  flagMarshallIsland,
  flagMartinique,
  flagMauritius,
  flagMicronesia,
  flagBangladesh,
  flagBarbados,
  flagBelarus,
  flagBelgium,
  flagBelize,
  flagBenin,
  flagBermuda,
  flagBhutan,
  flagBolivia,
  flagMoldova,
  flagMonaco,
  flagMongolia,
  flagMontenegro,
  flagMontserrat,
  flagMorocco,
  flagMozambique,
  flagMyanmar,
  flagNamibia,
  flagBonaire,
  flagBosniaAndHerzegovina,
  flagBotswana,
  flagBrazil,
  flagBritishVirginIslands,
  flagBrunei,
  flagBulgaria,
  flagBurkinaFaso,
  flagNepal,
  flagNewZealand,
  flagNicaragua,
  flagNiger,
  flagNigeria,
  flagNiue,
  flagNorfolkIsland,
  flagNorthKorea,
  flagBurundi,
  flagCambodia,
  flagCameroon,
  flagCanaryIslands,
  flagCapeVerde,
  flagCaymanIslands,
  flagCentralAfricanRepublic,
  flagChad,
  flagNorthernCyprus,
  flagNorthernMarianasIslands,
  flagNorway,
  flagPakistan,
  flagPalau,
  flagPanama,
  flagChile,
  flagZimbabwe,
  flagColombia,
  flagComoros,
  flagCookIslands,
  flagCostaRica,
  flagCroatia,
  flagPapuaNewGuinea,
  flagParaguay,
  flagPeru,
  flagPortugal,
  flagPuertoRico,
  flagQatar,
  flagCuba,
  flagCuracao,
  flagCzechRepublic,
  flagDemocraticRepublicOfTheCongo,
  flagDjibouti,
  flagDominica,
  flagDominicanRepublic,
  flagEastTimor,
  flagNorthMacedonia,
  flagRepublicOfTheCongo,
  flagRomania,
  flagRwanda,
  flagZambia,
  flagSamoa,
  flagSanMarino,
  flagEcuador,
  flagEgypt,
  flagElSalvador,
  flagEquatorialGuinea,
  flagEritrea,
  flagEstonia,
  flagEthiopia,
  flagFalklandIslands,
  flagSenegal,
  flagSerbia,
  flagSeychelles,
  flagSierraLeone,
  flagFaroeIslands,
  flagFiji,
  flagFrenchPolynesia,
  flagGambia,
  flagGeorgia,
  flagSintMaarten,
  flagSlovenia,
  flagSolomonIslands,
  flagSomalia,
  flagSouthSudan,
  flagGhana,
  flagGibraltar,
  flagGreenland,
  flagGrenada,
  flagGuam,
  flagGuatemala,
  flagGuernsey,
  flagSriLanka,
  flagSudan,
  flagSuriname,
  flagSyria,
  flagGuyana,
  flagHaiti,
  flagHonduras,
  flagHungary,
  flagIceland,
  flagIran,
  flagTajikistan,
  flagTanzania,
  flagTogo,
  flagTonga,
  flagTrinidadAndTobago,
  flagIreland,
  flagIsleOfMan,
  flagIsrael,
  flagIvoryCoast,
  flagJamaica,
  flagJersey,
  flagJordan,
  flagTunisia,
  flagTurkey,
  flagTurkmenistan,
  flagTurksAndCaicos,
  flagTuvalu,
  flagUganda,
  flagUkraine,
  flagKenya,
  flagKiribati,
  flagKosovo,
  flagKyrgyzstan,
  flagLaos,
  flagLatvia,
  flagLebanon,
  flagLesotho,
  flagUruguay,
  flagVanuatu,
  flagVaticanCity,
  flagVenezuela,
  flagVirginIslands,
  flagYemen,
} from 'src/assets/svg';

export const getCountries = (translate: any) => {
  const countries = [
    {
      idGroup: '',
      valueGroup: [
        {
          countryCd: 'USA',
          countryNum: '+1',
          countryNm: translate('commons.customs.national_selection.USA'),
          flag: flagUSA,
        },
        {
          countryCd: 'CAN',
          countryNum: '+1',
          countryNm: translate('commons.customs.national_selection.CAN'),
          flag: flagCanada,
        },
        {
          countryCd: 'JPN',
          countryNum: '+81',
          countryNm: translate('commons.customs.national_selection.JPN'),
          flag: flagJapan,
        },
        {
          countryCd: 'VNM',
          countryNum: '+84',
          countryNm: translate('commons.customs.national_selection.VNM'),
          flag: flagVietnam,
        },
        {
          countryCd: 'MEX',
          countryNum: '+52',
          countryNm: translate('commons.customs.national_selection.MEX'),
          flag: flagMexico,
        },
      ],
    },
    {
      idGroup: 'A',
      valueGroup: [
        {
          countryCd: 'DZA',
          countryNum: '+213',
          countryNm: translate('commons.customs.national_selection.DZA'),
          flag: flagAlgeria,
        },
        {
          countryCd: 'AND',
          countryNum: '+376',
          countryNm: translate('commons.customs.national_selection.AND'),
          flag: flagAndorra,
        },
        {
          countryCd: 'AGO',
          countryNum: '+244',
          countryNm: translate('commons.customs.national_selection.AGO'),
          flag: flagAngola,
        },
        {
          countryCd: 'AUS',
          countryNum: '+61',
          countryNm: translate('commons.customs.national_selection.AUS'),
          flag: flagAustralia,
        },
        {
          countryCd: 'AUT',
          countryNum: '+43',
          countryNm: translate('commons.customs.national_selection.AUT'),
          flag: flagAustria,
        },
        {
          countryCd: 'AZE',
          countryNum: '+994',
          countryNm: translate('commons.customs.national_selection.AZE'),
          flag: flagAzerbaijan,
        },
        {
          countryCd: 'AFG',
          countryNum: '+93',
          countryNm: translate('commons.customs.national_selection.AFG'),
          flag: flagAfghanistan,
        },
        {
          countryCd: 'ALA',
          countryNum: '+358',
          countryNm: translate('commons.customs.national_selection.ALA'),
          flag: flagAlandIslands,
        },
        {
          countryCd: 'ALB',
          countryNum: '+355',
          countryNm: translate('commons.customs.national_selection.ALB'),
          flag: flagAlbania,
        },
        {
          countryCd: 'ASM',
          countryNum: '+1684',
          countryNm: translate('commons.customs.national_selection.ASM'),
          flag: flagAmericanSamoa,
        },
        {
          countryCd: 'AIA',
          countryNum: '+1264',
          countryNm: translate('commons.customs.national_selection.AIA'),
          flag: flagAnguilla,
        },
        {
          countryCd: 'ATG',
          countryNum: '+1268',
          countryNm: translate('commons.customs.national_selection.ATG'),
          flag: flagAntiguaAndBarbuda,
        },
        {
          countryCd: 'ARG',
          countryNum: '+54',
          countryNm: translate('commons.customs.national_selection.ARG'),
          flag: flagArgentina,
        },
        {
          countryCd: 'ARM',
          countryNum: '+374',
          countryNm: translate('commons.customs.national_selection.ARM'),
          flag: flagArmenia,
        },
        {
          countryCd: 'ABW',
          countryNum: '+297',
          countryNm: translate('commons.customs.national_selection.ABW'),
          flag: flagAruba,
        },
      ],
    },
    {
      idGroup: 'B',
      valueGroup: [
        {
          countryCd: 'BHR',
          countryNum: '+973',
          countryNm: translate('commons.customs.national_selection.BHR'),
          flag: flagBahrain,
        },
        {
          countryCd: 'BHS',
          countryNum: '+1242',
          countryNm: translate('commons.customs.national_selection.BHS'),
          flag: flagBahamas,
        },
        {
          countryCd: 'BGD',
          countryNum: '+880',
          countryNm: translate('commons.customs.national_selection.BGD'),
          flag: flagBangladesh,
        },
        {
          countryCd: 'BRB',
          countryNum: '+1246',
          countryNm: translate('commons.customs.national_selection.BRB'),
          flag: flagBarbados,
        },
        {
          countryCd: 'BLR',
          countryNum: '+375',
          countryNm: translate('commons.customs.national_selection.BLR'),
          flag: flagBelarus,
        },
        {
          countryCd: 'BEL',
          countryNum: '+32',
          countryNm: translate('commons.customs.national_selection.BEL'),
          flag: flagBelgium,
        },
        {
          countryCd: 'BLZ',
          countryNum: '+501',
          countryNm: translate('commons.customs.national_selection.BLZ'),
          flag: flagBelize,
        },
        {
          countryCd: 'BEN',
          countryNum: '+229',
          countryNm: translate('commons.customs.national_selection.BEN'),
          flag: flagBenin,
        },
        {
          countryCd: 'BMU',
          countryNum: '+1441',
          countryNm: translate('commons.customs.national_selection.BMU'),
          flag: flagBermuda,
        },
        {
          countryCd: 'BTN',
          countryNum: '+975',
          countryNm: translate('commons.customs.national_selection.BTN'),
          flag: flagBhutan,
        },
        {
          countryCd: 'BOL',
          countryNum: '+591',
          countryNm: translate('commons.customs.national_selection.BOL'),
          flag: flagBolivia,
        },
        {
          countryCd: 'BQ-BO',
          countryNum: '+599',
          countryNm: translate('commons.customs.national_selection.BQ_BO'),
          flag: flagBonaire,
        },
        {
          countryCd: 'BIH',
          countryNum: '+387',
          countryNm: translate('commons.customs.national_selection.BIH'),
          flag: flagBosniaAndHerzegovina,
        },
        {
          countryCd: 'BWA',
          countryNum: '+267',
          countryNm: translate('commons.customs.national_selection.BWA'),
          flag: flagBotswana,
        },
        {
          countryCd: 'BRA',
          countryNum: '+55',
          countryNm: translate('commons.customs.national_selection.BRA'),
          flag: flagBrazil,
        },
        {
          countryCd: 'VGB',
          countryNum: '+1284',
          countryNm: translate('commons.customs.national_selection.VGB'),
          flag: flagBritishVirginIslands,
        },
        {
          countryCd: 'BRN',
          countryNum: '+673',
          countryNm: translate('commons.customs.national_selection.BRN'),
          flag: flagBrunei,
        },
        {
          countryCd: 'BGR',
          countryNum: '+359',
          countryNm: translate('commons.customs.national_selection.BGR'),
          flag: flagBulgaria,
        },
        {
          countryCd: 'BFA',
          countryNum: '+226',
          countryNm: translate('commons.customs.national_selection.BFA'),
          flag: flagBurkinaFaso,
        },
        {
          countryCd: 'BDI',
          countryNum: '+257',
          countryNm: translate('commons.customs.national_selection.BDI'),
          flag: flagBurundi,
        },
      ],
    },
    {
      idGroup: 'C',
      valueGroup: [
        {
          countryCd: 'CYP',
          countryNum: '+357',
          countryNm: translate('commons.customs.national_selection.CYP'),
          flag: flagCyprus,
        },
        {
          countryCd: 'CAN',
          countryNum: '+1',
          countryNm: translate('commons.customs.national_selection.CAN'),
          flag: flagCanada,
        },
        {
          countryCd: 'KHM',
          countryNum: '+855',
          countryNm: translate('commons.customs.national_selection.KHM'),
          flag: flagCambodia,
        },
        {
          countryCd: 'CMR',
          countryNum: '+237',
          countryNm: translate('commons.customs.national_selection.CMR'),
          flag: flagCameroon,
        },
        {
          countryCd: 'ES-CN',
          countryNum: '+3491',
          countryNm: translate('commons.customs.national_selection.ES_CN'),
          flag: flagCanaryIslands,
        },
        {
          countryCd: 'CPV',
          countryNum: '+238',
          countryNm: translate('commons.customs.national_selection.CPV'),
          flag: flagCapeVerde,
        },
        {
          countryCd: 'CYM',
          countryNum: '+1345',
          countryNm: translate('commons.customs.national_selection.CYM'),
          flag: flagCaymanIslands,
        },
        {
          countryCd: 'CAF',
          countryNum: '+236',
          countryNm: translate('commons.customs.national_selection.CAF'),
          flag: flagCentralAfricanRepublic,
        },
        {
          countryCd: 'TCD',
          countryNum: '+235',
          countryNm: translate('commons.customs.national_selection.TCD'),
          flag: flagChad,
        },
        {
          countryCd: 'CHL',
          countryNum: '+56',
          countryNm: translate('commons.customs.national_selection.CHL'),
          flag: flagChile,
        },
        {
          countryCd: 'CHN',
          countryNum: '+86',
          countryNm: translate('commons.customs.national_selection.CHN'),
          flag: flagChina,
        },
        {
          countryCd: 'COL',
          countryNum: '+57',
          countryNm: translate('commons.customs.national_selection.COL'),
          flag: flagColombia,
        },
        {
          countryCd: 'COM',
          countryNum: '+269',
          countryNm: translate('commons.customs.national_selection.COM'),
          flag: flagComoros,
        },
        {
          countryCd: 'COK',
          countryNum: '+682',
          countryNm: translate('commons.customs.national_selection.COK'),
          flag: flagCookIslands,
        },
        {
          countryCd: 'CRI',
          countryNum: '+506',
          countryNm: translate('commons.customs.national_selection.CRI'),
          flag: flagCostaRica,
        },
        {
          countryCd: 'HRV',
          countryNum: '+385',
          countryNm: translate('commons.customs.national_selection.HRV'),
          flag: flagCroatia,
        },
        {
          countryCd: 'CUB',
          countryNum: '+53',
          countryNm: translate('commons.customs.national_selection.CUB'),
          flag: flagCuba,
        },
        {
          countryCd: 'CUW',
          countryNum: '+599',
          countryNm: translate('commons.customs.national_selection.CUW'),
          flag: flagCuracao,
        },
        {
          countryCd: 'CZE',
          countryNum: '+420',
          countryNm: translate('commons.customs.national_selection.CZE'),
          flag: flagCzechRepublic,
        },
        {
          countryCd: 'COD',
          countryNum: '+243',
          countryNm: translate('commons.customs.national_selection.COD'),
          flag: flagDemocraticRepublicOfTheCongo,
        },
        {
          countryCd: 'COG',
          countryNum: '+242',
          countryNm: translate('commons.customs.national_selection.COG'),
          flag: flagRepublicOfTheCongo,
        },
      ],
    },
    {
      idGroup: 'D',
      valueGroup: [
        {
          countryCd: 'DNK',
          countryNum: '+45',
          countryNm: translate('commons.customs.national_selection.DNK'),
          flag: flagDenmark,
        },
        {
          countryCd: 'DJI',
          countryNum: '+253',
          countryNm: translate('commons.customs.national_selection.DJI'),
          flag: flagDjibouti,
        },
        {
          countryCd: 'DMA',
          countryNum: '+1767',
          countryNm: translate('commons.customs.national_selection.DMA'),
          flag: flagDominica,
        },
        {
          countryCd: 'DOM',
          countryNum: '+18',
          countryNm: translate('commons.customs.national_selection.DOM'),
          flag: flagDominicanRepublic,
        },
      ],
    },
    {
      idGroup: 'E',
      valueGroup: [
        {
          countryCd: 'TLS',
          countryNum: '+670',
          countryNm: translate('commons.customs.national_selection.TLS'),
          flag: flagEastTimor,
        },
        {
          countryCd: 'ECU',
          countryNum: '+593',
          countryNm: translate('commons.customs.national_selection.ECU'),
          flag: flagEcuador,
        },
        {
          countryCd: 'EGY',
          countryNum: '+20',
          countryNm: translate('commons.customs.national_selection.EGY'),
          flag: flagEgypt,
        },
        {
          countryCd: 'SLV',
          countryNum: '+503',
          countryNm: translate('commons.customs.national_selection.SLV'),
          flag: flagElSalvador,
        },
        {
          countryCd: 'GNQ',
          countryNum: '+240',
          countryNm: translate('commons.customs.national_selection.GNQ'),
          flag: flagEquatorialGuinea,
        },
        {
          countryCd: 'ERI',
          countryNum: '+291',
          countryNm: translate('commons.customs.national_selection.ERI'),
          flag: flagEritrea,
        },
        {
          countryCd: 'EST',
          countryNum: '+372',
          countryNm: translate('commons.customs.national_selection.EST'),
          flag: flagEstonia,
        },
        {
          countryCd: 'ETH',
          countryNum: '+251',
          countryNm: translate('commons.customs.national_selection.ETH'),
          flag: flagEthiopia,
        },
      ],
    },
    {
      idGroup: 'F',
      valueGroup: [
        {
          countryCd: 'FLK',
          countryNum: '+500',
          countryNm: translate('commons.customs.national_selection.FLK'),
          flag: flagFalklandIslands,
        },
        {
          countryCd: 'FIN',
          countryNum: '+358',
          countryNm: translate('commons.customs.national_selection.FIN'),
          flag: flagFinland,
        },
        {
          countryCd: 'FRA',
          countryNum: '+33',
          countryNm: translate('commons.customs.national_selection.FRA'),
          flag: flagFrance,
        },
        {
          countryCd: 'FRO',
          countryNum: '+298',
          countryNm: translate('commons.customs.national_selection.FRO'),
          flag: flagFaroeIslands,
        },
        {
          countryCd: 'FJI',
          countryNum: '+679',
          countryNm: translate('commons.customs.national_selection.FJI'),
          flag: flagFiji,
        },
        {
          countryCd: 'PYF',
          countryNum: '+689',
          countryNm: translate('commons.customs.national_selection.PYF'),
          flag: flagFrenchPolynesia,
        },
      ],
    },
    {
      idGroup: 'G',
      valueGroup: [
        {
          countryCd: 'GAB',
          countryNum: '+241',
          countryNm: translate('commons.customs.national_selection.GAB'),
          flag: flagGabon,
        },
        {
          countryCd: 'DEU',
          countryNum: '+49',
          countryNm: translate('commons.customs.national_selection.DEU'),
          flag: flagGermany,
        },
        {
          countryCd: 'GRC',
          countryNum: '+30',
          countryNm: translate('commons.customs.national_selection.GRC'),
          flag: flagGreece,
        },
        {
          countryCd: 'GIN',
          countryNum: '+224',
          countryNm: translate('commons.customs.national_selection.GIN'),
          flag: flagGuinea,
        },
        {
          countryCd: 'GMB',
          countryNum: '+220',
          countryNm: translate('commons.customs.national_selection.GMB'),
          flag: flagGambia,
        },
        {
          countryCd: 'GEO',
          countryNum: '+995',
          countryNm: translate('commons.customs.national_selection.GEO'),
          flag: flagGeorgia,
        },
        {
          countryCd: 'GHA',
          countryNum: '+233',
          countryNm: translate('commons.customs.national_selection.GHA'),
          flag: flagGhana,
        },
        {
          countryCd: 'GIB',
          countryNum: '+350',
          countryNm: translate('commons.customs.national_selection.GIB'),
          flag: flagGibraltar,
        },
        {
          countryCd: 'GRL',
          countryNum: '+299',
          countryNm: translate('commons.customs.national_selection.GRL'),
          flag: flagGreenland,
        },
        {
          countryCd: 'GRD',
          countryNum: '+1473',
          countryNm: translate('commons.customs.national_selection.GRD'),
          flag: flagGrenada,
        },
        {
          countryCd: 'GUM',
          countryNum: '+1671',
          countryNm: translate('commons.customs.national_selection.GUM'),
          flag: flagGuam,
        },
        {
          countryCd: 'GTM',
          countryNum: '+502',
          countryNm: translate('commons.customs.national_selection.GTM'),
          flag: flagGuatemala,
        },
        {
          countryCd: 'GGY',
          countryNum: '+44',
          countryNm: translate('commons.customs.national_selection.GGY'),
          flag: flagGuernsey,
        },
        {
          countryCd: 'GUY',
          countryNum: '+592',
          countryNm: translate('commons.customs.national_selection.GUY'),
          flag: flagGuyana,
        },
      ],
    },
    {
      idGroup: 'H',
      valueGroup: [
        {
          countryCd: 'HKG',
          countryNum: '+852',
          countryNm: translate('commons.customs.national_selection.HKG'),
          flag: flagHongKong,
        },
        {
          countryCd: 'HTI',
          countryNum: '+509',
          countryNm: translate('commons.customs.national_selection.HTI'),
          flag: flagHaiti,
        },
        {
          countryCd: 'HND',
          countryNum: '+504',
          countryNm: translate('commons.customs.national_selection.HND'),
          flag: flagHonduras,
        },
        {
          countryCd: 'HUN',
          countryNum: '+36',
          countryNm: translate('commons.customs.national_selection.HUN'),
          flag: flagHungary,
        },
      ],
    },
    {
      idGroup: 'I',
      valueGroup: [
        {
          countryCd: 'IND',
          countryNum: '+91',
          countryNm: translate('commons.customs.national_selection.IND'),
          flag: flagIndia,
        },
        {
          countryCd: 'IDN',
          countryNum: '+62',
          countryNm: translate('commons.customs.national_selection.IDN'),
          flag: flagIndonesia,
        },
        {
          countryCd: 'IRQ',
          countryNum: '+964',
          countryNm: translate('commons.customs.national_selection.IRQ'),
          flag: flagIraq,
        },
        {
          countryCd: 'ITA',
          countryNum: '+39',
          countryNm: translate('commons.customs.national_selection.ITA'),
          flag: flagItaly,
        },
        {
          countryCd: 'ISL',
          countryNum: '+354',
          countryNm: translate('commons.customs.national_selection.ISL'),
          flag: flagIceland,
        },
        {
          countryCd: 'IRN',
          countryNum: '+98',
          countryNm: translate('commons.customs.national_selection.IRN'),
          flag: flagIran,
        },
        {
          countryCd: 'IRL',
          countryNum: '+353',
          countryNm: translate('commons.customs.national_selection.IRL'),
          flag: flagIreland,
        },
        {
          countryCd: 'IMN',
          countryNum: '+44',
          countryNm: translate('commons.customs.national_selection.IMN'),
          flag: flagIsleOfMan,
        },
        {
          countryCd: 'ISR',
          countryNum: '+972',
          countryNm: translate('commons.customs.national_selection.ISR'),
          flag: flagIsrael,
        },
        {
          countryCd: 'CIV',
          countryNum: '+225',
          countryNm: translate('commons.customs.national_selection.CIV'),
          flag: flagIvoryCoast,
        },
      ],
    },
    {
      idGroup: 'J',
      valueGroup: [
        {
          countryCd: 'JPN',
          countryNum: '+81',
          countryNm: translate('commons.customs.national_selection.JPN'),
          flag: flagJapan,
        },
        {
          countryCd: 'JAM',
          countryNum: '+1876',
          countryNm: translate('commons.customs.national_selection.JAM'),
          flag: flagJamaica,
        },
        {
          countryCd: 'JEY',
          countryNum: '+447',
          countryNm: translate('commons.customs.national_selection.JEY'),
          flag: flagJersey,
        },
        {
          countryCd: 'JOR',
          countryNum: '+962',
          countryNm: translate('commons.customs.national_selection.JOR'),
          flag: flagJordan,
        },
      ],
    },
    {
      idGroup: 'K',
      valueGroup: [
        {
          countryCd: 'KAZ',
          countryNum: '+7',
          countryNm: translate('commons.customs.national_selection.KAZ'),
          flag: flagKazakhstan,
        },
        {
          countryCd: 'KOR',
          countryNum: '+82',
          countryNm: translate('commons.customs.national_selection.KOR'),
          flag: flagKorea,
        },
        {
          countryCd: 'KWT',
          countryNum: '+965',
          countryNm: translate('commons.customs.national_selection.KWT'),
          flag: flagKuwait,
        },
        {
          countryCd: 'KEN',
          countryNum: '+254',
          countryNm: translate('commons.customs.national_selection.KEN'),
          flag: flagKenya,
        },
        {
          countryCd: 'KIR',
          countryNum: '+686',
          countryNm: translate('commons.customs.national_selection.KIR'),
          flag: flagKiribati,
        },
        {
          countryCd: 'XKX',
          countryNum: '+383',
          countryNm: translate('commons.customs.national_selection.XKX'),
          flag: flagKosovo,
        },
        {
          countryCd: 'KGZ',
          countryNum: '+996',
          countryNm: translate('commons.customs.national_selection.KGZ'),
          flag: flagKyrgyzstan,
        },
      ],
    },
    {
      idGroup: 'L',
      valueGroup: [
        {
          countryCd: 'LBY',
          countryNum: '+218',
          countryNm: translate('commons.customs.national_selection.LBY'),
          flag: flagLibya,
        },
        {
          countryCd: 'LBR',
          countryNum: '+231',
          countryNm: translate('commons.customs.national_selection.LBR'),
          flag: flagLiberia,
        },
        {
          countryCd: 'LIE',
          countryNum: '+423',
          countryNm: translate('commons.customs.national_selection.LIE'),
          flag: flagLiechtenstein,
        },
        {
          countryCd: 'LTU',
          countryNum: '+370',
          countryNm: translate('commons.customs.national_selection.LTU'),
          flag: flagLithuania,
        },
        {
          countryCd: 'LUX',
          countryNum: '+352',
          countryNm: translate('commons.customs.national_selection.LUX'),
          flag: flagLuxembourg,
        },
        {
          countryCd: 'LAO',
          countryNum: '+856',
          countryNm: translate('commons.customs.national_selection.LAO'),
          flag: flagLaos,
        },
        {
          countryCd: 'LVA',
          countryNum: '+371',
          countryNm: translate('commons.customs.national_selection.LVA'),
          flag: flagLatvia,
        },
        {
          countryCd: 'LBN',
          countryNum: '+961',
          countryNm: translate('commons.customs.national_selection.LBN'),
          flag: flagLebanon,
        },
        {
          countryCd: 'LSO',
          countryNum: '+266',
          countryNm: translate('commons.customs.national_selection.LSO'),
          flag: flagLesotho,
        },
      ],
    },
    {
      idGroup: 'M',
      valueGroup: [
        {
          countryCd: 'MAS',
          countryNum: '+60',
          countryNm: translate('commons.customs.national_selection.MAS'),
          flag: flagMalaysia,
        },
        {
          countryCd: 'MRT',
          countryNum: '+222',
          countryNm: translate('commons.customs.national_selection.MRT'),
          flag: flagMauritania,
        },
        {
          countryCd: 'MEX',
          countryNum: '+52',
          countryNm: translate('commons.customs.national_selection.MEX'),
          flag: flagMexico,
        },
        {
          countryCd: 'MAC',
          countryNum: '+853',
          countryNm: translate('commons.customs.national_selection.MAC'),
          flag: flagMacao,
        },
        {
          countryCd: 'MDG',
          countryNum: '+261',
          countryNm: translate('commons.customs.national_selection.MDG'),
          flag: flagMadagascar,
        },
        {
          countryCd: 'MWI',
          countryNum: '+265',
          countryNm: translate('commons.customs.national_selection.MWI'),
          flag: flagMalawi,
        },
        {
          countryCd: 'MDV',
          countryNum: '+960',
          countryNm: translate('commons.customs.national_selection.MDV'),
          flag: flagMaldives,
        },
        {
          countryCd: 'MLI',
          countryNum: '+223',
          countryNm: translate('commons.customs.national_selection.MLI'),
          flag: flagMali,
        },
        {
          countryCd: 'MLT',
          countryNum: '+356',
          countryNm: translate('commons.customs.national_selection.MLT'),
          flag: flagMalta,
        },
        {
          countryCd: 'MHL',
          countryNum: '+692',
          countryNm: translate('commons.customs.national_selection.MHL'),
          flag: flagMarshallIsland,
        },
        {
          countryCd: 'MTQ',
          countryNum: '+596',
          countryNm: translate('commons.customs.national_selection.MTQ'),
          flag: flagMartinique,
        },
        {
          countryCd: 'MUS',
          countryNum: '+230',
          countryNm: translate('commons.customs.national_selection.MUS'),
          flag: flagMauritius,
        },
        {
          countryCd: 'FSM',
          countryNum: '+691',
          countryNm: translate('commons.customs.national_selection.FSM'),
          flag: flagMicronesia,
        },
        {
          countryCd: 'MDA',
          countryNum: '+373',
          countryNm: translate('commons.customs.national_selection.MDA'),
          flag: flagMoldova,
        },
        {
          countryCd: 'MCO',
          countryNum: '+377',
          countryNm: translate('commons.customs.national_selection.MCO'),
          flag: flagMonaco,
        },
        {
          countryCd: 'MNG',
          countryNum: '+976',
          countryNm: translate('commons.customs.national_selection.MNG'),
          flag: flagMongolia,
        },
        {
          countryCd: 'MNE',
          countryNum: '+382',
          countryNm: translate('commons.customs.national_selection.MNE'),
          flag: flagMontenegro,
        },
        {
          countryCd: 'MSR',
          countryNum: '+1664',
          countryNm: translate('commons.customs.national_selection.MSR'),
          flag: flagMontserrat,
        },
        {
          countryCd: 'MAR',
          countryNum: '+212',
          countryNm: translate('commons.customs.national_selection.MAR'),
          flag: flagMorocco,
        },
        {
          countryCd: 'MOZ',
          countryNum: '+258',
          countryNm: translate('commons.customs.national_selection.MOZ'),
          flag: flagMozambique,
        },
        {
          countryCd: 'MMR',
          countryNum: '+95',
          countryNm: translate('commons.customs.national_selection.MMR'),
          flag: flagMyanmar,
        },
      ],
    },
    {
      idGroup: 'N',
      valueGroup: [
        {
          countryCd: 'NLD',
          countryNum: '+31',
          countryNm: translate('commons.customs.national_selection.NLD'),
          flag: flagNetherlands,
        },
        {
          countryCd: 'NAM',
          countryNum: '+264',
          countryNm: translate('commons.customs.national_selection.NAM'),
          flag: flagNamibia,
        },
        {
          countryCd: 'NPL',
          countryNum: '+977',
          countryNm: translate('commons.customs.national_selection.NPL'),
          flag: flagNepal,
        },
        {
          countryCd: 'NZL',
          countryNum: '+64',
          countryNm: translate('commons.customs.national_selection.NZL'),
          flag: flagNewZealand,
        },
        {
          countryCd: 'NIC',
          countryNum: '+505',
          countryNm: translate('commons.customs.national_selection.NIC'),
          flag: flagNicaragua,
        },
        {
          countryCd: 'NER',
          countryNum: '+227',
          countryNm: translate('commons.customs.national_selection.NER'),
          flag: flagNiger,
        },
        {
          countryCd: 'NGA',
          countryNum: '+234',
          countryNm: translate('commons.customs.national_selection.NGA'),
          flag: flagNigeria,
        },
        {
          countryCd: 'NIU',
          countryNum: '+683',
          countryNm: translate('commons.customs.national_selection.NIU'),
          flag: flagNiue,
        },
        {
          countryCd: 'NFK',
          countryNum: '+672',
          countryNm: translate('commons.customs.national_selection.NFK'),
          flag: flagNorfolkIsland,
        },
        {
          countryCd: 'PRK',
          countryNum: '+850',
          countryNm: translate('commons.customs.national_selection.PRK'),
          flag: flagNorthKorea,
        },
        {
          countryCd: 'NCYP',
          countryNum: '+90',
          countryNm: translate('commons.customs.national_selection.NCYP'),
          flag: flagNorthernCyprus,
        },
        {
          countryCd: 'MNP',
          countryNum: '+1670',
          countryNm: translate('commons.customs.national_selection.MNP'),
          flag: flagNorthernMarianasIslands,
        },
        {
          countryCd: 'NOR',
          countryNum: '+47',
          countryNm: translate('commons.customs.national_selection.NOR'),
          flag: flagNorway,
        },
        {
          countryCd: 'MKD',
          countryNum: '+389',
          countryNm: translate('commons.customs.national_selection.MKD'),
          flag: flagNorthMacedonia,
        },
      ],
    },
    {
      idGroup: 'O',
      valueGroup: [
        {
          countryCd: 'OMN',
          countryNum: '+968',
          countryNm: translate('commons.customs.national_selection.OMN'),
          flag: flagOman,
        },
      ],
    },
    {
      idGroup: 'P',
      valueGroup: [
        {
          countryCd: 'PHL',
          countryNum: '+63',
          countryNm: translate('commons.customs.national_selection.PHL'),
          flag: flagPhilippines,
        },
        {
          countryCd: 'POL',
          countryNum: '+48',
          countryNm: translate('commons.customs.national_selection.POL'),
          flag: flagPoland,
        },
        {
          countryCd: 'PAK',
          countryNum: '+92',
          countryNm: translate('commons.customs.national_selection.PAK'),
          flag: flagPakistan,
        },
        {
          countryCd: 'PLW',
          countryNum: '+680',
          countryNm: translate('commons.customs.national_selection.PLW'),
          flag: flagPalau,
        },
        {
          countryCd: 'PAN',
          countryNum: '+507',
          countryNm: translate('commons.customs.national_selection.PAN'),
          flag: flagPanama,
        },
        {
          countryCd: 'PNG',
          countryNum: '+675',
          countryNm: translate('commons.customs.national_selection.PNG'),
          flag: flagPapuaNewGuinea,
        },
        {
          countryCd: 'PRY',
          countryNum: '+595',
          countryNm: translate('commons.customs.national_selection.PRY'),
          flag: flagParaguay,
        },
        {
          countryCd: 'PER',
          countryNum: '+51',
          countryNm: translate('commons.customs.national_selection.PER'),
          flag: flagPeru,
        },
        {
          countryCd: 'PRT',
          countryNum: '+351',
          countryNm: translate('commons.customs.national_selection.PRT'),
          flag: flagPortugal,
        },
        {
          countryCd: 'PRI',
          countryNum: '+1787',
          countryNm: translate('commons.customs.national_selection.PRI'),
          flag: flagPuertoRico,
        },
      ],
    },
    {
      idGroup: 'Q',
      valueGroup: [
        {
          countryCd: 'QAT',
          countryNum: '+974',
          countryNm: translate('commons.customs.national_selection.QAT'),
          flag: flagQatar,
        },
      ],
    },
    {
      idGroup: 'R',
      valueGroup: [
        {
          countryCd: 'RUS',
          countryNum: '+7',
          countryNm: translate('commons.customs.national_selection.RUS'),
          flag: flagRussia,
        },
        {
          countryCd: 'ROU',
          countryNum: '+40',
          countryNm: translate('commons.customs.national_selection.ROU'),
          flag: flagRomania,
        },
        {
          countryCd: 'RWA',
          countryNum: '+250',
          countryNm: translate('commons.customs.national_selection.RWA'),
          flag: flagRwanda,
        },
      ],
    },
    {
      idGroup: 'S',
      valueGroup: [
        {
          countryCd: 'SAU',
          countryNum: '+966',
          countryNm: translate('commons.customs.national_selection.SAU'),
          flag: flagSaudiArabia,
        },
        {
          countryCd: 'SGP',
          countryNum: '+65',
          countryNm: translate('commons.customs.national_selection.SGP'),
          flag: flagSingapore,
        },
        {
          countryCd: 'SVK',
          countryNum: '+421',
          countryNm: translate('commons.customs.national_selection.SVK'),
          flag: flagSlovakia,
        },
        {
          countryCd: 'RSA',
          countryNum: '+27',
          countryNm: translate('commons.customs.national_selection.RSA'),
          flag: flagSouthAfrica,
        },
        {
          countryCd: 'ESP',
          countryNum: '+34',
          countryNm: translate('commons.customs.national_selection.ESP'),
          flag: flagSpain,
        },
        {
          countryCd: 'SWE',
          countryNum: '+46',
          countryNm: translate('commons.customs.national_selection.SWE'),
          flag: flagSweden,
        },
        {
          countryCd: 'CHE',
          countryNum: '+41',
          countryNm: translate('commons.customs.national_selection.CHE'),
          flag: flagSwitzerland,
        },
        {
          countryCd: 'WSM',
          countryNum: '+685',
          countryNm: translate('commons.customs.national_selection.WSM'),
          flag: flagSamoa,
        },
        {
          countryCd: 'SMR',
          countryNum: '+378',
          countryNm: translate('commons.customs.national_selection.SMR'),
          flag: flagSanMarino,
        },
        {
          countryCd: 'SEN',
          countryNum: '+221',
          countryNm: translate('commons.customs.national_selection.SEN'),
          flag: flagSenegal,
        },
        {
          countryCd: 'SRB',
          countryNum: '+381',
          countryNm: translate('commons.customs.national_selection.SRB'),
          flag: flagSerbia,
        },
        {
          countryCd: 'SYC',
          countryNum: '+248',
          countryNm: translate('commons.customs.national_selection.SYC'),
          flag: flagSeychelles,
        },
        {
          countryCd: 'SLE',
          countryNum: '+232',
          countryNm: translate('commons.customs.national_selection.SLE'),
          flag: flagSierraLeone,
        },
        {
          countryCd: 'SXM',
          countryNum: '+599',
          countryNm: translate('commons.customs.national_selection.SXM'),
          flag: flagSintMaarten,
        },
        {
          countryCd: 'SVN',
          countryNum: '+386',
          countryNm: translate('commons.customs.national_selection.SVN'),
          flag: flagSlovenia,
        },
        {
          countryCd: 'SLB',
          countryNum: '+677',
          countryNm: translate('commons.customs.national_selection.SLB'),
          flag: flagSolomonIslands,
        },
        {
          countryCd: 'SOM',
          countryNum: '+252',
          countryNm: translate('commons.customs.national_selection.SOM'),
          flag: flagSomalia,
        },
        {
          countryCd: 'SSD',
          countryNum: '+211',
          countryNm: translate('commons.customs.national_selection.SSD'),
          flag: flagSouthSudan,
        },
        {
          countryCd: 'LKA',
          countryNum: '+94',
          countryNm: translate('commons.customs.national_selection.LKA'),
          flag: flagSriLanka,
        },
        {
          countryCd: 'SDN',
          countryNum: '+249',
          countryNm: translate('commons.customs.national_selection.SDN'),
          flag: flagSudan,
        },
        {
          countryCd: 'SUR',
          countryNum: '+597',
          countryNm: translate('commons.customs.national_selection.SUR'),
          flag: flagSuriname,
        },
        {
          countryCd: 'SYR',
          countryNum: '+963',
          countryNm: translate('commons.customs.national_selection.SYR'),
          flag: flagSyria,
        },
      ],
    },
    {
      idGroup: 'T',
      valueGroup: [
        {
          countryCd: 'TWN',
          countryNum: '+886',
          countryNm: translate('commons.customs.national_selection.TWN'),
          flag: flagTaiwan,
        },
        {
          countryCd: 'THA',
          countryNum: '+66',
          countryNm: translate('commons.customs.national_selection.THA'),
          flag: flagThailand,
        },
        {
          countryCd: 'TJK',
          countryNum: '+992',
          countryNm: translate('commons.customs.national_selection.TJK'),
          flag: flagTajikistan,
        },
        {
          countryCd: 'TZA',
          countryNum: '+255',
          countryNm: translate('commons.customs.national_selection.TZA'),
          flag: flagTanzania,
        },
        {
          countryCd: 'TGO',
          countryNum: '+228',
          countryNm: translate('commons.customs.national_selection.TGO'),
          flag: flagTogo,
        },
        {
          countryCd: 'TON',
          countryNum: '+676',
          countryNm: translate('commons.customs.national_selection.TON'),
          flag: flagTonga,
        },
        {
          countryCd: 'TTO',
          countryNum: '+1868',
          countryNm: translate('commons.customs.national_selection.TTO'),
          flag: flagTrinidadAndTobago,
        },
        {
          countryCd: 'TUN',
          countryNum: '+216',
          countryNm: translate('commons.customs.national_selection.TUN'),
          flag: flagTunisia,
        },
        {
          countryCd: 'TUR',
          countryNum: '+90',
          countryNm: translate('commons.customs.national_selection.TUR'),
          flag: flagTurkey,
        },
        {
          countryCd: 'TKM',
          countryNum: '+993',
          countryNm: translate('commons.customs.national_selection.TKM'),
          flag: flagTurkmenistan,
        },
        {
          countryCd: 'TCA',
          countryNum: '+1649',
          countryNm: translate('commons.customs.national_selection.TCA'),
          flag: flagTurksAndCaicos,
        },
        {
          countryCd: 'TUV',
          countryNum: '+688',
          countryNm: translate('commons.customs.national_selection.TUV'),
          flag: flagTuvalu,
        },
      ],
    },
    {
      idGroup: 'U',
      valueGroup: [
        {
          countryCd: 'ARE',
          countryNum: '+971',
          countryNm: translate('commons.customs.national_selection.ARE'),
          flag: flagUnitedArabEmirates,
        },
        {
          countryCd: 'GBR',
          countryNum: '+44',
          countryNm: translate('commons.customs.national_selection.GBR'),
          flag: flagUnitedKingdom,
        },
        {
          countryCd: 'UZB',
          countryNum: '+998',
          countryNm: translate('commons.customs.national_selection.UZB'),
          flag: flagUzbekistan,
        },
        {
          countryCd: 'USA',
          countryNum: '+1',
          countryNm: translate('commons.customs.national_selection.USA'),
          flag: flagUSA,
        },
        {
          countryCd: 'UGA',
          countryNum: '+256',
          countryNm: translate('commons.customs.national_selection.UGA'),
          flag: flagUganda,
        },
        {
          countryCd: 'UKR',
          countryNum: '+380',
          countryNm: translate('commons.customs.national_selection.UKR'),
          flag: flagUkraine,
        },
        {
          countryCd: 'URY',
          countryNum: '+598',
          countryNm: translate('commons.customs.national_selection.URY'),
          flag: flagUruguay,
        },
      ],
    },
    {
      idGroup: 'V',
      valueGroup: [
        {
          countryCd: 'VNM',
          countryNum: '+84',
          countryNm: translate('commons.customs.national_selection.VNM'),
          flag: flagVietnam,
        },
        {
          countryCd: 'VUT',
          countryNum: '+678',
          countryNm: translate('commons.customs.national_selection.VUT'),
          flag: flagVanuatu,
        },
        {
          countryCd: 'VAT',
          countryNum: '+379',
          countryNm: translate('commons.customs.national_selection.VAT'),
          flag: flagVaticanCity,
        },
        {
          countryCd: 'VEN',
          countryNum: '+58',
          countryNm: translate('commons.customs.national_selection.VEN'),
          flag: flagVenezuela,
        },
        {
          countryCd: 'VIR',
          countryNum: '+1340',
          countryNm: translate('commons.customs.national_selection.VIR'),
          flag: flagVirginIslands,
        },
      ],
    },
    {
      idGroup: 'Y',
      valueGroup: [
        {
          countryCd: 'YEM',
          countryNum: '+967',
          countryNm: translate('commons.customs.national_selection.YEM'),
          flag: flagYemen,
        },
      ],
    },
    {
      idGroup: 'Z',
      valueGroup: [
        {
          countryCd: 'ZWE',
          countryNum: '+263',
          countryNm: translate('commons.customs.national_selection.ZWE'),
          flag: flagZimbabwe,
        },
        {
          countryCd: 'ZMB',
          countryNum: '+260',
          countryNm: translate('commons.customs.national_selection.ZMB'),
          flag: flagZambia,
        },
      ],
    },
  ];

  return countries.map((country) => {
    if (country.idGroup.length > 0) {
      return {
        ...country,
        valueGroup: country.valueGroup.sort((a, b) => a.countryNm.localeCompare(b.countryNm)),
      };
    } else {
      return country;
    }
  });
};

export const getListFlag = () => {
  const countries = getCountries(() => null);
  return countries.reduce(
    (final: any, value: any) => {
      value.valueGroup.forEach((element: any) => {
        final[element.countryCd] = element.flag;
      });
      return final;
    },
    { CHN: flagChina },
  );
};

export const listFlag = {
  CHN: flagChina,
  USA: flagUSA,
  CAN: flagCanada,
  JPN: flagJapan,
  VNM: flagVietnam,
  MEX: flagMexico,
  DZA: flagAlgeria,
  AND: flagAndorra,
  AGO: flagAngola,
  AUS: flagAustralia,
  AUT: flagAustria,
  AZE: flagAzerbaijan,
  BHR: flagBahrain,
  CYP: flagCyprus,
  DNK: flagDenmark,
  FIN: flagFinland,
  FRA: flagFrance,
  GAB: flagGabon,
  DEU: flagGermany,
  GRC: flagGreece,
  GIN: flagGuinea,
  HKG: flagHongKong,
  IND: flagIndia,
  IDN: flagIndonesia,
  IRQ: flagIraq,
  ITA: flagItaly,
  KAZ: flagKazakhstan,
  KOR: flagKorea,
  KWT: flagKuwait,
  LBY: flagLibya,
  MAS: flagMalaysia,
  MRT: flagMauritania,
  NLD: flagNetherlands,
  OMN: flagOman,
  PHL: flagPhilippines,
  POL: flagPoland,
  RUS: flagRussia,
  SAU: flagSaudiArabia,
  SGP: flagSingapore,
  SVK: flagSlovakia,
  RSA: flagSouthAfrica,
  ESP: flagSpain,
  SWE: flagSweden,
  CHE: flagSwitzerland,
  TWN: flagTaiwan,
  THA: flagThailand,
  ARE: flagUnitedArabEmirates,
  GBR: flagUnitedKingdom,
  UZB: flagUzbekistan,
};

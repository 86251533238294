import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

interface IProps {
  onResetFilter: () => void;
}

export default function NotRanking({ onResetFilter: resetFilter }: IProps) {
  const translate = useTranslation().t;
  const mainWidth = useSelector((state: RootState) => state.globalReducer.mainWidth);

  const onResetFilter = (e: any) => {
    if (e.target.id === 'reset-filter') {
      resetFilter();
    }
  };

  return (
    <div
      style={{ width: mainWidth }}
      className="fixed bottom-0 z-10 bg-gz-white p-[20px_16px] text-center text-[14px] leading-[21px] shadow-not-ranking"
      onClick={onResetFilter}
      dangerouslySetInnerHTML={{
        __html: translate('pages.home.my_ranking.NOT_RANKING_IN_TABLE'),
      }}
    />
  );
}

import { formatDate, LOCAL_STORAGE_FCM_TOKEN, LOCALSTORAGE_SNS_DATA } from 'src/constants/const';
import { NotificationType } from 'src/models/notification';
import { signupMiddleware } from 'src/requests/api/account/signup';
import { authActions, login } from 'src/store/account/login/action';
import { notificationActions } from 'src/store/notification/action';
import i18n from 'src/locale';
import { getObjectLocalStorage, removeLocalStorage } from 'src/services/storage';
import { IMappingSNS, ISignupForm } from 'src/requests/api/account/prop-state.type';
import { EnumRequestCode, EnumStatusCode } from 'src/constants/enum';
import { DateTime } from 'src/services/datetime';
import { isEmpty } from 'lodash';

export const VERIFY_USERNAME = 'VERIFY_USERNAME';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';

const createUserInfo = (dataValue: ISignupForm) => async (dispatch: any) => {
  try {
    const pushToken = getObjectLocalStorage(LOCAL_STORAGE_FCM_TOKEN)!;
    dispatch(login.start());
    const params = {
      ...dataValue,
      birthYmd: DateTime.convertFormat(dataValue.birthYmd, formatDate.API_DATETIME, formatDate.NORMAL),
      ...(!isEmpty(pushToken) ? { pushToken } : {}),
    };
    const response = await signupMiddleware.registerUserInfo(params);
    if (response.code === EnumRequestCode.SUCCESS) {
      dispatch(authActions.handleAfterLogin(response.entities, '', true));
      removeLocalStorage(LOCALSTORAGE_SNS_DATA);
    } else {
      dispatch(login.failed());
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(login.failed());
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const mappingSNSLogin = (dataValue: IMappingSNS) => async (dispatch: any) => {
  try {
    dispatch(login.start());
    const response = await signupMiddleware.mappingSNSLogin(dataValue);
    if (response.entities.memberResult === EnumStatusCode.SUCCESS) {
      dispatch(authActions.handleAfterLogin(response.entities, dataValue.type));
      removeLocalStorage(LOCALSTORAGE_SNS_DATA);
    }
    if (response.entities.memberResult === EnumStatusCode.FAILED) {
      dispatch(
        notificationActions.addNotification(i18n.t('error.INVALID_USERNAME_OR_PASSWORD'), NotificationType.DANGER),
      );
      dispatch(login.failed());
    }
    if (response.entities.memberResult === EnumStatusCode.ERROR) {
      dispatch(
        notificationActions.addNotification(i18n.t('error.COULD_NOT_LOGIN_A_TEMPORARY_ERROR'), NotificationType.DANGER),
      );
      dispatch(login.failed());
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    dispatch(login.failed());
  }
};

export const registerActions = {
  createUserInfo,
  mappingSNSLogin,
};

import axios from 'axios';

const getLocationFromIP = async () => {
  const response = await axios.get('https://freeipapi.com/api/json');
  return response.data;
};

export const thirdPartyRequests = {
  getLocationFromIP,
};

export enum EnumWifiAction {
  TURN_ON_ALL = 'Y',
  TURN_ON_WIFI_ONLY = 'W',
  NEVER_AUTO_PLAY = 'N',
}
export enum EMyAlarmInfo {
  SCORE_ALARM = 'scoreAlarm',
  COMPE_ALARM = 'compeAlarm',
  FEED_ALARM = 'feedAlarm',
  RGN_ALARM = 'rgnAlarm',
  ETC_ALARM = 'etcAlarm',
  MARKETING_ALARM = 'marketingAlarm',
  BOOKING_ALARM = 'bookingAlarm',
  LEAGUE_ALARM = 'leagueAlarm',
}

import { useEffect, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { ANDROID_APP_LINK, IOS_APP_LINK } from 'src/view/pages/link-to-app';
import Modal from 'src/view/commons/elements/Modal';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_IGNORE_FORCE_UPDATE_IN_WEBVIEW, LOCAL_STORAGE_IS_NATIVE_APP } from 'src/constants/const';
import { useSelector } from 'react-redux';

function ForceUpdateModal() {
  const { t: translate } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isNativeAppReducer = useSelector((state: any) => state.webviewReducer.isNativeApp); // for app version below 4.1.4
  const isNativeAppLocalStorage = !!localStorage.getItem(LOCAL_STORAGE_IS_NATIVE_APP);
  const isNativeApp = isNativeAppLocalStorage || isNativeAppReducer;

  useEffect(() => {
    const ignoreForceUpdate = !!localStorage.getItem(LOCAL_STORAGE_IGNORE_FORCE_UPDATE_IN_WEBVIEW);
    if (isNativeApp && !ignoreForceUpdate) {
      !isNativeAppLocalStorage && localStorage.setItem(LOCAL_STORAGE_IS_NATIVE_APP, 'true');
      setIsOpen(true);
    }
  }, [isNativeApp]);

  const gotoAppStore = () => window.open(isAndroid ? ANDROID_APP_LINK : IOS_APP_LINK, '_blank');

  if (!isNativeApp) return <></>;

  return (
    <Modal
      isOpen={isOpen}
      zIndex={100}
      bgClassName="bg-opacity-75"
      className="w-full rounded-[16px] bg-transparent px-[24px] pt-[28px] pb-[8px]"
      onClose={() => {}}
    >
      <div className="flex flex-col items-center justify-center rounded-[16px] bg-white py-[16px]">
        <div className="w-full whitespace-pre-wrap border-b border-gray-300 px-[16px] pb-[24px] text-center">
          {translate('features.force_update.NEED_UPDATE')}
        </div>
        <div onClick={gotoAppStore} className="mt-[16px] cursor-pointer font-bold text-primary-75">
          {translate('features.force_update.GOTO_STORE')}
        </div>
      </div>
    </Modal>
  );
}

export default ForceUpdateModal;

import React, { useMemo } from 'react';
import { IconLevelDown, IconLevelKeep, IconLevelUp } from 'src/assets/svg';
import { EnumStatusRanking } from 'src/constants/enum';
import { twMerge } from 'tailwind-merge';

interface IProps {
  rankChange?: number;
  classNameIcon?: string;
  classNameText?: string;
  className?: string;
}

export default function StatusChangeRanking({ rankChange = 0, classNameIcon, classNameText, className }: IProps) {
  const status = useMemo(() => {
    if (rankChange < 0) {
      return EnumStatusRanking.DECREASE;
    }
    if (rankChange === 0) {
      return EnumStatusRanking.UNCHANGED;
    }
    return EnumStatusRanking.INCREASE;
  }, [rankChange]);

  const statusRanking = {
    [EnumStatusRanking.INCREASE]: {
      icon: <IconLevelUp className="h-[8px] w-[12px]" />,
      color: 'text-gz-danger',
    },
    [EnumStatusRanking.DECREASE]: {
      icon: <IconLevelDown className="h-[8px] w-[12px]" />,
      color: 'text-primary-75',
    },
    [EnumStatusRanking.UNCHANGED]: {
      icon: <IconLevelKeep className="h-[8px] w-[12px]" />,
      color: 'text-disable',
    },
  };

  return (
    <div className={twMerge('flex items-center', statusRanking[status]?.color, className)}>
      <div className={classNameIcon}>{statusRanking[status]?.icon}</div>
      <div className={classNameText}>{Math.abs(rankChange)}</div>
    </div>
  );
}

import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { searchParamsContants } from 'src/constants/const';
import { EnumNoHeaderSearchParams } from 'src/constants/enum';

export const useNoHeaderSearchParam = () => {
  const [params] = useSearchParams();
  const noHeader = params.get(searchParamsContants.NO_HEADER);

  const value = useMemo(() => {
    if (noHeader === EnumNoHeaderSearchParams.KEEP) {
      return EnumNoHeaderSearchParams.KEEP;
    } else if (noHeader === EnumNoHeaderSearchParams.ONCE) {
      return EnumNoHeaderSearchParams.ONCE;
    } else if (noHeader === EnumNoHeaderSearchParams.BACK_KEEP) {
      return EnumNoHeaderSearchParams.BACK_KEEP;
    } else if (noHeader === EnumNoHeaderSearchParams.BACK_ONCE) {
      return EnumNoHeaderSearchParams.BACK_ONCE;
    }

    return EnumNoHeaderSearchParams.NONE;
  }, [noHeader]);

  return value;
};

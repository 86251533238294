import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { LogoGolfzonFooter } from 'src/assets/svg';
import { pathConstants } from 'src/constants/const';
import { history } from 'src/services/history';

function Footer(): JSX.Element {
  const translate = useTranslation().t;
  return (
    <div className="flex justify-center p-[24px_24px_32px_24px]">
      <div className="gz-text-tiny !leading-[20px] text-text-disable">
        <div className="text-center">
          <span className="cursor-pointer" onClick={() => history.push(pathConstants.TERMS_OF_USE)}>
            {translate('layouts.footer.TERM_SERVICES')}
          </span>
          <span onClick={() => history.push(pathConstants.PRIVACY_POLICY)} className="ml-[8px] cursor-pointer">
            {translate('layouts.footer.PRIVACY_POLICY')}
          </span>
        </div>
        <div className="mt-[6px] text-center">{translate('layouts.footer.COPYRIGHT', { year: dayjs().year() })}</div>
        <img className="mx-auto mt-[6px]" src={LogoGolfzonFooter} alt="logo footer" />
      </div>
    </div>
  );
}

export default Footer;

import { EnumNotificationPositionType } from 'src/constants/enum/notification.enum';
export enum NotificationType {
  SUCCESS,
  DANGER,
  INFO,
  WARNING,
}

export interface INotification {
  id: number;
  message: string;
  type: NotificationType;
  additional?: INotiAdditional;
  position?: EnumNotificationPositionType;
}

export interface INotiAdditional {
  hasIconPreFix?: boolean;
  notAutoRemoveMess?: boolean;
  textAction?: ITextAction;
}

interface ITextAction {
  title: string;
  href?: string;
  onClick?: () => void;
  action?: any;
}

import { forwardRef, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useForwardRef } from 'src/hooks/use-forward-ref';
import { IconArrowRight } from 'src/assets/svg';
import { twMerge } from 'tailwind-merge';
import { isMobile } from 'react-device-detect';
import ScrollContainer from 'react-indiana-drag-scroll';

interface IProps {
  classContainer?: string;
  className?: string;
  hideScrollbars?: boolean;
  children: ReactNode;
  classButton?: string;
}

const ScrollView = forwardRef(
  ({ classContainer, className, hideScrollbars = true, children, classButton }: IProps, ref: any): ReactElement => {
    const innerRef = useForwardRef<HTMLDivElement>(ref, null);
    const [isScrollable, setIsScrollable] = useState(false);
    const [showPreviousBtn, setShowPreviosBtn] = useState(false);
    const [showNextBtn, setShowNextBtn] = useState(true);
    const [hideBtn, setHideBtn] = useState(false);
    const timeoutHideBtnRef = useRef<any>();

    useEffect(() => {
      hideBtnAfterTime(2000);
      return () => clearTimeout(timeoutHideBtnRef.current);
    }, []);

    useEffect(() => {
      if (!innerRef.current || isMobile) return;
      const resizeObserver = new ResizeObserver(() => {
        if (innerRef.current) {
          setIsScrollable(innerRef.current.scrollWidth > innerRef.current.clientWidth);
        }
      });
      resizeObserver.observe(innerRef.current);
      return () => resizeObserver.disconnect();
    }, [children]);

    useEffect(() => {
      const handleScroll = () => {
        setShowPreviosBtn(innerRef.current?.scrollLeft > 0);
        setShowNextBtn(
          innerRef.current?.scrollLeft < innerRef.current?.scrollWidth - innerRef.current?.clientWidth - 1,
        );
      };
      if (isMobile) return;
      innerRef.current?.addEventListener('scroll', handleScroll);
      return () => {
        innerRef.current?.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const onPrevious = () => {
      innerRef.current?.scrollTo({
        left: innerRef.current?.scrollLeft - innerRef.current?.clientWidth * 0.75,
        behavior: 'smooth',
      });
    };

    const onNext = () => {
      innerRef.current?.scrollTo({
        left: innerRef.current?.scrollLeft + innerRef.current?.clientWidth * 0.75,
        behavior: 'smooth',
      });
    };

    const showBtn = () => {
      clearTimeout(timeoutHideBtnRef.current);
      setHideBtn(false);
    };

    const hideBtnAfterTime = (time?: number) => {
      clearTimeout(timeoutHideBtnRef.current);
      timeoutHideBtnRef.current = setTimeout(() => setHideBtn(true), time ?? 1000);
    };

    if (isMobile) {
      return (
        <ScrollContainer innerRef={innerRef} className={twMerge(className, 'scroll-hidden')}>
          {children}
        </ScrollContainer>
      );
    }

    return (
      <div className="relative" onMouseEnter={showBtn} onMouseLeave={() => hideBtnAfterTime()}>
        <div ref={innerRef} className={twMerge(classContainer, 'overflow-auto', hideScrollbars && 'scroll-hidden')}>
          <div className={twMerge(className, 'w-max min-w-full')}>{children}</div>
          {isScrollable && !isMobile && (
            <>
              <div
                className={twMerge(
                  'absolute left-[16px] top-[50%] z-1 -translate-y-[50%] shadow-sm transition-all',
                  'flex h-[48px] w-[48px] items-center justify-center',
                  'cursor-pointer rounded-full bg-gray-10 active:bg-gray-20',
                  !(showPreviousBtn && !hideBtn) && 'pointer-events-none opacity-0',
                  classButton,
                )}
                onClick={onPrevious}
              >
                <IconArrowRight className="rotate-180 stroke-text" />
              </div>
              <div
                className={twMerge(
                  'absolute right-[16px] top-[50%] z-1 -translate-y-[50%] shadow-sm transition-all',
                  'flex h-[48px] w-[48px] items-center justify-center',
                  'cursor-pointer rounded-full bg-gray-10 active:bg-gray-20',
                  !(showNextBtn && !hideBtn) && 'pointer-events-none opacity-0',
                  classButton,
                )}
                onClick={onNext}
              >
                <IconArrowRight className="stroke-text" />
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);

export default ScrollView;

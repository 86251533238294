import { EnumRequestCode } from 'src/constants/enum';
import { signupMiddleware } from 'src/requests/api/account/signup';
import { setting } from 'src/requests/api/setting';

export const verifyUserName = async (value: string) => {
  const result = await signupMiddleware.verifySignupUserName({ nickName: value });
  if (result.code !== EnumRequestCode.SUCCESS) {
    throw new Error('verify username failed');
  }
  return result.entities.isAvailable;
};

export const verifyUserEmail = async (value: string) => {
  const result = await signupMiddleware.verifyCheckUserEmail({ email: value });
  if (result.code !== EnumRequestCode.SUCCESS) {
    throw new Error('verify user email failed');
  }
  return result.entities.isAvailable;
};

export const verifyUserOldPassword = async (value: string) => {
  const result = await setting.checkPasswordInfo({ password: value });
  if (result.code !== EnumRequestCode.SUCCESS) {
    throw new Error('verify user password failed');
  }
  return result.entities.isCheck;
};

import { useTranslation } from 'react-i18next';
import { history } from 'src/services/history';
import { pathConstants } from 'src/constants/const';
import { iconApple, IconClose, iconFacebook, iconGoogle, iconSNSLine, iconWechat } from 'src/assets/svg';
import Button from 'src/view/commons/elements/Button';
import Modal from 'src/view/commons/elements/Modal';
import { hideCharacterLast } from 'src/services/utils';
import { snsType } from 'src/constants/enum';
import { useSelector } from 'react-redux';

interface IFindPasswordResultProps {
  entities: any;
  isShow: boolean;
  onClose: () => void;
}

function FindPasswordResult({ entities, isShow, onClose }: IFindPasswordResultProps): JSX.Element {
  const translate = useTranslation().t;
  const isLoggedIn = useSelector((state: any) => state.authentication.isLoggedIn);

  return (
    <Modal isOpen={isShow} onClose={onClose}>
      <>
        <div className="relative flex justify-end p-[16px]">
          <IconClose className="h-6 w-6 cursor-pointer stroke-title" onClick={onClose} />
        </div>
        <div className="mb-8 flex h-[calc(100vh_-_4rem)] flex-col justify-between overflow-auto px-4 pt-10 pb-6 md:h-[60vh]">
          <div>
            <div className="gz-text-xl font-medium text-title">
              {translate('pages.account.find_password.find_password_result.TITLE_1')}
            </div>
            <div className="gz-text-xl mb-[24px] font-medium text-title">
              {translate('pages.account.find_password.find_password_result.TITLE_2')}
            </div>
            <div className="gz-text-xs mb-[40px]">
              {translate('pages.account.find_password.find_password_result.DESCRIPTION')}
            </div>
            <div className="gz-text-sm mb-1 font-light">
              {translate('pages.account.find_password.find_password_result.LABEL_USER_ID')}
            </div>
            <div className="gz-text-xs mb-[40px] font-medium">{hideCharacterLast(entities.usrId, 3)}</div>
            {entities.snsList.length > 0 && (
              <>
                <div className="gz-text-sm mb-2 font-light">
                  {translate('pages.account.find_password.find_password_result.SOCIAL_MEDIA')}
                </div>
                <div className="flex">
                  {entities.snsList.map((item: string, index: number) => {
                    let iconSocial;
                    if (item === snsType.Apple) iconSocial = iconApple;
                    if (item === snsType.Facebook) iconSocial = iconFacebook;
                    if (item === snsType.Google) iconSocial = iconGoogle;
                    if (item === snsType.Wechat) iconSocial = iconWechat;
                    if (item === snsType.Line) iconSocial = iconSNSLine;
                    return (
                      <span className="mr-2.5" key={index}>
                        <img src={iconSocial} alt="icon Social" />
                      </span>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <Button
            className="mt-[40px]"
            onClick={() => history.pushWithSearch(isLoggedIn ? pathConstants.HOME_PAGE : pathConstants.LOGIN)}
          >
            {translate(
              isLoggedIn ? 'layouts.auth.sidebar.HOME' : 'pages.account.find_password.find_password_result.GO_TO_LOGIN',
            )}
          </Button>
        </div>
      </>
    </Modal>
  );
}

export default FindPasswordResult;

import { EnumStoreDistance } from 'src/constants/enum';
import { ILocationParams } from 'src/requests/api/store-news/prop-state.type';
import { convertKmToMiles } from 'src/services/utils';

export const calculateDistance = (
  userLocation: ILocationParams,
  targetLocation: ILocationParams,
  storeDistanceUnit?: EnumStoreDistance,
  unit: boolean = true,
) => {
  const toRadian = (n: number) => (n * Math.PI) / 180;
  const lat2 = targetLocation.latitude ?? 0;
  const lon2 = targetLocation.longitude ?? 0;
  const lat1 = userLocation.latitude ?? 0;
  const lon1 = userLocation.longitude ?? 0;

  const R = 6371;
  const x1 = lat2 - lat1;
  const dLat = toRadian(x1);
  const x2 = lon2 - lon1;
  const dLon = toRadian(x2);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  if (!unit) {
    return d;
  }

  if (storeDistanceUnit === EnumStoreDistance.MILE) {
    return convertKmToMiles(d);
  }
  if (d < 0.1) return `${Math.ceil(d * 1000)} m`;
  return `${d.toFixed(1)} km`;
};

export const isLocationInsideMap = (
  point: { lat: number; longi: number },
  mapSide: {
    latitudeSouthwest: number;
    longitudeSouthwest: number;
    latitudeNortheast: number;
    longitudeNortheast: number;
  },
) => {
  if (mapSide.longitudeSouthwest > mapSide.longitudeNortheast) {
    return (
      point.lat >= mapSide.latitudeSouthwest &&
      point.lat <= mapSide.latitudeNortheast &&
      (point.longi >= mapSide.longitudeSouthwest || point.longi <= mapSide.longitudeNortheast)
    );
  }
  return (
    point.lat >= mapSide.latitudeSouthwest &&
    point.lat <= mapSide.latitudeNortheast &&
    point.longi >= mapSide.longitudeSouthwest &&
    point.longi <= mapSide.longitudeNortheast
  );
};

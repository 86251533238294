import { lazy } from 'react';
import { pathConstants } from 'src/constants/const';
import { IComponentRouteGroup } from 'src/models/route';
import GuestLayout from 'src/view/layouts/guest/Layout';

const LoginPage = lazy(() => import('src/view/pages/account/login'));
const SignupPage = lazy(() => import('src/view/pages/account/signup'));
const FindIdByPhone = lazy(() => import('src/view/pages/account/find-id/FindIdByPhone'));
const FindIdByEmail = lazy(() => import('src/view/pages/account/find-id/FindIdByEmail'));
const ExistingAccount = lazy(() => import('src/view/pages/account/signup/modal/ExistingAccount'));

export const guestRoutesGroup: IComponentRouteGroup[] = [
  {
    layout: <GuestLayout />,
    components: [
      { path: pathConstants.LOGIN, component: <LoginPage /> },
      { path: pathConstants.SIGNUP, component: <SignupPage /> },
      { path: pathConstants.FIND_ID, component: <FindIdByEmail /> },
      { path: pathConstants.FIND_ID_PHONE, component: <FindIdByPhone /> },
      { path: pathConstants.EXISTING_ACCOUNT, component: <ExistingAccount /> },
    ],
  },
];

import axios from 'src/requests';
import { IGetInfoParams } from 'src/requests/api/plus-items/prop-state.type';

const getInfo = async (params: IGetInfoParams) => {
  const response = await axios.get('plus-item/info', {
    params,
  });
  return response.data;
};

export const plusItemsRequests = {
  getInfo,
};

import { EnumPlayDifficultyType } from 'src/constants/enum';
import { twMerge } from 'tailwind-merge';

interface IPlayDifficultyProps {
  className?: string;
  difficulty: EnumPlayDifficultyType;
}

function PlayDifficulty({ className, difficulty }: IPlayDifficultyProps): JSX.Element {
  const bgColor = () => {
    if (difficulty === EnumPlayDifficultyType.G_TOUR) return 'bg-gz-danger';
    if (difficulty === EnumPlayDifficultyType.PRO) return 'bg-semantic-orange';
    if (difficulty === EnumPlayDifficultyType.ROOKIE) return 'bg-semantic-green';
    return 'bg-primary-75';
  };

  const name = () => {
    if (difficulty === EnumPlayDifficultyType.G_TOUR) return 'G';
    if (difficulty === EnumPlayDifficultyType.PRO) return 'P';
    if (difficulty === EnumPlayDifficultyType.ROOKIE) return 'R';
    return 'A';
  };

  return (
    <div
      className={twMerge(
        'flex items-center justify-center',
        'gz-text-tiny h-[16px] w-[16px] rounded-[4px] text-white',
        bgColor(),
        className,
      )}
    >
      {name()}
    </div>
  );
}

export default PlayDifficulty;

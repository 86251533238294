import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { unstable_HistoryRouter as HistoryBrowerRouter } from 'react-router-dom';
import { history } from 'src/services/history';
import reportWebVitals from 'src/reportWebVitals';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { store } from 'src/store';
import i18n from 'src/locale';

import 'react-magic-slider-dots/dist/magic-dots.css';
import 'react-date-picker/dist/DatePicker.css';
import 'src/assets/scss/global.scss';
import App from 'src/App';
import { HelmetProvider } from 'react-helmet-async';
import { SENTRY_DNS } from 'src/constants/const';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const ENVS_SENTRY_ENABLE = ['master', 'staging'];

const isEnableSentry = ENVS_SENTRY_ENABLE.includes(process.env.REACT_APP_ENVIRONMENT || '');

Sentry.init({
  dsn: SENTRY_DNS,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: isEnableSentry,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <HistoryBrowerRouter history={history}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </HistoryBrowerRouter>
    </I18nextProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { EnumFilterField } from 'src/constants/enum';
import { CountryListResponse } from 'src/requests/api/course/prop-state.type';
import {
  IEventBannerTournament,
  IParticipatedEvent,
  IRecommendTournament,
  ISearchTournament,
} from 'src/requests/api/tournament/prop-state.type';
import {
  UPDATE_TOURNAMENT_DATA,
  RESET_TOURNAMENT_DATA,
  ADD_TOURNAMENT_COUNTRY_LIST,
} from 'src/store/tournament/action';

export interface ITournamentState {
  position: number;
  participatedTournaments: IParticipatedEvent[];
  recommendTournaments: IRecommendTournament[];
  eventBanners: IEventBannerTournament[];
  tournamentList: ISearchTournament[];
  tournamentTotal: number;
  tournamentPageLoaded: number;
  tournamentPageIsLast: boolean;
  tournamentSearchName: string;
  tournamentCountryCode: EnumFilterField.ALL;
  tournamentSubCode: EnumFilterField.ALL;
  tournamentType: EnumFilterField.ALL;
  countryList: CountryListResponse[];
}

const initialState: ITournamentState = {
  position: 0,
  participatedTournaments: [],
  recommendTournaments: [],
  eventBanners: [],
  tournamentList: [],
  tournamentTotal: 0,
  tournamentPageLoaded: 0,
  tournamentPageIsLast: false,
  tournamentSearchName: '',
  tournamentCountryCode: EnumFilterField.ALL,
  tournamentSubCode: EnumFilterField.ALL,
  tournamentType: EnumFilterField.ALL,
  countryList: [],
};

export function tournamentReducer(state: ITournamentState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_TOURNAMENT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case ADD_TOURNAMENT_COUNTRY_LIST: {
      return {
        ...state,
        countryList: payload,
      };
    }
    case RESET_TOURNAMENT_DATA:
      return initialState;
    default:
      return state;
  }
}

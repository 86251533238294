import { useSelector } from 'react-redux';
import { picGuestAvatar, picUserAvatar } from 'src/assets/images/home';
import { IconFavouriteStar, IconSO } from 'src/assets/svg';
import { EnumRankLevel, EnumUserLevelCode, EnumValueBoolean } from 'src/constants/enum';
import { getLevelBorder } from 'src/services/user-info';
import { booleanValue } from 'src/services/utils';
import ImageCustom from 'src/view/commons/customs/Image';
import { twMerge } from 'tailwind-merge';

interface IAvatarImageProps {
  classContainer?: string;
  classImg?: string;
  classIcon?: string;
  src?: string;
  soYn?: EnumValueBoolean;
  siteFlagYn?: EnumValueBoolean;
  gradetcode?: EnumUserLevelCode | EnumRankLevel;
  myAvatar?: boolean;
  onClick?: (e: any) => void;
}

function AvatarImage({
  classContainer,
  classImg,
  classIcon,
  src,
  soYn,
  siteFlagYn,
  gradetcode,
  myAvatar,
  onClick,
}: IAvatarImageProps): JSX.Element {
  const isLoggedIn = useSelector((state: any) => state.authentication.isLoggedIn);

  return (
    <div className={twMerge('relative', classContainer)}>
      <ImageCustom
        className={twMerge(
          'h-[32px] w-[32px] rounded-full',
          gradetcode != null &&
            twMerge('avt-border-gradient flex-shrink-0 bg-border-brown', getLevelBorder(gradetcode)),
          classImg,
        )}
        imgPath={src}
        imgNotFound={myAvatar && !isLoggedIn ? picGuestAvatar : picUserAvatar}
        imgAlt="avatar"
        onClick={onClick}
      />
      {booleanValue(soYn) ? (
        <IconSO className={twMerge('absolute -right-[4px] bottom-0 h-[16px] w-[16px]', classIcon)} />
      ) : booleanValue(siteFlagYn) ? (
        <IconFavouriteStar className={twMerge('absolute -right-[4px] bottom-0  h-[16px] w-[16px]', classIcon)} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default AvatarImage;

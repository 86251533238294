import { EnumFilterAllField } from 'src/constants/enum';
import { ILeague } from 'src/models/league';
import { IParticipatedEvent, IRecommendTournament } from 'src/requests/api/tournament/prop-state.type';

export const UPDATE_LEAGUE_DATA = 'UPDATE_LEAGUE_DATA';
export const RESET_LEAGUE_DATA = 'RESET_LEAGUE_DATA';

export interface IUpdatingLeagueState {
  position?: number;
  participatedLeagues?: IParticipatedEvent[];
  recommendLeagues?: IRecommendTournament[];
  leagueList?: ILeague[];
  leagueTotal?: number;
  leaguePageLoaded?: number;
  leaguePageIsLast?: boolean;
  leagueSearchName?: string;
  joinStatus?: EnumFilterAllField.ALL;
  storeType?: EnumFilterAllField.ALL;
  leagueStatus?: EnumFilterAllField.ALL;
}

const updateLeagueData = (data: IUpdatingLeagueState) => ({
  type: UPDATE_LEAGUE_DATA,
  payload: data,
});

const resetLeagueData = () => ({
  type: RESET_LEAGUE_DATA,
});

export const leagueAction = {
  updateLeagueData,
  resetLeagueData,
};

import {
  flagAlgeria,
  flagAndorra,
  flagAngola,
  flagChina,
  flagAustralia,
  flagAustria,
  flagAzerbaijan,
  flagBahrain,
  flagCanada,
  flagCyprus,
  flagDenmark,
  flagFinland,
  flagFrance,
  flagGabon,
  flagGermany,
  flagGreece,
  flagGuinea,
  flagHongKong,
  flagIndia,
  flagIndonesia,
  flagIraq,
  flagItaly,
  flagJapan,
  flagKazakhstan,
  flagKorea,
  flagKuwait,
  flagLibya,
  flagMalaysia,
  flagMauritania,
  flagMexico,
  flagNetherlands,
  flagOman,
  flagPhilippines,
  flagPoland,
  flagRussia,
  flagSaudiArabia,
  flagSingapore,
  flagSlovakia,
  flagSouthAfrica,
  flagSpain,
  flagSweden,
  flagSwitzerland,
  flagTaiwan,
  flagThailand,
  flagUnitedArabEmirates,
  flagUnitedKingdom,
  flagUSA,
  flagUzbekistan,
  flagVietnam,
  flagAfghanistan,
  flagAlandIslands,
  flagAlbania,
  flagAmericanSamoa,
  flagAnguilla,
  flagAntiguaAndBarbuda,
  flagLiberia,
  flagLiechtenstein,
  flagLithuania,
  flagLuxembourg,
  flagMacao,
  flagMadagascar,
  flagMalawi,
  flagArgentina,
  flagArmenia,
  flagAruba,
  flagBahamas,
  flagMaldives,
  flagMali,
  flagMalta,
  flagMarshallIsland,
  flagMartinique,
  flagMauritius,
  flagMicronesia,
  flagBangladesh,
  flagBarbados,
  flagBelarus,
  flagBelgium,
  flagBelize,
  flagBenin,
  flagBermuda,
  flagBhutan,
  flagBolivia,
  flagMoldova,
  flagMonaco,
  flagMongolia,
  flagMontenegro,
  flagMontserrat,
  flagMorocco,
  flagMozambique,
  flagMyanmar,
  flagNamibia,
  flagBonaire,
  flagBosniaAndHerzegovina,
  flagBotswana,
  flagBrazil,
  flagBritishVirginIslands,
  flagBrunei,
  flagBulgaria,
  flagBurkinaFaso,
  flagNepal,
  flagNewZealand,
  flagNicaragua,
  flagNiger,
  flagNigeria,
  flagNiue,
  flagNorfolkIsland,
  flagNorthKorea,
  flagBurundi,
  flagCambodia,
  flagCameroon,
  flagCanaryIslands,
  flagCapeVerde,
  flagCaymanIslands,
  flagCentralAfricanRepublic,
  flagChad,
  flagNorthernCyprus,
  flagNorthernMarianasIslands,
  flagNorway,
  flagPakistan,
  flagPalau,
  flagPanama,
  flagChile,
  flagZimbabwe,
  flagColombia,
  flagComoros,
  flagCookIslands,
  flagCostaRica,
  flagCroatia,
  flagPapuaNewGuinea,
  flagParaguay,
  flagPeru,
  flagPortugal,
  flagPuertoRico,
  flagQatar,
  flagCuba,
  flagCuracao,
  flagCzechRepublic,
  flagDemocraticRepublicOfTheCongo,
  flagDjibouti,
  flagDominica,
  flagDominicanRepublic,
  flagEastTimor,
  flagNorthMacedonia,
  flagRepublicOfTheCongo,
  flagRomania,
  flagRwanda,
  flagZambia,
  flagSamoa,
  flagSanMarino,
  flagEcuador,
  flagEgypt,
  flagElSalvador,
  flagEquatorialGuinea,
  flagEritrea,
  flagEstonia,
  flagEthiopia,
  flagFalklandIslands,
  flagSenegal,
  flagSerbia,
  flagSeychelles,
  flagSierraLeone,
  flagFaroeIslands,
  flagFiji,
  flagFrenchPolynesia,
  flagGambia,
  flagGeorgia,
  flagSintMaarten,
  flagSlovenia,
  flagSolomonIslands,
  flagSomalia,
  flagSouthSudan,
  flagGhana,
  flagGibraltar,
  flagGreenland,
  flagGrenada,
  flagGuam,
  flagGuatemala,
  flagGuernsey,
  flagSriLanka,
  flagSudan,
  flagSuriname,
  flagSyria,
  flagGuyana,
  flagHaiti,
  flagHonduras,
  flagHungary,
  flagIceland,
  flagIran,
  flagTajikistan,
  flagTanzania,
  flagTogo,
  flagTonga,
  flagTrinidadAndTobago,
  flagIreland,
  flagIsleOfMan,
  flagIsrael,
  flagIvoryCoast,
  flagJamaica,
  flagJersey,
  flagJordan,
  flagTunisia,
  flagTurkey,
  flagTurkmenistan,
  flagTurksAndCaicos,
  flagTuvalu,
  flagUganda,
  flagUkraine,
  flagKenya,
  flagKiribati,
  flagKosovo,
  flagKyrgyzstan,
  flagLaos,
  flagLatvia,
  flagLebanon,
  flagLesotho,
  flagUruguay,
  flagVanuatu,
  flagVaticanCity,
  flagVenezuela,
  flagVirginIslands,
  flagYemen,
  flagEtc,
  flagGolfzon,
  flagEU,
} from 'src/assets/svg';

export const FLAG_USER_COUNTRIES = [
  {
    countryCd: 'USA',
    countryNum: '+1',
    flag: flagUSA,
  },
  {
    countryCd: 'CAN',
    countryNum: '+1',
    flag: flagCanada,
  },
  {
    countryCd: 'JPN',
    countryNum: '+81',
    flag: flagJapan,
  },
  {
    countryCd: 'VNM',
    countryNum: '+84',
    flag: flagVietnam,
  },
  {
    countryCd: 'MEX',
    countryNum: '+52',
    flag: flagMexico,
  },
  {
    countryCd: 'DZA',
    countryNum: '+213',
    flag: flagAlgeria,
  },
  {
    countryCd: 'AND',
    countryNum: '+376',
    flag: flagAndorra,
  },
  {
    countryCd: 'AGO',
    countryNum: '+244',
    flag: flagAngola,
  },
  {
    countryCd: 'AUS',
    countryNum: '+61',
    flag: flagAustralia,
  },
  {
    countryCd: 'AUT',
    countryNum: '+43',
    flag: flagAustria,
  },
  {
    countryCd: 'AZE',
    countryNum: '+994',
    flag: flagAzerbaijan,
  },
  {
    countryCd: 'AFG',
    countryNum: '+93',
    flag: flagAfghanistan,
  },
  {
    countryCd: 'ALA',
    countryNum: '+358',
    flag: flagAlandIslands,
  },
  {
    countryCd: 'ALB',
    countryNum: '+355',
    flag: flagAlbania,
  },
  {
    countryCd: 'ASM',
    countryNum: '+1684',
    flag: flagAmericanSamoa,
  },
  {
    countryCd: 'AIA',
    countryNum: '+1264',
    flag: flagAnguilla,
  },
  {
    countryCd: 'ATG',
    countryNum: '+1268',
    flag: flagAntiguaAndBarbuda,
  },
  {
    countryCd: 'ARG',
    countryNum: '+54',
    flag: flagArgentina,
  },
  {
    countryCd: 'ARM',
    countryNum: '+374',
    flag: flagArmenia,
  },
  {
    countryCd: 'ABW',
    countryNum: '+297',
    flag: flagAruba,
  },
  {
    countryCd: 'BHR',
    countryNum: '+973',
    flag: flagBahrain,
  },
  {
    countryCd: 'BHS',
    countryNum: '+1242',
    flag: flagBahamas,
  },
  {
    countryCd: 'BGD',
    countryNum: '+880',
    flag: flagBangladesh,
  },
  {
    countryCd: 'BRB',
    countryNum: '+1246',
    flag: flagBarbados,
  },
  {
    countryCd: 'BLR',
    countryNum: '+375',
    flag: flagBelarus,
  },
  {
    countryCd: 'BEL',
    countryNum: '+32',
    flag: flagBelgium,
  },
  {
    countryCd: 'BLZ',
    countryNum: '+501',
    flag: flagBelize,
  },
  {
    countryCd: 'BEN',
    countryNum: '+229',
    flag: flagBenin,
  },
  {
    countryCd: 'BMU',
    countryNum: '+1441',
    flag: flagBermuda,
  },
  {
    countryCd: 'BTN',
    countryNum: '+975',
    flag: flagBhutan,
  },
  {
    countryCd: 'BOL',
    countryNum: '+591',
    flag: flagBolivia,
  },
  {
    countryCd: 'BQ-BO',
    countryNum: '+599',
    flag: flagBonaire,
  },
  {
    countryCd: 'BIH',
    countryNum: '+387',
    flag: flagBosniaAndHerzegovina,
  },
  {
    countryCd: 'BWA',
    countryNum: '+267',
    flag: flagBotswana,
  },
  {
    countryCd: 'BRA',
    countryNum: '+55',
    flag: flagBrazil,
  },
  {
    countryCd: 'VGB',
    countryNum: '+1284',
    flag: flagBritishVirginIslands,
  },
  {
    countryCd: 'BRN',
    countryNum: '+673',
    flag: flagBrunei,
  },
  {
    countryCd: 'BGR',
    countryNum: '+359',
    flag: flagBulgaria,
  },
  {
    countryCd: 'BFA',
    countryNum: '+226',
    flag: flagBurkinaFaso,
  },
  {
    countryCd: 'BDI',
    countryNum: '+257',
    flag: flagBurundi,
  },
  {
    countryCd: 'CYP',
    countryNum: '+357',
    flag: flagCyprus,
  },
  {
    countryCd: 'CAN',
    countryNum: '+1',
    flag: flagCanada,
  },
  {
    countryCd: 'KHM',
    countryNum: '+855',
    flag: flagCambodia,
  },
  {
    countryCd: 'CMR',
    countryNum: '+237',
    flag: flagCameroon,
  },
  {
    countryCd: 'ES-CN',
    countryNum: '+3491',
    flag: flagCanaryIslands,
  },
  {
    countryCd: 'CPV',
    countryNum: '+238',
    flag: flagCapeVerde,
  },
  {
    countryCd: 'CYM',
    countryNum: '+1345',
    flag: flagCaymanIslands,
  },
  {
    countryCd: 'CAF',
    countryNum: '+236',
    flag: flagCentralAfricanRepublic,
  },
  {
    countryCd: 'TCD',
    countryNum: '+235',
    flag: flagChad,
  },
  {
    countryCd: 'CHL',
    countryNum: '+56',
    flag: flagChile,
  },
  {
    countryCd: 'CHN',
    countryNum: '+86',
    flag: flagChina,
  },
  {
    countryCd: 'COL',
    countryNum: '+57',
    flag: flagColombia,
  },
  {
    countryCd: 'COM',
    countryNum: '+269',
    flag: flagComoros,
  },
  {
    countryCd: 'COK',
    countryNum: '+682',
    flag: flagCookIslands,
  },
  {
    countryCd: 'CRI',
    countryNum: '+506',
    flag: flagCostaRica,
  },
  {
    countryCd: 'HRV',
    countryNum: '+385',
    flag: flagCroatia,
  },
  {
    countryCd: 'CUB',
    countryNum: '+53',
    flag: flagCuba,
  },
  {
    countryCd: 'CUW',
    countryNum: '+599',
    flag: flagCuracao,
  },
  {
    countryCd: 'CZE',
    countryNum: '+420',
    flag: flagCzechRepublic,
  },
  {
    countryCd: 'COD',
    countryNum: '+243',
    flag: flagDemocraticRepublicOfTheCongo,
  },
  {
    countryCd: 'COG',
    countryNum: '+242',
    flag: flagRepublicOfTheCongo,
  },
  {
    countryCd: 'DNK',
    countryNum: '+45',
    flag: flagDenmark,
  },
  {
    countryCd: 'DJI',
    countryNum: '+253',
    flag: flagDjibouti,
  },
  {
    countryCd: 'DMA',
    countryNum: '+1767',
    flag: flagDominica,
  },
  {
    countryCd: 'DOM',
    countryNum: '+18',
    flag: flagDominicanRepublic,
  },
  {
    countryCd: 'TLS',
    countryNum: '+670',
    flag: flagEastTimor,
  },
  {
    countryCd: 'ECU',
    countryNum: '+593',
    flag: flagEcuador,
  },
  {
    countryCd: 'EGY',
    countryNum: '+20',
    flag: flagEgypt,
  },
  {
    countryCd: 'SLV',
    countryNum: '+503',
    flag: flagElSalvador,
  },
  {
    countryCd: 'GNQ',
    countryNum: '+240',
    flag: flagEquatorialGuinea,
  },
  {
    countryCd: 'ERI',
    countryNum: '+291',
    flag: flagEritrea,
  },
  {
    countryCd: 'EST',
    countryNum: '+372',
    flag: flagEstonia,
  },
  {
    countryCd: 'ETH',
    countryNum: '+251',
    flag: flagEthiopia,
  },
  {
    countryCd: 'FLK',
    countryNum: '+500',
    flag: flagFalklandIslands,
  },
  {
    countryCd: 'FIN',
    countryNum: '+358',
    flag: flagFinland,
  },
  {
    countryCd: 'FRA',
    countryNum: '+33',
    flag: flagFrance,
  },
  {
    countryCd: 'FRO',
    countryNum: '+298',
    flag: flagFaroeIslands,
  },
  {
    countryCd: 'FJI',
    countryNum: '+679',
    flag: flagFiji,
  },
  {
    countryCd: 'PYF',
    countryNum: '+689',
    flag: flagFrenchPolynesia,
  },
  {
    countryCd: 'GAB',
    countryNum: '+241',
    flag: flagGabon,
  },
  {
    countryCd: 'DEU',
    countryNum: '+49',
    flag: flagGermany,
  },
  {
    countryCd: 'GRC',
    countryNum: '+30',
    flag: flagGreece,
  },
  {
    countryCd: 'GIN',
    countryNum: '+224',
    flag: flagGuinea,
  },
  {
    countryCd: 'GMB',
    countryNum: '+220',
    flag: flagGambia,
  },
  {
    countryCd: 'GEO',
    countryNum: '+995',
    flag: flagGeorgia,
  },
  {
    countryCd: 'GHA',
    countryNum: '+233',
    flag: flagGhana,
  },
  {
    countryCd: 'GIB',
    countryNum: '+350',
    flag: flagGibraltar,
  },
  {
    countryCd: 'GRL',
    countryNum: '+299',
    flag: flagGreenland,
  },
  {
    countryCd: 'GRD',
    countryNum: '+1473',
    flag: flagGrenada,
  },
  {
    countryCd: 'GUM',
    countryNum: '+1671',
    flag: flagGuam,
  },
  {
    countryCd: 'GTM',
    countryNum: '+502',
    flag: flagGuatemala,
  },
  {
    countryCd: 'GGY',
    countryNum: '+44',
    flag: flagGuernsey,
  },
  {
    countryCd: 'GUY',
    countryNum: '+592',
    flag: flagGuyana,
  },
  {
    countryCd: 'HKG',
    countryNum: '+852',
    flag: flagHongKong,
  },
  {
    countryCd: 'HTI',
    countryNum: '+509',
    flag: flagHaiti,
  },
  {
    countryCd: 'HND',
    countryNum: '+504',
    flag: flagHonduras,
  },
  {
    countryCd: 'HUN',
    countryNum: '+36',
    flag: flagHungary,
  },
  {
    countryCd: 'IND',
    countryNum: '+91',
    flag: flagIndia,
  },
  {
    countryCd: 'IDN',
    countryNum: '+62',
    flag: flagIndonesia,
  },
  {
    countryCd: 'IRQ',
    countryNum: '+964',
    flag: flagIraq,
  },
  {
    countryCd: 'ITA',
    countryNum: '+39',
    flag: flagItaly,
  },
  {
    countryCd: 'ISL',
    countryNum: '+354',
    flag: flagIceland,
  },
  {
    countryCd: 'IRN',
    countryNum: '+98',
    flag: flagIran,
  },
  {
    countryCd: 'IRL',
    countryNum: '+353',
    flag: flagIreland,
  },
  {
    countryCd: 'IMN',
    countryNum: '+44',
    flag: flagIsleOfMan,
  },
  {
    countryCd: 'ISR',
    countryNum: '+972',
    flag: flagIsrael,
  },
  {
    countryCd: 'CIV',
    countryNum: '+225',
    flag: flagIvoryCoast,
  },
  {
    countryCd: 'JPN',
    countryNum: '+81',
    flag: flagJapan,
  },
  {
    countryCd: 'JAM',
    countryNum: '+1876',
    flag: flagJamaica,
  },
  {
    countryCd: 'JEY',
    countryNum: '+447',
    flag: flagJersey,
  },
  {
    countryCd: 'JOR',
    countryNum: '+962',
    flag: flagJordan,
  },
  {
    countryCd: 'KAZ',
    countryNum: '+7',
    flag: flagKazakhstan,
  },
  {
    countryCd: 'KOR',
    countryNum: '+82',
    flag: flagKorea,
  },
  {
    countryCd: 'KWT',
    countryNum: '+965',
    flag: flagKuwait,
  },
  {
    countryCd: 'KEN',
    countryNum: '+254',
    flag: flagKenya,
  },
  {
    countryCd: 'KIR',
    countryNum: '+686',
    flag: flagKiribati,
  },
  {
    countryCd: 'XKX',
    countryNum: '+383',
    flag: flagKosovo,
  },
  {
    countryCd: 'KGZ',
    countryNum: '+996',
    flag: flagKyrgyzstan,
  },
  {
    countryCd: 'LBY',
    countryNum: '+218',
    flag: flagLibya,
  },
  {
    countryCd: 'LBR',
    countryNum: '+231',
    flag: flagLiberia,
  },
  {
    countryCd: 'LIE',
    countryNum: '+423',
    flag: flagLiechtenstein,
  },
  {
    countryCd: 'LTU',
    countryNum: '+370',
    flag: flagLithuania,
  },
  {
    countryCd: 'LUX',
    countryNum: '+352',
    flag: flagLuxembourg,
  },
  {
    countryCd: 'LAO',
    countryNum: '+856',
    flag: flagLaos,
  },
  {
    countryCd: 'LVA',
    countryNum: '+371',
    flag: flagLatvia,
  },
  {
    countryCd: 'LBN',
    countryNum: '+961',
    flag: flagLebanon,
  },
  {
    countryCd: 'LSO',
    countryNum: '+266',
    flag: flagLesotho,
  },
  {
    countryCd: 'MAS',
    countryNum: '+60',
    flag: flagMalaysia,
  },
  {
    countryCd: 'MRT',
    countryNum: '+222',
    flag: flagMauritania,
  },
  {
    countryCd: 'MEX',
    countryNum: '+52',
    flag: flagMexico,
  },
  {
    countryCd: 'MAC',
    countryNum: '+853',
    flag: flagMacao,
  },
  {
    countryCd: 'MDG',
    countryNum: '+261',
    flag: flagMadagascar,
  },
  {
    countryCd: 'MWI',
    countryNum: '+265',
    flag: flagMalawi,
  },
  {
    countryCd: 'MDV',
    countryNum: '+960',
    flag: flagMaldives,
  },
  {
    countryCd: 'MLI',
    countryNum: '+223',
    flag: flagMali,
  },
  {
    countryCd: 'MLT',
    countryNum: '+356',
    flag: flagMalta,
  },
  {
    countryCd: 'MHL',
    countryNum: '+692',
    flag: flagMarshallIsland,
  },
  {
    countryCd: 'MTQ',
    countryNum: '+596',
    flag: flagMartinique,
  },
  {
    countryCd: 'MUS',
    countryNum: '+230',
    flag: flagMauritius,
  },
  {
    countryCd: 'FSM',
    countryNum: '+691',
    flag: flagMicronesia,
  },
  {
    countryCd: 'MDA',
    countryNum: '+373',
    flag: flagMoldova,
  },
  {
    countryCd: 'MCO',
    countryNum: '+377',
    flag: flagMonaco,
  },
  {
    countryCd: 'MNG',
    countryNum: '+976',
    flag: flagMongolia,
  },
  {
    countryCd: 'MNE',
    countryNum: '+382',
    flag: flagMontenegro,
  },
  {
    countryCd: 'MSR',
    countryNum: '+1664',
    flag: flagMontserrat,
  },
  {
    countryCd: 'MAR',
    countryNum: '+212',
    flag: flagMorocco,
  },
  {
    countryCd: 'MOZ',
    countryNum: '+258',
    flag: flagMozambique,
  },
  {
    countryCd: 'MMR',
    countryNum: '+95',
    flag: flagMyanmar,
  },
  {
    countryCd: 'NLD',
    countryNum: '+31',
    flag: flagNetherlands,
  },
  {
    countryCd: 'NAM',
    countryNum: '+264',
    flag: flagNamibia,
  },
  {
    countryCd: 'NPL',
    countryNum: '+977',
    flag: flagNepal,
  },
  {
    countryCd: 'NZL',
    countryNum: '+64',
    flag: flagNewZealand,
  },
  {
    countryCd: 'NIC',
    countryNum: '+505',
    flag: flagNicaragua,
  },
  {
    countryCd: 'NER',
    countryNum: '+227',
    flag: flagNiger,
  },
  {
    countryCd: 'NGA',
    countryNum: '+234',
    flag: flagNigeria,
  },
  {
    countryCd: 'NIU',
    countryNum: '+683',
    flag: flagNiue,
  },
  {
    countryCd: 'NFK',
    countryNum: '+672',
    flag: flagNorfolkIsland,
  },
  {
    countryCd: 'PRK',
    countryNum: '+850',
    flag: flagNorthKorea,
  },
  {
    countryCd: 'NCYP',
    countryNum: '+90',
    flag: flagNorthernCyprus,
  },
  {
    countryCd: 'MNP',
    countryNum: '+1670',
    flag: flagNorthernMarianasIslands,
  },
  {
    countryCd: 'NOR',
    countryNum: '+47',
    flag: flagNorway,
  },
  {
    countryCd: 'MKD',
    countryNum: '+389',
    flag: flagNorthMacedonia,
  },
  {
    countryCd: 'OMN',
    countryNum: '+968',
    flag: flagOman,
  },
  {
    countryCd: 'PHL',
    countryNum: '+63',
    flag: flagPhilippines,
  },
  {
    countryCd: 'POL',
    countryNum: '+48',
    flag: flagPoland,
  },
  {
    countryCd: 'PAK',
    countryNum: '+92',
    flag: flagPakistan,
  },
  {
    countryCd: 'PLW',
    countryNum: '+680',
    flag: flagPalau,
  },
  {
    countryCd: 'PAN',
    countryNum: '+507',
    flag: flagPanama,
  },
  {
    countryCd: 'PNG',
    countryNum: '+675',
    flag: flagPapuaNewGuinea,
  },
  {
    countryCd: 'PRY',
    countryNum: '+595',
    flag: flagParaguay,
  },
  {
    countryCd: 'PER',
    countryNum: '+51',
    flag: flagPeru,
  },
  {
    countryCd: 'PRT',
    countryNum: '+351',
    flag: flagPortugal,
  },
  {
    countryCd: 'PRI',
    countryNum: '+1787',
    flag: flagPuertoRico,
  },
  {
    countryCd: 'QAT',
    countryNum: '+974',
    flag: flagQatar,
  },
  {
    countryCd: 'RUS',
    countryNum: '+7',
    flag: flagRussia,
  },
  {
    countryCd: 'ROU',
    countryNum: '+40',
    flag: flagRomania,
  },
  {
    countryCd: 'RWA',
    countryNum: '+250',
    flag: flagRwanda,
  },
  {
    countryCd: 'SAU',
    countryNum: '+966',
    flag: flagSaudiArabia,
  },
  {
    countryCd: 'SGP',
    countryNum: '+65',
    flag: flagSingapore,
  },
  {
    countryCd: 'SVK',
    countryNum: '+421',
    flag: flagSlovakia,
  },
  {
    countryCd: 'RSA',
    countryNum: '+27',
    flag: flagSouthAfrica,
  },
  {
    countryCd: 'ESP',
    countryNum: '+34',
    flag: flagSpain,
  },
  {
    countryCd: 'SWE',
    countryNum: '+46',
    flag: flagSweden,
  },
  {
    countryCd: 'CHE',
    countryNum: '+41',
    flag: flagSwitzerland,
  },
  {
    countryCd: 'WSM',
    countryNum: '+685',
    flag: flagSamoa,
  },
  {
    countryCd: 'SMR',
    countryNum: '+378',
    flag: flagSanMarino,
  },
  {
    countryCd: 'SEN',
    countryNum: '+221',
    flag: flagSenegal,
  },
  {
    countryCd: 'SRB',
    countryNum: '+381',
    flag: flagSerbia,
  },
  {
    countryCd: 'SYC',
    countryNum: '+248',
    flag: flagSeychelles,
  },
  {
    countryCd: 'SLE',
    countryNum: '+232',
    flag: flagSierraLeone,
  },
  {
    countryCd: 'SXM',
    countryNum: '+599',
    flag: flagSintMaarten,
  },
  {
    countryCd: 'SVN',
    countryNum: '+386',
    flag: flagSlovenia,
  },
  {
    countryCd: 'SLB',
    countryNum: '+677',
    flag: flagSolomonIslands,
  },
  {
    countryCd: 'SOM',
    countryNum: '+252',
    flag: flagSomalia,
  },
  {
    countryCd: 'SSD',
    countryNum: '+211',
    flag: flagSouthSudan,
  },
  {
    countryCd: 'LKA',
    countryNum: '+94',
    flag: flagSriLanka,
  },
  {
    countryCd: 'SDN',
    countryNum: '+249',
    flag: flagSudan,
  },
  {
    countryCd: 'SUR',
    countryNum: '+597',
    flag: flagSuriname,
  },
  {
    countryCd: 'SYR',
    countryNum: '+963',
    flag: flagSyria,
  },
  {
    countryCd: 'TWN',
    countryNum: '+886',
    flag: flagTaiwan,
  },
  {
    countryCd: 'THA',
    countryNum: '+66',
    flag: flagThailand,
  },
  {
    countryCd: 'TJK',
    countryNum: '+992',
    flag: flagTajikistan,
  },
  {
    countryCd: 'TZA',
    countryNum: '+255',
    flag: flagTanzania,
  },
  {
    countryCd: 'TGO',
    countryNum: '+228',
    flag: flagTogo,
  },
  {
    countryCd: 'TON',
    countryNum: '+676',
    flag: flagTonga,
  },
  {
    countryCd: 'TTO',
    countryNum: '+1868',
    flag: flagTrinidadAndTobago,
  },
  {
    countryCd: 'TUN',
    countryNum: '+216',
    flag: flagTunisia,
  },
  {
    countryCd: 'TUR',
    countryNum: '+90',
    flag: flagTurkey,
  },
  {
    countryCd: 'TKM',
    countryNum: '+993',
    flag: flagTurkmenistan,
  },
  {
    countryCd: 'TCA',
    countryNum: '+1649',
    flag: flagTurksAndCaicos,
  },
  {
    countryCd: 'TUV',
    countryNum: '+688',
    flag: flagTuvalu,
  },
  {
    countryCd: 'ARE',
    countryNum: '+971',
    flag: flagUnitedArabEmirates,
  },
  {
    countryCd: 'GBR',
    countryNum: '+44',
    flag: flagUnitedKingdom,
  },
  {
    countryCd: 'UZB',
    countryNum: '+998',
    flag: flagUzbekistan,
  },
  {
    countryCd: 'USA',
    countryNum: '+1',
    flag: flagUSA,
  },
  {
    countryCd: 'UGA',
    countryNum: '+256',
    flag: flagUganda,
  },
  {
    countryCd: 'UKR',
    countryNum: '+380',
    flag: flagUkraine,
  },
  {
    countryCd: 'URY',
    countryNum: '+598',
    flag: flagUruguay,
  },
  {
    countryCd: 'VNM',
    countryNum: '+84',
    flag: flagVietnam,
  },
  {
    countryCd: 'VUT',
    countryNum: '+678',
    flag: flagVanuatu,
  },
  {
    countryCd: 'VAT',
    countryNum: '+379',
    flag: flagVaticanCity,
  },
  {
    countryCd: 'VEN',
    countryNum: '+58',
    flag: flagVenezuela,
  },
  {
    countryCd: 'VIR',
    countryNum: '+1340',
    flag: flagVirginIslands,
  },
  {
    countryCd: 'YEM',
    countryNum: '+967',
    flag: flagYemen,
  },
  {
    countryCd: 'ZWE',
    countryNum: '+263',
    flag: flagZimbabwe,
  },
  {
    countryCd: 'ZMB',
    countryNum: '+260',
    flag: flagZambia,
  },
  {
    countryCd: 'ETC.',
    flag: flagEtc,
  },
  {
    countryCd: 'GOLFZON',
    flag: flagGolfzon,
  },
  {
    countryCd: 'EU',
    flag: flagEU,
  },
];

import Cookies from 'js-cookie';

export const saveSessionId = (sessionId: string) => {
  Cookies.set('accessToken', sessionId, { expires: 365 });
};

export const getSessionId = () => {
  return Cookies.get('accessToken');
};

export const removeSessionId = () => {
  Cookies.remove('accessToken');
  sessionStorage.clear();
};

export const saveGsmToken = (token: string) => {
  const unixTime = Date.now() / 1000; // divide by 1000 to convert from milliseconds to seconds
  sessionStorage.setItem('gsmToken', token);
  sessionStorage.setItem('timeSetGsmToken', unixTime.toString());
};

export const getTimeSetGsmToken = () => {
  return sessionStorage.getItem('timeSetGsmToken');
};

export const getGsmToken = () => {
  return sessionStorage.getItem('gsmToken');
};

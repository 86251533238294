import { pathConstants } from 'src/constants/const/path.constants';

export const pageViewName = {
  HOME: 'home',
  ACTIVITY: 'activity',
  FEED: 'feed',
  TOURNAMENT: 'event',
  LOCATIONS: 'locations',
  COURSE: 'course',
  SETTING: 'settings',
  POPUP: 'popup',
  SCREENLOGIN: 'screen_login',
  SCREENLOGINQ: 'screen_login_q',
  NOTIFICATIONS: 'notifications',
  MYPAGE: 'my_page',
  BANNER: 'banner',
  TRENDINGLOCATIONS: 'trending_locations',
  NOTICES: 'notices',
  TERMSOFUSE: 'terms_of_use',
  PRIVACYPOLICY: 'privacy_policy',
  LOGIN: 'login',
  SIGN_UP: 'sign_up',
  FIND_ID_BY_EMAIL: 'find_id_by_email',
  FIND_ID_BY_PHONE: 'find_id_by_phone',
  FIND_PASSWORD: 'find_password',
  LOCATIONS_MAP: 'locations_map',
  LOCATIONS_DETAIL: 'location_detail',
  COURSE_DETAIL: 'course_detail',
  GS_SCORES_DETAIL: 'gs_scores_detail',
  GDR_DRIVING_RANGE_DETAIL: 'gdr_driving_range_detail',
  GDR_PRACTICE_ROUNDS_DETAIL: 'gdr_practice_rounds_detail',
  MY_PRACTICE_DRIVING_RANGE_DETAIL: 'my_practice_driving_range_detail',
  MY_PRACTICE_APPROACH_DETAIL: 'my_practice_approach_detail',
  MY_PRACTICE_PUTTING_DETAIL: 'my_practice_putting_detail',
  MY_PRACTICE_SWING_VIDEO_DETAIL: 'my_practice_sv_detail',
};

export const eventName = {
  LOGIN_SUCCESS: 'login_success',
  POPUP_CONTENTS: 'pop_up_contents',
  POPUP_END: 'pop_up_end',
  POPUP_DONT_SHOW: 'pop_up_dont_show',
  NAVIGATIONBAR_HOME: 'navigation_bar_home',
  NAVIGATIONBAR_ACTIVITY: 'navigation_bar_activity',
  NAVIGATIONBAR_FEED: 'navigation_bar_feed',
  NAVIGATIONBAR_EVENT: 'navigation_bar_event',
  NAVIGATIONBAR_LOCATIONS: 'navigation_bar_locations',
  NAVIGATIONBAR_COURSE: 'navigation_bar_course',
  NAVIGATIONBAR_SETTINGS: 'navigation_bar_settings',
  MENUCARD_MYPAGE: 'menu_card_my_page',
  MENUCARD_FAQ: 'menu_card_faq',
  MENUCARD_SCORECARD: 'menu_card_scorecard',
  MENUCARD_ROUNDSV: 'menu_card_round_sv',
  MENUCARD_GDR: 'menu_card_gdr',
  MENUCARD_GDRSV: 'menu_card_gdr_sv',
  MENUCARD_MYPRACTICE: 'menu_card_my_practice',
  MENUCARD_MYPRACTICE_SV: 'menu_card_my_practice_sv',
  SCREENLOGIN_ROUND: 'screen_login_round',
  SCREENLOGIN_GDR: 'screen_login_gdr',
  SCREENLOGINQ_ROUND: 'screen_login_q_round',
  SCREENLOGINQ_GDR: 'screen_login_q_gdr',
  SETTING_EDIT_MYINFO: 'setting_edit_my_info',
  SETTING_GOOGLE: 'setting_google',
  SETTING_INSTAGRAM: 'setting_instagarm',
  SETTING_FACEBOOK: 'setting_facebook',
  SETTING_LINE: 'setting_line',
  SETTING_WECHAT: 'setting_wechat',
  SETTING_APPLE: 'setting_apple',
  SETTING_CHANGE_PASSWORD: 'setting_change_password',
  SETTING_EMAIL_INFORMATION: 'setting_email_information',
  SETTING_LOCATION: 'setting_location',
  SETTING_UNIT_SETTING: 'setting_unit_setting',
  SETTING_LANGUAGE: 'setting_language',
  SETTING_NOTIFICATION: 'setting_notification',
  SETTING_VIDEO_PLAY: 'setting_video_play',
  SETTING_NOTICE: 'setting_notice',
  SETTING_FAQ: 'setting_faq',
  SETTING_TERM_OF_SERVICE: 'setting_terms_of_service',
  SETTING_PRIVACY_POLICY: 'setting_privacy_policy',
  SETTING_CONTACT_US: 'setting_contact_us',
  SETTING_ROUND_SETTINGS: 'setting_round_setting',
  SETTING_LOGOUT: 'setting_logout',
  SETTING_DEACTIVATE_ACCOUNT: 'setting_deactivate_account',
  MYPAGE_EDIT_MY_INFO: 'my_page_edit_my_info',
  MYPAGE_SETTINGS: 'my_page_settings',
  MYPAGE_STATS: 'my_page_stats',
  MYPAGE_RANKING: 'my_page_ranking',
  MYPAGE_FEED: 'my_page_feed',
  HOMERANKING_LEADERBOARDS: 'home_ranking_leaderboards',
  HOMETOURNAMENTS_LEADERBOARDS: 'home_event_leaderboards',
  HOMETOURNAMENTS_MORE: 'home_event_more',
  HOMECOURSES_NEW_COURSES: 'home_courses_new_courses',
  HOMECOURSES_POPULAR_COURSES: 'home_courses_popular_courses',
  HOMECOURSES_MORE: 'home_courses_more',
  HOMELOCATIONSNEARME_STORE: 'home_locations_near_me_store',
  HOMELOCATIONSNEARME_FIND_LOCATIONS: 'home_locations_near_me_find_locations',
  NOTICES_NEWS: 'notices_news',
  NOTICES_MORE: 'notices_more',
  LOGIN_LOGIN: 'login_login',
  LOGIN_LANGUAGE: 'login_language',
  LOGIN_FIND_PASSWORD: 'login_find_password',
  LOGIN_FIND_ID: 'login_find_id',
  LOGIN_CREATE_AN_ACCOUNT: 'login_create_an_account',
  LOGIN_GOOGLE: 'login_google',
  LOGIN_FACEBOOK: 'login_facebook',
  LOGIN_LINE: 'login_line',
  LOGIN_APPLE: 'login_apple',
  RANKING_DETAIL_LEVEL_FILTER: 'ranking_detail_level_filter',
  RANKING_DETAIL_GHCP_FILTER: 'ranking_detail_ghcp_filter',
  RANKING_DETAIL_SUB_COUNTRY_FILTER: 'ranking_detail_sub_country_filter',
  RANKING_DETAIL_COUNTRIES: 'ranking_detail_countries',
  RANKING_DETAIL_GENDER: 'ranking_detail_gender',
  RANKING_DETAIL_SAVE_AS_IMAGE: 'ranking_detail_save_as_image',
  RANKING_DETAIL_VIEW_PLAYER: 'ranking_detail_view_player',

  HOME_BOOKNOW: 'home_book_now',
  HOME_NONLOGIN_BOOKNOW: 'home_non_login_book_now',
  HOME_BOOKMORE: 'home_book_more',
  HOME_BOOKINGHISTORY: 'home_booking_history',
  HOME_MYRANKING_MORE: 'home_my_ranking_more',
  HOME_MYRANKING_STORE: 'home_my_ranking_store',
  HOME_MYRANKING_DETAIL: 'home_my_ranking_detail',
  FEED_LIKE: 'feed_like',
  FEED_COMMENT: 'feed_comment',
  FEED_NICKNAME: 'feed_nick_name',
  FEED_PLAY: 'feed_play',
  FEED_FILTER: 'feed_filter',
  FEED_ADD: 'feed_add',
  EVENT_TOURNAMENT_PARTICIPATED: 'event_tournament_participated',
  EVENT_TOURNAMENT_MORE: 'event_tournament_more',
  EVENT_TOURNAMENT_SUGGESTED: 'event_tournament_suggested',
  EVENT_LEAGUE_PARTICIPATED: 'event_league_participated',
  EVENT_LEAGUE_MORE: 'event_league_more',
  EVENT_LEAGUE_SUGGESTED: 'event_league_recommended',
  EVENT_TOURNAMENT: 'event_tournament',
  EVENT_TOURNAMENT_DETAIL: 'event_tournament_detail',
  EVENT_TOURNAMENT_COUNTRY_FILTER: 'event_tournament_country_filter',
  EVENT_TOURNAMENT_REGION_FILTER: 'event_tournament_region_filter',
  EVENT_TOURNAMENT_TYPE_FILTER: 'event_tournament_type_filter',
  EVENT_SEARCH_TOURNAMENT: 'event_search_tournament',
  EVENT_LEAGUE: 'event_league',
  EVENT_LEAGUE_DETAIL: 'event_league_detail',
  EVENT_LEAGUE_REQUESTED_FILTER: 'event_league_requested_filter',
  EVENT_LEAGUE_STORE_FILTER: 'event_league_store_filter',
  EVENT_LEAGUE_STATUS_FILTER: 'event_league_status_filter',
  EVENT_SEARCH_LEAGUE: 'event_search_league',
  LOCATIONS_CHOOSE_AS_FAVORITE: 'locations_choose_as_favorite',
  LOCATIONS_VIEW_DETAIL: 'locations_view_detail',
  LOCATIONS_VIEW_IMAGES: 'locations_view_images',
  LOCATIONS_BOOKNOW_NEARME: 'locations_book_now_near_me',
  LOCATIONS_BOOKNOW: 'locations_book_now',
  LOCATIONS_HISTORY_BOOKING: 'locations_history_booking',
  LOCATIONS_NONLOGIN_BOOKNOW: 'locations_non_login_book_now',
  LOCATIONS_LOCATOR: 'locations_locator',
  LOCATIONS_MYFAVORITE: 'locations_my_favorite',
  LOCATIONS_FEED: 'locations_feed',
  COURSE_FAVORITE_COURSES: 'course_favorite_courses',
  COURSE_NEWLY_COURSES: 'course_newly_courses',
  COURSE_SUGGESTED_COURSES: 'course_suggested_courses',
  COURSE_CURRENTLY_PLAYED: 'course_currently_played',
  COURSE_SEARCH_COURSES: 'course_search_courses',
  COURSE_FILTER_COURSES: 'course_filter_courses',

  MENUCARD_MYPAGE_EDIT_MYINFO: 'menu_card_my_page_edit_my_info',
  MENUCARD_MYPAGE_SETTINGS: 'menu_card_my_page_settings',
  MENUCARD_MYPAGE_STATS: 'menu_card_my_page_stats',
  MENUCARD_MYPAGE_RANKING: 'menu_card_my_page_ranking',
  MENUCARD_MYPAGE_FEED: 'menu_card_my_page_feed',
  MENUCARD_FAQ_WHATISLEVEL: 'menu_card_faq_what_is_level',
  MENUCARD_SCORECARD_BACK: 'menu_card_scorecard_back',
  MENUCARD_SCORECARD_STORE: 'menu_card_scorecard_store',
  MENUCARD_SCORECARD_SCORECARDS: 'menu_card_scorecard_scorecards',
  MENUCARD_SCORECARD_SWINGVIDEO: 'menu_card_scorecard_swing_video',
  MENUCARD_SCORECARD_HIGHLIGHTS_PLAY: 'menu_card_scorecard_highlights_play',
  MENUCARD_SCORECARD_SEEMORE_VIDEOS: 'menu_card_scorecard_see_more_videos',
  MENUCARD_SCORECARD_LEADERBOARD: 'menu_card_scorecard_leaderboard',
  MENUCARD_ROUNDSV_PLAY: 'menu_card_round_sv_play',
  MENUCARD_ROUNDSV_OTHER: 'menu_card_round_sv_other',
  MENUCARD_GDR_BACK: 'menu_card_gdr_back',
  MENUCARD_GDR_ANALYSISBY_CLUBS: 'menu_card_gdr_analytics_by_clubs',
  MENUCARD_GDR_PLAY: 'menu_card_gdr_play',
  MENUCARD_GDR_FRONTVIEW: 'menu_card_gdr_front_view',
  MENUCARD_GDR_SIDEVIEW: 'menu_card_gdr_side_view',
  MENUCARD_GDRSV_PLAY: 'menu_card_gdr_sv_play',
  MENUCARD_GDRSV_OTHER: 'menu_card_gdr_sv_other',
  MENUCARD_MYPRACTICE_TOLEARNMORE: 'menu_card_my_practice_to_learn_more',
  MENUCARD_MYPRACTICE_OTHER: 'menu_card_my_practice_other',
  MENUCARD_MYPRACTICE_DRIVINGRANGE: 'menu_card_mypractice_driving_range',
  MENUCARD_MYPRACTICE_APPROACH: 'menu_card_mypractice_approach',
  MENUCARD_MYPRACTICE_PUTTING: 'menu_card_mypractice_putting',
  MENUCARD_MYPRACTICE_SWINGVIDEO: 'menu_card_mypractice_swing_video',
  MENUCARD_MYPRACTICESV_PLAY: 'menu_card_my_practicesv_play',

  NOTIFICATIONS_LIST: 'notifications_-_list',
  NOTIFICATIONS_BACK: 'notifications_-_back',

  SETTING_EDITMYINFO_BACK: 'setting_edit_my_info_back',
  SETTING_EDITMYINFO_SAVE: 'setting_edit_my_info_save',
  SETTING_EDITMYINFO_ACCESS_VIA_QR_CODE_SCAN: 'setting_edit_my_info_access_via_qr',
  SETTING_EDIT_MY_INFO_QR_CODE_EDIT_SUCCESSFUL: 'setting_edit_my_info_qr_edit_successful',
  SETTING_CHANGEPASSWORD_BACK: 'setting_change_password_back',
  SETTING_CHANGEPASSWORD_SAVE: 'setting_change_password_save',
  SETTING_CHANGEPASSWORD_FINDPASSWORD: 'setting_change_password_find_password',
  SETTING_EMAIL_BACK: 'setting_email_information_back',
  SETTING_EMAIL_VERIFY: 'setting_email_information_verify',
  SETTING_EMAIL_REVERIFY: 'setting_email_information_reverify',
  SETTING_EMAIL_SAVE: 'setting_email_information_save',
  SETTING_LOCATION_BACK: 'setting_location_back',
  SETTING_LOCATION_LOCATIONNAME: 'setting_location_locationname',
  SETTING_UNIT_SETTING_BACK: 'setting_unit_setting_back',
  SETTING_UNIT_SETTING_SAVE: 'setting_distance_save',
  SETTING_UNIT_SETTING_DISTANCE: 'setting_unit_setting_distance',
  SETTING_UNIT_SETTING_DISTANCE_TO_GREEN: 'setting_unit_setting_distance_to_green',
  SETTING_UNIT_SETTING_SPEED: 'setting_unit_setting_speed',
  SETTING_UNIT_SETTING_MAP_DISTANCE_UNIT: 'setting_unit_setting_map_distance_unit',
  SETTING_ROUND_SETTING_BACK: 'setting_round_setting_back',
  SETTING_ROUND_SETTING_SAVE: 'setting_round_setting_save',
  SETTING_ROUND_SETTING_TEE_POSITION: 'setting_round_setting_tee_position',
  SETTING_ROUND_SETTING_TEE_HIGH: 'setting_round_setting_tee_high',
  SETTING_ROUND_SETTING_DIFFICULTY: 'setting_round_setting_difficulty',
  SETTING_ROUND_SETTING_FAVORITE_CLUB: 'setting_round_setting_favorite_club',
  SETTING_ROUND_SETTING_SOUND_SUPPORT: 'setting_round_setting_sound_support',
  SETTING_ROUND_SETTING_ADD_CLUB: 'setting_round_setting_add_club',
  SETTING_ROUND_SETTING_REMOVE_CLUB: 'setting_round_setting_remove_club',
  SETTING_LANGUAGE_END: 'setting_language_end',
  SETTING_LANGUAGE_LANGUAGE_NAME: 'setting_language_language_name',
  SETTING_NOTIFICATION_BACK: 'setting_notification_back',
  SETTING_NOTIFICATION_OTHER: 'setting_notification_other',
  SETTING_VIDEOPLAY_END: 'setting_videoplay_end',
  SETTING_VIDEOPLAY_NEVER: 'setting_videoplay_never',
  SETTING_VIDEOPLAY_WIFI: 'setting_videoplay_wifi',
  SETTING_VIDEOPLAY_ALWAYS: 'setting_videoplay_always',
  SETTING_NOTICE_BACK: 'setting_notice_back',
  SETTING_NOTICE_ALL: 'setting_notice_all',
  SETTING_NOTICE_NOTICE: 'setting_notice_notice',
  SETTING_NOTICE_CAMPAIGN: 'setting_notice_campaign',
  SETTING_NOTICE_TOURNAMENT: 'setting_notice_tournament',
  SETTING_NOTICE_EVENT: 'setting_notice_event',
  SETTING_NOTICE_GDR: 'setting_notice_gdr',
  SETTING_FAQ_BACK: 'setting_faq_back',
  SETTING_FAQ_ALL: 'setting_faq_all',
  SETTING_FAQ_CREATEANACCOUNT: 'setting_faq_create_an_account',
  SETTING_FAQ_LOGIN: 'setting_faq_login',
  SETTING_FAQ_MYPAGE: 'setting_faq_my_page',
  SETTING_FAQ_SWINGVIDEO: 'setting_faq_swing_video',
  SETTING_FAQ_ACTIVITIES: 'setting_faq_activities',
  SETTING_FAQ_TOURNAMENT: 'setting_faq_tournament',
  SETTING_FAQ_COURSE: 'setting_faq_course',
  SETTING_FAQ_SHOPPING: 'setting_faq_shopping',
  SETTING_FAQ_NEWS: 'setting_faq_news',
  SETTING_FAQ_SYSTEM: 'setting_faq_system',
  SETTING_TERMSOFSERVICE_BACK: 'setting_terms_of_service_back',
  SETTING_TERMSOFSERVICE_OTHER: 'setting_terms_of_service_other',
  SETTING_PRIVACYPOLICY_BACK: 'setting_privacy_policy_back',
  SETTING_PRIVACYPOLICY_OTHERVERSION: 'setting_privacy_policy_other_version',
  SETTING_LOGOUT_CANCEL: 'setting_logout_cancel',
  SETTING_LOGOUT_LOGOUT: 'setting_logout_logout',
  SETTING_DEACTIVATEACCOUNT_DEACTIVATE: 'setting_deactivate_account_deactivate',

  MYPAGE_EDITMYINFO_BACK: 'my_page_edit_my_info_back',
  MYPAGE_EDITMYINFO_SAVE: 'my_page_edit_my_info_save',
  MYPAGE_SETTINGS_BACK: 'my_page_settings_back',
  MYPAGE_ROUND_TAGSMORE: 'my_page_round_tags_more',
  MYPAGE_ROUND_BACK: 'my_page_round_back',
  MYPAGE_GDR_BACK: 'my_page_gdr_back',
  MYPAGE_FEED_BACK: 'my_page_feed_back',
  MYPAGE_RANKING_SAVEASIMAGE: 'my_page_ranking_save_as_image',
  MYPAGE_RANKING_DETAIL: 'my_page_ranking_detail',
  MYPAGE_RANKING_STORE: 'my_page_ranking_store',

  HOMERANKING_LEADERBOARDS_END: 'homeranking_leaderboards_end',
  HOMERANKING_LEADERBOARDS_STROKE: 'homeranking_leaderboards_stroke',
  HOMERANKING_LEADERBOARDS_NEWPERIO: 'homeranking_leaderboards_new_perio',
  HOMERANKING_LEADERBOARDS_SCORE: 'homeranking_leaderboards_score',
  HOMERANKING_LEADERBOARDS_MOSTROUNDS: 'homeranking_leaderboards_most_rounds',
  HOMERANKING_LEADERBOARDS_COMMENT: 'homeranking_leaderboards_comment',
  HOMEEVENT_LEADERBOARDS_END: 'home_event_leaderboards_end',
  HOMEEVENT_LEADERBOARDS_OTHER: 'home_event_leaderboards_other',
  HOMEEVENT_MORE_PARTICIPATED: 'home_event_more_participated',
  HOMEEVENT_MORE_PARTICIPATEDMORE: 'home_event_more_participated_more',
  HOMEEVENT_MORE_SUGGESTEDTOURNAMENT: 'home_event_more_suggested',
  HOMEEVENT_MORE_SEARCHTOURNAMENT: 'home_event_more_search_tournament',
  HOMEEVENT_MORE_FILTERTOUNAMENT: 'home_event_more_filter_tournament',
  HOMECOURSES_NEWCOURSES_BACK: 'home_courses_new_courses_back',
  HOMECOURSES_NEWCOURSES_SHARE: 'home_courses_new_courses_share',
  HOMECOURSES_NEWCOURSES_SAVE: 'home_courses_new_courses_save',
  HOMECOURSES_MORE_FAVORITECOURSES: 'home_courses_more_favorite_courses',
  HOMECOURSES_POPULARCOURSES_BACK: 'home_courses_popular_courses_back',
  HOMECOURSES_POPULARCOURSES_OTHER: 'home_courses_popular_courses_other',
  HOMECOURSES_MORE_BACK: 'home_courses_more_back',
  HOMECOURSES_MORE_OTHER: 'home_courses_more_other',
  HOMELOCATIONSNEARME_STORE_BACK: 'locations_near_me_store_back',
  HOMELOCATIONSNEARME_STORE_CALL: 'locations_near_me_store_call',
  HOMELOCATIONSNEARME_STORE_SHARE: 'locations_near_me_store_share',
  HOMELOCATIONSNEARME_STORE_FAVORITE: 'locations_near_me_store_favorite',
  HOMELOCATIONSNEARME_FINDLOCATIONS_BACK: 'locations_near_me_find_locations_back',
  HOMELOCATIONSNEARME_FINDLOCATIONS_SEARCH: 'locations_near_me_find_locations_search',
  HOMELOCATIONSNEARME_FINDLOCATIONS_SYSTEM: 'locations_near_me_find_locations_system',
  HOMELOCATIONSNEARME_FINDLOCATIONS_FACILITY: 'locations_near_me_find_locations_facilty',
  HOMELOCATIONSNEARME_FINDLOCATIONS_DISTANCE: 'locations_near_me_find_locations_distance',
  HOMELOCATIONSNEARME_FINDLOCATIONS_FAVORITE: 'locations_near_me_find_locations_fav',

  NOTICES_NEWS_BACK: 'notices_news_back',
  NOTICES_NEWS_OTHER: 'notices_news_other',
  NOTICES_MORE_BACK: 'notices_more_back',
  NOTICES_MORE_ALL: 'notices_more_all',
  NOTICES_MORE_NOTICE: 'notices_more_notice',
  NOTICES_MORE_CAMPAIGN: 'notices_more_campaign',
  NOTICES_MORE_TOURNAMENT: 'notices_more_tournament',
  NOTICES_MORE_EVENT: 'notices_more_event',
  NOTICES_MORE_GDR: 'notices_more_gdr',

  LOCATIONS_LOCATOR_SEARCH: 'locations_locator_search',
  LOCATIONS_LOCATOR_SYSTEM: 'locations_locator_system',
  LOCATIONS_LOCATOR_FACILITY: 'locations_locator_facility',
  LOCATIONS_LOCATOR_DISTANCE: 'locations_locator_distance',
  LOCATIONS_LOCATOR_LOCATION_LIST: 'locations_locator_location_list',
  LOCATIONS_LOCATOR_DETAIL_CALL: 'locations_location_detail_call',
  LOCATIONS_LOCATOR_DETAIL_BOOKNOW: 'locations_location_detail_book_now',
  LOCATIONS_LOCATOR_DETAIL_INFO: 'locations_location_detail_info',
  LOCATIONS_LOCATOR_DETAIL_COMMUNITY: 'locations_location_detail_community',
  LOCATIONS_LOCATOR_DETAIL_EVENT: 'locations_location_detail_event',
  LOCATIONS_LOCATOR_DETAIL_LEADERBOARD: 'locations_location_detail_leaderboard',
  LOCATIONS_LOCATOR_DETAIL_VIEW_EVENT: 'locations_location_detail_view_event',
  LOCATIONS_LOCATOR_DETAIL_ALL_DEAL: 'locations_location_detail_all_deal',
  LOCATIONS_LOCATOR_DETAIL_DEAL_BY_ROOM: 'locations_location_detail_deal_by_room',
  LOCATIONS_LOCATOR_DETAIL_DEAL_BY_9H: 'locations_location_detail_deal_by_9h',
  LOCATIONS_LOCATOR_DETAIL_DEAL_BY_18H: 'locations_location_detail_deal_by_18h',
  LOCATIONS_LOCATOR_DETAIL_VIEW_IMAGES: 'locations_location_detail_view_images',

  SIGNUP_EMAIL_VERIFY: 'signup_email_verify',
  SIGNUP_EMAIL_REVERIFY: 'signup_email_reverify',
  SIGNUP_CONTACT_WITH_CS: 'signup_contact_with_cs',
  FIND_ID_PHONE_VERIFY: 'find_id_phone_verify',
  FIND_ID_PHONE_REVERIFY: 'find_id_phone_reverify',
  FIND_ID_EMAIL_SEND_INFORMATION: 'find_id_email_send_information',
  FIND_ID_CONTACT_WITH_CS: 'find_id_contact_with_cs',
  FIND_PASSWORD_EMAIL_VERIFY: 'find_password_email_verify',
  FIND_PASSWORD_EMAIL_REVERIFY: 'find_password_email_reverify',
  FIND_PASSWORD_PHONE_VERIFY: 'find_password_phone_verify',
  FIND_PASSWORD_PHONE_REVERIFY: 'find_password_phone_reverify',
  FIND_PASSWORD_CONTACT_WITH_CS: 'find_password_contact_with_cs',

  ACTIVITY_ROUNDS: 'activity_rounds',
  ACTIVITY_GDR: 'activity_gdr',
  ACTIVITY_MYPRACTICE: 'activity_my_practice',
  ACTIVITY_ROUNDS_VIDEO_REQUESTAI: 'activity_rounds_video_requestAI',
  ACTIVITY_ROUNDS_VIDEO_VIEWAI: 'activity_rounds_video_viewAI',
  ACTIVITY_ROUNDS_VIDEO_VIEWAI_POSTURE: 'activity_rounds_video_viewAI_pos',
  ACTIVITY_ROUNDS_VIDEO_VIEWAI_RHYTHM: 'activity_rounds_video_viewAI_rhy',
  ACTIVITY_ROUNDS_VIDEO_VIEWAI_SEQUENCES: 'activity_rounds_video_viewAI_seq',
  ACTIVITY_ROUNDS_VIDEO_VIEWAI_LAGGING: 'activity_rounds_video_viewAI_lag',
  ACTIVITY_ROUNDS_VIDEO_VIEWAI_CLUBSPEED: 'activity_rounds_video_viewAI_clbsp',
  ACTIVITY_ROUNDS_PAR3CHALLENGE_LEADERBOARD: 'activity_rounds_par3_leaderboard',
  ACTIVITY_GDR_VIDEO_REQUESTAI: 'activity_gdr_video_requestAI',
  ACTIVITY_GDR_VIDEO_VIEWAI: 'activity_gdr_video_viewAI',
  ACTIVITY_GDR_VIDEO_VIEWAI_POSTURE: 'activity_gdr_video_viewAI_pos',
  ACTIVITY_GDR_VIDEO_VIEWAI_RHYTHM: 'activity_gdr_video_viewAI_rhy',
  ACTIVITY_GDR_VIDEO_VIEWAI_SEQUENCES: 'activity_gdr_video_viewAI_seq',
  ACTIVITY_GDR_VIDEO_VIEWAI_LAGGING: 'activity_gdr_video_viewAI_lag',
  ACTIVITY_GDR_VIDEO_VIEWAI_SWINGPATH: 'activity_gdr_video_viewAI_swingpath',
  ACTIVITY_GDR_VIDEO_VIEWAI_CLUBSPEED: 'activity_gdr_video_viewAI_clbspd',
  ACTIVITY_MYPRACTICE_VIDEO_REQUESTAI: 'activity_my_practice_video_requestAI',
  ACTIVITY_MYPRACTICE_VIDEO_VIEWAI: 'activity_my_practice_video_viewAI',
  ACTIVITY_MYPRACTICE_VIDEO_VIEWAI_POSTURE: 'activity_my_practice_video_viewAI_pos',
  ACTIVITY_MYPRACTICE_VIDEO_VIEWAI_RHYTHM: 'activity_my_practice_video_viewAI_rhy',
  ACTIVITY_MYPRACTICE_VIDEO_VIEWAI_SEQUENCES: 'activity_my_practice_video_viewAI_seq',
  ACTIVITY_MYPRACTICE_VIDEO_VIEWAI_LAGGING: 'activity_my_practice_video_viewAI_lag',
  ACTIVITY_MYPRACTICE_VIDEO_VIEWAI_CLUBSPEED: 'activity_my_practice_video_viewAI_clbsp',

  GWC2024_LANGUAGE: 'gwc2024_languague',
  GWC2024_TOURNAMENT_NO: 'gwc2024_tournament_no',
  GWC2024_TOP1_LEADERBOARD: 'gwc2024_top_1_leaderboard',
  GWC2024_SOUTH_AMERICA_ZONE: 'gwc2024_north_south_america_zone',
  GWC2024_ASIA_ZONE: 'gwc2024_asia_zone',
  GWC2024_EUROPE_OCEANIA_AFRICA_ZONE: 'gwc2024_europe_oceania_africa_zone',
  GWC2024_YARDS: 'gwc2024_yards',
  GWC2024_METERS: 'gwc2024_meters',
  GWC2024_PLAYER_RANKING_LEADERBOARD: 'gwc2024_player_ranking_leaderboard',
};

export const eventScreenType = {
  MENUCARD: 'mnc',
  MYPAGE: 'myp',
  SETTING: 'set',
  HOME: 'home',
  HOME_RANKING: 'hrk',
  HOME_TOURNAMENT: 'ht',
  HOME_MORECOURSES: 'hmc',
  HOME_NEWCOURSES: 'hnc',
  HOME_POPULARCOURSES: 'hpc',
};

export const mapCategoryToPath: Record<string, string> = {
  [pathConstants.HOME_PAGE]: pageViewName.HOME,
  [pathConstants.RECORDS_GS_ROUND]: pageViewName.ACTIVITY,
  [pathConstants.FEED_PAGE]: pageViewName.FEED,
  [pathConstants.TOURNAMENT_PAGE]: pageViewName.TOURNAMENT,
  [pathConstants.STORE_PAGE]: pageViewName.LOCATIONS,
  [pathConstants.ABOUT_COURSE]: pageViewName.COURSE,
  [pathConstants.ALARM_LIST]: pageViewName.NOTIFICATIONS,
  [pathConstants.SETTING]: pageViewName.SETTING,
  [pathConstants.NEWS]: pageViewName.NOTICES,
  [pathConstants.TERMS_OF_USE]: pageViewName.TERMSOFUSE,
  [pathConstants.PRIVACY_POLICY]: pageViewName.PRIVACYPOLICY,
  [pathConstants.LOGIN]: pageViewName.LOGIN,
  [pathConstants.SIGNUP]: pageViewName.SIGN_UP,
  [pathConstants.FIND_ID]: pageViewName.FIND_ID_BY_EMAIL,
  [pathConstants.FIND_ID_PHONE]: pageViewName.FIND_ID_BY_PHONE,
  [pathConstants.FIND_PASSWORD]: pageViewName.FIND_PASSWORD,
};

import axios from 'src/requests';
import {
  IAccountwithdrawParams,
  IMappingSocialParams,
  IDisconnectSocialParams,
  IMyCountryParams,
  IMyDistanceParams,
  IMyInfoParams,
  IMyLanguageParams,
  IMyAlarmInfoParams,
  IMyPasswordParams,
  IMyPhoneParams,
  IVideoAutoPlayParams,
  ICheckPasswordParams,
  IPageRowCodeParams,
  INoticeParams,
  ILogoutParams,
  IMyClubInfoParams,
} from 'src/requests/api/setting/prop-state.type';

const getMySettingInfo = async () => {
  const response = await axios.get('/system/getMySettingInfo');
  return response.data.data;
};

const updateMySNSConnect = async (body: IMappingSocialParams) => {
  const response = await axios.post('/system/sns/mapping', body);
  return response.data.data;
};

const updateDisconnectMySNS = async (body: IDisconnectSocialParams) => {
  const response = await axios.post('/system/sns/disconnect', body);
  return response.data.data;
};

const getMyInfo = async () => {
  const response = await axios.get('/system/getMyInfo');
  return response.data.data;
};

const uploadMyAvata = async (bodyFormData: FormData) => {
  const response = await axios.post('/upload/file/nickImg', bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data.data;
};

const updateMyInfo = async (body: IMyInfoParams) => {
  const response = await axios.post('/system/setMyInfo', body);
  return response.data.data;
};

const checkPasswordInfo = async (params: ICheckPasswordParams) => {
  const response = await axios.get('/system/checkPassword', { params });
  return response.data.data;
};

const updateMyPassword = async (body: IMyPasswordParams) => {
  const response = await axios.post('/system/setPassword', body);
  return response.data.data;
};

const updateMyPhone = async (body: IMyPhoneParams) => {
  const response = await axios.post('/system/setCellphone', body);
  return response.data.data;
};

const updateMyCountry = async (body: IMyCountryParams) => {
  const response = await axios.post('/system/setCountry', body);
  return response.data.data;
};

const getClubInfoDistance = async () => {
  const response = await axios.get('/system/getClubInfo');
  return response.data.data;
};

const setDistanceUnitInfo = async (body: IMyDistanceParams) => {
  const response = await axios.post('/system/setDistanceUnitInfo', body);
  return response.data.data;
};

const setClubInfoDistance = async (body: IMyClubInfoParams) => {
  const response = await axios.post('/system/setClubInfo', body);
  return response.data.data;
};

const setMyLanguage = async (body: IMyLanguageParams) => {
  const response = await axios.post('/system/setLanguage', body);
  return response.data.data;
};

const getAlarmInfo = async () => {
  const response = await axios.get('/system/getMyAlarmInfo');
  return response.data.data;
};

const setAlarmInfo = async (body: IMyAlarmInfoParams) => {
  const response = await axios.post('/system/setAlarm', body);
  return response.data.data;
};

const setAlarmInfoWithBookingLeague = async (body: IMyAlarmInfoParams) => {
  const response = await axios.post('/system/alarm-setting', body);
  return response.data.data;
};

const setVideoAutoPlay = async (body: IVideoAutoPlayParams) => {
  const response = await axios.post('/system/setAutoPlay', body);
  return response.data.data;
};

const getNewsList = async (params: INoticeParams) => {
  const response = await axios.get('/system/getNoticeList', { params });
  return response.data.data;
};

const getFaqList = async (params: IPageRowCodeParams) => {
  const response = await axios.get('/system/getFaqList', { params });
  return response.data.data;
};

const logout = async (params: ILogoutParams) => {
  try {
    const response = await axios.post('/system/logout', params);
    return response.data.data;
  } catch (error: any) {
    console.warn(error);
  }
};

const setAccountwithdraw = async (body: IAccountwithdrawParams) => {
  const response = await axios.post('/system/userLeave', body);
  return response.data.data;
};

export const setting = {
  getMySettingInfo,
  updateMySNSConnect,
  updateDisconnectMySNS,
  getMyInfo,
  uploadMyAvata,
  updateMyInfo,
  checkPasswordInfo,
  updateMyPassword,
  updateMyPhone,
  updateMyCountry,
  getClubInfoDistance,
  setDistanceUnitInfo,
  setClubInfoDistance,
  setMyLanguage,
  getAlarmInfo,
  setAlarmInfo,
  setAlarmInfoWithBookingLeague,
  setVideoAutoPlay,
  getNewsList,
  getFaqList,
  logout,
  setAccountwithdraw,
};

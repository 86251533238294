import * as pic from 'src/assets/images';
import { EnumSystemName, EnumSystemCode } from 'src/constants/enum';

export const courseLabel = {
  HOLE: 'H',
  STANDARD_NO: 'Par',
};

export interface ISystemData {
  name: string;
  code: string;
  logo: string;
  isSelected?: boolean;
}

export const systemFilterData: ISystemData[] = [
  {
    name: EnumSystemName.NX,
    code: EnumSystemCode.NX,
    logo: pic.picIconNx,
  },
  {
    name: EnumSystemName.TWO_VISION,
    code: EnumSystemCode.TWO_VISION,
    logo: pic.picIconTwoVision,
  },
  {
    name: EnumSystemName.VISION_PLUS,
    code: EnumSystemCode.VISION_PLUS,
    logo: pic.picIconVisionPlus,
  },
  {
    name: EnumSystemName.VISION,
    code: EnumSystemCode.VISION,
    logo: pic.picIconVision,
  },
  {
    name: EnumSystemName.REAL,
    code: EnumSystemCode.REAL,
    logo: pic.picIconReal,
  },
  {
    name: EnumSystemName.GDR,
    code: EnumSystemCode.GDR,
    logo: pic.picIconGDR,
  },
  {
    name: EnumSystemName.GDR_PLUS,
    code: EnumSystemCode.GDR_PLUS,
    logo: pic.picIconGdrPlus,
  },
];

export const HOLES_COLOR = [
  {
    number: 1,
    color: 'text-[#FFBD00]',
  },
  {
    number: 2,
    color: 'text-[#0179E6]',
  },
  {
    number: 3,
    color: 'text-[#00B050]',
  },
  {
    number: 4,
    color: 'text-[#00ADEA]',
  },
  {
    number: 5,
    color: 'text-[#33CAFF]',
  },
  {
    number: 6,
    color: 'text-[#6791FC]',
  },
  {
    number: 7,
    color: 'text-[#007BDC]',
  },
  {
    number: 8,
    color: 'text-[#FF5FB5]',
  },
  {
    number: 9,
    color: 'text-[#FF3640]',
  },
  {
    number: 10,
    color: 'text-[#FFFAEB]',
  },
  {
    number: 11,
    color: 'text-[#D9F5FF]',
  },
  {
    number: 12,
    color: 'text-[#F1733A]',
  },
  {
    number: 13,
    color: 'text-[#09D97C]',
  },
  {
    number: 14,
    color: 'text-[#00CC99]',
  },
  {
    number: 15,
    color: 'text-[#FEF7F5]',
  },
  {
    number: 16,
    color: 'text-[#DAEFFF]',
  },
  {
    number: 17,
    color: 'text-[#FF8A00]',
  },
  {
    number: 18,
    color: 'text-[#40ABFF]',
  },
];

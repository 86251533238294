export const scorecardConstants = {
  HOLE_HEADER: 'HOLE',
  HOLE_KEY: 'hole',
  TEAM_KEY: 'team',
  BASE_SCORE_HEADER: 'T',
  BASE_SCORE_KEY: 'baseScore',
  STABLE_TOTAL_SCORE_KEY: 'stableTotScore',
  LAS_VEGAS_HEADER: 'LAS VEGAS',
  LAS_VEGAS_KEY: 'lasvegas',
  HIDDEN_HOLE_HEADER: 'HIDDEN H',
  TOTAL_HEADER: 'TOTAL',
  TOTAL_KEY: 'hiddentotscore',
  HANDICAP_HEADER: 'HANDICAP',
  HANDICAP_KEY: 'handicap',
  NEW_PERIO_HEADER: 'NEW PERIO',
  NEW_PERIO_KEY: 'newparioscore',
  HOLE_NO_KEY: 'holeNo',
  STABLE_NO_KEY: 'stableScore',
  MATCH_KEY: 'match',
  MATCH_TOTAL_KEY: 'matchTotal',
  PAR_ID_VALUE: 'par',
  PAR_NAME_VALUE: 'PAR',
  PR_STATUS_KEY: 'prStatus',
  SCORE_NAME: 'SCORE',
  STROKE_NAME: 'STROKE',
  POINTS_NAME: 'POINTS',
  LEADZONE_NAME: 'LEADZONE',
  USER_NICKNAME_KEY: 'usrNicknm',
  SKINS_USER_NICKNAME_KEY: 'prNick',
  HIDDEN_SCORE: 100,
  NEW_PERIO_HIDDEN_SCORE: 0,
  ALTERNATIVE_HIDDEN_SCORE: '/',
  ALTERNATIVE_ZERO_MATCH: 'H',
};

export const scoreCardWidths = {
  HOLE_WIDTH: 20,
  HOLE_WITH_EMPTY_TEAM_WIDTH: 18,
  HOLE_WITH_TEAM_WIDTH: 16,
  TEAM_WIDTH: 4,
  EMPTY_TEAM_WIDTH: 2,
  DEFAULT_WIDTH: 8,
  DEFAULT_2X_WIDTH: 16,
  DEFAULT_3X_WIDTH: 24,
  MAX_WIDTH: 100,
};

export const scorecardGdrDetail = {
  HOLE: 'holeNo',
  PAR: 'basicPar',
  SCORE: 'score',
  TOTAL: 'T',
};

export const scorecardNumber = {
  GDR_NAN_VALUE: 99,
};

export const scoreTypeColor = {
  HOLE_IN_ONE: 'bg-green-20',
  CONDOR: 'bg-semantic-yellow',
  ALBATROSS: 'bg-pink-75',
  EAGLE: 'bg-orange-60',
  BIRDIE: 'bg-indigo-50',
  PAR: 'bg-orange-15',
  BOGEY: 'bg-cyan-75',
  DOUBLE_BOGEY: 'bg-pink-50',
  TRIPLE_BOGEY: 'bg-violet-50',
  QUADRUPLE_BOGEY: 'bg-semantic-violet-25',
  DOUBLE_PAR: 'bg-green-75',
};

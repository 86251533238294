import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';
import { history } from 'src/services/history';
import { IClubShot, IGDRDrivingClubResponse } from 'src/requests/api/record/prop-state.type';
import BallDirection from 'src/view/commons/customs/BallDirection';
import { constUnit, formatDate, pathConstants } from 'src/constants/const';

interface IDrivingItemProps {
  classWrapper?: string;
  item?: IGDRDrivingClubResponse;
  isDetail?: boolean;
}
const DrivingItem: React.FC<IDrivingItemProps> = React.memo(({ item, isDetail, classWrapper }) => {
  const translate = useTranslation().t;
  const dateItem = dayjs(item?.statDate).format(formatDate.BASIC);

  const renderItem = (arrayData: IClubShot[]) => {
    const itemMax = Math.max(...arrayData.map((el: IClubShot) => el.shotCnt));
    const totalShotCnt = arrayData.reduce((currentValue, item) => currentValue + Number(item.shotCnt), 0);
    return arrayData.map((element: IClubShot, index: number) => {
      const percent = (Number(element.shotCnt) * 100) / totalShotCnt;
      return (
        <div
          key={`club-shot-${index}`}
          className="gz-text-xsm flex items-center justify-between [&:not(:last-child)]:mb-[12px]"
        >
          <div className="w-[32px] shrink-0 uppercase">{element.clubName}</div>
          <div className="ml-[12px] mr-[14px] w-full">
            <div
              className={twMerge(
                'h-[8px] rounded-r-[8px]',
                itemMax === element.shotCnt ? 'bg-primary-75' : 'bg-gray-20',
              )}
              style={{ width: `${percent}%` }}
            />
          </div>
          <div className="flex w-[70px] flex-shrink-0 items-center justify-end">
            <span>
              {element.distanceAvg} {element.distUnit}
            </span>
            <span className="ml-[4px]">
              <BallDirection
                gdrBallPathCode={element.ballPath}
                classIcon={twMerge('w-[12px] h-[12px] w-auto')}
                hideText
              />
            </span>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className={twMerge('w-full rounded-[16px] p-[18px] shadow-xsm', !isDetail ? 'cursor-pointer' : '', classWrapper)}
      {...(!isDetail
        ? {
            onClick: () => history.push(pathConstants.RECORD_DETAIL_GDR_DRIVING(dateItem)),
          }
        : {})}
    >
      <div className="mb-[24px] flex justify-between">
        <div className="text-center">
          <div className="gz-text-md mb-[8px] font-bold">{item?.totalShotCnt}</div>
          <div className="gz-text-xsm text-sub-body">
            {translate('pages.record.type.GDR.driving_range.PRACTICE_SEE')}
          </div>
        </div>
        <div className="text-center">
          <div className="gz-text-md mb-[8px] font-bold">
            {item?.totalExcellentRate}
            {constUnit.PERCENT}
          </div>
          <div className="gz-text-xsm text-sub-body">{translate('pages.record.type.GDR.driving_range.EXCELLENT')}</div>
        </div>
        <div className="text-center">
          <div className="gz-text-md mb-[8px] font-bold">
            {item?.totalNiceRate}
            {constUnit.PERCENT}
          </div>
          <div className="gz-text-xsm text-sub-body">{translate('pages.record.type.GDR.driving_range.NICE')}</div>
        </div>
      </div>
      {item?.clubShotList && item?.clubShotList.length > 0 && renderItem(item.clubShotList)}
    </div>
  );
});

export default DrivingItem;

import { connect } from 'react-redux';
import { EnumNotificationPositionType } from 'src/constants/enum/notification.enum';
import { INotification } from 'src/models';
import { twMerge } from 'tailwind-merge';

import Notification from './Notification';

interface Props {
  notifications: INotification[];
  keyboardShow: boolean;
}

function NotificationContainer({ notifications, keyboardShow }: Props): JSX.Element {
  const isTopPosition = notifications?.some((e) => e.position === EnumNotificationPositionType.TOP);

  return (
    <div
      className={twMerge(
        'fixed z-[999] w-full text-center',
        isTopPosition || !!keyboardShow ? 'top-[44px]' : 'bottom-[24px] md:bottom-auto md:top-[20px]',
      )}
    >
      {notifications.map((notification: INotification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  notifications: state.notification,
  keyboardShow: state.webviewReducer.keyboardShow,
});

export default connect(mapStateToProps)(NotificationContainer);

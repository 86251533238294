export enum EnumFeedListType {
  RECENT,
  STORE,
  INDIVIDUAL,
}

export enum EnumFeedAction {
  LIKE,
  PIN,
  DELETE,
  REPORT,
}

export enum EnumFeedType {
  SCORE = 'SCORE',
  NASMO = 'NASMO',
  GDR = 'GDR',
  TRAINING_MODE = 'TRAINING',
  SO = 'SO',
}

export enum EnumViewMoreOption {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  PIN = 'PIN',
  UNPIN = 'UNPIN',
  REPORT = 'REPORT',
}

import {
  picIconGDR,
  picIconGdrPlus,
  picIconNx,
  picIconReal,
  picIconTwoVision,
  picIconVision,
  picIconVisionPlus,
} from 'src/assets/images';
import { EnumSystemCode } from 'src/constants/enum';
import { twMerge } from 'tailwind-merge';

interface ISystemVersionProps {
  className?: string;
  classImg?: string;
  type?: EnumSystemCode;
}

function SystemVersion({ className, classImg, type }: ISystemVersionProps): JSX.Element {
  let img = picIconTwoVision;
  if (type === EnumSystemCode.NX) img = picIconNx;
  if (type === EnumSystemCode.VISION) img = picIconVision;
  if (type === EnumSystemCode.VISION_PLUS) img = picIconVisionPlus;
  if (type === EnumSystemCode.REAL) img = picIconReal;
  if (type === EnumSystemCode.GDR) img = picIconGDR;
  if (type === EnumSystemCode.GDR_PLUS) img = picIconGdrPlus;

  if (type == null || !Object.values(EnumSystemCode).includes(type)) {
    return <></>;
  }

  return (
    <span className={twMerge('h-[16px] w-[16px] items-center justify-center', className)}>
      <img className={twMerge('h-[16px] w-[16px]', classImg)} src={img} alt="" />
    </span>
  );
}

export default SystemVersion;

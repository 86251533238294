import axios from 'src/requests';
import { ICountryRequest, IRegionRequest } from 'src/requests/api/common/prop-state.type';

const getCountryList = async (params?: ICountryRequest) => {
  const response = await axios.get('/common/countryInfoList', { params });
  return response.data.data;
};

const getRegionList = async (params: IRegionRequest) => {
  const response = await axios.get('/common/stateCityList', { params });
  return response.data.data;
};

export const commonMiddleware = {
  getCountryList,
  getRegionList,
};

import { useState } from 'react';
import { IconScrollUp } from 'src/assets/svg';
import { twMerge } from 'tailwind-merge';
import { isDesktop } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { pathConstants } from 'src/constants/const';

function ButtonScrollToTop() {
  const location = useLocation();
  const [showBtn, setShowBtn] = useState(false);

  window.onscroll = () => {
    if (window.pageYOffset < 100 && showBtn) {
      setShowBtn(false);
    } else if (window.pageYOffset > 100 && !showBtn) {
      setShowBtn(true);
    }
  };

  // Add setTimeout to run after mount DOM
  const scrollToTop = () => setTimeout(() => window.scrollTo(0, 0));

  if (location.pathname === pathConstants.ROULETTE) return <></>;

  return showBtn && isDesktop ? (
    <div
      onClick={scrollToTop}
      className={twMerge(
        'fixed right-[24px] z-99 flex h-[36px] w-[36px] items-center justify-center rounded-full bg-gray-secondary',
        'bottom-[30px]',
      )}
    >
      <IconScrollUp />
    </div>
  ) : (
    <></>
  );
}

export default ButtonScrollToTop;

export const saveLocalStorage = (name: string, value: string) => {
  localStorage.setItem(name, value);
};

export const getLocalStorage = (name: string) => {
  return localStorage.getItem(name);
};

export const saveObjectLocalStorage = (name: string, value: any) => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const getObjectLocalStorage = (name: string) => {
  return JSON.parse(localStorage.getItem(name) || '{}');
};

export const removeLocalStorage = (name: string) => {
  localStorage.removeItem(name);
};

export const resetLocalStorage = () => {
  localStorage.clear();
};

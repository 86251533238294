import { useEffect, useState } from 'react';
import { isOpeningKeyboard } from 'src/services/mobile-keyboard';

const useDetectKeyboardOpen = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const listener = () => {
      if (!window.visualViewport) return;
      const newState = isOpeningKeyboard();
      if (isKeyboardOpen !== newState) {
        setIsKeyboardOpen(newState);
      }
      document.documentElement.style.setProperty('--viewport-height', `${window.visualViewport.height}px`);
    };
    window.visualViewport?.addEventListener('resize', listener);
    return () => {
      window.visualViewport?.removeEventListener('resize', listener);
    };
  }, [isKeyboardOpen]);

  return isKeyboardOpen;
};

export default useDetectKeyboardOpen;

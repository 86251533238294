import React from 'react';
import { useTranslation } from 'react-i18next';
import { IItemOption } from 'src/models/profile';
import { twMerge } from 'tailwind-merge';

interface IProps {
  options: IItemOption[];
  value: string;
  onChange: (value: string) => void;
  isTranslate?: boolean;
}

export default function FilterPopUp({ options = [], value, onChange, isTranslate = false }: IProps) {
  const translate = useTranslation().t;
  return (
    <div>
      {options?.map((item) => (
        <div key={item.value}>
          <div
            onClick={() => onChange(item.value)}
            className={twMerge(
              'cursor-pointer py-[10px] text-center text-[14px] leading-[24px]',
              value === item.value && 'bg-lavender text-darkblue-60',
            )}
          >
            {isTranslate ? translate(item.title) : item.title}
          </div>
          <div className="border-b border-solid border-gray-20" />
        </div>
      ))}
    </div>
  );
}

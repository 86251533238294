import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import DropdownMenu from 'src/view/commons/elements/DropdownMenu';
import { locales, pathConstants, searchParamsContants } from 'src/constants/const';
import { IconAngleLeft, iconGlobe } from 'src/assets/svg';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import { ILogoutOptions, authActions } from 'src/store/account/login';
import { IWebviewData } from 'src/store/webview';
import { useStickyHeaderOnSafari } from 'src/hooks/sticky-header-on-Safari';
import { history } from 'src/services/history';
import { settingDispatch } from 'src/store/setting';
import { firebaseInstance } from 'src/init-firebase';
import { eventName } from 'src/constants/const/firebase.constants';
import { useNoHeaderSearchParam } from 'src/hooks/use-no-header-search-param';
import { EnumNoHeaderSearchParams } from 'src/constants/enum';

interface IHeaderProps {
  logout: (options: ILogoutOptions) => void;
  userInfo: IMyInfoResponse | null;
  webviewReducer: IWebviewData;
}

function Header({ logout, webviewReducer }: IHeaderProps): JSX.Element {
  useStickyHeaderOnSafari();
  const location = useLocation();
  const dispatch = useDispatch();
  const noHeader = useNoHeaderSearchParam();
  const [currentLang, setCurrentLang] = useState<string>();
  const settingInfo = useSelector((state: any) => state.settingInfoReducer.settingInfo);

  const { language: appLanguage, isLoggedIn } = webviewReducer;
  const isLoginPath = location.pathname === pathConstants.LOGIN;

  useEffect(() => {
    if (!!appLanguage && !isLoggedIn) {
      setCurrentLang(appLanguage);
    }
  }, [appLanguage, isLoggedIn]);

  useEffect(() => {
    if (isLoginPath && !!settingInfo?.langCd) {
      setCurrentLang(settingInfo?.langCd);
    }
  }, [location, settingInfo.langCd]);

  useEffect(() => {
    setCurrentLang(settingInfo.langCd);
  }, [settingInfo.langCd]);

  const changeLanguage = (language: string) => {
    firebaseInstance.trackEvent(eventName.LOGIN_LANGUAGE, true, {
      selected_language: language,
    });
    setCurrentLang(language);
    dispatch(
      settingDispatch.info({
        langCd: language,
      }),
    );
  };

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (location.pathname === pathConstants.UPDATE_NUMBER) {
      logout({
        replace: true,
        redirectPath: pathConstants.LOGIN,
        clearedSearchParams: [searchParamsContants.PREV_IDX],
      });
      return;
    }
    history.pop();
  };

  useEffect(() => {
    if (location.pathname === pathConstants.UPDATE_NUMBER) {
      window.history.pushState(window.history.state, '', document.URL);
      window.addEventListener('popstate', onBackButtonEvent);
    }
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
    };
  }, [location]);

  const back = () => {
    history.pop();
  };

  const headerElement = () => {
    return (
      <>
        <div>
          {(!isLoginPath || !!window.history?.state?.idx) &&
            noHeader !== EnumNoHeaderSearchParams.BACK_KEEP &&
            noHeader !== EnumNoHeaderSearchParams.BACK_ONCE && (
              <>
                <div className="flex w-full items-center py-[8px]">
                  <div
                    className="ml-[8px] flex flex-shrink-0 cursor-pointer
             rounded-[50%] p-[8px] active:bg-light-gray"
                    onClick={back}
                  >
                    <IconAngleLeft className="h-[16px] w-[16px] stroke-title" />
                  </div>
                </div>
              </>
            )}
        </div>
        <div>
          {isLoginPath && (
            <div className="gz-text-xs mr-[16px] flex items-center">
              <img src={iconGlobe} alt="union" className="h-[16px] w-[16px]" aria-hidden="true" />
              <DropdownMenu
                value={currentLang}
                items={locales}
                classItem={isMobile ? 'hover:bg-gz-white w-[104px]' : 'w-[128px]'}
                onChange={changeLanguage}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  if (noHeader === EnumNoHeaderSearchParams.KEEP || noHeader === EnumNoHeaderSearchParams.ONCE) {
    return <></>;
  }

  if (isMobile && isIOS) {
    return (
      <header id="ios-toolbar-wrap" className={twMerge('sticky top-0 z-10 w-full')}>
        <div
          id="ios-toolbar"
          className={twMerge('absolute left-0 right-0 bg-gz-white', 'flex items-center justify-between')}
        >
          {headerElement()}
        </div>
      </header>
    );
  }

  return <header className={twMerge('flex items-center justify-between')}>{headerElement()}</header>;
}

const mapStateToProps = (state: any) => ({
  userInfo: state.mainInfoReducer.userInfo,
  webviewReducer: state.webviewReducer,
});
const mapDispatchToProps = {
  logout: authActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import i18n from 'src/locale';
import { NotificationType } from 'src/models/notification';
import { notificationActions } from 'src/store/notification';
import { EnumRequestCode, EnumValueBoolean, pageSize } from 'src/constants/enum';
import {
  IFeedData,
  IGsSwingVideoFeedData,
  IGdrSwingVideoFeedData,
  IScoreCardFeedData,
  IOffsetFeed,
  IStoreFeedData,
  IIndividualFeedData,
  IFeedCommentAndLikeCount,
  IParamFilter,
  ITmSwingVideoFeedData,
} from 'src/store/feed/reducer';
import { storeFeed } from 'src/requests/api/feed/store-news';
import { profileRequests } from 'src/requests/api/profile';
import { EnumFeedAction, EnumFeedListType } from 'src/constants/enum/feed.enum';
import { IFeed } from 'src/models';
import { booleanValue } from 'src/services/utils';

export const FEED_OFFSET = 'FEED_OFFSET';
export const RESET_FEED = 'RESET_FEED';
export const GET_RECENT_FEED = 'GET_RECENT_FEED';
export const GET_STORE_FEED = 'GET_STORE_FEED';
export const GET_INDIVIDUAL_FEED = 'GET_INDIVIDUAL_FEED';
export const GET_SCORE_CARD_FEED = 'GET_SCORE_CARD_FEED';
export const GET_GS_SWING_VIDEO_FEED = 'GET_GS_SWING_VIDEO_FEED';
export const GET_GDR_SWING_VIDEO_FEED = 'GET_GDR_SWING_VIDEO_FEED';
export const GET_TM_SWING_VIDEO_FEED = 'GET_TM_SWING_VIDEO_FEED';
export const UPDATE_COMMENT_AND_LIKE_COUNT = 'UPDATE_COMMENT_AND_LIKE_COUNT';
export const SET_PARAMS_FILTER = 'UPDATE_PARAMS_FILTER';

export const feed = {
  setOffset: (dataOffset: IOffsetFeed) => ({
    type: FEED_OFFSET,
    payload: {
      offsetListFeed: dataOffset,
    },
  }),
  resetFeed: () => ({
    type: RESET_FEED,
  }),
  getRecentFeed: (dataObject: IFeedData) => ({
    type: GET_RECENT_FEED,
    payload: {
      dataRecentFeed: dataObject,
    },
  }),
  getStoreFeed: (dataObject: IStoreFeedData) => {
    return {
      type: GET_STORE_FEED,
      payload: {
        dataStoreFeed: dataObject,
      },
    };
  },
  getIndividualFeed: (dataObject: IIndividualFeedData) => ({
    type: GET_INDIVIDUAL_FEED,
    payload: {
      dataIndividualFeed: dataObject,
    },
  }),
  getGsSwingVideoFeed: (dataObject: IGsSwingVideoFeedData) => ({
    type: GET_GS_SWING_VIDEO_FEED,
    payload: {
      dataGsSwingVideoFeed: dataObject,
    },
  }),
  getGdrSwingVideoFeed: (dataObject: IGdrSwingVideoFeedData) => ({
    type: GET_GDR_SWING_VIDEO_FEED,
    payload: {
      dataGdrSwingVideoFeed: dataObject,
    },
  }),
  getTmSwingVideoFeed: (dataObject: ITmSwingVideoFeedData) => ({
    type: GET_TM_SWING_VIDEO_FEED,
    payload: {
      dataTmSwingVideoFeed: dataObject,
    },
  }),
  getScoreCardFeed: (dataObject: IScoreCardFeedData) => ({
    type: GET_SCORE_CARD_FEED,
    payload: {
      dataScoreCardFeed: dataObject,
    },
  }),
  setParamsFilter: (dataObject: IParamFilter) => ({
    type: SET_PARAMS_FILTER,
    payload: {
      dataFilter: dataObject,
    },
  }),
};

const getRecentFeed =
  (isLoadingMore: boolean = false, paramsFilter?: IParamFilter) =>
  async (dispatch: any, getState: any) => {
    const stateRedux = getState().feedReducer;
    const prevStateRedux = stateRedux.dataRecentFeed;
    if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
    try {
      const currentPage = prevStateRedux.loaded + 1;
      const params = {
        page: currentPage,
        rows: pageSize.SPECIAL,
        ...paramsFilter,
      };
      const result = await storeFeed.getFeedList(params);
      const dataObject = getDataObjectFeed(result, currentPage, prevStateRedux);
      dispatch(feed.getRecentFeed(dataObject));
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  };

const refreshRecentFeed = (paramsFilter?: IParamFilter) => async (dispatch: any, getState: any) => {
  try {
    const params = {
      page: 1,
      rows: pageSize.SPECIAL,
      ...paramsFilter,
    };
    const result = await storeFeed.getFeedList(params);
    const dataObject = getDataObjectFeed(result, 1, {
      values: [],
      loaded: 0,
      lastPage: false,
    });
    dispatch(feed.getRecentFeed(dataObject));
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const getStoreFeed =
  (isLoadingMore: boolean = false, storeId?: number, paramsFilter?: IParamFilter) =>
  async (dispatch: any, getState: any) => {
    const stateRedux = getState().feedReducer;
    const prevStateRedux = stateRedux.dataStoreFeed;
    if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
    try {
      const currentPage = prevStateRedux.loaded + 1;
      const params = {
        page: currentPage,
        rows: pageSize.SPECIAL,
        detailNo: storeId,
        ...paramsFilter,
      };
      const result = await storeFeed.getFeedList(params);
      const dataObject = getDataObjectFeed(result, currentPage, prevStateRedux);
      dispatch(feed.getStoreFeed({ ...dataObject, storeId }));
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  };

const refreshStoreFeed = (storeId?: number, paramsFilter?: IParamFilter) => async (dispatch: any, getState: any) => {
  try {
    const params = {
      page: 1,
      rows: pageSize.SPECIAL,
      detailNo: storeId,
      ...paramsFilter,
    };
    const result = await storeFeed.getFeedList(params);
    const dataObject = getDataObjectFeed(result, 1, {
      values: [],
      loaded: 0,
      lastPage: false,
    });
    dispatch(feed.getStoreFeed({ ...dataObject, storeId }));
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const getIndividualFeed =
  (isLoadingMore: boolean = false, userNo?: string) =>
  async (dispatch: any, getState: any) => {
    const stateRedux = getState().feedReducer;
    const loggingUserNo = getState().mainInfoReducer.userInfo?.usrNo?.toString();
    const prevStateRedux = stateRedux.dataIndividualFeed;
    if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
    try {
      const currentPage = prevStateRedux.loaded + 1;
      const params = {
        page: currentPage,
        rows: pageSize.SPECIAL,
      } as any;
      if (loggingUserNo !== userNo) {
        params.partnerUsrNo = userNo;
      }
      const result = await profileRequests.getFeedList(params);
      const dataObject = getDataObjectFeed(result, currentPage, prevStateRedux);
      dispatch(feed.getIndividualFeed({ ...dataObject, userNo }));
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  };

const refreshIndividualFeed = (userNo?: string) => async (dispatch: any, getState: any) => {
  try {
    const loggingUserNo = getState().mainInfoReducer.userInfo?.usrNo?.toString();
    const params = {
      page: 1,
      rows: pageSize.SPECIAL,
    } as any;
    if (loggingUserNo !== userNo) {
      params.partnerUsrNo = userNo;
    }
    const result = await profileRequests.getFeedList(params);
    const dataObject = getDataObjectFeed(result, 1, {
      values: [],
      loaded: 0,
      lastPage: false,
    });
    dispatch(feed.getIndividualFeed({ ...dataObject, userNo }));
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const getDataObjectFeed = (result: any, currentPage: number, prevData: any) => {
  if (result.code === EnumRequestCode.FAILED) throw new Error("Can't get list Feed");
  if (result.entities.length < 1) {
    return {
      ...prevData,
      loaded: currentPage,
      lastPage: true,
    };
  } else {
    const prevFeedIds = prevData.values.map((feed: IFeed) => feed.feedNo);
    const additionalFeed = result.entities
      .map((feed: IFeed) => {
        return {
          ...feed,
          order: booleanValue(feed.fixYn) ? 0 : 1,
        };
      })
      .filter((feed: IFeed) => !prevFeedIds.includes(feed.feedNo)); // Prevent from duplicated feeds
    const dataList = [...prevData.values, ...additionalFeed];
    const isLast = result.totalCount <= dataList.length;
    return {
      values: dataList,
      loaded: currentPage,
      lastPage: isLast,
    };
  }
};

/*
  1. Find feed in the reducer by feedNo and feedListType
  2. Handle feed based on the feedAction
  3. Update reducer
*/
const updateFeedById =
  (feedNo: number, feedListType: EnumFeedListType, feedAction: EnumFeedAction) =>
  async (dispatch: any, getState: any) => {
    const stateRedux = getState().feedReducer;
    if (feedListType === EnumFeedListType.RECENT || feedListType === EnumFeedListType.STORE) {
      const recentFeedState = getUpdatedFeedState(feedNo, feedAction, stateRedux.dataRecentFeed);
      const storeFeedState = getUpdatedFeedState(feedNo, feedAction, stateRedux.dataStoreFeed);
      dispatch(feed.getRecentFeed({ ...recentFeedState }));
      dispatch(feed.getStoreFeed({ ...storeFeedState }));
    } else if (feedListType === EnumFeedListType.INDIVIDUAL) {
      const individualFeedState = getUpdatedFeedState(feedNo, feedAction, stateRedux.dataIndividualFeed);
      dispatch(feed.getIndividualFeed({ ...individualFeedState }));
    }
  };

const getUpdatedFeedState = (feedNo: number, feedAction: EnumFeedAction, prevState: any) => {
  const feedIndex = prevState?.values.findIndex((feed: IFeed) => feed.feedNo === feedNo);
  if (feedIndex < 0) return prevState;

  const updatingFeed: IFeed = prevState.values[feedIndex];
  switch (feedAction) {
    case EnumFeedAction.DELETE:
    case EnumFeedAction.REPORT:
      prevState.values.splice(feedIndex, 1);
      break;
    case EnumFeedAction.LIKE:
      prevState.values[feedIndex] = {
        ...updatingFeed,
        myLikeYn: updatingFeed.myLikeYn === EnumValueBoolean.TRUE ? EnumValueBoolean.FALSE : EnumValueBoolean.TRUE,
        likeCnt: updatingFeed.myLikeYn === EnumValueBoolean.TRUE ? updatingFeed.likeCnt - 1 : updatingFeed.likeCnt + 1,
      };
      break;
    case EnumFeedAction.PIN:
      prevState.values[feedIndex] = {
        ...updatingFeed,
        fixYn: updatingFeed.fixYn === EnumValueBoolean.TRUE ? EnumValueBoolean.FALSE : EnumValueBoolean.TRUE,
        order:
          updatingFeed.fixYn === EnumValueBoolean.TRUE
            ? 1
            : Math.min(...prevState.values.map((feed: IFeed) => feed.order)) - 1,
      };
      break;
    default:
      break;
  }
  return { ...prevState };
};

const getGsSwingVideoFeed = (isLoadingMore?: boolean) => async (dispatch: any, getState: any) => {
  const prevStateRedux = getState().feedReducer.dataGsSwingVideoFeed;
  if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
  try {
    const currentPage = prevStateRedux.loaded + 1;
    const params = {
      page: currentPage,
      rows: pageSize.MEDIUM,
    };
    const result = await storeFeed.getListGsNasmo(params);
    if (result.code === EnumRequestCode.FAILED) throw new Error("Can't get list GS Nasmo");
    if (result.entities.length < 1) {
      const dataObject = {
        ...prevStateRedux,
        loaded: currentPage,
        lastPage: true,
      };
      dispatch(feed.getGsSwingVideoFeed(dataObject));
      return;
    }
    const dataList = [...prevStateRedux.values, ...result.entities];
    const isLast = result.totalCount <= dataList.length;
    const dataObject = {
      values: dataList,
      loaded: currentPage,
      lastPage: isLast,
    };
    dispatch(feed.getGsSwingVideoFeed(dataObject));
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const getGdrSwingVideoFeed = (isLoadingMore?: boolean) => async (dispatch: any, getState: any) => {
  const prevStateRedux = getState().feedReducer.dataGdrSwingVideoFeed;
  if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
  try {
    const currentPage = prevStateRedux.loaded + 1;
    const params = {
      page: currentPage,
      rows: pageSize.MEDIUM,
    };
    const result = await storeFeed.getListGdrNasmo(params);
    if (result.code === EnumRequestCode.FAILED) throw new Error("Can't get list GDR Nasmo");
    if (result.entities.length < 1) {
      const dataObject = {
        ...prevStateRedux,
        loaded: currentPage,
        lastPage: true,
      };
      dispatch(feed.getGdrSwingVideoFeed(dataObject));
      return;
    }
    const dataList = [...prevStateRedux.values, ...result.entities];
    const isLast = result.totalCount <= dataList.length;
    const dataObject = {
      values: dataList,
      loaded: currentPage,
      lastPage: isLast,
    };
    dispatch(feed.getGdrSwingVideoFeed(dataObject));
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const getTmSwingVideoFeed = (isLoadingMore?: boolean) => async (dispatch: any, getState: any) => {
  const prevStateRedux = getState().feedReducer.dataTmSwingVideoFeed;
  if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
  try {
    const currentPage = prevStateRedux.loaded + 1;
    const params = {
      page: currentPage,
      rows: pageSize.SMALL,
    };
    const result = await storeFeed.getListTrainingModeNasmo(params);
    if (result.code === EnumRequestCode.FAILED) throw new Error("Can't get list traning mode Nasmo");
    if (result.entities.length < 1) {
      const dataObject = {
        ...prevStateRedux,
        loaded: currentPage,
        lastPage: true,
      };
      dispatch(feed.getTmSwingVideoFeed(dataObject));
      return;
    }
    const dataList = [...prevStateRedux.values, ...result.entities];
    const isLast =
      result.totalCount <= dataList.length || !Array.isArray(result.entities) || result.entities.length === 0;
    const dataObject = {
      values: dataList,
      loaded: currentPage,
      lastPage: isLast,
    };
    dispatch(feed.getTmSwingVideoFeed(dataObject));
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const getScoreCardFeed = (isLoadingMore?: boolean) => async (dispatch: any, getState: any) => {
  const prevStateRedux = getState().feedReducer.dataScoreCardFeed;
  if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
  try {
    const currentPage = prevStateRedux.loaded + 1;
    const params = {
      page: currentPage,
      rows: pageSize.MEDIUM,
    };
    const result = await storeFeed.getListScoreCard(params);
    if (result.code === EnumRequestCode.FAILED) throw new Error("Can't get list Feed Nasmo");
    if (result.entities.length < 1) {
      const dataObject = {
        ...prevStateRedux,
        loaded: currentPage,
        lastPage: true,
      };
      dispatch(feed.getScoreCardFeed(dataObject));
      return;
    }
    const dataList = [...prevStateRedux.values, ...result.entities];
    const isLast = result.totalCount <= dataList.length;
    const dataObject = {
      values: dataList,
      loaded: currentPage,
      lastPage: isLast,
    };
    dispatch(feed.getScoreCardFeed(dataObject));
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const updateCommentAndLikeCount =
  (data: IFeedCommentAndLikeCount, type: EnumFeedListType) => async (dispatch: any, getState: any) => {
    const feedState = getState().feedReducer;
    if (type === EnumFeedListType.STORE) {
      const storeFeedValues = feedState.dataStoreFeed.values;
      const index = storeFeedValues.findIndex((feed: IFeed) => feed.feedNo === data.feedNo);
      if (index < 0) return;
      storeFeedValues[index].commentCnt = data.commentCnt != null ? data.commentCnt : storeFeedValues[index].commentCnt;
      storeFeedValues[index].likeCnt = data.likeCnt != null ? data.likeCnt : storeFeedValues[index].likeCnt;
      storeFeedValues[index].myLikeYn = data.myLikeYn != null ? data.myLikeYn : storeFeedValues[index].myLikeYn;
      dispatch(feed.getStoreFeed({ ...feedState.dataStoreFeed, values: [...storeFeedValues] }));
      return;
    }

    if (type === EnumFeedListType.RECENT) {
      const recentFeedValues = feedState.dataRecentFeed.values;
      const index = recentFeedValues.findIndex((feed: IFeed) => feed.feedNo === data.feedNo);
      if (index < 0) return;
      recentFeedValues[index].commentCnt =
        data.commentCnt != null ? data.commentCnt : recentFeedValues[index].commentCnt;
      recentFeedValues[index].likeCnt = data.likeCnt != null ? data.likeCnt : recentFeedValues[index].likeCnt;
      recentFeedValues[index].myLikeYn = data.myLikeYn != null ? data.myLikeYn : recentFeedValues[index].myLikeYn;
      dispatch(feed.getRecentFeed({ ...feedState.dataRecentFeed, values: [...recentFeedValues] }));
      return;
    }

    if (type === EnumFeedListType.INDIVIDUAL) {
      const individualFeedValues = feedState.dataIndividualFeed.values;
      const index = individualFeedValues.findIndex((feed: IFeed) => feed.feedNo === data.feedNo);
      if (index < 0) return;
      individualFeedValues[index].commentCnt =
        data.commentCnt != null ? data.commentCnt : individualFeedValues[index].commentCnt;
      individualFeedValues[index].likeCnt = data.likeCnt != null ? data.likeCnt : individualFeedValues[index].likeCnt;
      individualFeedValues[index].myLikeYn =
        data.myLikeYn != null ? data.myLikeYn : individualFeedValues[index].myLikeYn;
      dispatch(feed.getIndividualFeed({ ...feedState.dataIndividualFeed, values: [...individualFeedValues] }));
      return;
    }
  };

export const feedAction = {
  getRecentFeed,
  refreshRecentFeed,
  getStoreFeed,
  refreshStoreFeed,
  getIndividualFeed,
  refreshIndividualFeed,
  updateFeedById,
  getGsSwingVideoFeed,
  getGdrSwingVideoFeed,
  getTmSwingVideoFeed,
  getScoreCardFeed,
  updateCommentAndLikeCount,
};

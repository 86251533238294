import { ILocation } from 'src/store/global';
import { IPermission, IWebviewData } from 'src/store/webview/reducer';

export const WEBVIEW_BROWSER = 'WEBVIEW_BROWSER';
export const IS_NATIVEAPP_PERMISSION = 'IS_NATIVEAPP_PERMISSION';
export const UPDATE_NATIVEAPP_LOCATION = 'UPDATE_NATIVEAPP_LOCATION';

const saveMobileInfo = (payload: IWebviewData) => ({
  type: WEBVIEW_BROWSER,
  payload,
});

const setMobilePermission = ({ nativeAppPermission, isLocationEnabled }: IPermission) => ({
  type: IS_NATIVEAPP_PERMISSION,
  payload: {
    nativeAppPermission,
    isLocationEnabled,
  },
});

const updateMobileLocation = (nativeAppLocation: ILocation | null) => ({
  type: UPDATE_NATIVEAPP_LOCATION,
  payload: {
    nativeAppLocation,
  },
});

export const webviewActions = {
  saveMobileInfo,
  setMobilePermission,
  updateMobileLocation,
};

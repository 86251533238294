import { useEffect, useState } from 'react';
import Banner from 'src/view/pages/nx/leaderboard-par-3/components/Banner';
import MyRanking from 'src/view/pages/nx/leaderboard-par-3/components/MyRanking';
import Top3Ranking from 'src/view/pages/nx/leaderboard-par-3/components/Top3Ranking';
import RankingItem from 'src/view/pages/nx/leaderboard-par-3/components/RankingItem';
import { useTranslation } from 'react-i18next';
import { nxRequests } from 'src/requests/api/nx';
import { IPar3ChallengeUser } from 'src/models';
import InfiniteScrollWrapper from 'src/view/commons/customs/InfiniteScroll';
import NotFoundItem from 'src/view/commons/customs/NotFoundItem';
import { booleanValue } from 'src/services/utils';
import { pageSize } from 'src/constants/enum';
import Header from 'src/view/layouts/global/Header';

const LeaderBoardPar3 = () => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const [dataTopPlayer, setDataTopPlayer] = useState<IPar3ChallengeUser[]>([]);
  const [page, setPage] = useState(1);
  const [myRanking, setMyRanking] = useState<Partial<IPar3ChallengeUser>>({});

  const translate = useTranslation().t;

  const getPar3ChallengePlayedRoundNum = async () => {
    try {
      const result = await nxRequests.getPar3ChallengePlayedRoundNum();
      setMyRanking((preState) => ({ ...preState, playedRoundNum: result?.entities?.playedRoundNum }));
    } catch (err) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getCurrentUserRanking = (data: IPar3ChallengeUser[]) => {
    return data.find((item) => booleanValue(item.isMyRank));
  };

  const fetchData = async () => {
    setLoadingMore(true);

    try {
      const result = await nxRequests.getPar3ChallengeLeaderboard({ page, rows: pageSize.NORMAL });
      setPage(page + 1);

      const dataList: IPar3ChallengeUser[] = result?.entities;
      const currentRank = getCurrentUserRanking(dataList);
      if (currentRank && !myRanking?.usrNo) {
        setMyRanking((preState) => ({ ...preState, ...currentRank }));
      }
      const newData = [...dataTopPlayer, ...(dataList || [])];
      setDataTopPlayer(newData);
      setIsLastPage(newData?.length >= result?.totalCount || !result?.totalCount || result?.entities?.length === 0);
    } catch (err) {
      setIsLastPage(true);
    } finally {
      loadingFirst && setLoadingFirst(false);
      setLoadingMore(false);
    }
  };

  const topFromFourth = dataTopPlayer?.slice(3);

  const top3RankingConvert = dataTopPlayer?.slice(0, 3);

  useEffect(() => {
    getPar3ChallengePlayedRoundNum();
  }, []);

  const Skeleton = () => (
    <div>
      {[...Array(10)].map((_, index) => (
        <div
          className="flex animate-pulse items-center justify-between border-b border-dark-10 px-[16px] py-[12px] last:border-b-0"
          key={index}
        >
          <div className="flex items-center">
            <div className="mr-[12px] h-[16px] w-[20px] rounded-[4px] bg-disable" />
            <div className="mr-[8px] h-[40px] w-[40px] rounded-full bg-disable" />
            <div>
              <div className="h-[16px] w-[120px] rounded-[4px] bg-disable" />
              <div className="mt-[8px] h-[12px] w-[160px] rounded-[4px] bg-disable" />
            </div>
          </div>
          <div className="h-[18px] w-[50px] rounded-[4px] bg-disable" />
        </div>
      ))}
    </div>
  );

  const SkeletonMyRanking = () => (
    <div className="my-[8px] mb-[16px] flex animate-pulse items-center justify-between px-[16px] py-[20px] last:border-b-0">
      <div className="mr-[12px] ml-[16px] h-[16px] w-[20px] rounded-[4px] bg-disable" />
      <div className="mr-[12px] h-[40px] w-[40px] rounded-full bg-disable" />
      <div className="flex flex-1">
        <div className="mr-[8px] flex-1">
          <div className="mb-[8px] h-[12px] rounded-[4px] bg-disable" />
          <div className="h-[16px] w-[120px] rounded-[4px] bg-disable" />
        </div>
        <div className="flex-1">
          <div className="mb-[8px] h-[12px] rounded-[4px] bg-disable" />
          <div className="h-[16px] w-[120px] rounded-[4px] bg-disable" />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Header
        heightHeader="h-[44px]"
        className="shadow-none"
        title={translate('pages.nx.par_3_leaderboard.TITLE')}
        classTitle="gz-text-md font-bold m-0"
      />
      <Banner />
      {loadingFirst ? (
        <SkeletonMyRanking />
      ) : (
        <MyRanking
          className="sticky top-[44px] z-[10] my-[8px] border-white-70 bg-gz-white/90 px-[16px] py-[8px] backdrop-blur"
          myInfo={myRanking}
        />
      )}

      <div className="px-[16px]">
        <div>
          {loadingFirst && <Skeleton />}
          {dataTopPlayer.length > 0 ? (
            <Top3Ranking top3Ranking={top3RankingConvert} />
          ) : (
            !(loadingMore || loadingFirst) && (
              <NotFoundItem className="pb-[80px]" text={translate('pages.nx.par_3_leaderboard.NO_DATA')} />
            )
          )}
          <InfiniteScrollWrapper loadMore={fetchData} isLastPage={isLastPage} loading={loadingMore || loadingFirst}>
            <div className="mt-[16px]">
              {topFromFourth?.map((topPlayer, index) => (
                <RankingItem key={index} topPlayer={topPlayer} />
              ))}
            </div>
          </InfiniteScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardPar3;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { thirdPartyRequests } from 'src/requests/api/third-party';
import { RootState } from 'src/store';
import { IpInfoState, ipInfoDispatch } from 'src/store/ipinfo';
import { countries } from 'country-data';

export const useGeoLocation = () => {
  const dispatch = useDispatch();
  const settingCountryCd = useSelector((state: RootState) => state.settingInfoReducer.settingInfo?.countryCd);
  const ipInfo: IpInfoState = useSelector((state: RootState) => state.ipInfoReducer);
  const [countryCode, setCountryCode] = useState(settingCountryCd);

  useEffect(() => {
    if (!!ipInfo?.fetch) return;
    if (!!countryCode && !!ipInfo?.countryCode3Digits) return;

    getCountryCode();
  }, [countryCode, ipInfo?.countryCode3Digits, ipInfo?.fetch]);

  const getCountryCode = async () => {
    dispatch(
      ipInfoDispatch.info({
        fetch: true,
      }),
    );
    let response;
    try {
      response = await thirdPartyRequests.getLocationFromIP();
    } catch (err) {}
    const responseCountryCd = response?.countryCode ?? 'US';
    if (!countryCode) {
      setCountryCode(responseCountryCd);
    }
    if (!ipInfo?.countryCode3Digits) {
      dispatch(
        ipInfoDispatch.info({
          countryCode: responseCountryCd,
          countryCode3Digits: countries[responseCountryCd].alpha3,
          longitude: response?.longitude,
          latitude: response?.longitude,
        }),
      );
    }
  };

  return { countryCode, ipInfo };
};

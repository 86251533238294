export enum EnumLevelTagCode {
  PENALTY = 1,
  UNDEFEATED_RECORD = 2,
  FAIRWAY_RATE_100_PERCENT = 3,
  FAIRWAY_RATE_80_PERCENT = 4,
  GIR_100_PERCENT = 5,
  GIR_80_PERCENT = 6,
  NICE_EAGLE = 7,
  NICE_HOLE_IN_ONE = 8,
  NICE_ALBATROSS = 9,
  NICE_CYCLING_BIRDIE = 10,
  NICE_EVEN_PAR = 11,
  NICE_UNDER_PAR = 12,
  NO_BOGEY = 13,
  HITS_100_SUCCESSFULLY = 14,
  FIRST_SINGLE = 15,
  BREAK_BEST_SCORE_RECORD = 16,
  BREAK_BEST_DRIVING_RECORD = 17,
  BREAK_BEST_PUTTING_RECORD = 18,
  CHIP_IN_SUCCESS = 19,
  SAND_SAVE_RATE_100_PERCENT = 20,
  FIRST_PLACE_AMONG_COMPANIONS = 21,
  FIRST_PRACTICE_DONE_IN_GDR = 22,
  EFFORT_NEVER_BETRAYS = 23,
  EFFORT_NEVER_BETRAYS_2 = 24,
  HIGHEST_PRACTICE_BALLS_IN_STORE_THIS_MONTH = 25,
  NEW_DRIVER_DISTANCE_RECORD_IN_DRIVING_RANGE = 26,
  NICE_SHOT_30_COMBO_IN_DRIVING_RANGE = 27,
  ACCUMULATED_380400_KILOMETERS_IN_DRIVING_RANGE = 28,
  ACCUMULATED_42000_KILOMETERS_IN_DRIVING_RANGE = 29,
  ACCUMULATED_21000_KILOMETERS_IN_DRIVING_RANGE = 30,
  ACCUMULATED_6400_KILOMETERS_IN_DRIVING_RANGE = 31,
  ACCUMULATED_1947_METERS_IN_DRIVING_RANGE = 32,
  NICE_SHOT_20_COMBO_IN_DRIVING_RANGE = 33,
  NICE_SHOT_25_COMBO_IN_DRIVING_RANGE = 34,
  FIRST_PUTTING_SUCCESS_1_7_METERS_IN_DRIVING_RANGE = 35,
  FIRST_PUTTING_SUCCESS_10_15_METERS_IN_DRIVING_RANGE = 36,
  BEST_SCORE_9_HOLE_PRACTICE = 37,
  BEST_SCORE_18_HOLE_PRACTICE = 38,
  REACH_NUMBER_OF_GS_ROUNDS_18H = 39,
  EIGHTEEN_HOLE_PAR_SCORE = 40,
  PERFECT_LOGIN_STREAK = 41,
  REACH_NUMBER_OF_GS_ROUNDS_9H = 42,
}

export enum EnumProfileTabs {
  ROUND = 'level',
  GDR = 'gdr',
  FEED = 'feed',
}

export enum EnumGDRLevel {
  BEGINNER_LEVEL_1 = 1,
  BEGINNER_LEVEL_2 = 2,
  BEGINNER_LEVEL_3 = 3,
  LAST_100_STROKES = 4,
  MIDDLE_100_STROKES = 5,
  FIRST_100_STROKES = 6,
  LAST_90_STROKES = 7,
  MIDDLE_90_STROKES = 8,
  FIRST_90_STROKES = 9,
  LAST_80_STROKES = 10,
  MIDDLE_80_STROKES = 11,
  FIRST_80_STROKES = 12,
  LAST_70_STROKES = 13,
  MIDDLE_70_STROKES = 14,
  FIRST_70_STROKES = 15,
  SCRATCH = 16,
  SEMI_PRO = 17,
  TOUR_PRO = 18,
}

export enum EnumTagCategory {
  GDR = 'GDR',
  GS = 'GS',
}

import { EnumFilterField, EnumRequestCode } from 'src/constants/enum';
import { NotificationType } from 'src/models/notification';
import { commonMiddleware } from 'src/requests/api/common';
import { ICountryRequest } from 'src/requests/api/common/prop-state.type';
import {
  IEventBannerTournament,
  IParticipatedEvent,
  IRecommendTournament,
  ISearchTournament,
} from 'src/requests/api/tournament/prop-state.type';
import { notificationActions } from 'src/store/notification';
import i18n from 'src/locale';
import { CountryListResponse } from 'src/requests/api/course/prop-state.type';
import { RUSSIA_COUNTRY_CODE } from 'src/constants/const';

export const UPDATE_TOURNAMENT_DATA = 'UPDATE_TOURNAMENT_DATA';
export const ADD_TOURNAMENT_COUNTRY_LIST = 'ADD_TOURNAMENT_COUNTRY_LIST';
export const RESET_TOURNAMENT_DATA = 'RESET_TOURNAMENT_DATA';

export interface IUpdatingTournamentState {
  position?: number;
  participatedTournaments?: IParticipatedEvent[];
  recommendTournaments?: IRecommendTournament[];
  eventBanners?: IEventBannerTournament[];
  tournamentList?: ISearchTournament[];
  tournamentTotal?: number;
  tournamentPageLoaded?: number;
  tournamentPageIsLast?: boolean;
  tournamentSearchName?: string;
  tournamentCountryCode?: EnumFilterField.ALL;
  tournamentSubCode?: EnumFilterField.ALL;
  tournamentType?: EnumFilterField.ALL;
}

export const tournamentActions = {
  updateTournamentData: (data: IUpdatingTournamentState) => ({
    type: UPDATE_TOURNAMENT_DATA,
    payload: data,
  }),
  addTournamentCountryList: (data: CountryListResponse[]) => ({
    type: ADD_TOURNAMENT_COUNTRY_LIST,
    payload: data,
  }),
  resetTournamentData: () => ({
    type: RESET_TOURNAMENT_DATA,
  }),
};

const getCountryList = (params?: ICountryRequest) => async (dispatch: any) => {
  try {
    const response = await commonMiddleware.getCountryList(params);
    if (response.code === EnumRequestCode.SUCCESS) {
      // move RUS to the last index
      const countryList = [...response?.entities] || [];
      const russiaIndex = countryList.findIndex((el: any) => el?.countryCd === RUSSIA_COUNTRY_CODE);
      if (russiaIndex !== -1) {
        countryList.push(countryList.splice(russiaIndex, 1)[0]);
      }
      dispatch(tournamentActions.addTournamentCountryList(countryList));
    } else {
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(error.message, NotificationType.DANGER));
  }
};

export const tournamentMiddleware = {
  getCountryList,
};

import { ReactElement } from 'react';
import { isDesktop } from 'react-device-detect';
import PullToRefresh from 'react-simple-pull-to-refresh';
import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';
import Footer from 'src/view/layouts/global/Footer';

import 'src/assets/scss/pull-to-refresh.scss';

interface IPullToRefreshProps {
  children: ReactElement;
  isPullable?: boolean;
  showFooter?: boolean;
  onRefresh: () => Promise<any>;
}

function CustomPullToRefresh({ children, onRefresh, showFooter, isPullable }: IPullToRefreshProps): JSX.Element {
  const element = () => {
    return (
      <>
        {children}
        {showFooter && <Footer />}
      </>
    );
  };

  if (isDesktop) return element();

  return (
    <PullToRefresh
      pullingContent=""
      refreshingContent={
        <div className="pt-[24px]">
          <LoadingIndicator className="h-[24px] w-[24px]" />
        </div>
      }
      onRefresh={onRefresh}
      isPullable={isPullable}
    >
      {element()}
    </PullToRefresh>
  );
}

export default CustomPullToRefresh;

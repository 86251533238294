import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VERIFY_CODE_LIFETIME } from 'src/constants/const';
import { DateTime } from 'src/services/datetime';

interface Props {
  updateDuration?: (duration: boolean) => void;
}

const VerifyCodeAlert = forwardRef(({ updateDuration }: Props, ref) => {
  const translate = useTranslation().t;
  const [duration, setDuration] = useState(VERIFY_CODE_LIFETIME);
  const timeout = useRef<any>(null);

  useEffect(() => {
    if (duration <= 0) {
      updateDuration && updateDuration(true);
      return;
    }
    timeout.current = setTimeout(() => {
      setDuration(duration - 1);
      updateDuration && updateDuration(false);
    }, 1000);
    return () => {
      clearTimeout(timeout.current);
    };
  }, [duration]);

  useImperativeHandle(ref, () => ({
    resetCountDown() {
      setDuration(VERIFY_CODE_LIFETIME);
    },
  }));

  return (
    <div
      className="gz-text-xsm mt-2 text-sub-body"
      dangerouslySetInnerHTML={{
        __html: translate('commons.customs.verify_code_alert.SENT_SMS_SUCCESSFULLY_ALERT', {
          time: `<span class="text-primary-75">${DateTime.convertDurationToMinute(duration)}</span>`,
        }),
      }}
    ></div>
  );
});

export default VerifyCodeAlert;

import { DetailedHTMLProps, Fragment, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';

interface IAuthGuestViewProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: React.ReactNode;
  fragment?: boolean;
}

interface IViewProps {
  className?: string;
  children?: React.ReactNode;
}

const AuthGuestView = ({ children, fragment, ...rest }: IAuthGuestViewProps) => {
  if (fragment) return <>{children}</>;
  return <div {...rest}>{children}</div>;
};

const Auth = ({ children }: IViewProps): JSX.Element => {
  const isLoggedIn = useSelector((state: any) => state.authentication.isLoggedIn);
  if (!isLoggedIn) return <></>;
  return <>{children ?? <Fragment />}</>;
};

const Guest = ({ children }: IViewProps): JSX.Element => {
  const isLoggedIn = useSelector((state: any) => state.authentication.isLoggedIn);
  if (isLoggedIn) return <></>;
  return <>{children ?? <Fragment />}</>;
};

const Global = ({ children }: IViewProps): JSX.Element => {
  return <>{children ?? <Fragment />}</>;
};

AuthGuestView.Auth = Auth;
AuthGuestView.Guest = Guest;
AuthGuestView.Global = Global;

export default AuthGuestView;

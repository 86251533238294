import { RegExConstants } from 'src/constants/const';
import { InputStatus } from 'src/view/commons/elements/Input';

const defaultRules = {
  id: (val: string) =>
    RegExp(/^[A-Za-z0-9]{4,12}$/).test(val) && RegExp(/[A-Za-z]/).test(val) && RegExp(/[0-9]/).test(val),
  password: (val: string) =>
    RegExp(/^.{6,12}$/).test(val) && RegExp(/[A-Za-z]/g).test(val) && RegExp(/[0-9]/g).test(val),
  //RegExp(/!|"|#|\$|%|&|'|\(|\)|\*|\+|,|-|\.|\/|:|;|<|=|>|\?|@|\[|＼|\]|\^|_|`|{|\||}|~|\\/g).test(val),
  password_simple: (val: string) =>
    RegExp(/^.{6,12}$/).test(val) && RegExp(/[A-Za-z]/g).test(val) && RegExp(/[0-9]/g).test(val),
  password_not_match: (val: string, param: string) => Boolean(val === param),
  pass_match_old: (val: string, param: string) => Boolean(val !== param),
  whitespace: (val: string) => !RegExp(/\s/g).test(val),
  special_char: (val: string) => !RegExp(/[^!"#$%&'()*+,-./:;<=>?@[＼\]^_`{|}~\\A-Za-z0-9]/g).test(val),
  special_simple: (val: string) => !RegExp(/[^!@#$^&+=A-Za-z0-9]/g).test(val),
  nickname: (val: string) => RegExConstants.NICKNAME.test(val),
  phone: (val: string) => /^[0-9]{7,}$/.test(val),
  email: (val: string) => RegExConstants.EMAIL.test(val),
  verify_phone_code: (val: string) => RegExp(/^\d{5}$/).test(val),
  required: (val: string) => !!val,
};

const passes = (rule: string, value: string, param?: string | null) => {
  if (defaultRules[rule] == null) return true;
  if (rule !== 'required' && (value == null || value === '')) return true;
  return defaultRules[rule](value, param);
};

const normalizeValues = (input: string, validation: string) => {
  return {
    value: input,
    rule: getValidation(validation),
    param: getParam(validation),
  };
};

const getValidation = (validation: string) => {
  return validation.split(':')[0];
};

const getParam = (validation: string) => {
  const params = validation.split(':');
  if (params.length > 1) {
    const [, ...lastParams] = params;
    return lastParams.join(':');
  }
  return null;
};

export const validate = (value: string, validationStr: string): string | null | undefined => {
  if (!validationStr) return;
  const validations = validationStr.split('|');
  for (const validation of validations) {
    const parsedValidation = normalizeValues(value, validation);
    if (!passes(parsedValidation.rule, parsedValidation.value, parsedValidation.param)) {
      return parsedValidation.rule;
    }
  }
  return;
};

export const allValid = (form: any): boolean => {
  const keys = Object.keys(form);
  return keys.every((key: string) => form[key].status === InputStatus.Done);
};

export const isValidURL = (str?: string) => {
  try {
    new URL(str!);
    return true;
  } catch (error) {
    return false;
  }
};

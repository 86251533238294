import dayjs from 'dayjs';
import { TFunction } from 'react-i18next';
import { TUNING_TIME_TIMEZONE, formatDate } from 'src/constants/const';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import padStart from 'lodash/padStart';

dayjs.extend(timezone);
dayjs.extend(utc);

interface IFormatDateOptions {
  timezone?: string;
  format?: string;
  translateMonth?: boolean;
  shortMonth?: boolean;
  padStart?: boolean;
}

interface IConvertTimezoneOptions {
  fromKeepLocalTime?: boolean;
  toKeepLocalTime?: boolean;
}

export const DateTime = {
  getLocaleDatetime: (datetime?: string, tz?: string) => {
    /*
      Fix format date not recognized in Safari browser
      Wrong: yyyy-MM-dd yyyy-MM-dd HH:mm:ss yyyy-MM-dd HH:mm:ss.S
      Correct: yyyy/MM/dd yyyy/MM/dd HH:mm:ss
    */
    if (!datetime) return new Date();
    const formattedDate = datetime.slice(0, 19).replace(/-|\./g, '/');
    try {
      if (tz != null) {
        return new Date(dayjs.tz(formattedDate, tz).toString());
      }
      return new Date(formattedDate);
    } catch (error) {
      return new Date(datetime);
    }
  },
  getUTCTime(datetime?: string) {
    let dateString = new Date();
    if (datetime) {
      dateString = new Date(datetime);
    }
    const utcOffset = dateString.getTimezoneOffset() * 60 * 1000;
    const utcTime = new Date(dateString.getTime() + utcOffset);
    return utcTime;
  },

  formatDate: (
    datetime: string | number,
    translate: TFunction<'translation', undefined>,
    initOptions?: IFormatDateOptions,
  ) => {
    if (!datetime) return '';
    const options = {
      timezone: TUNING_TIME_TIMEZONE,
      format: formatDate.T_NORMAL_FULL_DATE,
      translateMonth: true, // Only in English. Ex: 12 -> December/Dec
      shortMonth: true, // Only in English. Ex: December -> Dec
      padStart: true,
      ...initOptions,
    };

    /*
      Fix format date not recognized in Safari browser
      Wrong: yyyy-MM-dd yyyy-MM-dd HH:mm:ss yyyy-MM-dd HH:mm:ss.S
      Correct: yyyy/MM/dd yyyy/MM/dd HH:mm:ss
    */
    let formattedDate = datetime;
    if (typeof datetime === 'string') {
      formattedDate = datetime.slice(0, 19).replace(/-/g, '/');
    }
    const padStartLength = options.padStart ? 2 : 1;
    const dt = new Date(
      typeof datetime === 'string' ? dayjs.tz(formattedDate, options.timezone).toString() : formattedDate,
    );
    const year = dt.getFullYear();
    let month = options.translateMonth
      ? padStart(translate('date.month.' + (dt.getMonth() + 1)), padStartLength, '0')
      : padStart((dt.getMonth() + 1).toString(), padStartLength, '0');
    if (options.shortMonth) {
      month = month.substring(0, 3);
    }
    const date = padStart(dt.getDate().toString(), padStartLength, '0');
    const hour = padStart(dt.getHours().toString(), padStartLength, '0');
    const minute = padStart(dt.getMinutes().toString(), padStartLength, '0');
    const second = padStart(dt.getSeconds().toString(), padStartLength, '0');
    return translate(`date.${options.format}`, {
      year,
      month,
      date,
      hour,
      minute,
      second,
    });
  },

  getYear: (datetime: string, tz?: string) => {
    const formattedDate = datetime.slice(0, 19).replace(/-/g, '/');
    let dt = new Date(formattedDate);
    if (tz) {
      dt = new Date(`${formattedDate} ${tz}`);
    }
    return dt.getFullYear();
  },

  convertDurationToMinute: (duration: number, minutePadLength = 1, secondPadLength = 2) => {
    const second = duration % 60;
    const minute = Math.floor(duration / 60);
    return `${padStart(minute?.toString(), minutePadLength, '0')}:${padStart(
      second?.toString(),
      secondPadLength,
      '0',
    )}`;
  },

  convertFormat: (date?: string, newFormat?: string, oldFormat?: string) => {
    if (!date || !newFormat || !oldFormat) return '';
    return dayjs(dayjs(date, oldFormat).toDate()).format(newFormat);
  },
};

interface IDayjsFormatDateOptions {
  translate?: TFunction<'translation', undefined>;
  format?: string;
  translateMonth?: boolean;
  shortMonth?: boolean;
  padStart?: boolean;
  isApiTimezone?: boolean;
}

export const Dayjs = {
  convertEstToLocale: (date?: string) => {
    if (!date) return dayjs();
    return dayjs.tz(date, TUNING_TIME_TIMEZONE).local();
  },

  convertLocaleToEst: (date?: string) => {
    if (!date) return dayjs();
    return dayjs(date).tz(TUNING_TIME_TIMEZONE);
  },

  convertDayjsToLocal: (date?: dayjs.Dayjs) => {
    if (!date) return dayjs();
    return date.local();
  },

  convertDayjsToEst: (date?: dayjs.Dayjs) => {
    if (!date) return dayjs();
    return date.tz(TUNING_TIME_TIMEZONE);
  },

  covertFromTzToTz: (datetime?: string, fromTz?: string, toTz?: string, initOptions?: IConvertTimezoneOptions) => {
    const options: IConvertTimezoneOptions = {
      fromKeepLocalTime: true,
      toKeepLocalTime: false,
      ...initOptions,
    };
    if (!datetime || !fromTz || !toTz) return dayjs();
    return dayjs(datetime)
      .tz(fromTz, options.fromKeepLocalTime)
      .tz(toTz, options.toKeepLocalTime)
      .tz(dayjs.tz.guess(), true);
  },

  formatDate: (dateStr?: string, initOptions?: IDayjsFormatDateOptions) => {
    if (!dateStr) return '';
    const options = {
      format: formatDate.T_NORMAL_FULL_DATE,
      translateMonth: true, // Only in English. Ex: 12 -> December/Dec
      shortMonth: true, // Only in English. Ex: December -> Dec
      padStart: true,
      isApiTimezone: true,
      ...initOptions,
    };
    const datetime = options.isApiTimezone ? Dayjs.convertEstToLocale(dateStr) : dayjs(dateStr);
    const padStartLength = options.padStart ? 2 : 1;
    const year = datetime.year().toString();
    let month =
      options.translateMonth && options.translate != null
        ? padStart(options.translate('date.month.' + (datetime.month() + 1)), padStartLength, '0')
        : padStart((datetime.month() + 1).toString(), padStartLength, '0');
    if (options.shortMonth) {
      month = month.substring(0, 3);
    }
    const date = padStart(datetime.date().toString(), padStartLength, '0');
    const hour = padStart(datetime.hour().toString(), padStartLength, '0');
    const minute = padStart(datetime.minute().toString(), padStartLength, '0');
    const second = padStart(datetime.second().toString(), padStartLength, '0');
    if (options.translate == null)
      return options.format
        .replace('YYYY', year)
        .replace('MM', month)
        .replace('DD', date)
        .replace('HH', hour)
        .replace('mm', minute)
        .replace('ss', second);
    return options.translate(`date.${options.format}`, {
      year,
      month,
      date,
      hour,
      minute,
      second,
    });
  },
};

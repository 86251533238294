import { useLayoutEffect, useState } from 'react';

export enum EnumScreenSize {
  MOBILE,
  TABLET,
  DESKTOP,
}

export function useResizeWindow(callback?: () => void) {
  const [screenSize, setScreenSize] = useState(EnumScreenSize.DESKTOP);

  const handleResize = () => {
    callback?.call(null);
    if (window.innerWidth < 768) {
      setScreenSize(EnumScreenSize.MOBILE);
    } else if (window.innerWidth < 1024) {
      setScreenSize(EnumScreenSize.TABLET);
    } else {
      setScreenSize(EnumScreenSize.DESKTOP);
    }
  };

  useLayoutEffect(() => {
    handleResize();
    setTimeout(() => handleResize(), 1);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { screenSize };
}

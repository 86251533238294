import { twMerge } from 'tailwind-merge';

interface ITimelineProps {
  children: any;
}

interface IItemProps {
  className?: string;
  children: any;
}

interface ITimeProps {
  className?: string;
  date: number;
  day: string;
}

interface IContentProps {
  className?: string;
  children: any;
}

const Timeline = ({ children }: ITimelineProps) => <>{children}</>;
const Item = ({ className, children }: IItemProps) => {
  return <div className={twMerge('flex [&:not(:first-child)]:mt-[16px]', className)}>{children}</div>;
};

const Time = ({ className, date, day }: ITimeProps) => {
  return (
    <div className={twMerge('flex flex-col items-center', className)}>
      <div className="w-[70px] text-center">
        <div className="gz-text-xsm">{day}</div>
        <div className="gz-text-md mt-[4px] font-bold">{date}</div>
      </div>
      <div className="mt-[8px] h-full w-[0.5px] bg-sub-body" />
    </div>
  );
};
const Content = ({ className, children }: IContentProps) => (
  <div className={twMerge('w-full', className)}>{children}</div>
);

Timeline.Item = Item;
Timeline.Time = Time;
Timeline.Content = Content;

export default Timeline;

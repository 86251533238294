import { useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VideoRegion from 'src/view/pages/record/commons/VideoRegion';
import Button from 'src/view/commons/elements/Button';
import { twMerge } from 'tailwind-merge';
import { fixDecimal, removePlus } from 'src/services/utils';
import { constUnit, mobileAction } from 'src/constants/const';
import {
  EnumAIRecordType,
  EnumTmBallPathCode,
  EnumTrainingModeSwingPosition,
  EnumTypeClub,
  TypeDistance,
} from 'src/constants/enum';
import { getBallPathBySwingPositon, getTmClubName, getTrainingModeTypeClub } from 'src/services/training-mode';
import { notificationActions } from 'src/store/notification';
import { NotificationType } from 'src/models';
import { sendDataToApp } from 'src/hooks/webview';
import { EnumFeedType } from 'src/constants/enum/feed.enum';
import { trainingModeRecord } from 'src/requests/api/record/training-mode';
import { getTmImageFromUrl } from 'src/services/feed';
import { ITrainingModeFeedState } from 'src/view/pages/feed/create-edit/create-training-mode';

export interface ITrainingModeVideoDetail {
  tsCode: number;
  nasmoId?: number;
  club?: string;
  clubCode?: string;
  totalDistance: number;
  shotType?: EnumTmBallPathCode;
  ballSpeed?: number;
  launchAngle?: string;
  carryDistance?: number;
  backSpin?: number;
  toPinDistance?: number;
  ballDirection?: string;
  holeInRate?: number;
  speedUnit?: string;
  distanceUnit: TypeDistance;
  urlVideo: string;
  urlImg: string;
  tsTimeStart: string;
  tsSwingPosition: EnumTrainingModeSwingPosition;
  analysisId?: string;
}

interface IVideoProps {
  className?: string;
  classSideWrapper?: string;
  classDescription?: string;
  videoDetail?: ITrainingModeVideoDetail;
  headerHeight?: number;
  expanded?: boolean;
  shortData?: boolean;
  loading?: boolean;
  isNativeApp?: boolean;
  hasAIAnalysis?: boolean;
  onRequestAIClick?: () => void;
  onViewAIResultClick?: () => void;
  addNotification: (message: string, type: NotificationType) => void;
}

function VideoDetail({
  className,
  classSideWrapper,
  classDescription,
  videoDetail,
  headerHeight,
  expanded,
  shortData,
  loading,
  isNativeApp,
  hasAIAnalysis = false,
  onRequestAIClick,
  onViewAIResultClick,
  addNotification,
}: IVideoProps) {
  const translate = useTranslation().t;
  const videoRef = useRef<any>(null);

  const analysisSwing = async () => {
    try {
      if (!videoDetail) return;
      const state: ITrainingModeFeedState = {
        tsCode: videoDetail.tsCode,
        club: getTmClubName(videoDetail.club),
        totalDistance: videoDetail.totalDistance,
        distanceUnit: videoDetail.distanceUnit,
        gameNo: 0,
        nasmoId: 0,
        imgSeq: 0,
        timeStart: videoDetail.tsTimeStart,
        nasmoFile: videoDetail.urlVideo,
        nasmoImg: videoDetail.urlImg,
      };
      const response = await trainingModeRecord.getSwingVideoAnalysis(getTmImageFromUrl(videoDetail?.urlImg));
      sendDataToApp({
        action: mobileAction.EDIT_VIDEO,
        info: {
          ...state,
          videoId: undefined,
          feedType: EnumFeedType.TRAINING_MODE,
          feedNo: response?.entities?.feedNo,
          isVideoSideView: false,
        },
      });
    } catch (error) {
      console.warn(error);
      addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    }
  };

  const renderDescription = () => {
    const clubType = getTrainingModeTypeClub(videoDetail?.clubCode);
    let shortDataList: any[] = [
      {
        title: translate('pages.record.type.training_mode.detail.CLUB'),
        value: videoDetail?.club,
        render: videoDetail?.club,
      },
      {
        title: translate('pages.record.type.training_mode.detail.BALL_SPEED'),
        value: videoDetail?.ballSpeed,
        render: `${fixDecimal(videoDetail?.ballSpeed, false, 1)} ${videoDetail?.speedUnit}`,
      },
      {
        title: translate('pages.record.type.training_mode.detail.TOTAL'),
        value: videoDetail?.totalDistance,
        render: `${fixDecimal(videoDetail?.totalDistance, false, 1)} ${videoDetail?.distanceUnit}`,
      },
      {
        title: translate('pages.record.type.training_mode.detail.LAUNCH_ANGLE'),
        value: videoDetail?.launchAngle,
        render: `${removePlus(fixDecimal(videoDetail?.launchAngle, false, 1))}${constUnit.DEGREE}`,
      },
      {
        title: translate('pages.record.type.training_mode.detail.CARRY'),
        value: videoDetail?.carryDistance,
        render: `${fixDecimal(videoDetail?.carryDistance, false, 1)} ${videoDetail?.distanceUnit}`,
      },
      {
        title: translate('pages.record.type.training_mode.detail.BACK_SPIN'),
        value: videoDetail?.backSpin,
        render: videoDetail?.backSpin,
      },
    ];

    if (clubType === EnumTypeClub.PUTTER) {
      shortDataList = [
        {
          title: translate('pages.record.type.training_mode.detail.TOTAL'),
          value: videoDetail?.totalDistance,
          render: `${fixDecimal(videoDetail?.totalDistance, false, 1)} ${videoDetail?.distanceUnit}`,
        },
        {
          title: translate('pages.record.type.training_mode.detail.TO_PIN'),
          value: videoDetail?.toPinDistance,
          render: `${videoDetail?.toPinDistance} ${videoDetail?.distanceUnit}`,
        },
        {
          title: translate('pages.record.type.training_mode.detail.BALL_DIRECTION'),
          value: videoDetail?.ballDirection,
          render: `${removePlus(fixDecimal(videoDetail?.ballDirection, false, 1))}${constUnit.DEGREE}`,
        },
        {
          title: translate('pages.record.type.training_mode.detail.HOLE_IN'),
          value: videoDetail?.holeInRate,
          render: `${fixDecimal(videoDetail?.holeInRate, false, 1)} ${videoDetail?.distanceUnit}`,
        },
      ];
    }

    return (
      <div className={twMerge('p-[24px] pt-[16px]', classDescription)}>
        {shortData && (
          <div className="grid grid-cols-2 gap-x-[12px] gap-y-[16px] rounded-[16px] bg-bg px-[11.5px] py-[18px] md:gap-x-[24px]">
            {shortDataList.map((shortData, index) => (
              <div key={index} className="flex justify-between">
                <div className="gz-text-xsm text-sub-body">{shortData.title}</div>
                {shortData.value != null && <div className="gz-text-sm font-bold">{shortData.render}</div>}
              </div>
            ))}
          </div>
        )}
        <div
          className={twMerge(
            'grid gap-x-[16px] [&:not(:first-child)]:pt-[16px]',
            isNativeApp ? 'grid-cols-2' : 'grid-cols-1',
          )}
        >
          <Button color="secondary-gray" onClick={() => videoRef.current?.downloadVideo()}>
            {translate('pages.record.type.training_mode.swing_video.SAVE_VIDEO')}
          </Button>
          {isNativeApp && (
            <Button color="secondary" onClick={analysisSwing}>
              {translate('pages.record.type.training_mode.swing_video.SWING_ANALYSIS')}
            </Button>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="video-wrapper video-wrapper__standar w-full bg-disable" />
      </div>
    );
  }

  return (
    <>
      {videoDetail && videoDetail.urlVideo && (
        <>
          <VideoRegion
            ref={videoRef}
            className={className}
            classSideWrapper={classSideWrapper}
            videoDetail={{
              nasmoId: 0,
              nasmoFile: videoDetail.urlVideo,
              nasmoImgFile: videoDetail.urlImg,
              clubName: getTmClubName(videoDetail.club),
              clubCode: videoDetail.clubCode,
              distance: videoDetail.totalDistance?.toString(),
              distUnit: videoDetail.distanceUnit,
              tmBallPath: getBallPathBySwingPositon(videoDetail.shotType, videoDetail.tsSwingPosition),
              regFeedNo: 0,
              tsCode: videoDetail?.tsCode,
              analysisId: videoDetail?.analysisId,
            }}
            distanceDecimal={1}
            headerHeight={headerHeight}
            expandedPosition={expanded ? 350 : undefined}
            videoDescription={renderDescription()}
            hideSuffix
            hasAIAnalysis={hasAIAnalysis}
            saveErrorMessage={translate('mess.SAVE_TRAINING_MODE_VIDEO_ERROR')}
            recordType={EnumAIRecordType.PRACTICE}
            onRequestAIClick={onRequestAIClick}
            onViewAIResultClick={onViewAIResultClick}
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  isNativeApp: state.webviewReducer.isNativeApp,
});

const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetail);

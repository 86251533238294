import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { pathConstants } from 'src/constants/const';
import { eventName } from 'src/constants/const/firebase.constants';
import HeaderTabsCustom from 'src/view/commons/customs/HeaderTabsCustom';
import AuthGuestView from 'src/view/commons/customs/guest-mode/AuthGuestView';
import Header from 'src/view/layouts/global/Header';

export default function EventHomeHeader(): JSX.Element {
  const translate = useTranslation().t;
  const { pathname } = useLocation();

  return (
    <AuthGuestView fragment>
      <AuthGuestView.Auth>
        <HeaderTabsCustom
          tabs={[
            {
              title: translate('pages.tournaments.TOURNAMENTS_TITLE'),
              path: pathConstants.TOURNAMENT_PAGE,
              eventName: eventName.EVENT_TOURNAMENT,
            },
            {
              title: translate('pages.tournaments.LEAGUES_TITLE'),
              path: pathConstants.LEAGUE_PAGE,
              eventName: eventName.EVENT_LEAGUE,
            },
          ]}
        />
      </AuthGuestView.Auth>
      <AuthGuestView.Guest>
        {pathname === pathConstants.LEAGUE_PAGE ? null : (
          <Header heightHeader="h-[48px]" title={translate('pages.tournaments.TOURNAMENTS_TITLE')} canBack={false} />
        )}
      </AuthGuestView.Guest>
    </AuthGuestView>
  );
}

import { useTranslation } from 'react-i18next';
import { EnumUserLevelCode } from 'src/constants/enum';
import { getEnumKeyByValue } from 'src/services/global';

interface IRankLevel {
  as?: 'span' | 'div' | 'p';
  className?: string;
  code?: EnumUserLevelCode;
}

function RankLevel({ as, className, code }: IRankLevel): JSX.Element {
  const translate = useTranslation().t;

  if (code == null || getEnumKeyByValue(EnumUserLevelCode, code) == null) return <></>;

  const RankContainer = as ?? 'div';
  return (
    <RankContainer className={className}>
      {translate('pages.tournaments.detail.information_detail.grade_handy.' + code)}
    </RankContainer>
  );
}

export default RankLevel;

import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { pathConstants } from 'src/constants/const';
import { Fragment } from 'react';
import { history } from 'src/services/history';

/* 
  Purpose: Pass sessionId and snsType directly in url params to login
  Description: In mobile, everytime users open app, we need to login immediately with sessionId in mobile's localstorage.
  Flow:
    1. Mobile goes to Authenticates page (Main, Record,...) and pass sessionId into params
    2. If sessionId exist, store it in redux then stay at current page
        Else get sessionId from redux
    3. sessionId null -> redirect Login page
        sessionId exists -> stay current page
*/
function AuthenticatedGuard({ sessionId, children }: { sessionId: string; children?: JSX.Element }): JSX.Element {
  if (!sessionId || sessionId === '') {
    const redirectUrl = encodeURIComponent(history.location.pathname + history.location.search + history.location.hash);
    const idx = window.history?.state?.idx;
    const searchParams = `?redirect_url=${redirectUrl}&idx=${idx}`;

    return <Navigate replace to={pathConstants.LOGIN + searchParams} />;
  }
  return children ?? <Fragment />;
}

const mapStateToAuthenticationProps = (state: any) => ({
  sessionId: state.authentication.sessionId,
});

export default connect(mapStateToAuthenticationProps)(AuthenticatedGuard);

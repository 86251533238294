import { IconClose } from 'src/assets/svg';
import { EnumScreenSize, useResizeWindow } from 'src/hooks/use-resize-window';
import BottomSheet from 'src/view/commons/elements/BottomSheet';
import Modal from 'src/view/commons/elements/Modal';
import { twMerge } from 'tailwind-merge';

interface IPopUpProps {
  classModal?: string;
  classBottomSheet?: string;
  children: JSX.Element;
  isOpen: boolean;
  title?: string;
  noHeader?: boolean;
  onClose: () => void;
}

export default function PopUp({
  classModal,
  classBottomSheet,
  children,
  isOpen,
  title,
  noHeader = false,
  onClose,
}: IPopUpProps) {
  const { screenSize } = useResizeWindow();
  const isMobile = screenSize === EnumScreenSize.MOBILE;

  return (
    <>
      {isMobile ? (
        <BottomSheet className={classBottomSheet} isOpen={isOpen} onClose={onClose}>
          {children}
        </BottomSheet>
      ) : (
        <Modal onClose={onClose} isOpen={isOpen} className={twMerge('max-w-lg', classModal)}>
          <>
            {!noHeader && (
              <div className="my-[8px] flex items-center justify-between px-[16px]">
                <div className="gz-text-lg font-medium text-title">{title}</div>
                <div className="cursor-pointer" onClick={onClose}>
                  <IconClose className="flex-shrink-0 stroke-title" />
                </div>
              </div>
            )}

            {children}
          </>
        </Modal>
      )}
    </>
  );
}

import { Popover, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { IconInfoCircle, IconClose } from 'src/assets/svg';
import { twMerge } from 'tailwind-merge';

interface INoteTooltipProps {
  className?: string;
  classTitle?: string;
  classIconNote?: string;
  classContentNote?: string;
  classIconClose?: string;
  classContent?: string;
  title: string;
  headerTitle?: string;
  content: React.ReactNode;
}

const NoteTooltip: React.FC<INoteTooltipProps> = React.memo(
  ({
    className,
    classTitle,
    classIconNote,
    classContentNote,
    classContent,
    title,
    headerTitle,
    content,
    classIconClose,
  }) => {
    return (
      <Popover className="relative">
        <Popover.Button className={twMerge('flex items-center outline-none', className)}>
          <div className={twMerge('font-bold dark:text-gz-white', classTitle)}>{title}</div>
          <span className="cursor-pointer">
            <IconInfoCircle className={twMerge('dark:stroke-gz-white', classIconNote)} />
          </span>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition-opacity duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Panel
            className={twMerge(
              'absolute z-10 mt-[8px] w-[244px] rounded-[12px]',
              'gz-text-sm flex-row flex-nowrap shadow-xsm',
              'bg-gz-white text-sub-body dark:border-dark-33 dark:bg-dark-16 dark:text-gz-white',
              classContentNote,
            )}
          >
            {({ close }) => (
              <>
                {headerTitle ? (
                  <>
                    <div className="flex justify-between border-b-[1px] border-text/10 p-[16px]">
                      <div className="gz-text-md font-bold text-title">{headerTitle}</div>
                      <div className={twMerge('shrink-0 cursor-pointer', classIconClose)} onClick={() => close()}>
                        <IconClose className="stroke-title dark:stroke-gz-white" />
                      </div>
                    </div>
                    <div className={twMerge('p-[16px]', classContent)}>{content}</div>
                  </>
                ) : (
                  <div className="flex justify-between p-[16px]">
                    <div>{content}</div>
                    <div className={twMerge('shrink-0 cursor-pointer', classIconClose)} onClick={() => close()}>
                      <IconClose className="stroke-title dark:stroke-gz-white" />
                    </div>
                  </div>
                )}
              </>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    );
  },
);

export default NoteTooltip;

import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Timeline from 'src/view/commons/customs/Timeline';
import { Dayjs } from 'src/services/datetime';

interface IProps {
  children: JSX.Element;
  dataObject: any[];
}
const ListGroupMonth = ({ children, dataObject }: IProps) => {
  const translate = useTranslation().t;

  return (
    <div className="mb-[8px] [&:not(:last-child)]:mb-[24px]">
      {dataObject.map((item: any, index: number) => {
        const datetime = Dayjs.convertEstToLocale(item.tsTimeStart);
        const date = datetime.get('date');
        const day = datetime.get('day');
        return (
          <Timeline key={`list-group-${index}`}>
            <Timeline.Item className="-ml-[14px]">
              <Timeline.Time day={translate(`date.${day}`)} date={date} />
              <Timeline.Content>{React.cloneElement(children, { item: item })}</Timeline.Content>
            </Timeline.Item>
          </Timeline>
        );
      })}
    </div>
  );
};

export default memo(ListGroupMonth);

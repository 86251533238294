import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cupHoldInOne, iconTop1, rankTop1, rankTop2, rankTop3 } from 'src/assets/images';
import { picUserAvatar } from 'src/assets/images/home';
import { EnumRankLevel } from 'src/constants/enum';
import { IPar3ChallengeUser } from 'src/models';
import { getFlagByCountryCd } from 'src/services/utils';
import AvatarImage from 'src/view/commons/customs/AvatarImage';
import NoteTooltip from 'src/view/commons/customs/NoteTooltip';
import { twMerge } from 'tailwind-merge';

interface ITop3Ranking {
  top3Ranking: IPar3ChallengeUser[];
  className?: string;
}

interface IStyleAndIconTop3 {
  sizeAvatar: string;
  iconRank: string;
  cssIconRank: string;
  avatar?: string;
  iconTop1?: string;
  classItem: string;
}

const Top3Ranking = ({ top3Ranking, className }: ITop3Ranking) => {
  const styleAndIconTop3: IStyleAndIconTop3[] = [
    {
      sizeAvatar: 'w-[88px] h-[88px]',
      iconRank: rankTop1,
      cssIconRank: 'bottom-[-22px] w-[40px]',
      avatar: picUserAvatar,
      iconTop1: iconTop1,
      classItem: 'order-2',
    },
    {
      sizeAvatar: 'w-[64px] h-[64px]',
      iconRank: rankTop2,
      cssIconRank: 'bottom-[-18px] w-[32px]',
      classItem: 'order-1',
    },
    {
      sizeAvatar: 'w-[64px] h-[64px]',
      iconRank: rankTop3,
      cssIconRank: 'bottom-[-18px] w-[32px]',
      classItem: 'order-3',
    },
  ];

  const translate = useTranslation().t;

  const top3RenderUI: (IPar3ChallengeUser & IStyleAndIconTop3)[] = useMemo(() => {
    return top3Ranking?.map((item, index) => ({ ...item, ...styleAndIconTop3?.[index] }));
  }, [top3Ranking, styleAndIconTop3]);

  return (
    <div
      className={twMerge(
        'relative z-1 grid grid-cols-3 items-end justify-between gap-x-[8px] rounded-[12px] bg-gz-white p-[16px] shadow-top3-par3 sm:gap-x-[16px] md:px-[32px]',
        className,
      )}
    >
      {top3RenderUI?.map((top, index) => (
        <div key={index} className={twMerge('flex flex-col items-center', top.classItem)}>
          <div className="relative h-fit w-fit rounded-full">
            {top?.usrNickImg && (
              <AvatarImage src={top.usrNickImg} gradetcode={EnumRankLevel.BRONZE} classImg={top.sizeAvatar} />
            )}
            {top?.countryCd && (
              <img
                src={getFlagByCountryCd(top?.countryCd)}
                className="absolute right-[-2px] bottom-0 h-[24px] w-[24px] shrink-0"
                alt={top?.countryCd}
              />
            )}
            {top.iconTop1 && <img src={top.iconTop1} className="absolute right-0 -top-[8px] w-[28px]" alt={'top1'} />}
            <img
              src={top.iconRank}
              className={twMerge('absolute left-1/2 -translate-x-1/2', top.cssIconRank)}
              alt={'top1'}
            />
          </div>
          <div className="mt-[28px] flex w-full flex-col items-center">
            <div className="gz-text-xs w-full overflow-hidden text-ellipsis whitespace-nowrap text-center">
              {top?.usrNickNm}
            </div>
            <div className="relative mt-[6px] h-[32px] w-[52px] rounded-[8px] bg-blue-light-98 text-center leading-[32px]">
              <span className="text-[20px] font-bold text-gz-primary">{top?.holeInOneCnt}</span>
              <img src={cupHoldInOne} alt="cup" className="absolute -right-[15px] top-0 w-[32px]" />
            </div>
          </div>
        </div>
      ))}
      <div className="absolute right-[16px] top-[16px]">
        <NoteTooltip
          title=""
          headerTitle={translate('pages.nx.par_3_leaderboard.calculationConditions.TITLE')}
          content={
            <div>
              <ul className="gz-text-xsm ml-[16px] list-outside list-disc">
                <li className="mb-[8px]">{translate('pages.nx.par_3_leaderboard.calculationConditions.DES_1')}</li>
                <li className="mb-[8px]">{translate('pages.nx.par_3_leaderboard.calculationConditions.DES_2')} </li>
                <li className="mb-[8px]">{translate('pages.nx.par_3_leaderboard.calculationConditions.DES_3')} </li>
                <li className="mb-[8px]">{translate('pages.nx.par_3_leaderboard.calculationConditions.DES_4')}</li>
                <li>{translate('pages.nx.par_3_leaderboard.calculationConditions.DES_5')} </li>
              </ul>
            </div>
          }
          classTitle={twMerge('gz-text-xl mr-[8px]')}
          classIconNote={twMerge('w-[24px] h-[24px] stroke-gray-35')}
          classContentNote="right-0 left-auto p-0 rounded-[8px] w-[310px]"
        />
      </div>
    </div>
  );
};

export default Top3Ranking;

import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { IconCircleCheck, IconCircleClose, iconEyeOff, iconEyeOn } from 'src/assets/svg';
import { DEFAULT_DEBOUNCE_TIME } from 'src/constants/const';
import { validate } from 'src/services/validators';
import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';
import { twMerge } from 'tailwind-merge';
import { notificationActions } from 'src/store/notification';
import { connect } from 'react-redux';
import { NotificationType } from 'src/models';

export enum InputStatus {
  Normal,
  Error,
  Done,
  Loading,
}

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  containerClassName?: string;
  className?: string;
  classInput?: string;
  rules?: string;
  message?: string | JSX.Element;
  status?: InputStatus;
  error?: string;
  errorMessages?: Record<string, string | JSX.Element>;
  doneMessage?: string | JSX.Element;
  isShowValue?: boolean;
  iconSuffix?: boolean;
  oldValue?: string;
  oldValueMessage?: string | JSX.Element;
  dispatchVerifyFunc?: ((value: string) => Promise<boolean>) | null;
  onResult?: (isValid: boolean) => void;
  addNotification: (message: string, type: NotificationType) => void;
}

function Input({
  containerClassName,
  className,
  classInput,
  rules,
  message,
  status,
  error,
  errorMessages,
  doneMessage,
  isShowValue,
  iconSuffix = true,
  oldValue,
  oldValueMessage,
  dispatchVerifyFunc,
  onResult,
  addNotification,
  ...rest
}: Props): JSX.Element {
  const translate = useTranslation().t;
  const [value, setValue] = useState<any>(rest.defaultValue ?? '');
  const [showPassword, setShowPassword] = useState(isShowValue ?? false);
  const [currentStatus, setCurrentStatus] = useState(InputStatus.Normal);
  const [currentError, setCurrentError] = useState('');
  const [firstInitialized, setFirstInitialized] = useState(true);
  const verifyText = useRef('');

  const finalError = error ?? currentError;
  const finalStatus = status ?? currentStatus;
  const hasSameOldValue = !!oldValueMessage && value === oldValue;

  useEffect(() => {
    if (rest.value != null) {
      setValue(rest.value);
    }
  }, [rest.value]);

  useEffect(() => {
    if (firstInitialized && !value) {
      return;
    }
    verifyText.current = value;
    if (!rules) {
      onResult && onResult(true);
      return;
    }
    const error = validate(value, rules);

    /* 
      Handle dispatch verify function
      Ignore in case input old default value and has oldValueMessage
    */
    if (dispatchVerifyFunc != null && value && !error && !hasSameOldValue) {
      setCurrentStatus(InputStatus.Normal);
      setCurrentError('');
      onResult && onResult(false);
      debounceHandleVerifyFunc(value);
      return;
    }
    if (dispatchVerifyFunc != null && (error || hasSameOldValue)) {
      debounceHandleVerifyFunc.cancel();
    }

    setCurrentStatus(error ? InputStatus.Error : value ? InputStatus.Done : InputStatus.Normal);
    setCurrentError(error ?? '');
    onResult && onResult(!error);
  }, [value]);

  useEffect(() => {
    if ((firstInitialized && !value) || !rules) {
      return;
    }
    const error = validate(value, rules);
    setCurrentStatus(error ? InputStatus.Error : value ? InputStatus.Done : InputStatus.Normal);
    setCurrentError(error ?? '');
    onResult && onResult(!error);
  }, [rules]);

  const debounceHandleVerifyFunc = useCallback(
    _.debounce(async (text: string) => {
      if (dispatchVerifyFunc == null || error || hasSameOldValue) return;
      setCurrentStatus(InputStatus.Loading);
      try {
        const result = await dispatchVerifyFunc(text);
        if (verifyText.current !== text) return; // Prevent from receiving result after change value
        setCurrentStatus(result ? InputStatus.Done : InputStatus.Error);
        setCurrentError('verified');
        onResult && onResult(result);
      } catch (error) {
        console.warn(error);
        addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
        setCurrentStatus(InputStatus.Normal);
        setCurrentError('');
        onResult && onResult(false);
      }
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (firstInitialized) {
      setFirstInitialized(false);
    }
    const value = e.target.value;
    setValue(value);
    rest.onChange && rest.onChange(e);
  };

  const suffixIcon = (): JSX.Element => {
    if (finalStatus === InputStatus.Loading) {
      return <LoadingIndicator />;
    }
    if (finalStatus === InputStatus.Error) {
      return (
        <span className="cursor-pointer py-1 pl-2">
          <IconCircleClose className="fill-gz-danger stroke-white" />
        </span>
      );
    }
    if (finalStatus === InputStatus.Done) {
      return (
        <span className="cursor-pointer py-1 pl-2">
          <IconCircleCheck className="fill-primary-75" />
        </span>
      );
    }
    return <></>;
  };

  const textColor = (): string => {
    if (finalStatus === InputStatus.Error) return 'text-gz-danger';
    return 'text-sub-body';
  };

  const borderColor = (): string => {
    if (finalStatus === InputStatus.Error) return 'border-gz-danger';
    if (finalStatus === InputStatus.Done) return 'border-primary-75';
    return 'border-gray-20';
  };

  const messageElement = (): JSX.Element => {
    let text;
    if (finalStatus === InputStatus.Done) {
      text = !firstInitialized ? oldValueMessage : doneMessage;
    } else if (finalStatus === InputStatus.Error) {
      text = errorMessages && errorMessages[finalError] ? errorMessages[finalError] : '';
    } else {
      text = message;
    }
    if (text && typeof text === 'string')
      return <div className={twMerge('gz-text-xsm mt-[8px]', textColor())}>{text}</div>;
    if (text) return <>{text}</>;
    return <></>;
  };

  return (
    <div className={twMerge('flex w-full flex-col', containerClassName)}>
      <div
        className={twMerge(
          'flex items-center justify-between',
          'h-[48px] w-full rounded-[6px] py-2 pl-3 pr-3',
          'border-[1px] border-solid',
          'transition-colors',
          borderColor(),
          finalStatus === InputStatus.Normal || finalStatus === InputStatus.Loading
            ? 'focus-within:border-primary-75'
            : '',
          className,
        )}
      >
        <input
          className={twMerge('w-full flex-auto', classInput)}
          {...rest}
          type={showPassword ? 'text' : rest.type}
          onChange={handleChangeInput}
        />
        {rest.type === 'password' && value && (
          <img
            className="cursor-pointer py-1 pl-2"
            src={showPassword ? iconEyeOn : iconEyeOff}
            alt=""
            onClick={handleShowPassword}
          />
        )}
        {iconSuffix && suffixIcon()}
      </div>
      <div>{messageElement()}</div>
    </div>
  );
}

const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};

export default connect(null, mapDispatchToProps)(Input);

import axios from 'src/requests';
import {
  IGetDataCountryParams,
  IGetDataGlobalParams,
  IGetDataLevelParams,
  IGetDataStoreParams,
  IGetDataSubCountryParams,
  IGetFeedListParams,
  IGetLevelTagListParams,
  IPartnerUsrNoParams,
} from 'src/requests/api/profile/prop-state.type';

const getMainInfo = async () => {
  const response = await axios.get('/myPage/getMainInfo');
  return response.data.data;
};

const getGDRInfo = async () => {
  const response = await axios.get('/gdr/getPracticeClubOverviewInfo');
  return response.data.data;
};

const getMainEtcInfo = async () => {
  const response = await axios.get('/myPage/getMainEtcInfo');
  return response.data.data;
};

const getPartnerMainInfo = async (params: IPartnerUsrNoParams) => {
  const response = await axios.get('/myPage/getPartnerMainInfo', {
    params,
  });
  return response.data.data;
};

const getLevelTagList = async (params: IGetLevelTagListParams) => {
  const response = await axios.get('/myPage/getTagList', {
    params,
  });
  return response.data.data;
};

const getFeedList = async (params: IGetFeedListParams) => {
  const response = await axios.get('/myPage/getFeedList', {
    params,
  });
  return response.data.data;
};

const getPartnerRoundInfo = async (params: IPartnerUsrNoParams) => {
  const response = await axios.get('/myPage/getPartnerRoundInfo', {
    params,
  });
  return response.data.data;
};

const getFilterDataRanking = async (countryCode: string) => {
  const response = await axios.get('/main/ranking/additional-info', {
    params: {
      countryCode,
    },
  });
  return response.data.data;
};

const getDataGlobalRankingDetail = async (params: IGetDataGlobalParams) => {
  const response = await axios.get('/main/ranking/global/list', { params });
  return response.data.data;
};

const getDataSubCountryRankingDetail = async (params: IGetDataSubCountryParams) => {
  const response = await axios.get('/main/ranking/sub-country/list', { params });
  return response.data.data;
};

const getDataCountryRankingDetail = async (params: IGetDataCountryParams) => {
  const response = await axios.get('/main/ranking/country/list', { params });
  return response.data.data;
};

const getDataLevelRankingDetail = async (params: IGetDataLevelParams) => {
  const response = await axios.get('/main/ranking/level/list', { params });
  return response.data.data;
};

const getDataStoreRankingDetail = async (params: IGetDataStoreParams) => {
  const response = await axios.get('/main/ranking/store-players', { params });
  return response.data.data;
};
export const profileRequests = {
  getMainInfo,
  getGDRInfo,
  getMainEtcInfo,
  getPartnerMainInfo,
  getLevelTagList,
  getFeedList,
  getPartnerRoundInfo,
  getFilterDataRanking,
  getDataGlobalRankingDetail,
  getDataSubCountryRankingDetail,
  getDataCountryRankingDetail,
  getDataLevelRankingDetail,
  getDataStoreRankingDetail,
};

import { notitficationActionTypes } from 'src/constants/const/index';
import { INotification } from 'src/models';

export interface NotificationAction {
  type: string;
  payload?: INotification;
}

const initialState: INotification[] = [];

export const notification = (state: INotification[] = initialState, action: NotificationAction) => {
  switch (action.type) {
    case notitficationActionTypes.ADD_NOTIFICATION: {
      const payload = {
        ...action.payload,
        id: 1,
      };
      return [payload];
    }
    case notitficationActionTypes.REMOVE_NOTIFICATION:
      return [];
    default:
      return state;
  }
};

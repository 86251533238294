import { useTranslation } from 'react-i18next';
import { IconRefreshActive } from 'src/assets/svg';
import { IItemOption } from 'src/models/profile';
import { getFlagByCountryCd } from 'src/services/utils';
import { twMerge } from 'tailwind-merge';

interface IFilterCountryProps {
  currentCountry: string;
  changeCountry: (country: string) => void;
  listCountry: IItemOption[];
  onClickGlobal: () => void;
  isGlobal?: boolean;
}

export default function FilterCountry({
  listCountry = [],
  changeCountry,
  currentCountry,
  onClickGlobal,
  isGlobal,
}: IFilterCountryProps) {
  const translation = useTranslation().t;
  return (
    <div className="flex max-h-[calc(var(--window-height)_*8_/10)] flex-col overflow-hidden">
      <div className="mx-[24px] mb-[16px] mt-[8px] flex justify-between">
        <span className={twMerge('gz-text-md font-medium', isGlobal && 'w-full text-center')}>
          {translation('pages.home.my_ranking.TOOLTIP')}
        </span>
        {!isGlobal && (
          <div className="ml-[16px] flex shrink-0 cursor-pointer items-center" onClick={onClickGlobal}>
            <IconRefreshActive className="mr-[8px] h-[16px] w-[16px]" />
            <div className="text-[15px] font-normal text-gz-primary">{translation('pages.home.my_ranking.GLOBAL')}</div>
          </div>
        )}
      </div>
      <div className="flex-1 overflow-y-auto">
        {listCountry.map((country) => (
          <div key={country?.value}>
            <div
              onClick={() => {
                changeCountry(country.value);
              }}
              className={twMerge(
                'flex cursor-pointer items-center justify-between px-6 ',
                currentCountry === country.value && 'bg-lavender text-darkblue-60',
              )}
            >
              <div className="flex items-center py-[0.75rem]">
                <img
                  src={getFlagByCountryCd(country?.value?.toString())}
                  className="h-[1.5rem] w-[1.5rem]"
                  alt={country?.title}
                />
                <div className={twMerge('gz-text-xs ml-2', 'font-bold')}>{country.title}</div>
              </div>
            </div>
            <div className="border-b border-solid border-gray-20" />
          </div>
        ))}
      </div>
    </div>
  );
}

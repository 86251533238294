import axios from 'src/requests';

import {
  IUpdateSmsAuthYnEmailRequest,
  ICheckExistingSNSAccountRequest,
  ICheckExistingAccountRequest,
  ICheckIdRequest,
  ICheckVerifyCodeRequest,
  IMappingSNS,
  ISignupForm,
  ISmsVerifyPhoneRequest,
  IVerifyUserEmail,
  ICodeVerifyEmailRequest,
  IVerifyUserName,
} from 'src/requests/api/account/prop-state.type';

const sendSmsVerifyPhone = async (body: ISmsVerifyPhoneRequest, tokenCaptcha: string) => {
  const response = await axios.post('/join/sendSmsNum', body, {
    headers: { 'Captcha-Key': tokenCaptcha, 'Captcha-Key-Version': 'v2' },
  });
  return response.data.data;
};

const sendCodeVerifyEmail = async (body: ICodeVerifyEmailRequest) => {
  const response = await axios.post('/join/sendEmailNumCreateUsr', body);
  return response.data.data;
};

const checkVerifyCode = async (body: ICheckVerifyCodeRequest) => {
  const response = await axios.post('/join/authSmsNum', body);
  return response.data.data;
};

const checkDuplicateId = async (params: ICheckIdRequest) => {
  const response = await axios.get('/join/checkDupId', { params });
  return response.data.data;
};

const checkExistingAccountLinkedToCellphone = async (body: ICheckExistingAccountRequest) => {
  const response = await axios.post('/login/checkUserCellphone', body);
  return response.data.data;
};

const checkExistingAccountLinkedToEmail = async (params: IVerifyUserEmail): Promise<any> => {
  const response = await axios.get('/login/findUserId', { params });
  return response.data.data;
};

const getEmailsHaveSendInfo = async (params: { usrNo: string }): Promise<any> => {
  const response = await axios.get('/login/send/findUserId', { params });
  return response.data.data;
};

const verifySignupUserName = async (params: IVerifyUserName): Promise<any> => {
  const response = await axios.get('/join/checkDupNickName', {
    params,
  });
  return response.data.data;
};

const verifyUserName = async (params: IVerifyUserName): Promise<any> => {
  try {
    const response = await axios.get('/join/checkDupNickName', {
      params,
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

const verifyCheckUserEmail = async (params: IVerifyUserEmail): Promise<any> => {
  const response = await axios.get('/join/checkDupEmail', {
    params,
  });
  return response.data.data;
};

const registerUserInfo = async (body: ISignupForm): Promise<any> => {
  const response = await axios.post('/join/createUserInfo', body);
  return response.data.data;
};

const mappingSNSLogin = async (body: IMappingSNS): Promise<any> => {
  const response = await axios.post('/join/sns/mapping', body);
  return response.data.data;
};

const autoCreateIdPassword = async () => {
  const response = await axios.get('/join/makeUserId');
  return response.data.data;
};

const checkExistingAccountLinkedCellphoneUpdateSNS = async (body: ICheckExistingSNSAccountRequest, session: string) => {
  const response = await axios.post('/join/authCellphone', body);
  return response.data.data;
};

const sendFirstEmailCode = async (body: ICodeVerifyEmailRequest) => {
  const response = await axios.post('/login/sendFirstEmailNum', body);
  return response.data.data;
};

const updateSmsAuthYnEmail = async (body: IUpdateSmsAuthYnEmailRequest) => {
  const response = await axios.post('/login/authFirstEmailNum', body);
  return response.data.data;
};

export const signupMiddleware = {
  sendSmsVerifyPhone,
  sendCodeVerifyEmail,
  checkVerifyCode,
  checkExistingAccountLinkedToCellphone,
  checkExistingAccountLinkedToEmail,
  getEmailsHaveSendInfo,
  checkDuplicateId,
  verifySignupUserName,
  verifyUserName,
  verifyCheckUserEmail,
  registerUserInfo,
  mappingSNSLogin,
  autoCreateIdPassword,
  checkExistingAccountLinkedCellphoneUpdateSNS,
  sendFirstEmailCode,
  updateSmsAuthYnEmail,
};

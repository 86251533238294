import axios from 'src/requests';
import { IDetailID, IPageRowIdParams, IPageRowParam } from 'src/requests/api/common/prop-state.type';
import {
  IFeedDuplicateParams,
  IFeedParams,
  IPosFeedsEditParam,
  IPostFeedsParam,
} from 'src/requests/api/feed/prop-state.type';
import { IStoreIdParams } from 'src/requests/api/store-news/prop-state.type';

const getListGsNasmo = async (params?: IPageRowIdParams) => {
  const repsonse = await axios.get('/feed/getGsNasmoList', {
    params,
  });
  return repsonse.data.data;
};

const getVideoGsNasmo = async (params?: IDetailID) => {
  const repsonse = await axios.get('/feed/getGsNasmoInfo', {
    params,
  });
  return repsonse.data.data;
};

const getListGdrNasmo = async (params?: IPageRowParam) => {
  const repsonse = await axios.get('/feed/getGdrNasmoList', {
    params,
  });
  return repsonse.data.data;
};

const getVideoGdrNasmo = async (params?: IDetailID) => {
  const repsonse = await axios.get('/feed/getGdrNasmoInfo', {
    params,
  });
  return repsonse.data.data;
};

const getListTrainingModeNasmo = async (params?: IPageRowParam) => {
  const repsonse = await axios.get('/training/swingVideo/feed', {
    params,
  });
  return repsonse.data.data;
};

const getListScoreCard = async (params?: IPageRowParam) => {
  const repsonse = await axios.get('/feed/getGsScoreList', {
    params,
  });
  return repsonse.data.data;
};

const getScoreCardFeed = async (params?: IDetailID) => {
  const repsonse = await axios.get('/feed/getGsScoreInfo', {
    params,
  });
  return repsonse.data.data;
};

const getFeedDetailModify = async (params?: IDetailID) => {
  const repsonse = await axios.get('/feed/detailInfo', {
    params,
  });
  return repsonse.data.data;
};

const uploadFileFeed = async (body: FormData) => {
  const response = await axios.post('/upload/file/feed', body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data.data;
};

const uploadMultiFileFeed = async (bodyFormData: FormData) => {
  const response = await axios.post('/upload/file/soFeed', bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data.data;
};

const createFeed = async (body: IPostFeedsParam) => {
  const response = await axios.post('/feed/save', body);
  return response.data.data;
};

const updateFeed = async (body: IPosFeedsEditParam) => {
  const response = await axios.post('/feed/modify', body);
  return response.data.data;
};

const getSoFeedList = async (params: IStoreIdParams) => {
  const response = await axios.get('/site/getSoFeedList', {
    params,
  });
  return response.data.data;
};

const getFeedList = async (params: IPageRowIdParams) => {
  const response = await axios.get('/site/getFeedList', {
    params,
  });
  return response.data.data;
};

const setFeedFix = async (body: IFeedParams) => {
  const response = await axios.post('/feed/setFix', body);
  return response.data.data;
};

const deleteFeed = async (body: IFeedParams) => {
  const response = await axios.post('/feed/setDelete', body);
  return response.data.data;
};

const reportFeed = async (body: IFeedParams) => {
  const response = await axios.post('/feed/setHide', body);
  return response.data.data;
};

const registerLike = async (body: IFeedParams) => {
  const response = await axios.post('/feed/regLike', body);
  return response.data.data;
};

const checkNasmoInfo = async (params: IFeedDuplicateParams) => {
  const response = await axios.get('/feed/getNasmoCheck', {
    params,
  });
  return response.data.data;
};

export const storeFeed = {
  getListGsNasmo,
  getListGdrNasmo,
  getVideoGsNasmo,
  getVideoGdrNasmo,
  getListTrainingModeNasmo,
  getListScoreCard,
  getScoreCardFeed,
  getFeedDetailModify,
  uploadFileFeed,
  uploadMultiFileFeed,
  createFeed,
  updateFeed,
  getSoFeedList,
  getFeedList,
  setFeedFix,
  deleteFeed,
  reportFeed,
  registerLike,
  checkNasmoInfo,
};

import { useTranslation } from 'react-i18next';
import {
  IconBallDraw,
  IconBallFade,
  IconBallHook,
  IconBallPull,
  IconBallPush,
  IconBallSlice,
  IconBallStraight,
} from 'src/assets/svg';
import { EnumGdrBallPathCode, EnumGsBallPath, EnumGsBallPathCode, EnumTmBallPathCode } from 'src/constants/enum';
import { twMerge } from 'tailwind-merge';

interface IBallDirectionProps {
  className?: string;
  classText?: string;
  classIcon?: string;
  gsBallPathCode?: EnumGsBallPathCode;
  gsBallPath?: EnumGsBallPath;
  gdrBallPathCode?: EnumGdrBallPathCode;
  tmBallPathCode?: EnumTmBallPathCode;
  hideText?: boolean;
  hideIcon?: boolean;
}

function BallDirection({
  className,
  classText,
  classIcon,
  gsBallPathCode,
  gsBallPath,
  gdrBallPathCode,
  tmBallPathCode,
  hideText = false,
  hideIcon = false,
}: IBallDirectionProps): JSX.Element {
  const translate = useTranslation().t;

  const nameBallPathCode = () => {
    if (
      gsBallPathCode === EnumGsBallPathCode.STRAIGHT ||
      gdrBallPathCode === EnumGdrBallPathCode.STRAIGHT ||
      tmBallPathCode === EnumTmBallPathCode.STRAIGHT
    )
      return translate('pages.record.ball_direction.STRAIGHT');
    if (
      gsBallPathCode === EnumGsBallPathCode.HOOK ||
      gdrBallPathCode === EnumGdrBallPathCode.HOOK ||
      tmBallPathCode === EnumTmBallPathCode.HOOK
    )
      return translate('pages.record.ball_direction.HOOK');
    if (
      gsBallPathCode === EnumGsBallPathCode.SLICE ||
      gdrBallPathCode === EnumGdrBallPathCode.SLICE ||
      tmBallPathCode === EnumTmBallPathCode.SLICE
    )
      return translate('pages.record.ball_direction.SLICE');
    if (
      gsBallPathCode === EnumGsBallPathCode.PULL ||
      gdrBallPathCode === EnumGdrBallPathCode.PULL ||
      tmBallPathCode === EnumTmBallPathCode.PULL
    )
      return translate('pages.record.ball_direction.PULL');
    if (
      gsBallPathCode === EnumGsBallPathCode.PULL_SLICE ||
      gdrBallPathCode === EnumGdrBallPathCode.PULL_SLICE ||
      tmBallPathCode === EnumTmBallPathCode.PULL_SLICE
    )
      return translate('pages.record.ball_direction.PULL_SLICE');
    if (gdrBallPathCode === EnumGdrBallPathCode.PULL_HOOK) return translate('pages.record.ball_direction.PULL_HOOK');
    if (
      gsBallPathCode === EnumGsBallPathCode.PUSH ||
      gdrBallPathCode === EnumGdrBallPathCode.PUSH ||
      tmBallPathCode === EnumTmBallPathCode.PUSH
    )
      return translate('pages.record.ball_direction.PUSH');
    if (gdrBallPathCode === EnumGdrBallPathCode.PUSH_SLICE) return translate('pages.record.ball_direction.PUSH_SLICE');
    if (
      gsBallPathCode === EnumGsBallPathCode.PUSH_HOOK ||
      gdrBallPathCode === EnumGdrBallPathCode.PUSH_HOOK ||
      tmBallPathCode === EnumTmBallPathCode.PUSH_HOOK
    )
      return translate('pages.record.ball_direction.PUSH_HOOK');
    if (
      gsBallPathCode === EnumGsBallPathCode.DRAW ||
      gdrBallPathCode === EnumGdrBallPathCode.DRAW ||
      tmBallPathCode === EnumTmBallPathCode.DRAW
    )
      return translate('pages.record.ball_direction.DRAW');
    if (
      gsBallPathCode === EnumGsBallPathCode.FADE ||
      gdrBallPathCode === EnumGdrBallPathCode.FADE ||
      tmBallPathCode === EnumTmBallPathCode.FADE
    )
      return translate('pages.record.ball_direction.FADE');
    return null;
  };

  const iconBallPathCode = () => {
    if (
      gsBallPathCode === EnumGsBallPathCode.STRAIGHT ||
      gsBallPath === EnumGsBallPath.STRAIGHT ||
      gdrBallPathCode === EnumGdrBallPathCode.STRAIGHT ||
      tmBallPathCode === EnumTmBallPathCode.STRAIGHT
    )
      return <IconBallStraight className={classIcon} />;
    if (
      gsBallPathCode === EnumGsBallPathCode.HOOK ||
      gsBallPathCode === EnumGsBallPathCode.PUSH_HOOK ||
      gdrBallPathCode === EnumGdrBallPathCode.HOOK ||
      gdrBallPathCode === EnumGdrBallPathCode.PULL_HOOK ||
      gdrBallPathCode === EnumGdrBallPathCode.PUSH_HOOK ||
      tmBallPathCode === EnumTmBallPathCode.HOOK ||
      tmBallPathCode === EnumTmBallPathCode.PUSH_HOOK
    )
      return <IconBallHook className={classIcon} />;
    if (
      gsBallPathCode === EnumGsBallPathCode.SLICE ||
      gsBallPathCode === EnumGsBallPathCode.PULL_SLICE ||
      gdrBallPathCode === EnumGdrBallPathCode.SLICE ||
      gdrBallPathCode === EnumGdrBallPathCode.PULL_SLICE ||
      gdrBallPathCode === EnumGdrBallPathCode.PUSH_SLICE ||
      tmBallPathCode === EnumTmBallPathCode.SLICE ||
      tmBallPathCode === EnumTmBallPathCode.PULL_SLICE
    )
      return <IconBallSlice className={classIcon} />;
    if (
      gsBallPathCode === EnumGsBallPathCode.PULL ||
      gdrBallPathCode === EnumGdrBallPathCode.PULL ||
      tmBallPathCode === EnumTmBallPathCode.PULL
    )
      return <IconBallPull className={classIcon} />;
    if (
      gsBallPathCode === EnumGsBallPathCode.PUSH ||
      gdrBallPathCode === EnumGdrBallPathCode.PUSH ||
      tmBallPathCode === EnumTmBallPathCode.PUSH
    )
      return <IconBallPush className={classIcon} />;
    if (
      gsBallPathCode === EnumGsBallPathCode.DRAW ||
      gdrBallPathCode === EnumGdrBallPathCode.DRAW ||
      gsBallPath === EnumGsBallPath.DRAW ||
      tmBallPathCode === EnumTmBallPathCode.DRAW
    )
      return <IconBallDraw className={classIcon} />;
    if (
      gsBallPathCode === EnumGsBallPathCode.FADE ||
      gdrBallPathCode === EnumGdrBallPathCode.FADE ||
      gsBallPath === EnumGsBallPath.FADE ||
      tmBallPathCode === EnumTmBallPathCode.FADE
    )
      return <IconBallFade className={classIcon} />;
    return null;
  };

  if (!nameBallPathCode() && !iconBallPathCode()) return <></>;

  if (gsBallPathCode != null || gsBallPath != null || gdrBallPathCode != null || tmBallPathCode != null) {
    return (
      <div className={twMerge('gz-text-2xl font-bold', className)}>
        {!hideText && (
          <span className={twMerge('text-gz-white [&:not(:last-child)]:mr-[6px]', classText)}>
            {nameBallPathCode()}
          </span>
        )}
        {!hideIcon && <div className="inline-block flex-shrink-0 align-middle">{iconBallPathCode()}</div>}
      </div>
    );
  }

  return <></>;
}

export default BallDirection;

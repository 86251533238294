import { flagGolfzon } from 'src/assets/svg';
import { FLAG_USER_COUNTRIES } from '../constants/const/flag-user-countries.constants';
import {
  bgBronzeLevel,
  bgBronzeLevelSmall,
  bgDiamondLevel,
  bgDiamondLevelSmall,
  bgGoldLevel,
  bgGoldLevelSmall,
  bgPlatinumLevel,
  bgPlatinumLevelSmall,
  bgSilverLevel,
  bgSilverLevelSmall,
  picBronzeLevel,
  picBronzeLevelSmall,
  picDiamondLevel,
  picDiamondLevelSmall,
  picGoldLevel,
  picGoldLevelSmall,
  picPlatinumLevel,
  picPlatinumLevelSmall,
  picSilverLevel,
  picSilverLevelSmall,
} from 'src/assets/images';
import { EnumRankLevel, EnumUserLevelCode } from 'src/constants/enum';
import { picBronzeRank, picDiamondRank, picGoldRank, picPlatinumRank, picSilverRank } from 'src/assets/images';
import { RUSSIA_COUNTRY_CODE } from 'src/constants/const';

export const hideUserId = (userid?: string): string => {
  if (!userid || userid.length < 3) return userid ?? '';
  return userid.substring(0, 2) + userid.substring(2, userid.length).replaceAll(/./g, '*');
};

export const isUserStoreOwner = (userType?: string): boolean => {
  return userType === 'Y';
};

export const getLevelImage = (code?: EnumUserLevelCode | EnumRankLevel) => {
  if (
    code === EnumUserLevelCode.DIAMOND_3 ||
    code === EnumUserLevelCode.DIAMOND_2 ||
    code === EnumUserLevelCode.DIAMOND_1 ||
    code === EnumUserLevelCode.BEFORE_DIAMOND ||
    code === EnumUserLevelCode.INACTIVE_DIAMOND ||
    code === EnumRankLevel.DIAMOND
  ) {
    return {
      bg: bgDiamondLevel,
      bgSmall: bgDiamondLevelSmall,
      icon: picDiamondLevel,
      iconSmall: picDiamondLevelSmall,
      iconSquare: picDiamondRank,
      gradient: 'bg-diamond-gradient',
    };
  }
  if (
    code === EnumUserLevelCode.PLATINUM_3 ||
    code === EnumUserLevelCode.PLATINUM_2 ||
    code === EnumUserLevelCode.PLATINUM_1 ||
    code === EnumUserLevelCode.BEFORE_PLATINUM ||
    code === EnumUserLevelCode.INACTIVE_PLATINUM ||
    code === EnumRankLevel.PLATINUM
  ) {
    return {
      bg: bgPlatinumLevel,
      bgSmall: bgPlatinumLevelSmall,
      icon: picPlatinumLevel,
      iconSmall: picPlatinumLevelSmall,
      iconSquare: picPlatinumRank,
      gradient: 'bg-platinum-gradient',
    };
  }
  if (
    code === EnumUserLevelCode.GOLD_3 ||
    code === EnumUserLevelCode.GOLD_2 ||
    code === EnumUserLevelCode.GOLD_1 ||
    code === EnumUserLevelCode.BEFORE_GOLD ||
    code === EnumUserLevelCode.INACTIVE_GOLD ||
    code === EnumRankLevel.GOLD
  ) {
    return {
      bg: bgGoldLevel,
      bgSmall: bgGoldLevelSmall,
      icon: picGoldLevel,
      iconSmall: picGoldLevelSmall,
      iconSquare: picGoldRank,
      gradient: 'bg-gold-gradient',
    };
  }
  if (
    code === EnumUserLevelCode.SILVER_3 ||
    code === EnumUserLevelCode.SILVER_2 ||
    code === EnumUserLevelCode.SILVER_1 ||
    code === EnumUserLevelCode.BEFORE_SILVER ||
    code === EnumUserLevelCode.INACTIVE_SILVER ||
    code === EnumRankLevel.SILVER
  ) {
    return {
      bg: bgSilverLevel,
      bgSmall: bgSilverLevelSmall,
      icon: picSilverLevel,
      iconSmall: picSilverLevelSmall,
      iconSquare: picSilverRank,
      gradient: 'bg-silver-gradient',
    };
  }
  return {
    bg: bgBronzeLevel,
    bgSmall: bgBronzeLevelSmall,
    icon: picBronzeLevel,
    iconSmall: picBronzeLevelSmall,
    iconSquare: picBronzeRank,
    gradient: 'bg-bronze-gradient',
  };
};

export const getLevelBorder = (code?: EnumUserLevelCode | EnumRankLevel) => {
  if (
    code === EnumUserLevelCode.DIAMOND_3 ||
    code === EnumUserLevelCode.DIAMOND_2 ||
    code === EnumUserLevelCode.DIAMOND_1 ||
    code === EnumUserLevelCode.BEFORE_DIAMOND ||
    code === EnumUserLevelCode.INACTIVE_DIAMOND ||
    code === EnumRankLevel.DIAMOND
  ) {
    return 'bg-border-diamond';
  }
  if (
    code === EnumUserLevelCode.PLATINUM_3 ||
    code === EnumUserLevelCode.PLATINUM_2 ||
    code === EnumUserLevelCode.PLATINUM_1 ||
    code === EnumUserLevelCode.BEFORE_PLATINUM ||
    code === EnumUserLevelCode.INACTIVE_PLATINUM ||
    code === EnumRankLevel.PLATINUM
  ) {
    return 'bg-border-platium';
  }
  if (
    code === EnumUserLevelCode.GOLD_3 ||
    code === EnumUserLevelCode.GOLD_2 ||
    code === EnumUserLevelCode.GOLD_1 ||
    code === EnumUserLevelCode.BEFORE_GOLD ||
    code === EnumUserLevelCode.INACTIVE_GOLD ||
    code === EnumRankLevel.GOLD
  ) {
    return 'bg-border-gold';
  }
  if (
    code === EnumUserLevelCode.SILVER_3 ||
    code === EnumUserLevelCode.SILVER_2 ||
    code === EnumUserLevelCode.SILVER_1 ||
    code === EnumUserLevelCode.BEFORE_SILVER ||
    code === EnumUserLevelCode.INACTIVE_SILVER ||
    code === EnumRankLevel.SILVER
  ) {
    return 'bg-border-silver';
  }
  return 'bg-border-brown';
};

export const getCountryFlag = (countryCd: string) => {
  let imgCountry = '';
  if (countryCd) {
    if (countryCd === RUSSIA_COUNTRY_CODE) return flagGolfzon;
    imgCountry = FLAG_USER_COUNTRIES.find((item) => item.countryCd === countryCd)?.flag!;
  }
  return imgCountry || flagGolfzon;
};

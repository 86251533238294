import React from 'react';
import { useTranslation } from 'react-i18next';
import { holdInOne } from 'src/assets/images';

export default function Banner() {
  const translate = useTranslation().t;

  return (
    <div className="relative h-[64px] bg-banner-par3-challenge">
      <div className="absolute top-1/2 flex w-full -translate-y-1/2 items-center justify-between px-[16px]">
        <div>
          <div className="gz-text-md mb-[4px] font-bold text-semantic-green-30">
            {translate('pages.nx.par_3_leaderboard.BANNER_TITLE')}
          </div>
          <div className="gz-text-xsm text-gz-white">{translate('pages.nx.par_3_leaderboard.BANNER_DESC')}</div>
        </div>
        <img src={holdInOne} alt="hold-in-one" className="w-[57px]" />
      </div>
    </div>
  );
}

import { iconRankBronze, iconRankGold, iconRankSilver } from 'src/assets/svg';
import { topNumber } from 'src/constants/enum';
import ImageCustom from 'src/view/commons/customs/Image';
import { twMerge } from 'tailwind-merge';

interface ILeaderboardRankProps {
  className?: string;
  rank: number | string;
  sameRank?: boolean;
  isMy?: boolean;
}

function LeaderboardRank({ className, rank, sameRank, isMy = false }: ILeaderboardRankProps): JSX.Element {
  const rankNumber = Number(rank);
  const rankPrefix = sameRank ? 'T' : '';
  const myPrefix = isMy ? 'My ' : '';
  const displayedRank = myPrefix + rankPrefix + rank;

  if (rankNumber === topNumber.FIRST && !isMy) {
    return (
      <div className={twMerge('relative', className)}>
        <div className="gz-text-xsm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform break-normal text-text-level-gold">
          {displayedRank}
        </div>
        <ImageCustom imgPath={iconRankGold} imgAlt="top level" />
      </div>
    );
  }
  if (rankNumber === topNumber.SECOND && !isMy) {
    return (
      <div className={twMerge('relative', className)}>
        <div className="gz-text-xsm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform break-normal text-text-level-silver">
          {displayedRank}
        </div>
        <ImageCustom imgPath={iconRankSilver} imgAlt="top level" />
      </div>
    );
  }
  if (rankNumber === topNumber.THIRD && !isMy) {
    return (
      <div className={twMerge('relative', className)}>
        <div className="gz-text-xsm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform break-normal text-text-level-bronze">
          {displayedRank}
        </div>
        <ImageCustom imgPath={iconRankBronze} imgAlt="top level" />
      </div>
    );
  }
  return <div className={twMerge('break-normal', className)}>{displayedRank}</div>;
}

export default LeaderboardRank;

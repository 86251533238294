import { RESET_LEAGUE_DATA, UPDATE_LEAGUE_DATA } from './action';
import { EnumFilterField } from 'src/constants/enum';
import { ILeague } from 'src/models/league';
import { IParticipatedEvent, IRecommendTournament } from 'src/requests/api/tournament/prop-state.type';

export interface ILeagueState {
  position: number;
  participatedLeagues: IParticipatedEvent[];
  recommendLeagues: IRecommendTournament[];
  leagueList: ILeague[];
  leagueTotal: number;
  leaguePageLoaded: number;
  leaguePageIsLast: boolean;
  leagueSearchName: string;
  joinStatus: EnumFilterField;
  storeType: EnumFilterField;
  leagueStatus: EnumFilterField;
}

const initialState: ILeagueState = {
  position: 0,
  participatedLeagues: [],
  recommendLeagues: [],
  leagueList: [],
  leagueTotal: 0,
  leaguePageLoaded: 0,
  leaguePageIsLast: false,
  leagueSearchName: '',
  joinStatus: EnumFilterField.ALL,
  storeType: EnumFilterField.ALL,
  leagueStatus: EnumFilterField.ALL,
};

export function leagueReducer(state: ILeagueState = initialState, action: any) {
  switch (action.type) {
    case UPDATE_LEAGUE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_LEAGUE_DATA:
      return initialState;
    default:
      return state;
  }
}

import {
  picIconArcade,
  picIconGDR,
  picIconGdrPlus,
  picIconNx,
  picIconReal,
  picIconSocial,
  picIconTwoVision,
  picIconVision,
  picIconVisionPlus,
} from 'src/assets/images';

export const DEFAULT_DISTANCE_AWAY = 15;
export const INIT_DISTANCE_AWAY = 0.1;
export const DARKMODE_MAP_ID = '31591874ad621404';
export const LIGHTMODE_MAP_ID = 'bcb2c47d8fbc2469';
export const MAP_AREA = 'MAP_AREA';

const roundList = [
  {
    title: 'TVNX',
    icon: picIconNx,
    value: 'TVNX',
  },
  {
    title: 'TWOVISION',
    icon: picIconTwoVision,
    value: 'T',
  },
  {
    title: 'Social',
    icon: picIconSocial,
    value: 'S',
  },
  {
    title: 'ARCADE',
    icon: picIconArcade,
    value: 'AC',
  },
  {
    title: 'VISION PLUS',
    icon: picIconVisionPlus,
    value: 'VP',
  },
  {
    title: 'VISION',
    icon: picIconVision,
    value: 'V',
  },
  {
    title: 'REAL',
    icon: picIconReal,
    value: 'R',
  },
];
const practiceList = [
  {
    title: 'GDR',
    icon: picIconGDR,
    value: 'G',
  },
  {
    title: 'GDR Plus',
    icon: picIconGdrPlus,
    value: 'GP',
  },
];
const facilityList = [
  {
    title: 'RIGHT_HAND',
    value: 'RH',
  },
  {
    title: 'BOTH_HANDS',
    value: 'BH',
  },
  {
    title: 'SWING_PLATE',
    value: 'SP',
  },
  {
    title: 'PARKING',
    value: 'P',
  },
];

export const storeMapConstants = {
  roundList,
  practiceList,
  facilityList,
};

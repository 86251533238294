import { useTranslation } from 'react-i18next';
import { IconRecordLock } from 'src/assets/svg';
import { history } from 'src/services/history';
import Button from 'src/view/commons/elements/Button';
import Footer from 'src/view/layouts/global/Footer';

export default function GuestRecord(): JSX.Element {
  const translate = useTranslation().t;

  return (
    <>
      <div className="mt-[64px] mb-[128px] flex flex-col items-center px-[24px]">
        <IconRecordLock className="h-[48px] w-[48px]" />
        <div className="gz-text-xs my-[16px] text-center text-sub-body">{translate('pages.record.REQUEST_LOGIN')}</div>
        <Button
          className="mt-[16px] w-auto min-w-[163px] px-[24px]"
          size="medium"
          onClick={() => history.pushToLogin()}
        >
          {translate('pages.account.login.id_and_password_login.BTN_LOGIN')}
        </Button>
      </div>
      <Footer />
    </>
  );
}

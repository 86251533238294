import axios from 'axios';
import FileSaver from 'file-saver';
import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { IconChart, IconEllipsisVertical } from 'src/assets/svg';
import { mobileAction, pathConstants } from 'src/constants/const';
import {
  EnumAIRecordType,
  EnumGdrBallPathCode,
  EnumTmBallPathCode,
  EnumTypeClub,
  EnumValueBoolean,
} from 'src/constants/enum';
import { EnumFeedType } from 'src/constants/enum/feed.enum';
import { sendDataToApp } from 'src/hooks/webview';
import { NotificationType } from 'src/models';
import { storeFeed } from 'src/requests/api/feed/store-news';
import { GSrecord } from 'src/requests/api/record/GS';
import { getTypeClub } from 'src/services/gdr-and-gs';
import { convertToSwingAIAnalysisParams } from 'src/services/global';
import { history } from 'src/services/history';
import { booleanValue, fixDecimal } from 'src/services/utils';
import { notificationActions } from 'src/store/notification';
import BallDirection from 'src/view/commons/customs/BallDirection';
import ConfirmAlertDialog from 'src/view/commons/customs/ConfirmAlertDialog';
import VideoCustom from 'src/view/commons/customs/Video';
import Button from 'src/view/commons/elements/Button';
import DropdownMenu from 'src/view/commons/elements/DropdownMenu';
import FootWeightAnalysis from 'src/view/pages/record/commons/FootWeightAnalysis';
import { twMerge } from 'tailwind-merge';

enum EnumVideoAction {
  SWING_ANALYSIS = '1',
  SAVE = '2',
  DELETE = '3',
}

export interface IVideoDetail {
  pgCode?: number;
  nasmoFile: string;
  nasmoImgFile?: string;
  nasmoSideFile?: string;
  nasmoSideImgFile?: string;
  clubName: string;
  clubCode?: string;
  distance: string;
  distUnit: string;
  gdrBallPath?: EnumGdrBallPathCode;
  tmBallPath?: EnumTmBallPathCode;
  holeNo?: string;
  nasmoId: number;
  regFeedNo: number;
  videoId?: string;
  sideVideoId?: string;
  clubNm?: string;
  dist?: string;
  analysisId?: string;
  tsCode?: number;
  hasWeight?: EnumValueBoolean;
}

interface IVideoRegionProps {
  className?: string;
  classSideWrapper?: string;
  videoDescription?: React.ReactNode;
  videoDetail: IVideoDetail;
  distanceDecimal?: number;
  haveSideVideo?: boolean;
  isNativeApp?: boolean;
  darkMode?: boolean;
  expandedPosition?: number;
  hasAIAnalysis?: boolean;
  headerHeight?: number;
  hideSuffix?: boolean;
  saveErrorMessage?: string;
  recordType?: EnumAIRecordType;
  handleDelete?: (isVideoSide?: boolean) => void;
  addNotification: (message: string, type: NotificationType) => void;
  onPlayVideo?: () => void;
  onSideViewClick?: () => void;
  onFrontViewClick?: () => void;
  onRequestAIClick?: () => void;
  onViewAIResultClick?: () => void;
}

const VideoRegion = forwardRef(
  (
    {
      className,
      classSideWrapper,
      videoDescription,
      videoDetail,
      distanceDecimal,
      haveSideVideo,
      isNativeApp,
      darkMode = false,
      expandedPosition,
      hasAIAnalysis = false,
      headerHeight = 0,
      hideSuffix = false,
      saveErrorMessage,
      recordType,
      handleDelete,
      addNotification,
      onPlayVideo,
      onSideViewClick,
      onFrontViewClick,
      onRequestAIClick,
      onViewAIResultClick,
    }: IVideoRegionProps,
    ref: any,
  ) => {
    const translate = useTranslation().t;
    const [isVideoSide, setIsVideoSide] = useState<boolean>();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openSwingAnalysisWarning, setOpenSwingAnalysisWarning] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [showExpandedBtn, setShowExpandedBtn] = useState(false);
    const [heightExpansion, setHeightExpansion] = useState('100vh');
    const videoRef = useRef<any>();
    const videoCustomRef = useRef<any>();

    const VIDEO_DETAIL_ACTIONS = [
      ...(isNativeApp
        ? [
            {
              title: translate('pages.record.type.GDR.swing_video.action_video.SWING_ANALYSIS'),
              value: EnumVideoAction.SWING_ANALYSIS,
              className: 'text-primary-75',
            },
          ]
        : []),
      {
        title: translate('pages.record.type.GDR.swing_video.action_video.SAVE'),
        value: EnumVideoAction.SAVE,
        className: darkMode ? 'text-gz-white' : '',
      },
      {
        title: translate('pages.record.type.GDR.swing_video.action_video.DELETE'),
        value: EnumVideoAction.DELETE,
        className: 'text-gz-danger',
      },
    ];

    const i18n = useTranslation().i18n;
    const settingInfo = useSelector((state: any) => state.settingInfoReducer.settingInfo);
    const [currentHasAIAnalysis, setCurrentHasAIAnalysis] = useState(hasAIAnalysis);
    const [hasFootWeightAnalysis, setHasFootWeightAnalysis] = useState(false);
    const [weightData, setWeightData] = useState('');
    const [frontViewAnalysisId = '', sideViewAnalysisId = ''] = (videoDetail?.analysisId || '')?.split(',');
    const isGDR = recordType === EnumAIRecordType.GDR;
    const isShowBtnViewAIResult =
      (!isGDR && !!videoDetail?.analysisId) ||
      (isGDR && isVideoSide && !!sideViewAnalysisId) ||
      (isGDR && !isVideoSide && !!frontViewAnalysisId);

    useEffect(() => {
      if (expandedPosition == null) return;
      window.addEventListener('scroll', handleScroll, { passive: true });
      if (!videoRef.current) return;
      const resizeObserver = new ResizeObserver(() => {
        if (videoRef.current) {
          setHeightExpansion(`calc(100vh - ${videoRef.current.offsetHeight + headerHeight}px)`);
        }
      });
      resizeObserver.observe(videoRef.current); // Watch offsetHeight changed
      return () => {
        window.removeEventListener('scroll', handleScroll);
        resizeObserver.disconnect();
      };
    }, []);

    useEffect(() => {
      if (haveSideVideo && !videoDetail?.nasmoFile) {
        setIsVideoSide(true);
      }
    }, [videoDetail]);

    /*
      AI Analysis is only available for Wood/Utility/Iron clubs
    */
    useEffect(() => {
      if (!videoDetail?.clubCode) {
        setCurrentHasAIAnalysis(false);
      } else {
        const typeClub = getTypeClub(videoDetail.clubCode);
        const validClub = [EnumTypeClub.DRIVER, EnumTypeClub.WOOD, EnumTypeClub.UTILITY, EnumTypeClub.IRON].includes(
          typeClub,
        );
        setCurrentHasAIAnalysis(hasAIAnalysis && validClub);
      }
    }, [videoDetail.clubCode, hasAIAnalysis]);

    /*
      Foot Weight Analysis has data
    */
    useEffect(() => {
      setHasFootWeightAnalysis(booleanValue(videoDetail?.hasWeight));
    }, [videoDetail.hasWeight]);

    useEffect(() => {
      if (videoDetail?.nasmoId && booleanValue(videoDetail?.hasWeight)) {
        setWeightData('');
        GSrecord.getFootWeightData(videoDetail.nasmoId).then((res) => {
          setWeightData(res.entities);
        });
      }
    }, [videoDetail.nasmoId]);

    useImperativeHandle(ref, () => ({
      downloadVideo() {
        downloadVideo();
      },
      analysisSwing() {
        handleAction(EnumVideoAction.SWING_ANALYSIS);
      },
    }));

    const handleScroll = () => {
      if (expandedPosition == null) return;
      setShowExpandedBtn(window.scrollY > expandedPosition);
    };

    const downloadVideo = async () => {
      try {
        const videoPath =
          isVideoSide && videoDetail?.nasmoSideFile ? videoDetail?.nasmoSideFile : videoDetail?.nasmoFile;
        if (isNativeApp) {
          sendDataToApp({ action: mobileAction.DOWNLOAD_VIDEO, info: videoPath });
        } else {
          await axios
            .get(videoPath, {
              responseType: 'blob',
            })
            .then((res) => {
              FileSaver.saveAs(res.data, 'video-nasmo.mp4');
            });
          addNotification(translate('mess.SAVE_VIDEO_SUCCESS'), NotificationType.SUCCESS);
        }
      } catch (error: any) {
        console.warn(error);
        addNotification(saveErrorMessage ?? translate('mess.SAVE_VIDEO_ERROR'), NotificationType.DANGER);
      }
    };

    const handleAction = async (action: string) => {
      switch (action) {
        case EnumVideoAction.SWING_ANALYSIS: {
          try {
            const typeSwing = !videoDetail.pgCode ? EnumFeedType.GDR : EnumFeedType.NASMO; // routerState?.pathname?.includes('gdr')
            const videoId = isVideoSide ? videoDetail?.sideVideoId : videoDetail?.videoId;

            const response = await storeFeed.checkNasmoInfo({
              detailNo: videoDetail.pgCode ?? 0,
              nasmoNo: typeSwing === EnumFeedType.GDR ? Number(videoId!) : videoDetail?.nasmoId!,
              type: typeSwing,
            });
            sendDataToApp({
              action: mobileAction.EDIT_VIDEO,
              info: {
                nasmoFile: isVideoSide ? videoDetail?.nasmoSideFile : videoDetail?.nasmoFile,
                videoId: typeSwing === EnumFeedType.GDR ? videoId : undefined,
                nasmoId: videoDetail?.nasmoId,
                feedType: typeSwing,
                feedNo: response?.entities?.feedNo,
                isVideoSideView: isVideoSide,
              },
            });
          } catch (error) {
            console.warn(error);
            addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
          }
          break;
        }
        case EnumVideoAction.SAVE:
          downloadVideo();
          break;
        default:
          setOpenConfirm(true);
          break;
      }
    };

    const onHandleConfirm = async () => {
      handleDelete?.call(null, isVideoSide);
      setOpenConfirm(false);
    };

    const handleViewResult = () => {
      /* mobile backup */
      // if (isNativeApp) {
      //   const swingVideoInfo = convertToSwingAIAnalysisParams({
      //     userNo: userInfo?.usrNo,
      //     lang: i18n?.language,
      //     analysisId: videoDetail?.analysisId,
      //   });
      //   sendDataToApp({
      //     action: mobileAction.VIEW_SWING_AI_ANALYSIS,
      //     info: swingVideoInfo,
      //   });
      // } else
      onViewAIResultClick?.call(null);
      let _analysisId = videoDetail?.analysisId;
      if (isGDR) {
        _analysisId = isVideoSide ? sideViewAnalysisId : frontViewAnalysisId;
      }
      history.push(pathConstants.RECORD_SWING_AI_ANALYSIS(recordType?.toLocaleLowerCase(), _analysisId));
    };

    const handleRequestAI = () => {
      onRequestAIClick?.call(null);
      if (!isNativeApp) {
        setOpenSwingAnalysisWarning(true);
      } else {
        const extraData = {
          [EnumAIRecordType.GS]: { gameNo: videoDetail?.pgCode },
          [EnumAIRecordType.GDR]: { nasmoId: videoDetail?.nasmoId, analysisId: videoDetail?.analysisId, isVideoSide },
          [EnumAIRecordType.PRACTICE]: { trainingTsCd: Number(videoDetail?.tsCode) },
        };
        const requestSwingAiParams = convertToSwingAIAnalysisParams({
          userNo: settingInfo?.usrNo,
          clubType: videoDetail?.clubCode,
          gender: settingInfo?.sex,
          videoUrl: haveSideVideo && isVideoSide ? videoDetail?.nasmoSideFile : videoDetail?.nasmoFile,
          videoKey: Number(videoDetail?.tsCode) || videoDetail?.nasmoId,
          lang: i18n?.language,
          type: recordType,
          ...(recordType ? extraData[recordType] : {}),
        });
        sendDataToApp({
          action: mobileAction.REQUEST_SWING_AI,
          info: requestSwingAiParams,
        });
      }
    };

    const getProgressVideo = () => {
      if (videoCustomRef.current) {
        return videoCustomRef.current.getCurrentTime() / videoCustomRef.current.getDuration();
      }
      return 0;
    };

    const renderDialog = () => {
      const isRegFeed = videoDetail.regFeedNo && videoDetail.regFeedNo > 0;
      return (
        <ConfirmAlertDialog
          title={translate(
            isRegFeed
              ? 'pages.record.type.GDR.swing_video.DELETE_SWING_AND_FEED_DESCRRIPTION'
              : 'pages.record.type.GDR.swing_video.DELETE_SWING_DESCRRIPTION',
          )}
          textOK={translate('pages.record.type.GDR.swing_video.action_video.DELETE')}
          textCancel={translate('pages.record.type.GDR.swing_video.action_video.CANCEL')}
          classTitle="p-[22px_53px]"
          classCancel="text-primary-75"
          classOK="text-gz-danger"
          isOpen={openConfirm}
          onConfirm={onHandleConfirm}
          onClose={() => setOpenConfirm(false)}
          reverseAction
        />
      );
    };

    const renderSwingAnalysisDialog = () => {
      return (
        <ConfirmAlertDialog
          title={translate('pages.record.type.GDR.swing_video.AI_WARNING')}
          textCancel={translate('pages.record.type.GDR.swing_video.action_video.CLOSE')}
          classTitle="p-[16px_24px]"
          classCancel="text-primary-75 w-full"
          classOK="hidden"
          isOpen={openSwingAnalysisWarning}
          onClose={() => setOpenSwingAnalysisWarning(false)}
        />
      );
    };

    const renderActionSide = () => {
      if (!haveSideVideo) return <></>;
      return (
        <div className={twMerge('gz-text-xs flex w-full gap-[8px] pt-[16px]', classSideWrapper)}>
          {videoDetail?.nasmoFile && (
            <div className="relative">
              <button
                className={twMerge(
                  'rounded-t-[12px] px-[24px] py-[8px] font-bold shadow-video-des focus:outline-none',
                  !isVideoSide ? 'bg-gz-white text-primary-75' : 'bg-light-gray-10 text-gray-35',
                )}
                onClick={() => {
                  onFrontViewClick?.call(null);
                  setIsVideoSide(false);
                }}
              >
                {translate('pages.record.type.GDR.swing_video.FRONT_VIEW')}
              </button>
              {!isVideoSide && <div className="absolute bottom-[-1px] z-1 h-[6px] w-full bg-gz-white" />}
            </div>
          )}
          {videoDetail?.nasmoSideFile && (
            <div className="relative">
              <button
                className={twMerge(
                  'rounded-t-[12px] px-[24px] py-[8px] font-bold shadow-video-des focus:outline-none',
                  isVideoSide ? 'bg-gz-white text-primary-75' : 'bg-light-gray-10 text-gray-35',
                )}
                onClick={() => {
                  onSideViewClick?.call(null);
                  setIsVideoSide(true);
                }}
              >
                {translate('pages.record.type.GDR.swing_video.SIDE_VIEW')}
              </button>
              {isVideoSide && <div className="absolute bottom-[-1px] z-1 h-[6px] w-full bg-gz-white" />}
            </div>
          )}
        </div>
      );
    };

    const renderFootWeightAnalysis = () => {
      return (
        recordType === EnumAIRecordType.GS &&
        hasFootWeightAnalysis &&
        weightData && (
          <FootWeightAnalysis
            className={twMerge(!currentHasAIAnalysis && 'mt-[16px]')}
            weightData={weightData}
            getProgressVideo={getProgressVideo}
          />
        )
      );
    };

    return (
      <>
        <div className={className}>
          <div className="relative" ref={videoRef}>
            <VideoCustom
              ref={videoCustomRef}
              videoPath={!isVideoSide ? videoDetail?.nasmoFile : videoDetail?.nasmoSideFile}
              thumbnail={!isVideoSide ? videoDetail?.nasmoImgFile : videoDetail?.nasmoSideImgFile}
              repeatPlay
              prefix={
                <>
                  <div className="align-middle text-gz-white">
                    <span className="gz-text-md mr-[4px] font-bold">{videoDetail?.clubName}</span>
                    <span className="gz-text-sm mr-[4px]">{`${fixDecimal(
                      videoDetail?.distance,
                      false,
                      distanceDecimal,
                    )} ${videoDetail?.distUnit}`}</span>
                    <span className="ml-[4px] inline-block">
                      <BallDirection
                        gdrBallPathCode={videoDetail.gdrBallPath}
                        tmBallPathCode={videoDetail.tmBallPath}
                        classIcon="h-[14px] w-auto"
                        hideText
                      />
                    </span>
                  </div>
                  {videoDetail?.holeNo != null && (
                    <div className="gz-text-xsm text-gz-white">
                      {translate('nasmo.HOLE', { number: videoDetail.holeNo })}
                    </div>
                  )}
                </>
              }
              suffix={
                !hideSuffix ? (
                  <>
                    <DropdownMenu
                      highlight={false}
                      items={VIDEO_DETAIL_ACTIONS}
                      onChange={handleAction}
                      classItem="gz-text-xs"
                      classMenuItems={twMerge(darkMode ? 'bg-gz-black border-gz-black' : '')}
                    >
                      <IconEllipsisVertical className="rounded-full fill-gz-white stroke-gz-white active:bg-disable" />
                    </DropdownMenu>
                  </>
                ) : (
                  <></>
                )
              }
              onPlayVideo={onPlayVideo}
            />
            <div
              className={twMerge(
                (currentHasAIAnalysis || showExpandedBtn) && 'flex bg-gz-white p-[16px] shadow-bottom transition-all',
              )}
            >
              {currentHasAIAnalysis && (
                <div className="w-full">
                  {isShowBtnViewAIResult ? (
                    <Button size="medium" onClick={handleViewResult}>
                      {translate('pages.record.type.GDR.swing_video.VIEW_AI_RESULT')}
                    </Button>
                  ) : (
                    <Button size="medium" onClick={handleRequestAI}>
                      {translate('pages.record.type.GDR.swing_video.REQUEST_AI_NOW')}
                    </Button>
                  )}
                </div>
              )}
              {showExpandedBtn && (
                <>
                  {recordType === EnumAIRecordType.GS && hasFootWeightAnalysis && (
                    <Button
                      size="medium"
                      className={twMerge(currentHasAIAnalysis && 'ml-[8px]', expanded && 'bg-blue-81 text-gz-white')}
                      color="transparent-blue"
                      onClick={() => setExpanded(!expanded)}
                    >
                      {translate('pages.record.type.GS.swing_video.WEIGHT_FLOW')}
                    </Button>
                  )}
                  {videoDescription && (
                    <>
                      {currentHasAIAnalysis ? (
                        <Button
                          size="medium"
                          className={twMerge('ml-[8px] w-[40px] shrink-0', expanded && 'bg-blue-81 text-gz-white')}
                          color="transparent-blue"
                          onClick={() => setExpanded(!expanded)}
                        >
                          <IconChart className="m-auto stroke-current" />
                        </Button>
                      ) : (
                        <Button size="medium" onClick={() => setExpanded(!expanded)}>
                          {translate('pages.record.type.GDR.swing_video.VIEW_SHOT_ANALYSIS')}
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>

            <div
              className={twMerge(
                'absolute top-[100%] z-10 w-full',
                'overflow-hidden bg-gz-white transition-[height]',
                !expanded && 'shadow-bottom',
              )}
              style={{ height: !showExpandedBtn || !expanded ? 0 : heightExpansion }}
            >
              {expanded && (
                <>
                  {renderActionSide()}
                  {videoDescription}
                  {renderFootWeightAnalysis()}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="video-description">
          {renderActionSide()}
          {videoDescription}
          {renderFootWeightAnalysis()}
        </div>
        {renderDialog()}
        {renderSwingAnalysisDialog()}
      </>
    );
  },
);
const mapStateToProps = (state: any) => ({
  isNativeApp: state.webviewReducer.isNativeApp,
});
const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(memo(VideoRegion));

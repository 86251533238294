import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';
import { twMerge } from 'tailwind-merge';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  className?: string;
  children?: string | JSX.Element;
  color?: 'default' | 'secondary' | 'secondary-gray' | 'transparent' | 'transparent-blue';
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
}

function Button({
  children,
  className,
  color = 'default',
  size = 'large',
  loading = false,
  ...rest
}: Props): JSX.Element {
  const btnColor = () => {
    if (color === 'secondary') {
      return 'bg-primary-20 text-primary-75 active:bg-primary-75 active:text-gz-white';
    }
    if (color === 'secondary-gray') {
      return 'bg-gray-secondary text-text active:bg-disable';
    }
    if (color === 'transparent') {
      return 'bg-none text-text active:bg-primary-20 active:text-primary-75';
    }
    if (color === 'transparent-blue') {
      return 'bg-none text-text active:bg-primary-75 active:text-gz-white border border-primary-75 text-primary-75';
    }
    return 'bg-primary-75 text-gz-white active:bg-gz-primary active:text-gz-white';
  };

  const btnSize = () => {
    if (size === 'large') {
      return 'h-[48px] gz-text-md';
    }
    if (size === 'small') {
      return 'h-[32px] gz-text-xs';
    }
    return 'h-[40px] gz-text-xs';
  };

  return (
    <button
      {...rest}
      className={twMerge(
        'w-full rounded-[6px] font-medium leading-[0] transition-colors focus:outline-none',
        'disabled:cursor-not-allowed disabled:bg-gray-10 disabled:text-text-disable',
        loading && 'pointer-events-none',
        btnColor(),
        btnSize(),
        className,
      )}
    >
      {!loading ? children : <LoadingIndicator />}
    </button>
  );
}

export default Button;

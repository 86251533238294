import axios from 'src/requests';
import {
  IChangePasswordRequest,
  ICheckUserCellphoneRequest,
  ICheckUserEmailRequest,
  ICheckUserIdRequest,
  ICheckVerifyEmailRequest,
  ISendVerifyEmailRequest,
} from 'src/requests/api/account/prop-state.type';

const checkUserId = async (params: ICheckUserIdRequest) => {
  const response = await axios.get('/login/checkUserId', {
    params,
  });
  return response.data.data;
};

const checkUserCellphone = async (body: ICheckUserCellphoneRequest) => {
  const response = await axios.post('/login/checkUserCellphone', body);
  return response.data.data;
};

const checkUserEmail = async (body: ICheckUserEmailRequest) => {
  const response = await axios.post('/login/checkUserEmail', body);
  return response.data.data;
};

const sendVerifyEmail = async (body: ISendVerifyEmailRequest) => {
  const response = await axios.post('/join/sendEmailNum', body);
  return response.data.data;
};

const checkVerifyEmail = async (body: ICheckVerifyEmailRequest) => {
  const response = await axios.post('/join/authEmailNum', body);
  return response.data.data;
};

const changePasssword = async (body: IChangePasswordRequest, token: string) => {
  const response = await axios.post('/login/changeUserPw', body, {
    headers: {
      token,
    },
  });
  return response.data.data;
};

export const findPasswordMiddleware = {
  checkUserId,
  checkUserCellphone,
  checkUserEmail,
  sendVerifyEmail,
  checkVerifyEmail,
  changePasssword,
};

import { EnumFilterField } from 'src/constants/enum/global.enum';

export enum EnumListCodeCategory {
  NOTIFICATIONS = 'N101',
  CAMPAIGNS = 'N102',
  TOURNAMENT = 'N103',
  SPECIALS = 'N104',
  GDR = 'GDR',
}

export type EnumNewsFilterCode = EnumListCodeCategory | EnumFilterField;

export enum EnumListCodeFaq {
  REGISTRATION = 'F101',
  LOGIN = 'F102',
  MY_PAGE = 'F103',
  SWING_REPLAY = 'F104',
  MY_SCORES = 'F105',
  TOURNAMENTS = 'F106',
  COURSES = 'F107',
  SHOPPING = 'F108',
  NEWS = 'F109',
  SYSTEM = 'F110',
  RANKING = 'F111',
}
export enum EnumListFaqSeq {
  LEVEL = '19',
}

export type EnumFaqFilterCode = EnumListCodeFaq | EnumFilterField;

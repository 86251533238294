import { ChangeEvent, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { allValid, validate } from 'src/services/validators';
import { EnumStatusCode } from 'src/constants/enum';
import { NotificationType } from 'src/models';
import { signupMiddleware } from 'src/requests/api/account/signup';
import { notificationActions } from 'src/store/notification';
import Input, { InputStatus } from 'src/view/commons/elements/Input';
import InputPhoneNumber from 'src/view/commons/customs/phone-country/InputPhoneNumber';
import InputEmail from 'src/view/commons/customs/InputEmail';
import { findPasswordMiddleware } from 'src/requests/api/account/find-password';
import ButtonNext from 'src/view/commons/customs/ButtonNext';
import VerifyCodeAlert from 'src/view/commons/customs/VerifyCodeAlert';
import { ICountry, ICountryGroup, IVerifyPhoneEntitiesResponse } from 'src/requests/api/account/prop-state.type';
import { initialCountry, OTP_MAX_LENGTH } from 'src/constants/const';
import { twMerge } from 'tailwind-merge';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { getCountries } from 'src/constants/const/countries.constants';
import { firebaseInstance } from 'src/init-firebase';
import { eventName } from 'src/constants/const/firebase.constants';

interface IVerifyPhoneOrEmailProps {
  addNotification: (message: string, type: NotificationType) => void;
  entities: any;
  onNextStep: (entities: any) => void;
  appCountry?: string;
}

function VerifyPhoneOrEmail({
  addNotification,
  entities,
  onNextStep,
  appCountry,
}: IVerifyPhoneOrEmailProps): JSX.Element {
  const translate = useTranslation().t;
  const isNotVerifyPhone = !entities?.cellphone || entities?.cellphone?.includes('null');
  const isNotVerifyEmail = !entities.email;
  const [isVerifyingPhone, setIsVerifyingPhone] = useState(isNotVerifyEmail);
  const [durationExpired, setDurationExpired] = useState(false);

  const [currentCountry, setCurrentCountry] = useState<ICountry>({
    ...initialCountry,
    countryNm: translate('commons.customs.national_selection.USA'),
  });
  const [loading, setLoading] = useState(false);
  const [verifyForm, setVerifyForm] = useState({
    cellphone: {
      value: '',
      status: InputStatus.Normal,
      rules: 'required|phone',
      error: '',
      errorMessages: {
        required: translate('error.PHONE_NUMBER_iS_INVALID'),
        phone: translate('error.PHONE_NUMBER_iS_INVALID'),
      },
    },
    verifiedCode: {
      value: '',
      status: InputStatus.Normal,
      error: '',
    },
    email: {
      value: '',
      status: InputStatus.Normal,
      rules: 'required|email',
      error: '',
      errorMessages: {
        required: translate('error.EMAIL_IS_INVALID'),
        email: translate('error.EMAIL_IS_INVALID'),
      },
    },
  });
  const tokenRef = useRef<string>('');
  const sendSmsEntitiesResponse = useRef<IVerifyPhoneEntitiesResponse | null>(null);
  const verifyCodeAlertRef = useRef<any>(null);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const inputName = e.target.name;
    const value = e.target.value;
    if (e.target.maxLength > 0 && value.length > e.target.maxLength) return;
    const error = validate(value, verifyForm[inputName].rules);

    if (durationExpired && inputName === 'verifiedCode') {
      const newFormData = {
        ...verifyForm,
        [inputName]: {
          ...verifyForm[inputName],
          value,
        },
      };
      setVerifyForm(newFormData);
      return;
    }
    let status = InputStatus.Normal;
    if (inputName === 'verifiedCode' && value.length === e.target.maxLength) {
      status = InputStatus.Done;
    }
    setVerifyForm({
      ...verifyForm,
      [inputName]: {
        ...verifyForm[inputName],
        status: error ? InputStatus.Error : status,
        error: error ?? '',
        value: e.target.value,
      },
    });
  };

  useEffect(() => {
    if (durationExpired) {
      setVerifyForm({
        ...verifyForm,
        verifiedCode: { ...verifyForm.verifiedCode, status: InputStatus.Error, error: 'invalid' },
      });
    } else {
      setVerifyForm({
        ...verifyForm,
        verifiedCode: {
          ...verifyForm.verifiedCode,
          status: verifyForm.verifiedCode.value.length === OTP_MAX_LENGTH ? InputStatus.Done : InputStatus.Normal,
          error: '',
        },
      });
    }
  }, [durationExpired]);

  const onSendSmsSuccess = (entities: IVerifyPhoneEntitiesResponse, token?: string) => {
    sendSmsEntitiesResponse.current = entities;
    tokenRef.current = token!;
    if (verifyCodeAlertRef.current) {
      verifyCodeAlertRef.current.resetCountDown();
    }
    setVerifyForm({
      ...verifyForm,
      cellphone: {
        ...verifyForm.cellphone,
        status: InputStatus.Done,
      },
      verifiedCode: {
        ...verifyForm.verifiedCode,
        value: '',
        status: InputStatus.Normal,
      },
    });
  };

  const onSendEmailSuccess = (entities: IVerifyPhoneEntitiesResponse, token?: string) => {
    sendSmsEntitiesResponse.current = entities;
    tokenRef.current = token!;
    if (verifyCodeAlertRef.current) {
      verifyCodeAlertRef.current.resetCountDown();
    }
    setVerifyForm({
      ...verifyForm,
      email: {
        ...verifyForm.email,
        status: InputStatus.Done,
      },
      verifiedCode: {
        ...verifyForm.verifiedCode,
        value: '',
        status: InputStatus.Normal,
      },
    });
  };

  const handleChangeCountry = (country: ICountry) => {
    setCurrentCountry(country);
  };

  const countries: ICountryGroup[] = useMemo(() => getCountries(translate), [translate]);

  const findCountry = (countryCode: string) => {
    let appCountryData: any;
    countries?.find((group) => {
      const value = group?.valueGroup?.find((country: any) => country.countryCd === countryCode);
      appCountryData = value;
      return !!value;
    });
    if (!isEmpty(appCountryData)) {
      setCurrentCountry(appCountryData);
    }
  };

  useEffect(() => {
    if (!!appCountry) {
      findCountry(appCountry);
    }
  }, [appCountry]);

  const isValidForm = () => {
    let verifyingForm = {
      cellphone: verifyForm.cellphone,
    } as any;
    if (!isVerifyingPhone) {
      verifyingForm = {
        email: verifyForm.email,
      };
    }
    return allValid({
      ...verifyingForm,
      verifiedCode: verifyForm.verifiedCode,
    });
  };

  const handleNextStep = async (e: SyntheticEvent) => {
    const checkVerifyCode = async () => {
      let result;
      if (isVerifyingPhone) {
        result = await signupMiddleware.checkVerifyCode({
          authSeq: parseInt(sendSmsEntitiesResponse.current!.authSeq),
          authNum: verifyForm.verifiedCode.value,
          countryNum: currentCountry.countryNum,
          cellphone: verifyForm.cellphone.value,
        });
      } else {
        result = await findPasswordMiddleware.checkVerifyEmail({
          authSeq: parseInt(sendSmsEntitiesResponse.current!.authSeq),
          authNum: verifyForm.verifiedCode.value,
          email: verifyForm.email.value,
        });
      }
      if (result.entities.authResult === EnumStatusCode.SUCCESS) {
        onNextStep({
          token: tokenRef.current,
        });
      } else {
        setVerifyForm({
          ...verifyForm,
          verifiedCode: {
            ...verifyForm.verifiedCode,
            status: InputStatus.Error,
            error: 'invalid',
          },
        });
      }
    };

    setLoading(true);
    try {
      await checkVerifyCode();
    } catch (error: any) {
      console.warn(error);
      addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    }
    setLoading(false);
  };

  const changeFormType = () => {
    // Reset form when navigate between Authenticate with cellphone and email
    setVerifyForm({
      ...verifyForm,
      cellphone: {
        ...verifyForm.cellphone,
        value: '',
        error: '',
        status: InputStatus.Normal,
      },
      verifiedCode: {
        ...verifyForm.verifiedCode,
        value: '',
        error: '',
        status: InputStatus.Normal,
      },
      email: {
        ...verifyForm.email,
        value: '',
        error: '',
        status: InputStatus.Normal,
      },
    });
    setIsVerifyingPhone(!isVerifyingPhone);
  };

  const phoneNumberForm = (): JSX.Element => {
    return (
      <>
        <div className="gz-text-xs mb-4 font-medium">
          (
          {entities.cellphone && !entities.cellphone.includes('null')
            ? entities.cellphone
            : translate('pages.account.find_password.verify_phone_or_email.NOT_VERIFIED_PHONE_ALERT')}
          )
        </div>
        <InputPhoneNumber
          name="cellphone"
          autoComplete="off"
          autoFocus
          value={verifyForm.cellphone.value}
          status={verifyForm.cellphone.status}
          defaultCountry={currentCountry}
          onChange={handleChangeInput}
          onChangeCountry={handleChangeCountry}
          usrNo={entities.usrNo}
          containerClassName="mb-4"
          placeholder={translate('pages.account.find_password.verify_phone_or_email.PLACEHOLDER_MOBILE_PHONE_NUMBER')}
          onSendSmsSuccess={onSendSmsSuccess}
          doneMessage={<VerifyCodeAlert updateDuration={setDurationExpired} ref={verifyCodeAlertRef} />}
          errorMessage={verifyForm.cellphone.errorMessages[verifyForm.cellphone.error]}
          onSendVerifyCode={(isResent) => {
            firebaseInstance.trackEvent(
              isResent ? eventName.FIND_PASSWORD_PHONE_REVERIFY : eventName.FIND_PASSWORD_PHONE_VERIFY,
            );
          }}
        />
        <Input
          type="number"
          name="verifiedCode"
          autoComplete="off"
          containerClassName="mb-4"
          maxLength={5}
          placeholder={translate('pages.account.find_password.verify_phone_or_email.PLACEHOLDER_AUTHENTICATION_NUMBER')}
          value={verifyForm.verifiedCode.value}
          error={verifyForm.verifiedCode.error}
          status={verifyForm.verifiedCode.status}
          onChange={handleChangeInput}
          errorMessages={{
            invalid: (
              <div className="gz-text-xsm mt-2 text-gz-danger">
                {translate('error.VERIFY_CODE_iS_INVALID_1')}
                <br />
                {translate('error.VERIFY_CODE_iS_INVALID_2')}
              </div>
            ),
          }}
          pattern="[0-9]*"
        />
        {!isNotVerifyEmail && (
          <div className="flex items-center justify-between">
            <div className="gz-text-sm font-light">
              {translate('pages.account.find_password.verify_phone_or_email.DONT_HAVE_PHONE_NUMBER')}
            </div>
            <div
              className={twMerge(
                'gz-text-sm gz-text-xs cursor-pointer font-medium text-primary-75',
                isMobile ? 'active:opacity-[0.5]' : 'hover:opacity-[0.5]',
              )}
              onClick={changeFormType}
            >
              {translate('pages.account.find_password.verify_phone_or_email.AUTHENTICATE_WITH_EMAIL')}
            </div>
          </div>
        )}
      </>
    );
  };

  const emailForm = (): JSX.Element => {
    return (
      <>
        <div className="gz-text-xs mb-4 font-medium">
          (
          {entities.email
            ? entities.email
            : translate('pages.account.find_password.verify_phone_or_email.NOT_VERIFIED_EMAIL_ALERT')}
          )
        </div>
        <InputEmail
          name="email"
          autoComplete="off"
          autoFocus
          value={verifyForm.email.value}
          status={verifyForm.email.status}
          onChange={handleChangeInput}
          usrNo={entities.usrNo}
          containerClassName="mb-4"
          placeholder={translate('pages.account.find_password.verify_phone_or_email.PLACEHOLDER_EMAIL')}
          onSendEmailSuccess={onSendEmailSuccess}
          doneMessage={<VerifyCodeAlert ref={verifyCodeAlertRef} />}
          errorMessage={verifyForm.email.errorMessages[verifyForm.email.error]}
          onSendVerifyCode={(isResent) => {
            firebaseInstance.trackEvent(
              isResent ? eventName.FIND_PASSWORD_EMAIL_REVERIFY : eventName.FIND_PASSWORD_EMAIL_VERIFY,
            );
          }}
        />
        <Input
          name="verifiedCode"
          autoComplete="off"
          containerClassName="mb-4"
          type="tel"
          pattern="[0-9]*"
          placeholder={translate('pages.account.find_password.verify_phone_or_email.PLACEHOLDER_AUTHENTICATION_NUMBER')}
          value={verifyForm.verifiedCode.value}
          status={verifyForm.verifiedCode.status}
          error={verifyForm.verifiedCode.error}
          onChange={handleChangeInput}
          errorMessages={{
            invalid: (
              <div className="gz-text-xsm mt-2 text-gz-danger">
                {translate('error.VERIFY_CODE_iS_INVALID_EMAIL')}
                <br />
                {translate('error.VERIFY_CODE_iS_INVALID_2')}
              </div>
            ),
          }}
          maxLength={5}
        />
        {!isNotVerifyPhone && (
          <div className="flex items-center justify-between">
            <div className="gz-text-sm font-light">
              {translate('pages.account.find_password.verify_phone_or_email.DONT_HAVE_EMAIL')}
            </div>
            <div
              className={twMerge(
                'gz-text-xs cursor-pointer font-medium text-primary-75',
                isMobile ? 'active:opacity-[0.5]' : 'hover:opacity-[0.5]',
              )}
              onClick={changeFormType}
            >
              {translate('pages.account.find_password.verify_phone_or_email.AUTHENTICATE_WITH_PHONE')}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <div className="mb-4 flex items-center">
        <div className="gz-text-xl mr-2 font-bold text-title">
          {translate('pages.account.find_password.verify_phone_or_email.TITLE')}
        </div>
        <div className="gz-text-tiny rounded-[30px] bg-disable px-[8px] py-[2px] font-bold text-white">2/2</div>
      </div>
      <div className="gz-text-xs mb-1">
        {translate('pages.account.find_password.verify_phone_or_email.DESCRIPTION')}
      </div>
      {isVerifyingPhone ? phoneNumberForm() : emailForm()}
      <ButtonNext type="submit" disabled={!isValidForm()} loading={loading} onClick={handleNextStep} />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  appCountry: state.webviewReducer.country,
});

const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhoneOrEmail);

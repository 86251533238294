import { LOCAL_STORAGE_IS_NATIVE_APP } from './constants/const/storage.constants';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import {
  collection,
  addDoc,
  getFirestore,
  Firestore,
  increment,
  setDoc,
  doc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';
import { CT_FIREBASE_CONFIG, mobileAction } from 'src/constants/const';
import { sendDataToApp } from 'src/hooks/webview';
import { history } from 'src/services/history';
import { EnumFireStoreCollectionName } from 'src/constants/enum';
const ERROR_CONNECTION_CODE = -1;

// Initialize Firebase
let app: FirebaseApp;
let firebaseAnalytics: Analytics;
const isNativeApp = !!localStorage.getItem(LOCAL_STORAGE_IS_NATIVE_APP);
let db: Firestore;

const isValid =
  !!CT_FIREBASE_CONFIG.apiKey &&
  !!CT_FIREBASE_CONFIG.projectId &&
  !!CT_FIREBASE_CONFIG.appId &&
  !!CT_FIREBASE_CONFIG.measurementId;

const initialize = () => {
  if (!isValid || !!app || isNativeApp) return;
  try {
    if (isValid) {
      app = initializeApp(CT_FIREBASE_CONFIG);
      firebaseAnalytics = getAnalytics(app);
      db = getFirestore(app);
    }
  } catch (error) {
    throw error;
  }
};

const setUser = (userId: string) => {
  try {
    if (isNativeApp) {
      sendDataToApp({
        action: mobileAction.FIREBASE_TRACKING_EVENTS,
        info: { userId },
      });
    } else {
      if (!firebaseAnalytics) return;
      setUserId(firebaseAnalytics, userId);
    }
  } catch (error) {
    console.warn('set firebase user failed');
  }
};

const trackEvent = (eventName: string, condition = true, params?: any) => {
  if (!condition || !eventName) return;

  const eventNameArr = eventName.split('_');
  if (!isNativeApp) {
    if (!firebaseAnalytics) return;
    try {
      logEvent(firebaseAnalytics, eventName, {
        event_category: eventNameArr[0],
        ...params,
      });
    } catch (error) {
      console.warn('log firebase event failed');
    }
  } else {
    sendDataToApp({
      action: mobileAction.FIREBASE_TRACKING_EVENTS,
      info: { eventName, ...params },
    });
  }
};

const trackPageView = (page: string) => {
  trackEvent('page_view', true, {
    page_path: page,
  });
};

const logError = async (error: string) => {
  try {
    if (isNativeApp) {
      sendDataToApp({
        action: mobileAction.FIREBASE_LOG_ERRORS,
        info: { error, device_info: navigator.userAgent, page: history.location.pathname },
      });
    } else {
      if (!isValid) return;
      await addDoc(collection(db, 'errors'), {
        error,
        device_info: navigator.userAgent,
        page: history.location.pathname,
        date: new Date().toString(),
        timestamp: Date.now(),
      });
    }
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};

const logCrashlytics = async (traceLog: string, document: EnumFireStoreCollectionName) => {
  try {
    if (!isValid) return;
    await addDoc(collection(db, document), {
      device_info: navigator.userAgent,
      date: new Date().toString(),
      traceLog,
      timestamp: Date.now(),
    });
  } catch (e) {
    console.error(e);
  }
};

const initialFireStoreForNativeApp = () => {
  if (!isValid || !!app) return;
  try {
    if (isValid && isNativeApp) {
      app = initializeApp(CT_FIREBASE_CONFIG);
      db = getFirestore(app);
    }
  } catch (error) {
    throw error;
  }
};

const logSmartLoginErrors = async ({
  errorCode,
  data,
  type,
}: {
  errorCode?: number;
  data?: any;
  type: EnumFireStoreCollectionName;
}) => {
  try {
    if (!isValid) return;
    const docInfo = await getDoc(doc(db, type, EnumFireStoreCollectionName.TOTAL_COUNT));
    if (docInfo.exists()) {
      updateDoc(
        doc(db, type, EnumFireStoreCollectionName.TOTAL_COUNT),
        errorCode
          ? {
              total_error: increment(1),
            }
          : {
              total_error_connection: increment(1),
            },
      );
    } else {
      setDoc(doc(db, type, EnumFireStoreCollectionName.TOTAL_COUNT), {
        total_request: 1,
        total_error: 1,
        total_error_connection: 0,
      });
    }

    await addDoc(
      collection(db, type, EnumFireStoreCollectionName.ERRORS, EnumFireStoreCollectionName.ERRORS_COLLECTION),
      {
        ...{
          device_info: navigator.userAgent,
          date: new Date().toString(),
          timestamp: Date.now(),
        },
        ...(errorCode ? { error_code: errorCode, data } : { error_code: ERROR_CONNECTION_CODE }),
      },
    );
  } catch (e) {
    console.error(e);
  }
};

const logSmartLoginTotalRequest = async (type: EnumFireStoreCollectionName) => {
  try {
    if (!isValid) return;
    const docInfo = await getDoc(doc(db, type, EnumFireStoreCollectionName.TOTAL_COUNT));
    if (docInfo.exists()) {
      updateDoc(doc(db, type, EnumFireStoreCollectionName.TOTAL_COUNT), {
        total_request: increment(1),
      });
    } else {
      setDoc(doc(db, type, EnumFireStoreCollectionName.TOTAL_COUNT), {
        total_request: 1,
        total_error: 0,
        total_error_connection: 0,
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const firebaseInstance = {
  initialize,
  setUser,
  trackEvent,
  trackPageView,
  logError,
  initialFireStoreForNativeApp,
  logSmartLoginErrors,
  logSmartLoginTotalRequest,
  logCrashlytics,
};

import axios from 'src/requests';
import { IAgreeMarketingPolicyRequest } from 'src/requests/api/home/prop-state.type';

const agreeMarketingPolicy = async (body: IAgreeMarketingPolicyRequest) => {
  const response = await axios.post('/system/setMyInfoMarketingOptIn', body);
  return response.data.data;
};

export const policyMiddleware = {
  agreeMarketingPolicy,
};

import { useEffect, useState } from 'react';

export function GetMessageWebview(nameAction: string, callback?: (data: any) => void) {
  const [stateMess, setStateMess] = useState<string>();

  useEffect(() => {
    const getMess = (message: any) => {
      if (message.data.action === nameAction) {
        setStateMess(message.data);
      }
      if (message.data.action === nameAction && callback) {
        callback(message.data);
      }
    };

    // Get mess from ReactNative for Android
    document.addEventListener('message', getMess);
    // Get mess from ReactNative for IOS
    window.addEventListener('message', getMess);
    return () => {
      document.removeEventListener('message', getMess);
      window.removeEventListener('message', getMess);
    };
  }, []);

  return [stateMess];
}

export const sendDataToApp = (data: any) => {
  const webviewRef = (window as any).ReactNativeWebView;
  if (webviewRef) {
    webviewRef.postMessage(JSON.stringify(data));
  }
};

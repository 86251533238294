import { IpInfoState } from 'src/store/ipinfo/reducer';

export const MY_IP_INFO = 'MY_IP_INFO';

export const ipInfoDispatch = {
  info: (info: IpInfoState) => ({
    type: MY_IP_INFO,
    payload: info,
  }),
};

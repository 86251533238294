import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { EnumAchievementCategory, EnumRecordAchievement } from 'src/constants/enum';

interface IRecordAchievementProps {
  recordTags: string;
  recordTagsCnt: string;
  text?: boolean;
}

function RecordAchievement({ recordTags, recordTagsCnt, text }: IRecordAchievementProps): JSX.Element {
  const translate = useTranslation().t;

  const achievementList = {
    [EnumRecordAchievement.HOLE_IN_ONE]: {
      title: () => translate('pages.record.achievement.HOLE_IN_ONE'),
      category: EnumAchievementCategory.EXCELLENT,
    },
    [EnumRecordAchievement.ALBATROSSES]: {
      title: () => translate('pages.record.achievement.ALBATROSSES'),
      category: EnumAchievementCategory.EXCELLENT,
    },
    [EnumRecordAchievement.ALL_EAGLE]: {
      title: () => translate('pages.record.achievement.ALL_EAGLE'),
      category: EnumAchievementCategory.EXCELLENT,
    },
    [EnumRecordAchievement.ALL_BIRDIE]: {
      title: () => translate('pages.record.achievement.ALL_BIRDIE'),
      category: EnumAchievementCategory.EXCELLENT,
    },
    [EnumRecordAchievement.EVEN_PAR]: {
      title: () => translate('pages.record.achievement.EVEN_PAR'),
      category: EnumAchievementCategory.EXCELLENT,
    },
    [EnumRecordAchievement.BEST_SCORE]: {
      title: () => translate('pages.record.achievement.BEST_SCORE'),
      category: EnumAchievementCategory.EXCELLENT,
    },
    [EnumRecordAchievement.NO_BOGEY]: {
      title: () => translate('pages.record.achievement.NO_BOGEY'),
      category: EnumAchievementCategory.EXCELLENT,
    },
    [EnumRecordAchievement.EAGLE_STREAK]: {
      title: (count: number) => translate('pages.record.achievement.EAGLE_STREAK', { count }),
      category: EnumAchievementCategory.GOOD,
    },
    [EnumRecordAchievement.BIRDIE_STREAK]: {
      title: (count: number) => translate('pages.record.achievement.BIRDIE_STREAK', { count }),
      category: EnumAchievementCategory.GOOD,
    },
    [EnumRecordAchievement.PAR_STREAK]: {
      title: (count: number) => translate('pages.record.achievement.PAR_STREAK', { count }),
      category: EnumAchievementCategory.GOOD,
    },
    [EnumRecordAchievement.EAGLE_MULTIPLE]: {
      title: (count: number) => translate('pages.record.achievement.EAGLE_MULTIPLE', { count }),
      category: EnumAchievementCategory.GOOD,
    },
    [EnumRecordAchievement.BIRDIE_MULTIPLE]: {
      title: (count: number) => translate('pages.record.achievement.BIRDIE_MULTIPLE', { count }),
      category: EnumAchievementCategory.GOOD,
    },
    [EnumRecordAchievement.PAR_MULTIPLE]: {
      title: (count: number) => translate('pages.record.achievement.PAR_MULTIPLE', { count }),
      category: EnumAchievementCategory.GOOD,
    },

    // check based on "tag" field
    [EnumRecordAchievement.LONG_PUTTS_METER]: {
      title: (count: number) => translate('pages.record.achievement.LONG_PUTTS_METER', { count }),
      category: EnumAchievementCategory.GOOD,
    },
    [EnumRecordAchievement.LONG_PUTTS_YARD]: {
      title: (count: number) => translate('pages.record.achievement.LONG_PUTTS_YARD', { count }),
      category: EnumAchievementCategory.GOOD,
    },
  };

  const getAchievements = () => {
    if (!recordTags || !recordTags.trim()) return <></>;
    const tags = recordTags
      .trim()
      .split(',')
      .map((tag) => tag.trim());
    const tagsCnt = recordTagsCnt
      .trim()
      .split(',')
      .map((tagCnt) => Number(tagCnt.trim()));
    if (text) return tags.map((tag: string, index: number) => achievementList[tag].title(tagsCnt[index])).join(', ');
    return (
      <div className="mt-[16px] flex flex-wrap items-center gap-[8px]">
        {tags.map((tag: string, index: number) => (
          <Fragment key={tag}>
            {achievementList[tag] ? (
              <div
                className={twMerge(
                  'gz-text-xsm rounded-[30px] p-[4px_8px] text-gz-white',
                  achievementList[tag].category === EnumAchievementCategory.EXCELLENT
                    ? 'bg-semantic-green-10 text-semantic-green-2'
                    : 'bg-blue-10 text-primary-75',
                )}
              >
                {achievementList[tag].title(tagsCnt[index])}
              </div>
            ) : null}
          </Fragment>
        ))}
      </div>
    );
  };

  return <>{getAchievements()}</>;
}

export default RecordAchievement;

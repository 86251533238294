import {
  ITmApproachDetailResponse,
  ITmDrivingRangeDetailResponse,
  ITmPuttingDetailResponse,
  ITmSwingVideoDetailResponse,
} from 'src/requests/api/record/prop-state.type';
import { UPDATE_TRAINING_MODE_DETAIL, RESET_TRAINING_MODE_DETAIL } from 'src/store/training-mode-detail/action';

export interface TrainingModeDetailState {
  drivingRange?: ITmDrivingRangeDetailResponse;
  loadingDrivingRange?: boolean;
  approach?: ITmApproachDetailResponse;
  loadingApproach?: boolean;
  putting?: ITmPuttingDetailResponse;
  loadingPutting?: boolean;
  swingVideo?: ITmSwingVideoDetailResponse;
  loadingSwingVideo?: boolean;
}

export const initialState: TrainingModeDetailState = {
  loadingDrivingRange: true,
  loadingApproach: true,
  loadingPutting: true,
  loadingSwingVideo: true,
};

export function trainingModeDetailReducer(state: TrainingModeDetailState = initialState, action: any) {
  switch (action.type) {
    case UPDATE_TRAINING_MODE_DETAIL:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_TRAINING_MODE_DETAIL:
      return initialState;
    default:
      return state;
  }
}

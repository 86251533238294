export const LOCALSTORAGE_SNS_TYPE = 'sns_type';
export const LOCALSTORAGE_SNS_DATA = 'sns_data';
export const LOCALSTORAGE_USER_INFO = 'user_info';
export const LOCALSTORAGE_SETTING_INFO = 'setting_info';
export const LOCALSTORAGE_IP_INFO = 'ip_info';
export const LOCALSTORAGE_GEOLOCATION_USER = 'geolocation_user';
export const LOCAL_STORAGE_HIDDEN_ADVERTISING_DATE = 'hiddenAdvertisingDate';
export const LOCAL_STORAGE_FCM_TOKEN = 'fcmToken';
export const LOCAL_STORAGE_TRAINING_MODE_HIDE_NEW_LABEL = 'tmHiddenNewLabel';
export const LOCAL_STORAGE_AI_ANALYSIS_LAST_OPEN_TIME = 'AIAnalysisLastOpen';
export const LOCAL_STORAGE_LEAGUE_HIDE_NEW_LABEL = 'leagueHiddenNewLabel';
export const LOCAL_STORAGE_IS_NATIVE_APP = 'isNativeApp';
export const LOCAL_STORAGE_IGNORE_FORCE_UPDATE_IN_WEBVIEW = 'ignoreForceUpdateInWebView';
export const LOCAL_STORAGE_ACTIVITIES_START_TIME = 'activitiesStartTime';
export const LOCAL_STORAGE_WINDOW_RELOAD_DATETIME = 'window_reload_datetime';

import { IComment } from 'src/models/comment';

export const COMMENT_ACTION_ADD_COMMENT_LIST = 'COMMENT_ACTION_ADD_COMMENT_LIST';
export const COMMENT_ACTION_NEW_ITEM = 'COMMENT_ACTION_NEW_ITEM';
export const COMMENT_ACTION_START_REPLY = 'COMMENT_ACTION_START_REPLY';
export const COMMENT_ACTION_CLOSE_REPLY = 'COMMENT_ACTION_CLOSE_REPLY';
export const COMMENT_ACTION_RESET_COMMENT_STATE = 'COMMENT_ACTION_RESET_COMMENT_STATE';

const addCommentList = (commentList: IComment[]) => ({
  type: COMMENT_ACTION_ADD_COMMENT_LIST,
  payload: commentList,
});

const getCommentList =
  (commentList: IComment[], clearBefore?: boolean) =>
  (dispatch: any, getState: any): void => {
    if (clearBefore) {
      const newCommentList: IComment[] = commentList.filter((entity: IComment) => entity.cmtLevel === 1);
      const replyList: IComment[] = commentList.filter((entity: IComment) => entity.cmtLevel > 1);
      replyList.forEach((reply: IComment) => {
        const parentComment = newCommentList.find((comment) => reply.cmtNo === comment.cmtNo);
        if (parentComment == null) return;
        parentComment.replies != null ? parentComment.replies.push(reply) : (parentComment.replies = [reply]);
      });
      dispatch(addCommentList(newCommentList));
    } else {
      let newCommentList: IComment[] = getState().commentsReducer.comments;
      commentList.forEach((comment: IComment) => {
        if (comment.cmtLevel === 1) {
          newCommentList = [comment, ...newCommentList];
        } else {
          const parentComment = newCommentList.find((newComment) => newComment.cmtNo === comment.cmtNo);
          if (parentComment == null) return;
          parentComment.replies != null ? parentComment.replies.push(comment) : (parentComment.replies = [comment]);
        }
      });
      dispatch(addCommentList(newCommentList));
    }
  };

const getInfoNewComment = (newComment: IComment) => ({
  type: COMMENT_ACTION_NEW_ITEM,
  payload: {
    newComment,
  },
});

const deleteComment =
  (deletedComment: IComment) =>
  (dispatch: any, getState: any): void => {
    let newCommentList: IComment[] = getState().commentsReducer.comments;
    if (deletedComment.cmtLevel === 1) {
      newCommentList = newCommentList.filter((comment) => deletedComment.seq !== comment.seq);
      dispatch(addCommentList(newCommentList));
      return;
    }
    const parentComment = newCommentList.find((comment) => deletedComment.cmtNo === comment.cmtNo);
    if (parentComment && parentComment.replies) {
      parentComment.replies = parentComment.replies.filter((comment) => deletedComment.seq !== comment.seq);
    }
    dispatch(addCommentList(newCommentList));
  };

const startReply = (commentNo: number, usrName: string, usrNo: string) => ({
  type: COMMENT_ACTION_START_REPLY,
  payload: {
    commentNo,
    usrName,
    usrNo,
  },
});

const closeReply = () => ({
  type: COMMENT_ACTION_CLOSE_REPLY,
});

const resetCommentState = () => ({
  type: COMMENT_ACTION_RESET_COMMENT_STATE,
});

export const commentActions = {
  getCommentList,
  getInfoNewComment,
  deleteComment,
  startReply,
  closeReply,
  resetCommentState,
};

import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { IconChevronDown, IconClose, IconRadio, IconRadioActive } from 'src/assets/svg';
import { EnumFilterField } from 'src/constants/enum';
import BottomSheet from 'src/view/commons/elements/BottomSheet';
import { useModal } from 'src/hooks/use-modal';
import { EnumScreenSize, useResizeWindow } from 'src/hooks/use-resize-window';
import Button from 'src/view/commons/elements/Button';
import { useTranslation } from 'react-i18next';

export interface IDropDown {
  title: string;
  value: string;
}

export interface Item {
  title: string;
  value: string | number;
  prefix?: JSX.Element;
  suffix?: JSX.Element;
  className?: string;
}

interface Props {
  children?: JSX.Element;
  defaultTitle?: string;
  defaultValue?: string | number;
  value?: string | number;
  highlight?: boolean;
  items: Item[];
  iconColor?: string;
  classButton?: string;
  classIcon?: string;
  classContainer?: string;
  classMenuItems?: string;
  classItem?: string;
  classActiveItem?: string;
  textLeft?: boolean;
  right?: boolean;
  onChange?: (val: any) => void;
  classBtnCustom?: string;
  bottomSheet?: boolean;
  titleBottomSheet?: string;
  withTranslate?: boolean;
  unforceChange?: boolean;
}

function DropdownMenu({
  children,
  defaultTitle = '',
  defaultValue,
  value,
  highlight = true,
  items,
  iconColor,
  classButton,
  classIcon,
  classContainer,
  classMenuItems,
  classItem,
  classActiveItem,
  right,
  textLeft,
  onChange,
  classBtnCustom,
  bottomSheet,
  titleBottomSheet = '',
  withTranslate,
  unforceChange = false,
}: Props): JSX.Element {
  const translate = useTranslation().t;
  const { screenSize } = useResizeWindow();
  const isMobile = screenSize === EnumScreenSize.MOBILE;
  const { isOpen, onOpen, onClose } = useModal();
  const [selectedValue, setSelectedValue] = useState(items.find((item) => item.value === defaultValue) ?? items[1]);
  const [temporaryValue, setTemporaryValue] = useState(selectedValue);
  const hasBottomSheet = isMobile && bottomSheet;

  useEffect(() => {
    setTemporaryValue(selectedValue);
  }, [isOpen]);

  useEffect(() => {
    if (value != null) {
      const newValue = items.find((item) => item.value === value) ?? items[1];
      setSelectedValue(newValue);
      setTemporaryValue(newValue);
    }
  }, [items, value]);

  const handleChange = (item: Item) => {
    if (item.value === selectedValue.value && unforceChange) return;
    setSelectedValue(item);
    onChange && onChange(item.value);
  };

  const menuButton = () => {
    if (children) {
      if (hasBottomSheet)
        return (
          <div className={twMerge('p-0 focus:outline-none', classBtnCustom)} onClick={onOpen}>
            {children}
          </div>
        );
      return <Menu.Button className={twMerge('p-0 focus:outline-none', classBtnCustom)}>{children}</Menu.Button>;
    }

    if (hasBottomSheet)
      return (
        <div
          className={twMerge(
            'flex w-full items-center justify-center text-title dark:bg-black dark:text-gz-white',
            'bg-white p-[10px] focus:outline-none',
            classButton || '',
          )}
          onClick={onOpen}
        >
          <span className="mr-1">
            {selectedValue?.value === EnumFilterField.ALL && !!defaultTitle ? defaultTitle : selectedValue?.title}
          </span>
          <IconChevronDown
            className={twMerge(
              'shrink-0 dark:fill-gz-white',
              iconColor ? `fill-${iconColor}` : 'fill-title',
              classIcon,
            )}
          />
        </div>
      );
    return (
      <Menu.Button
        className={twMerge(
          'flex w-full items-center justify-center text-title dark:bg-black dark:text-gz-white',
          'bg-white p-[10px] focus:outline-none',
          classButton || '',
        )}
      >
        <span className="mr-1">
          {selectedValue?.value === EnumFilterField.ALL && !!defaultTitle
            ? defaultTitle
            : withTranslate
            ? translate(selectedValue?.title)
            : selectedValue?.title}
        </span>
        <IconChevronDown
          className={twMerge('shrink-0 dark:fill-gz-white', iconColor ? `fill-${iconColor}` : 'fill-title', classIcon)}
        />
      </Menu.Button>
    );
  };

  const menuContent = () => {
    if (hasBottomSheet) {
      return (
        <BottomSheet className="z-[99]" isOpen={isOpen} onClose={onClose}>
          <div className="p-[16px]">
            <div className="flex items-center justify-between">
              <div className="gz-text-md font-medium dark:text-gz-white">{titleBottomSheet}</div>
              <IconClose className="shrink-0 cursor-pointer stroke-text dark:stroke-gz-white" onClick={onClose} />
            </div>
            <div className="gz-text-xs mt-[16px]">
              {items.map((item: Item) => (
                <div
                  key={item.value}
                  className="flex items-center justify-between [&:not(:first-child)]:mt-[16px]"
                  onClick={() => setTemporaryValue(item)}
                >
                  <div className="dark:text-gz-white/80">{item.title}</div>
                  {temporaryValue.value === item.value ? (
                    <IconRadioActive className="flex-shrink-0" />
                  ) : (
                    <IconRadio className="flex-shrink-0 stroke-gray-2" />
                  )}
                </div>
              ))}
            </div>
            <Button
              size="medium"
              className="mt-[16px]"
              onClick={() => {
                handleChange(temporaryValue);
                onClose();
              }}
            >
              {translate('commons.filter.APPLY')}
            </Button>
          </div>
        </BottomSheet>
      );
    }

    return (
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={twMerge(
            'absolute z-1 w-auto whitespace-nowrap rounded-[4px] shadow focus:outline-none',
            'border border-gray-20 bg-gz-white dark:border-dark-33 dark:bg-dark-16',
            right ? 'left-0' : 'right-0',
            classMenuItems || '',
          )}
        >
          {items.map((item: Item) => (
            <Menu.Item key={item?.value}>
              {(active) => (
                <div
                  className={twMerge(
                    'cursor-pointer py-[10px] dark:text-gz-white',
                    isMobile
                      ? 'active:text-primary-75 dark:active:text-primary-75'
                      : 'hover:text-primary-75 dark:hover:text-primary-75',
                    classItem,
                    textLeft ? 'pl-[12px] pr-[24px]' : 'pr-[12px] pl-[24px] text-right',
                    item?.className,
                    highlight && selectedValue?.value === item?.value && 'text-primary-75',
                    selectedValue?.value === item?.value && twMerge('dark:text-primary-75', classActiveItem),
                  )}
                  onClick={() => handleChange(item)}
                >
                  {item?.prefix}
                  {withTranslate ? translate(item?.title) : item?.title}
                  {item?.suffix}
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    );
  };

  return (
    <Menu as="div" className={twMerge('relative inline-block text-left', classContainer)}>
      {menuButton()}
      {menuContent()}
    </Menu>
  );
}

export default DropdownMenu;

import { ChangeEvent, SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { history } from 'src/services/history';
import { pathConstants } from 'src/constants/const';
import { findPasswordMiddleware } from 'src/requests/api/account/find-password';
import { NotificationType } from 'src/models';
import { notificationActions } from 'src/store/notification';
import Input from 'src/view/commons/elements/Input';
import ButtonNext from 'src/view/commons/customs/ButtonNext';
import { twMerge } from 'tailwind-merge';
import { isMobile } from 'react-device-detect';

interface ICheckUserIdProps {
  addNotification: (message: string, type: NotificationType) => void;
  onNextStep: (entities: any) => void;
}

function CheckUserId({ addNotification, onNextStep }: ICheckUserIdProps): JSX.Element {
  const translate = useTranslation().t;
  const [loading, setLoading] = useState(false);
  const [usrId, setUsrId] = useState<string>('');

  const handleNextStep = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!usrId) {
      return;
    }
    setLoading(true);
    try {
      const response = await findPasswordMiddleware.checkUserId({
        usrId,
      });
      if (response.entities.usrNo) {
        onNextStep({
          ...response.entities,
          usrId,
        });
      } else {
        addNotification(translate('error.ID_NOT_EXIST'), NotificationType.DANGER);
      }
    } catch (error) {
      console.warn(error);
      addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeInput = async (e: ChangeEvent<HTMLInputElement>) => {
    if (loading) return;
    const value = e.target.value;
    setUsrId(value);
  };

  return (
    <>
      <div className="mb-4 flex items-center">
        <div className="gz-text-xl mr-2 font-bold text-title">
          {translate('pages.account.find_password.id_input.TITLE')}
        </div>
        <div className="gz-text-tiny rounded-[30px] bg-disable px-[8px] py-[2px] font-bold text-white">1/2</div>
      </div>
      <div className="gz-text-xs mb-4">{translate('pages.account.find_password.id_input.DESCRIPTION')}</div>
      <form name="form" onSubmit={handleNextStep}>
        <Input
          name="id"
          autoComplete="off"
          autoCapitalize="off"
          autoFocus
          className="mb-4"
          value={usrId}
          onChange={(e) => handleChangeInput(e)}
          placeholder={translate('pages.account.find_password.id_input.PLACEHOLDER_ID')}
        />
        <div className="flex justify-between">
          <div className="gz-text-sm font-light">{translate('pages.account.find_password.id_input.FORGOT_ID')}</div>
          <div
            className={twMerge(
              'gz-text-sm cursor-pointer font-medium text-primary-75',
              isMobile ? 'active:opacity-[0.5]' : 'hover:opacity-[0.5]',
            )}
            onClick={() => history.pushWithSearch(pathConstants.FIND_ID)}
          >
            {translate('pages.account.find_password.id_input.FIND_ID')}
          </div>
        </div>
        <ButtonNext type="submit" loading={loading} disabled={!usrId} />
      </form>
    </>
  );
}

const mapDispatchToProps = {
  addNotification: notificationActions.addNotification,
};

export default connect(null, mapDispatchToProps)(CheckUserId);

import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { notificationActions } from 'src/store/notification';
import { INotification, NotificationType } from 'src/models';
import { IconCheck } from 'src/assets/svg';
import { isMobile } from 'react-device-detect';

interface Props {
  notification: INotification;
  removeNotification: (n: INotification) => void;
  keyboardShow: boolean;
}

function Notification({ notification, removeNotification, keyboardShow }: Props): JSX.Element {
  const timeout = useRef<any>();
  useEffect(() => {
    removeMess();
  }, [notification, removeNotification]);

  const onClickAddition = notification?.additional?.textAction?.onClick;

  const handleOnClickAddition = () => {
    removeNotification(notification);
    onClickAddition && onClickAddition();
  };

  const removeMess = () => {
    if (notification?.additional?.notAutoRemoveMess) return;
    !!timeout.current && clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      removeNotification(notification);
    }, 3000);
    return () => clearTimeout(timeout.current);
  };

  const colorByType = (): string => {
    if (notification.type === NotificationType.DANGER) {
      return 'bg-toast-error border-toast-error text-gz-white backdrop-blur-[10px]';
    }
    if (notification.type === NotificationType.SUCCESS) {
      return 'bg-toast-success border-toast-success text-gz-white backdrop-blur-[10px]';
    }
    if (notification.type === NotificationType.WARNING) {
      return 'bg-text border-text text-gz-white';
    }
    return 'bg-primary-20 border-primary-20 text-gz-primary';
  };

  return (
    <div
      className={twMerge(
        'gz-text-xs relative mx-[24px] rounded py-2.5 px-[8px]',
        'flex items-center justify-center',
        colorByType(),
      )}
      role="alert"
    >
      {notification.additional?.hasIconPreFix ? (
        <IconCheck className="mr-[5px] h-[20px] w-[20px] stroke-gz-white" />
      ) : null}
      <div className={twMerge(notification.additional?.textAction && 'flex items-center justify-between')}>
        <span className="block whitespace-pre-wrap sm:inline">{notification.message}</span>
        {notification.additional?.textAction && (
          <div
            className={twMerge(
              'flex-shrink-0 cursor-pointer pl-[24px]',
              isMobile ? 'active:text-secondary-75 active:no-underline' : 'hover:text-secondary-75 hover:no-underline',
            )}
            onClick={handleOnClickAddition}
          >
            {notification.additional?.textAction?.title}
          </div>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  removeNotification: notificationActions.removeNotification,
};
const mapStateToProps = (state: any) => ({
  keyboardShow: state.webviewReducer.keyboardShow,
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);

import { languageCode, countryCode, IAB_BASE_URL, mapGlobalLangToInappLang } from 'src/constants/const';
import { EnuGender, EnumSwingAIClubType, EnumSwingAIGender, EnumTypeClub } from 'src/constants/enum';
import { getTrainingModeTypeClub } from 'src/services/training-mode';

// Update font family for language
export const setFontFamily = (language: string) => {
  if (!language) return;

  if (language === 'en' || language === 'vn') {
    document.body.classList.add('latin-font');
  } else {
    document.body.classList.remove('latin-font');
  }
};

export const convertLangCodeToCountryCode = (langCd?: string) => {
  let countryCodeTmp = '';
  Object.entries(languageCode).forEach((dataCountry: any) => {
    const [key, value] = dataCountry;
    if (value === langCd) countryCodeTmp = countryCode[`${key}`];
  });
  return countryCodeTmp;
};

export const ordinalAffixRank = (rank: string) => {
  const rankNum = parseInt(rank);
  const value = rankNum % 10;
  const affix = rankNum % 100;
  if (value === 1 && affix !== 11) {
    return 'st';
  }
  if (value === 2 && affix !== 12) {
    return 'nd';
  }
  if (value === 3 && affix !== 13) {
    return 'rd';
  }
  return 'th';
};

export const getUnit = (distUnit?: string) => {
  let info = {
    DISTANCE: distUnit,
    FEET: '',
    SPEED: '',
  };
  if (distUnit === 'm') {
    info = {
      ...info,
      FEET: 'm',
      SPEED: 'm/s',
    };
  }
  if (distUnit === 'yd') {
    info = {
      ...info,
      FEET: 'ft',
      SPEED: 'mph',
    };
  }
  return info;
};

export const listToMatrix = (array: any[], size: number) => {
  const matrix: any[] = [];
  let i, k;
  for (i = 0, k = -1; i < array.length; i++) {
    if (i % size === 0) {
      k++;
      matrix[k] = [];
    }
    matrix[k].push(array[i]);
  }
  return matrix;
};

export const degreesToRadians = (degrees: number) => {
  return degrees * (Math.PI / 180);
};

export const getEnumKeyByValue = (enumObj: any, value: any) => {
  const indexOfEnum = Object.values(enumObj).indexOf(value);
  const key = Object.keys(enumObj)[indexOfEnum];
  return key;
};

export const getVideoDuration = async (fileUpload: any) => {
  const fileCallbackToPromise = (fileObj: any) => {
    return Promise.race([
      new Promise((resolve) => {
        if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
        else fileObj.onloadedmetadata = resolve;
      }),
      new Promise((_, reject) => {
        setTimeout(reject, 1000);
      }),
    ]);
  };

  const objectUrl = URL.createObjectURL(fileUpload);
  // const isVideo = type.startsWith('video/');
  const video = document.createElement('video');
  video.src = objectUrl;
  await fileCallbackToPromise(video);
  return {
    duration: video.duration,
    width: video.videoWidth,
    height: video.videoHeight,
  };
};

export const scrollIntoTopAfterChangingTab = (ref: HTMLDivElement | null, stickyTop: number = 0) => {
  if (!ref) return;
  const top = ref.getBoundingClientRect().top;
  if (top + stickyTop < 0) {
    ref.style.scrollMarginTop = `${stickyTop}px`;
    ref.scrollIntoView({
      block: 'start',
      inline: 'start',
    });
  }
};

export const getInappBaseByLanguage = (lang?: string) => {
  if (!lang) return IAB_BASE_URL;

  return `${IAB_BASE_URL}${mapGlobalLangToInappLang[lang]}/`;
};

export const formatHttpUrl = (link?: string) => {
  if (!link) return '';
  return link.match(/^http[s]?:\/\//) ? link : 'http://' + link;
};

export const convertToSwingAIAnalysisParams = (params: {
  userNo?: string;
  videoUrl?: string;
  videoKey?: number;
  clubType?: string;
  gender?: string;
  lang?: string;
  type?: string;
  analysisId?: string;
}) => {
  return {
    ...params,
    clubType:
      getTrainingModeTypeClub(params?.clubType) === EnumTypeClub.DRIVER
        ? EnumSwingAIClubType.DRIVER
        : EnumSwingAIClubType?.IRON,
    gender: params?.gender === EnuGender.MALE ? EnumSwingAIGender.MALE : EnumSwingAIGender.FEMALE,
    lang: params.lang === 'kr' ? 'ko' : params.lang || 'en',
  };
};

import { EnumListCodeFaq, EnumFilterField } from 'src/constants/enum';
import { IListMore } from 'src/requests/api/common/prop-state.type';
import { INewsListResponse } from 'src/requests/api/home/prop-state.type';
import { FAQ_INFO, FAQ_RESET } from 'src/store/faq/action';

export interface faqListDataState {
  [EnumFilterField.ALL]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.REGISTRATION]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.LOGIN]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.MY_PAGE]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.SWING_REPLAY]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.MY_SCORES]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.TOURNAMENTS]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.COURSES]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.SHOPPING]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.NEWS]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.SYSTEM]: IListMore<INewsListResponse>;
  [EnumListCodeFaq.RANKING]: IListMore<INewsListResponse>;
}

export interface faqInfoState {
  dataFaq: faqListDataState;
}

const initialState: faqInfoState = {
  dataFaq: {
    [EnumFilterField.ALL]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.REGISTRATION]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.LOGIN]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.MY_PAGE]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.SWING_REPLAY]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.MY_SCORES]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.TOURNAMENTS]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.COURSES]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.SHOPPING]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.NEWS]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.SYSTEM]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeFaq.RANKING]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
  },
};

export function faqReducer(state: faqInfoState = initialState, action: any) {
  switch (action.type) {
    case FAQ_INFO:
      return {
        ...state,
        dataFaq: {
          ...state.dataFaq,
          [action.payload.keyFilter]: action.payload.dataList,
        },
      };
    case FAQ_RESET:
      return initialState;
    default:
      return state;
  }
}

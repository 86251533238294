import { Tab } from '@headlessui/react';
import { Fragment, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { formatDate, pathConstants } from 'src/constants/const';
import { eventName } from 'src/constants/const/firebase.constants';
import { useEventFirebase } from 'src/hooks/use-event-firebase';
import { firebaseInstance } from 'src/init-firebase';
import { Dayjs } from 'src/services/datetime';
import { history } from 'src/services/history';
import { TrainingModeDetailState, trainingModeDetail } from 'src/store/training-mode-detail';
import DraggableScrollView from 'src/view/commons/customs/DraggableScrollView';
import Header from 'src/view/layouts/global/Header';
import { twMerge } from 'tailwind-merge';

interface IProps {
  trainingModeDetail: TrainingModeDetailState;
  reset: () => void;
}

function HeaderSection({ trainingModeDetail, reset }: IProps) {
  const translate = useTranslation().t;
  const { pathname } = useLocation();
  const { id } = useParams();
  const { isMenucard } = useEventFirebase();

  const tabs = [
    {
      title: translate('pages.record.type.training_mode.driving_range.TITLE'),
      path: pathConstants.RECORD_DETAIL_TM_DRIVING_RANGE(id ?? ''),
    },
    {
      title: translate('pages.record.type.training_mode.approach.TITLE'),
      path: pathConstants.RECORD_DETAIL_TM_APPROACH(id ?? ''),
    },
    {
      title: translate('pages.record.type.training_mode.putting.TITLE'),
      path: pathConstants.RECORD_DETAIL_TM_PUTTING(id ?? ''),
    },
    {
      title: translate('pages.record.type.training_mode.swing_video.TITLE'),
      path: pathConstants.RECORD_DETAIL_TM_SWING_VIDEOS(id ?? ''),
    },
  ];

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const trackEvent = (index: number) => {
    firebaseInstance.trackEvent(eventName.MENUCARD_MYPRACTICE_DRIVINGRANGE, isMenucard && index === 0);
    firebaseInstance.trackEvent(eventName.MENUCARD_MYPRACTICE_APPROACH, isMenucard && index === 1);
    firebaseInstance.trackEvent(eventName.MENUCARD_MYPRACTICE_PUTTING, isMenucard && index === 2);
    firebaseInstance.trackEvent(eventName.MENUCARD_MYPRACTICE_SWINGVIDEO, isMenucard && index === 3);
  };

  const regDate =
    trainingModeDetail.drivingRange?.tdRegDate ||
    trainingModeDetail.approach?.tdRegDate ||
    trainingModeDetail.putting?.tdRegDate;

  return (
    <>
      <Header
        className="shadow-none"
        title={
          <>
            <span>
              {translate('pages.record.type.training_mode.detail.TITLE_TRAINING_LOG', {
                date: Dayjs.formatDate(regDate, { translate, format: formatDate.T_TEXT_FULL_DATE, shortMonth: false }),
              })}
            </span>
            <span> (#{id})</span>
          </>
        }
      />
      <Tab.Group as={Fragment}>
        <DraggableScrollView
          sticky
          className={twMerge(
            'scroll-hidden sticky z-10 whitespace-nowrap bg-gz-white pt-[8px] shadow-bottom',
            isDesktop ? 'top-[56px]' : 'top-[44px]',
          )}
        >
          <Tab.List>
            {tabs.map((tab, idx) => {
              return (
                <Tab as={Fragment} key={idx}>
                  <button
                    className={twMerge(
                      'gz-text-xs ml-[24px] pb-[10px] last:mr-[24px] focus:!outline-none',
                      pathname === tab.path && 'border-b-[4px] border-primary-76 border-opacity-60 font-bold',
                    )}
                    onClick={() => {
                      history.replace(tab.path);
                      trackEvent(idx);
                    }}
                  >
                    {tab.title}
                  </button>
                </Tab>
              );
            })}
          </Tab.List>
        </DraggableScrollView>
      </Tab.Group>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  trainingModeDetail: state.trainingModeDetailReducer,
});

const mapDispatchToProps = {
  reset: trainingModeDetail.reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSection);

import { IAIAnalysisResult } from 'src/requests/api/AI/prop-state.type';
import { CLEAR_AI_ANALYSIS_DATA, UPDATE_AI_ANALYSIS_DATA, UPDATE_AI_ANALYSIS_OFFSET } from 'src/store/AI/action';

export interface IAIState {
  loading: boolean;
  type?: string | null;
  analysisId?: string | null;
  lang?: string | null;
  data?: IAIAnalysisResult | null;
  offset: number;
}

const initialState: IAIState = {
  loading: true,
  offset: 0,
};

export function AIReducer(state: IAIState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_AI_ANALYSIS_DATA: {
      return {
        ...state,
        ...payload,
      };
    }
    case UPDATE_AI_ANALYSIS_OFFSET: {
      return {
        ...state,
        offset: payload,
      };
    }
    case CLEAR_AI_ANALYSIS_DATA: {
      return {
        ...state,
        type: null,
        analysisId: null,
        lang: null,
        data: null,
      };
    }
    default:
      return state;
  }
}

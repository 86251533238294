import axios from 'axios';
import { CT_SCREEN_LOGIN } from 'src/constants/const';

const instance = axios.create({
  baseURL: CT_SCREEN_LOGIN.gdr,
  timeout: 70000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// For POST requests
axios.interceptors.response.use(
  (res) => res,
  (err) => Promise.reject(err),
);

export default instance;

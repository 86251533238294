import { NotificationType } from 'src/models/notification';
import { notificationActions } from 'src/store/notification';
import { EnumFilterField, EnumNewsFilterCode, EnumRequestCode, pageSize } from 'src/constants/enum';
import { setting } from 'src/requests/api/setting';
import { INewsListResponse } from 'src/requests/api/home/prop-state.type';
import { IListMore } from 'src/requests/api/common/prop-state.type';

export const CHANGE_SELECTED_CATEGORY = 'CHANGE_SELECTED_CATEGORY';
export const RESET_SELECTED_CATEGORY = 'RESET_SELECTED_CATEGORY';
export const NOTICE_INFO = 'NOTICE_INFO';
export const NEWS_OFFSET = 'NEWS_OFFSET';

export const noticeDispatch = {
  changeSelectedCategory: (value: EnumNewsFilterCode) => ({
    type: CHANGE_SELECTED_CATEGORY,
    payload: value,
  }),
  resetSelectedCategory: () => ({
    type: RESET_SELECTED_CATEGORY,
  }),
  info: (dataNotice: IListMore<INewsListResponse>, keyFilter: EnumNewsFilterCode) => ({
    type: NOTICE_INFO,
    payload: {
      dataNotice,
      keyFilter,
    },
  }),
  setOffset: (dataOffset: number) => ({
    type: NEWS_OFFSET,
    payload: {
      offsetNews: dataOffset,
    },
  }),
};

const newsNoticeInfo =
  (typeData: EnumNewsFilterCode, isLoadingMore?: boolean) => async (dispatch: any, getState: any) => {
    const prevStateRedux = getState().newsNoticeReducer.dataNews[typeData];
    if (isLoadingMore && (prevStateRedux.lastPage || prevStateRedux.loaded === 0)) return;
    try {
      const currentPage = prevStateRedux.loaded + 1;
      const params = {
        page: currentPage,
        size: pageSize.NORMAL,
        ...(typeData !== EnumFilterField.ALL && { subCode: typeData }),
      };
      const result = await setting.getNewsList(params);
      if (result.code === EnumRequestCode.FAILED) throw new Error("Can't get list News Notice");
      if (result.entities.length < 1) {
        const dataObject = {
          ...prevStateRedux,
          loaded: currentPage,
          lastPage: true,
        };
        dispatch(noticeDispatch.info(dataObject, typeData));
        return;
      }
      const dataList = [...prevStateRedux.values, ...result.entities];
      const isLast = result.totalCount <= dataList.length;
      const dataObject = {
        values: dataList,
        loaded: currentPage,
        lastPage: isLast,
      };
      dispatch(noticeDispatch.info(dataObject, typeData));
    } catch (error: any) {
      console.warn(error);
      dispatch(notificationActions.addNotification(error.message, NotificationType.DANGER));
    }
  };
export const newsAction = {
  newsNoticeInfo,
};

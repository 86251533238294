import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { cupMyHoldInOne, iconRound } from 'src/assets/images';
import { IPar3ChallengeUser } from 'src/models';
import { RootState } from 'src/store';
import AvatarImage from 'src/view/commons/customs/AvatarImage';
import { twMerge } from 'tailwind-merge';

interface IMyRanking {
  className?: string;
  myInfo: Partial<IPar3ChallengeUser>;
}

const MyRanking = ({ myInfo, className }: IMyRanking) => {
  const translate = useTranslation().t;
  const userInfo = useSelector((state: RootState) => state.mainInfoReducer.userInfo);

  return (
    <div className={twMerge('flex items-center justify-between', className)}>
      <div className="gz-text-xs w-[48px] shrink-0 text-center font-bold">{myInfo?.ranking || '-'}</div>
      <div className="mr-[8px] shrink-0">
        <AvatarImage src={userInfo?.usrNickImage} gradetcode={userInfo?.gradetcode} classImg="w-[40px] h-[40px]" />
      </div>
      <div className="relative mr-[8px] h-[68px] w-full overflow-hidden rounded-[12px] bg-semantic-blue-10 py-[12px] pl-[12px]">
        <div className="gz-text-xsm mb-[4px]">{translate('pages.nx.par_3_leaderboard.MY_HOLD_IN_ONE')}</div>
        <div className="gz-text-md font-bold">
          {translate('pages.nx.par_3_leaderboard.HOLD_IN_ONE_TIME', { count: myInfo?.holeInOneCnt || 0 })}
        </div>
        <img src={cupMyHoldInOne} alt="cup" className="absolute bottom-0 right-0 w-[75px]" />
      </div>
      <div className="relative h-[68px] w-full overflow-hidden rounded-[12px] bg-semantic-blue-10 p-[12px]">
        <div className="gz-text-xsm mb-[4px]">{translate('pages.nx.par_3_leaderboard.PLAYED_ROUNDS')}</div>
        <div className="gz-text-md font-bold">{myInfo?.playedRoundNum || 0}</div>
        <img src={iconRound} alt="cup" className="absolute bottom-0 right-0 w-[75px]" />
      </div>
    </div>
  );
};

export default MyRanking;

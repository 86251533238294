import { noStoreData } from 'src/assets/images';
import { twMerge } from 'tailwind-merge';

interface INotFoundItemProps {
  className?: string;
  classImage?: string;
  image?: string;
  text?: string;
  classText?: string;
}

function NotFoundItem({ className, classImage, image, text, classText }: INotFoundItemProps) {
  return (
    <div className={twMerge('flex flex-col items-center pt-[60px]', className)}>
      <img
        className={twMerge('[&:not(:last-child)]:pb-[24px]', classImage)}
        src={image ?? noStoreData}
        alt="no-items"
      />
      {!!text && (
        <div className={twMerge('gz-text-xs whitespace-pre-wrap text-center text-sub-body', classText)}>{text}</div>
      )}
    </div>
  );
}

export default NotFoundItem;

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { IconDirectionDown } from 'src/assets/svg';
import { pathConstants } from 'src/constants/const';
import { eventName } from 'src/constants/const/firebase.constants';
import { GENDER_TYPE, RANKING_SCORE_TYPE, gHandyOptions, genderOptions } from 'src/constants/const/profile.constants';
import { EnumTypeTableRanking } from 'src/constants/enum';
import { useModal } from 'src/hooks/use-modal';
import { firebaseInstance } from 'src/init-firebase';
import { NotificationType } from 'src/models';
import { IItemOption } from 'src/models/profile';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import { profileRequests } from 'src/requests/api/profile';
import { history } from 'src/services/history';
import { RootState } from 'src/store';
import { notificationActions } from 'src/store/notification';
import PopUp from 'src/view/commons/elements/PopUp';
import Header from 'src/view/layouts/global/Header';
import FilterCountry from 'src/view/pages/profile/ranking-detail/common/FilterCountry';
import FilterPopUp from 'src/view/pages/profile/ranking-detail/common/FilterPopUp';
import NotRanking from 'src/view/pages/profile/ranking-detail/common/NotRanking';
import { twMerge } from 'tailwind-merge';

interface IProps {
  type: EnumTypeTableRanking;
  onChangeFilter: (value: IDataFilter) => void;
  isHasMyRanking: boolean;
  loading?: boolean;
}

interface IDataFilter {
  sex?: string;
  [key: string]: any;
}

export default function Filter({ type, onChangeFilter, isHasMyRanking, loading }: IProps) {
  const translate = useTranslation().t;
  const userInfo = useSelector<RootState, IMyInfoResponse | null>((state) => state.mainInfoReducer.userInfo);
  const mainWidth = useSelector((state: RootState) => state.globalReducer.mainWidth);

  const { isOpen: isFilterGenderOpen, onOpen: onFilterGenderOpen, onClose: onFilterGenderClose } = useModal(false);
  const { isOpen: isFilterOpen, onOpen: onFilterOpen, onClose: onFilterClose } = useModal(false);
  const [params, setSearchParams] = useSearchParams();
  const rgnCodeParams = params.get('rgnCode');
  const gradeCodeParams = params.get('gradeCode');
  const rgnNoParams = params.get('rgnNo');
  const rgnNameParams = params.get('rgnName');
  const countryCodeParams = params.get('countryCode');

  const dispatch = useDispatch();
  const [initFilter, setInitFilter] = useState<IDataFilter>({});
  const [currentGender, setCurrentGender] = useState<string>(genderOptions[0].value);
  const [currentCountry, setCurrentCountry] = useState<string>(countryCodeParams ?? '');
  const [listCountry, setListCountry] = useState<IItemOption[]>([]);
  const [listSubCountry, setListSubCountry] = useState<IItemOption[]>([]);
  const [listLevel, setListLevel] = useState<IItemOption[]>([]);
  const [currentSubCountry, setCurrentSubCountry] = useState<string>(rgnCodeParams ?? '');
  const [currentLevel, setCurrentLevel] = useState<string>(gradeCodeParams ?? '');
  const [currentGHandy, setCurrentGHandy] = useState<string>(RANKING_SCORE_TYPE.G_HCP_TYPE);

  const currentGenderTitle = genderOptions.find((item) => item.value === currentGender)?.title;

  useEffect(() => {
    fetchFilterDataRanking();
  }, []);

  const fetchFilterDataRanking = async () => {
    try {
      const data = await profileRequests.getFilterDataRanking(countryCodeParams!);
      if (data?.entities) {
        setListLevel(convertDataToOption(data?.entities?.levelList, 'gradeCode', 'gradeCode'));
        setListSubCountry(convertDataToOption(data?.entities?.cityList, 'cityName', 'rgnCode'));
        setListCountry(convertDataToOption(data?.entities?.countryList, 'countryName', 'countryCode'));
      }
    } catch (err) {
      dispatch(notificationActions.addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  };

  const convertDataToOption = (array: any[], titleField: string, valueField: string) => {
    if (!array?.length) return [];
    return array.map((item) => ({
      title: item[titleField],
      value: item[valueField]?.toString(),
    }));
  };

  const onChangeGender = (gender: string) => {
    setCurrentGender(gender);
    onFilterGenderClose();

    firebaseInstance.trackEvent(eventName.RANKING_DETAIL_GENDER, true, {
      filter_value: gender,
    });
  };

  const onChangeGHandy = (gHandy: string) => {
    setCurrentGHandy(gHandy);
    onFilterClose();

    firebaseInstance.trackEvent(eventName.RANKING_DETAIL_GHCP_FILTER, true, {
      filter_value: gHandy,
    });
  };

  const onChangeCountry = (country: string) => {
    setCurrentCountry(country);
    onFilterClose();

    firebaseInstance.trackEvent(eventName.RANKING_DETAIL_COUNTRIES, true, {
      filter_value: country,
    });
    history.replace({
      pathname: pathConstants.PROFILE_RANKING_COUNTRY_DETAIL(userInfo?.usrNo),
      search: `?countryCode=${country}`,
    });
  };

  const onClickGlobal = () => {
    onFilterClose();
    setCurrentCountry('');
    history.replace(pathConstants.PROFILE_RANKING_GLOBAL_DETAIL(userInfo?.usrNo));
  };

  const onChangeSubCountry = (subCountry: string) => {
    setCurrentSubCountry(subCountry);
    onFilterClose();
    setSearchParams({ rgnCode: subCountry.toString(), countryCode: countryCodeParams! }, { replace: true });

    firebaseInstance.trackEvent(eventName.RANKING_DETAIL_SUB_COUNTRY_FILTER, true, {
      filter_value: subCountry.toString(),
    });
  };

  const onChangeLevel = (level: string) => {
    setCurrentLevel(level);
    onFilterClose();
    setSearchParams({ gradeCode: level.toString() }, { replace: true });

    firebaseInstance.trackEvent(eventName.RANKING_DETAIL_LEVEL_FILTER, true, {
      filter_value: level.toString(),
    });
  };

  const customFilterByType = useMemo(() => {
    if (type === EnumTypeTableRanking.COUNTRY || type === EnumTypeTableRanking.GLOBAL)
      return {
        component: (
          <FilterCountry
            listCountry={listCountry}
            currentCountry={currentCountry!}
            changeCountry={onChangeCountry}
            onClickGlobal={onClickGlobal}
            isGlobal={type === EnumTypeTableRanking.GLOBAL}
          />
        ),
        title:
          type === EnumTypeTableRanking.GLOBAL
            ? 'Global'
            : listCountry.find((item) => item.value === currentCountry)?.title ?? '',
        key: type === EnumTypeTableRanking.GLOBAL ? '' : 'countryCode',
        filterValue: type === EnumTypeTableRanking.GLOBAL ? '' : currentCountry,
      };
    if (type === EnumTypeTableRanking.SUB_COUNTRY)
      return {
        component: <FilterPopUp value={currentSubCountry} onChange={onChangeSubCountry} options={listSubCountry} />,
        title: listSubCountry.find((item) => item.value === currentSubCountry)?.title ?? '',
        key: 'subCode',
        filterValue: currentSubCountry,
      };
    if (type === EnumTypeTableRanking.LEVEL)
      return {
        component: <FilterPopUp value={currentLevel} onChange={onChangeLevel} options={listLevel} />,
        title: listLevel.find((item) => item.value === currentLevel)?.title ?? '',
        key: 'gradeCode',
        filterValue: currentLevel,
      };
    if (type === EnumTypeTableRanking.STORE)
      return {
        component: <FilterPopUp value={currentGHandy} onChange={onChangeGHandy} isTranslate options={gHandyOptions} />,
        title: translate(gHandyOptions.find((item) => item.value === currentGHandy)?.title!) ?? '',
        key: 'rankingScoreType',
        filterValue: currentGHandy,
      };
    return {
      component: <></>,
      title: '',
      key: '',
      filterValue: '',
    };
  }, [type, listSubCountry, listLevel, listCountry, currentLevel, currentGHandy, currentSubCountry, currentCountry]);

  useEffect(() => {
    const dataFilter: IDataFilter = {
      sex: currentGender,
      [customFilterByType?.key]: customFilterByType?.filterValue,
    };
    if (type === EnumTypeTableRanking.STORE) {
      delete dataFilter.sex;
      dataFilter.rgnNo = rgnNoParams ?? '';
      dataFilter.genderType = currentGender;
    }
    onChangeFilter(dataFilter);
  }, [currentGender, currentCountry, currentGHandy, currentSubCountry, currentLevel]);

  useEffect(() => {
    const dataFilter: IDataFilter = {
      sex: currentGender,
      [customFilterByType?.key]: customFilterByType?.filterValue,
    };
    if (type === EnumTypeTableRanking.STORE) {
      delete dataFilter.sex;
      dataFilter.rgnNo = rgnNoParams ?? '';
      dataFilter.genderType = currentGender;
    }
    setInitFilter(dataFilter);
  }, []);

  const onResetFilter = () => {
    if (type === EnumTypeTableRanking.GLOBAL) {
      history.replace(pathConstants.PROFILE_RANKING_GLOBAL_DETAIL(userInfo?.usrNo));
      setCurrentCountry('');
    } else if (type === EnumTypeTableRanking.COUNTRY) {
      history.replace({
        pathname: pathConstants.PROFILE_RANKING_COUNTRY_DETAIL(userInfo?.usrNo),
        search: `?countryCode=${initFilter?.countryCode}`,
      });
    }
    onChangeFilter(initFilter);
    setCurrentCountry(initFilter?.countryCode);
    setCurrentSubCountry(initFilter?.rgnCd);
    setCurrentLevel(initFilter?.gradeCode);
    setCurrentGHandy(initFilter?.rankingScoreType);
    setCurrentGender(GENDER_TYPE.ALL_GENDER);
  };

  const notInTable = () => {
    return (
      <>
        {type === EnumTypeTableRanking.STORE ? (
          <div
            style={{ width: mainWidth }}
            className="fixed bottom-0 z-10 bg-gz-white p-[20px_16px] text-center text-[14px] leading-[21px] shadow-not-ranking"
          >
            {translate('pages.home.my_ranking.NOT_RANKING_IN_TABLE_STORE')}
          </div>
        ) : (
          <NotRanking onResetFilter={onResetFilter} />
        )}
      </>
    );
  };

  return (
    <>
      <Header
        title={translate('pages.home.my_ranking.RANKING', {
          name: type === EnumTypeTableRanking.STORE ? decodeURIComponent(rgnNameParams!) : customFilterByType?.title,
        })}
      />

      <div className="mx-[8px] mt-[16px] flex items-center">
        <div
          onClick={onFilterOpen}
          className={twMerge('flex min-h-[24px] w-fit cursor-pointer items-center p-[5px_8px]', 'text-text')}
        >
          <div className="text-[15px] font-normal">{customFilterByType?.title}</div>
          <IconDirectionDown className="ml-[6px] stroke-text" />
        </div>

        <div
          onClick={() => onFilterGenderOpen()}
          className={twMerge('flex min-h-[24px] w-fit cursor-pointer items-center p-[5px_8px]', 'text-text')}
        >
          <div className="text-[15px] font-normal">{translate(currentGenderTitle!)}</div>
          <IconDirectionDown className="ml-[6px] stroke-text" />
        </div>

        <PopUp isOpen={isFilterGenderOpen} onClose={onFilterGenderClose}>
          <FilterPopUp value={currentGender} onChange={onChangeGender} isTranslate options={genderOptions} />
        </PopUp>

        <PopUp isOpen={isFilterOpen} onClose={onFilterClose}>
          {customFilterByType.component}
        </PopUp>
      </div>
      {!loading && !isHasMyRanking && notInTable()}
    </>
  );
}

import { useLayoutEffect } from 'react';
import { NavigationType, useNavigationType } from 'react-router-dom';

export const useBackButton = () => {
  const navType = useNavigationType();
  return [NavigationType.Pop, NavigationType.Replace].includes(navType);
};

export const useScrollToOldPosition = (scrollable: boolean, stateOffset: number, callback: (value: number) => void) => {
  const isBackState = useBackButton();

  useLayoutEffect(() => {
    if (scrollable && isBackState) {
      setTimeout(() => {
        window.scrollTo(0, stateOffset);
      }, 50);
    }

    return () => {
      callback(window.scrollY);
    };
  }, []);
};

import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { history } from 'src/services/history';
import {
  IconActiveCalendar,
  IconActiveCupGolf,
  IconActiveFlagLine,
  IconActiveHome,
  IconActiveSetting,
  IconActiveStore,
  IconActiveYoutube,
  IconCalendar,
  IconCupGolf,
  IconFlagNewLine,
  IconHome,
  IconSetting,
  IconStore,
  IconYoutube,
  LogoGolfzon,
} from 'src/assets/svg';
import { LOCAL_STORAGE_ACTIVITIES_START_TIME, MIN_DATE, formatDate, pathConstants } from 'src/constants/const';
import { twMerge } from 'tailwind-merge';
import { connect, useSelector } from 'react-redux';
import { records } from 'src/store/records';
import { feed } from 'src/store/feed';
import { tournamentActions } from 'src/store/tournament';
import { courseActions } from 'src/store/course';
import { firebaseInstance } from 'src/init-firebase';
import { eventName } from 'src/constants/const/firebase.constants';
import { leagueAction } from 'src/store/league';
import { getObjectLocalStorage, saveObjectLocalStorage } from 'src/services/storage';
import dayjs from 'dayjs';
import { infoAccount } from 'src/requests/api/home';
import { listGDR } from 'src/requests/api/record/GDR';
import { EnumRequestCode } from 'src/constants/enum';
import { isEmpty } from 'lodash';

interface ISidebarProps {
  classNameDesktop?: string;
  classNameMobile?: string;
  isNativeApp: boolean;
  showOnMobile?: boolean;
  resetRecord: () => void;
  resetFeed: () => void;
  resetTournamentData: () => void;
  resetLeagueData: () => void;
  resetCourseList: () => void;
}

function Sidebar({
  classNameDesktop,
  classNameMobile,
  isNativeApp,
  showOnMobile = true,
  resetRecord,
  resetFeed,
  resetTournamentData,
  resetLeagueData,
  resetCourseList,
}: ISidebarProps): JSX.Element {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const translate = useTranslation().t;
  const { pathname } = useLocation();
  const sidebarDesktopRef = useRef<HTMLDivElement>(null);
  const sidebarMobileRef = useRef<HTMLDivElement>(null);

  const activityTimeLocal = getObjectLocalStorage(LOCAL_STORAGE_ACTIVITIES_START_TIME);
  const { startTimeGDR = MIN_DATE, startTimeGS = MIN_DATE, startTimeMyPractice = MIN_DATE } = activityTimeLocal;

  const isLoggedIn = useSelector((state: any) => state.authentication.isLoggedIn);

  const handleActivityTab = async () => {
    let parsedDateGS, parsedDateGDR, parsedDateMyPractice;
    parsedDateGS = parsedDateGDR = parsedDateMyPractice = dayjs();
    if (!isEmpty(activityTimeLocal)) {
      parsedDateGS = dayjs(startTimeGS);
      parsedDateGDR = dayjs(startTimeGDR);
      parsedDateMyPractice = dayjs(startTimeMyPractice);
    } else {
      try {
        const [resultGs, resultGDR, resultMyPractice] = await Promise.all([
          infoAccount.getGsInfo(),
          listGDR.getDrivingClubRequest({
            searchDate: dayjs().format(formatDate.BASIC),
            rows: 1,
          }),
          infoAccount.getMyPractice(),
        ]);
        if ([resultGs?.code, resultGDR?.code, resultMyPractice?.code].some((el) => el !== EnumRequestCode.SUCCESS)) {
          throw new Error('get profile failed');
        }
        parsedDateGS = dayjs(resultGs?.entities?.timestart || MIN_DATE);
        parsedDateGDR = dayjs(resultGDR.entities[0]?.statDate || MIN_DATE);
        parsedDateMyPractice = dayjs(resultMyPractice?.entities?.[0]?.tsTimeStart || MIN_DATE);

        saveObjectLocalStorage(LOCAL_STORAGE_ACTIVITIES_START_TIME, {
          startTimeGS: resultGs?.entities?.timestart,
          startTimeGDR: resultGDR.entities[0]?.statDate,
          startTimeMyPractice: resultMyPractice?.entities?.[0]?.tsTimeStart,
        });
      } catch (error) {
        console.warn(error);
      }
    }
    // set default tab
    if (parsedDateGDR.isAfter(parsedDateGS) && parsedDateGDR.isAfter(parsedDateMyPractice))
      history.push(pathConstants.RECORDS_GDR);
    else if (parsedDateMyPractice.isAfter(parsedDateGS)) history.push(pathConstants.RECORDS_TRAINING_MODE);
    else history.push(pathConstants.RECORDS_GS_ROUND);
  };

  const mobileMenu = [
    {
      title: translate('layouts.auth.sidebar.HOME'),
      icon: <IconHome />,
      activeIcon: <IconActiveHome />,
      path: pathConstants.HOME_PAGE,
      activePaths: [pathConstants.HOME_PAGE, pathConstants.ABOUT_PROFILE],
      runAction: function () {
        firebaseInstance.trackEvent(eventName.NAVIGATIONBAR_HOME);
      },
    },
    {
      title: translate('layouts.auth.sidebar.RECORDS'),
      icon: <IconCalendar className="fill-text" />,
      activeIcon: <IconActiveCalendar />,
      path: pathConstants.RECORDS_GS_ROUND,
      activePaths: [pathConstants.RECORDS],
      runAction: function () {
        resetRecord();
        firebaseInstance.trackEvent(eventName.NAVIGATIONBAR_ACTIVITY);
      },
    },
    {
      title: translate('layouts.auth.sidebar.FEED'),
      icon: <IconYoutube />,
      activeIcon: <IconActiveYoutube />,
      path: pathConstants.FEED_PAGE,
      activePaths: [pathConstants.FEED_PAGE],
      runAction: function () {
        resetFeed();
        firebaseInstance.trackEvent(eventName.NAVIGATIONBAR_FEED);
      },
    },
    {
      title: translate('layouts.auth.sidebar.EVENTS'),
      icon: <IconCupGolf />,
      path: pathConstants.TOURNAMENT_PAGE,
      activePaths: [pathConstants.TOURNAMENT_PAGE, pathConstants.LEAGUE_PAGE],
      activeIcon: <IconActiveCupGolf className="bg-gz-white" />,
      runAction: function () {
        resetTournamentData();
        resetLeagueData();
        firebaseInstance.trackEvent(eventName.NAVIGATIONBAR_EVENT);
      },
    },
    {
      title: translate('layouts.auth.sidebar.STORES'),
      icon: <IconStore className="fill-gz-white stroke-text" />,
      activeIcon: <IconActiveStore />,
      path: pathConstants.STORE_PAGE,
      activePaths: [pathConstants.STORE_PAGE],
      runAction: function () {
        firebaseInstance.trackEvent(eventName.NAVIGATIONBAR_LOCATIONS);
      },
    },
  ];

  const desktopMenu = [
    ...mobileMenu,
    {
      title: translate('layouts.auth.sidebar.COURSES'),
      icon: <IconFlagNewLine width="28px" height="28px" className="fill-gz-white" />,
      activeIcon: <IconActiveFlagLine width="28px" height="28px" />,
      path: pathConstants.ABOUT_COURSE,
      activePaths: [pathConstants.COURSE],
      runAction: function () {
        resetCourseList();
        firebaseInstance.trackEvent(eventName.NAVIGATIONBAR_COURSE);
      },
    },
    {
      title: translate('layouts.auth.sidebar.SETTINGS'),
      icon: <IconSetting width="28px" height="28px" className="fill-title" />,
      activeIcon: <IconActiveSetting width="28px" height="28px" />,
      path: pathConstants.SETTING,
      activePaths: [pathConstants.SETTING],
      runAction: function () {
        firebaseInstance.trackEvent(eventName.NAVIGATIONBAR_SETTINGS);
      },
    },
  ];

  const activeLink = (item: any, index: number) => {
    return item.activePaths.some((activePath: string) =>
      new RegExp(`^${activePath}${activePath === pathConstants.HOME_PAGE ? '$' : ''}`).test(pathname),
    );
  };

  const goToPage = (item?: any) => {
    if (pathname === item.path) return;
    if (!!item.runAction) item.runAction();
    if (!item.path) return;
    if (item.path === pathConstants.RECORDS_GS_ROUND && isLoggedIn) {
      handleActivityTab();
      return;
    }
    history.push(item.path);
  };

  return (
    <>
      <div
        ref={sidebarDesktopRef}
        className={twMerge(
          'sticky top-0 h-[100vh] flex-shrink-0 bg-white',
          'bottom-0 top-0 flex justify-end border-r border-gray-10',
          classNameDesktop,
        )}
      >
        <ul className="ml-auto w-[220px]">
          <li className="gz-text-xs flex cursor-pointer items-center py-[6px] pl-[30%]">
            <LogoGolfzon
              className="mt-[8px] h-auto w-[100px] cursor-pointer"
              onClick={() => history.push(pathConstants.HOME_PAGE)}
            />
          </li>
          {desktopMenu.map((item, index: number) => (
            <li
              key={item.title}
              className="gz-text-xs mt-[24px] flex cursor-pointer items-center py-[6px] pl-[30%]"
              onMouseOver={() => setHoverIndex(index)}
              onFocus={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(-1)}
              onClick={() => goToPage(item)}
            >
              <div className="flex-shrink-0">
                {activeLink(item, index) || hoverIndex === index ? item.activeIcon : item.icon}
              </div>
              <div className={twMerge('ml-[14px]', activeLink(item, index) && 'text-gz-primary')}>{item.title}</div>
            </li>
          ))}
        </ul>
      </div>
      {showOnMobile && (
        <div
          ref={sidebarMobileRef}
          className={twMerge(
            'fixed bottom-0 left-0 right-0 z-[11] h-[64px] rounded-t-[16px] bg-white px-[16px] shadow-2xl',
            !isNativeApp && 'bottom-inset-style',
            classNameMobile,
          )}
        >
          <ul className="flex h-[64px] w-full">
            {mobileMenu.map((item, index: number) => (
              <li
                key={item.title}
                className={twMerge('gz-text-xs flex w-[20%] cursor-pointer flex-col items-center justify-center')}
                onMouseOver={() => setHoverIndex(index)}
                onFocus={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(-1)}
                onClick={() => goToPage(item)}
              >
                {activeLink(item, index) ? item.activeIcon : item.icon}
                <div
                  className={twMerge(
                    'mt-[4px] text-[11px] font-medium leading-[16px]',
                    activeLink(item, index) && 'text-gz-primary',
                  )}
                >
                  {item.title}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => ({
  isNativeApp: state.webviewReducer.isNativeApp,
});
const mapDispatchToProps = {
  resetRecord: records.resetRecord,
  resetFeed: feed.resetFeed,
  resetTournamentData: tournamentActions.resetTournamentData,
  resetLeagueData: leagueAction.resetLeagueData,
  resetCourseList: courseActions.resetCourseList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

import axios from 'src/requests';
import { IPageRowParam } from 'src/requests/api/common/prop-state.type';
import { ITmAvailableMonthsParams, ITmDrivingApproachPuttingParams } from 'src/requests/api/record/prop-state.type';

const getDrivingApproachPutting = async (params: ITmDrivingApproachPuttingParams) => {
  const repsonse = await axios.get('/training/list', {
    params,
  });
  return repsonse.data.data;
};

const getSwingVideo = async (params?: IPageRowParam) => {
  const repsonse = await axios.get('/training/swingVideo', {
    params,
  });
  return repsonse.data.data;
};

const getAvailableMonths = async (params: ITmAvailableMonthsParams) => {
  const repsonse = await axios.get('/training/listMonthAvailable', {
    params,
  });
  return repsonse.data.data;
};

const getDrivingRangeDetail = async (tsCode: string) => {
  const repsonse = await axios.get(`/training/drivingRange/${tsCode}`);
  return repsonse.data.data;
};

const getApproachDetail = async (tsCode: string) => {
  const repsonse = await axios.get(`/training/approach/${tsCode}`);
  return repsonse.data.data;
};

const getPuttingDetail = async (tsCode: string) => {
  const repsonse = await axios.get(`/training/putting/${tsCode}`);
  return repsonse.data.data;
};

const getSwingVideoDetail = async (tsCode: string) => {
  const repsonse = await axios.get(`/training/swingVideo/${tsCode}`);
  return repsonse.data.data;
};

const getSwingVideoAnalysis = async (imgUrl: string) => {
  const repsonse = await axios.get('/training/swingAnalysis/', {
    params: {
      imgUrl,
    },
  });
  return repsonse.data.data;
};

export const trainingModeRecord = {
  getDrivingApproachPutting,
  getSwingVideo,
  getAvailableMonths,
  getDrivingRangeDetail,
  getApproachDetail,
  getPuttingDetail,
  getSwingVideoDetail,
  getSwingVideoAnalysis,
};

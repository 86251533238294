/* 
  // Nickname must have lowercase, uppercase, number & !"#$%&'()*+,-./:;<=>?@\[\]\^_`{|}~
    const NICKNAMEFULL = /^(?=.*\d)(?=.*[a-zぁ-んァ-ン一-龥])(?=.*[!"#$%&'()*+,-./:;<=>?@\[\]\^_`{|}~])[a-z0-9ぁ-んァ-ン一-龥!"#$%&'()*+,-./:;<=>?@\[\]\^_`{|}~]{4,12}$/;

    const EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Input have character Which haven't in regex
    const NOT_ALLOW_OTHER_CHARACTER = /[^A-Za-z0-9ぁ-んァ-ン一-龥(!@#$^&+=)]/;
*/

// Taken from HTML spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const EMAIL =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

// For only allow to be enter <Input />
const NUMBER_DOT_ONLY = /[^\d.]/;

// For only number
const NUMBER_ONLY = /^\d+$/;

// Nickname must have number, special, lowercase, uppercase or japanese
// const NICKNAME = /^[\sA-Za-z0-9ぁ-んァ-ン一-龥(!@#$^&+=)]{4,12}$/;
const NICKNAME = /^[ぁ-んァ-ンｧ-ﾝﾞﾟ一-龥A-Za-z0-9Ａ-Ｚ０-９々〆〤ヶぁ-ゔァ-ヴー(!@#$^&+=)]{4,12}$/;

//check avatar file type
const AVATAR_FILE_TYPE = /jpg|jpeg|gif/;

export const RegExConstants = {
  EMAIL,
  NUMBER_DOT_ONLY,
  NUMBER_ONLY,
  NICKNAME,
  AVATAR_FILE_TYPE,
};

import { useTranslation } from 'react-i18next';
import ImageCustom from 'src/view/commons/customs/Image';
import { picLogo } from 'src/assets/images/traning-mode';
import { history } from 'src/services/history';
import { CT_ENV_FLAVOR } from 'src/constants/const';
import { EnumFlavor } from 'src/constants/enum/env.enum';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import { connect } from 'react-redux';
import { useEventFirebase } from 'src/hooks/use-event-firebase';
import { firebaseInstance } from 'src/init-firebase';
import { eventName } from 'src/constants/const/firebase.constants';

interface IProps {
  userInfo: IMyInfoResponse | null;
}

const QA_GLOBAL_LINK_PATH = '/news/612/category/N101';
const QA_JAPANESE_LINK_PATH = '/news/613/category/N101';
const LIVE_GLOBAL_LINK_PATH = '/news/542/category/N101';
const LIVE_JAPANESE_LINK_PATH = '/news/543/category/N101';

const NoTrainingModeData = ({ userInfo }: IProps) => {
  const translate = useTranslation().t;
  const { isMenucard } = useEventFirebase();

  const openLink = () => {
    isMenucard && firebaseInstance.trackEvent(eventName.MENUCARD_MYPRACTICE_TOLEARNMORE);
    const isJapaneseUser = userInfo?.countryCd === 'JPN';
    let path = QA_GLOBAL_LINK_PATH;
    if (CT_ENV_FLAVOR !== EnumFlavor.LIVE && isJapaneseUser) {
      path = QA_JAPANESE_LINK_PATH;
    } else if (CT_ENV_FLAVOR === EnumFlavor.LIVE && isJapaneseUser) {
      path = LIVE_JAPANESE_LINK_PATH;
    } else if (CT_ENV_FLAVOR === EnumFlavor.LIVE) {
      path = LIVE_GLOBAL_LINK_PATH;
    }
    history.push(path);
  };

  return (
    <div className="gz-text-sm flex flex-col items-center p-[60px_48px_120px_48px]">
      <ImageCustom className="w-full max-w-[360px]" imgPath={picLogo} />
      <div
        className="mt-[24px] text-center leading-[1.5] text-sub-body"
        dangerouslySetInnerHTML={{
          __html: translate('pages.record.type.training_mode.TWOVISION_WARNING'),
        }}
      />
      <div className="mt-[16px] cursor-pointer text-primary-75" onClick={openLink}>
        {translate('pages.record.type.training_mode.LEARN_MORE')}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userInfo: state.mainInfoReducer.userInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NoTrainingModeData);

export enum TypeDistance {
  IMPERIAL = 'yd',
  METRIC = 'km',
  METER = 'm',
  FEET = 'ft',
}

export enum TypeSpeed {
  METER = 'm/s',
  MPH = 'mph',
}

export enum topNumber {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
}

export enum typeLevel {
  FIRST = 1,
  SECOND = 2,
}

export enum pageSize {
  SMALL = 6,
  NORMAL = 10,
  SPECIAL = 12,
  MEDIUM = 15,
  BIG_SIZE = 20,
}

export enum EnumStatusCode {
  SUCCESS = 100,
  FAILED = 101,
  ERROR = 102,
}

export enum EnumRequestCode {
  SUCCESS = '1',
  FAILED = '0',
}

export enum EnumVerifyTokenCode {
  SUCCESS = '200',
  FAILED = '401',
}

export enum EnumValueBoolean {
  TRUE = 'Y',
  FALSE = 'N',
}

export enum EnumPlayDifficultyType {
  ROOKIE = 2,
  AMATEUR = 0,
  PRO = 1,
  G_TOUR = 3,
}

export enum EnumGsBallPathCode {
  STRAIGHT = '1',
  HOOK = '2',
  SLICE = '3',
  PULL = '4',
  PUSH = '5',
  PULL_SLICE = '6',
  PUSH_HOOK = '7',
  DRAW = '8',
  FADE = '9',
}

export enum EnumGsBallPath {
  STRAIGHT = 'U001',
  FADE = 'U002',
  DRAW = 'U003',
}

export enum EnumGdrBallPathCode {
  HOOK = '0',
  STRAIGHT = '1',
  SLICE = '2',
  PUSH = '3',
  PULL = '4',
  PUSH_HOOK = '5',
  PUSH_SLICE = '6',
  PULL_HOOK = '7',
  PULL_SLICE = '8',
  FADE = '9',
  DRAW = '10',
}

export enum EnumTmBallPathCode {
  STRAIGHT = '1',
  PULL = '2',
  PUSH = '3',
  SLICE = '4',
  HOOK = '5',
  DRAW = '6',
  FADE = '7',
  PULL_SLICE = '8',
  PUSH_HOOK = '9',
}

export enum EnumGsEventName {
  REQ_MOB_START = 'ReqMobStart',
  RES_MOB_START = 'ResMobStart',
  REQ_CHECK_IN = 'ReqCheckin',
  RES_CHECK_IN = 'ResCheckin',
}

export enum EnumScreenLoginMode {
  NONE = 'NONE',
  GS = 'GS',
  GDR = 'GDR',
}

// End for Home Page

export enum EnumCountryFilterType {
  COURSE = 'CS',
  TOURNAMENT = 'TM',
}

export enum EnumFilterField {
  ALL = '',
}

export enum EnumFilterAllField {
  ALL = 'ALL',
}

export enum EnumTypeOpenWindow {
  NEW_WINDOW = '1',
  CURRENT_WINDOW = '2',
}

export enum EnumSaveMediaType {
  SCORE_CARD,
  VIDEO,
}

export enum EnumActionBanner {
  VIEW = 'view',
  CLICK = 'click',
}

export enum EnumTypeApiSendMail {
  SIGNUP = 1,
  UPDATE_SMSAUTHYN = 2,
}

export enum EnumNoHeaderSearchParams {
  KEEP = 'keep',
  ONCE = 'once',
  BACK_KEEP = 'back_keep',
  BACK_ONCE = 'back_once',
  NONE = 'none',
}

export enum EnumFireStoreCollectionName {
  SMART_LOGIN_GS = 'smart_login_gs',
  SMART_LOGIN_GDR = 'smart_login_gdr',
  TOTAL_COUNT = 'total_count',
  ERRORS = 'errors',
  ERRORS_COLLECTION = 'errors_collection',
  CRASHLYITC_ERRORS = 'crashlytic_errors',
  CRASHLYITC_TRACES = 'crashlytic_traces',
}
export enum EnumStoreDistance {
  KILOMETER = 'km',
  MILE = 'mile',
}

import { memo, useEffect, useRef } from 'react';
import { isIOS } from 'react-device-detect';
import LoadingIndicator from 'src/view/commons/elements/LoadingIndicator';

interface IInfiniteScrollProps {
  isLoadMore?: boolean;
  classWrapper?: string;
  loadMore?: (value: boolean) => void;
  children: JSX.Element;
  loader?: JSX.Element;
  isLastPage?: boolean;
  loading?: boolean;
}

function InfiniteScrollWrapper({
  isLoadMore = true,
  classWrapper,
  loadMore,
  children,
  loader,
  isLastPage,
  loading,
}: IInfiniteScrollProps): JSX.Element {
  if (!isLoadMore) return <>{children}</>;
  return (
    <InfiniteScroll
      classWrapper={classWrapper}
      loadMore={loadMore}
      loading={loading}
      loader={loader}
      isLastPage={isLastPage}
    >
      {children}
    </InfiniteScroll>
  );
}

const InfiniteScroll: React.FC<IInfiniteScrollProps> = ({
  classWrapper,
  loadMore,
  children,
  loader,
  isLastPage,
  loading,
}) => {
  const startLoadMoreRef = useRef<any>();
  const observer = useRef<any>();

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: isIOS ? 0.1 : 0,
  };
  useEffect(() => {
    observer.current?.disconnect();
    observer.current = new IntersectionObserver(([entry]) => {
      if (entry?.isIntersecting && !loading) {
        loadMore && loadMore(true);
      }
    }, options);

    if (startLoadMoreRef.current) {
      observer.current.observe(startLoadMoreRef.current);
    }
    return () => {
      observer.current.disconnect();
    };
  }, [children, loading]);

  const renderLoaderMore = () => (
    <>
      {!isLastPage && (
        <div ref={startLoadMoreRef} className="flex items-center justify-center py-[12px]">
          <LoadingIndicator className="h-4 w-4 self-center" />
        </div>
      )}
    </>
  );

  return (
    <>
      <div className={classWrapper}>{children}</div>
      {loader && <div ref={startLoadMoreRef}>{loader}</div>}
      {!loader && renderLoaderMore()}
    </>
  );
};

export default memo(InfiniteScrollWrapper);

import { EnumListCodeCategory, EnumFilterField, EnumNewsFilterCode } from 'src/constants/enum';
import { IListMore } from 'src/requests/api/common/prop-state.type';
import { INewsListResponse } from 'src/requests/api/home/prop-state.type';
import { CHANGE_SELECTED_CATEGORY, NOTICE_INFO, NEWS_OFFSET, RESET_SELECTED_CATEGORY } from 'src/store/news/action';

export interface newListDataState {
  [EnumFilterField.ALL]: IListMore<INewsListResponse>;
  [EnumListCodeCategory.NOTIFICATIONS]: IListMore<INewsListResponse>;
  [EnumListCodeCategory.CAMPAIGNS]: IListMore<INewsListResponse>;
  [EnumListCodeCategory.TOURNAMENT]: IListMore<INewsListResponse>;
  [EnumListCodeCategory.SPECIALS]: IListMore<INewsListResponse>;
  [EnumListCodeCategory.GDR]: IListMore<INewsListResponse>;
}

export interface newsInfoState {
  selectedCategory: EnumNewsFilterCode;
  dataNews: newListDataState;
  offsetNews: number;
}

const initialState: newsInfoState = {
  selectedCategory: EnumFilterField.ALL,
  dataNews: {
    [EnumFilterField.ALL]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeCategory.NOTIFICATIONS]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeCategory.CAMPAIGNS]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeCategory.TOURNAMENT]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeCategory.SPECIALS]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
    [EnumListCodeCategory.GDR]: {
      values: [],
      loaded: 0,
      lastPage: false,
    },
  },
  offsetNews: 0,
};

export function newsNoticeReducer(state: newsInfoState = initialState, action: any) {
  switch (action.type) {
    case CHANGE_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case NOTICE_INFO:
      return {
        ...state,
        dataNews: {
          ...state.dataNews,
          [action.payload.keyFilter]: action.payload.dataNotice,
        },
      };
    case NEWS_OFFSET:
      return {
        ...state,
        offsetNews: action.payload.offsetNews,
      };
    case RESET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: initialState.selectedCategory,
      };
    default:
      return state;
  }
}

import { getObjectLocalStorage, saveObjectLocalStorage } from 'src/services/storage';
import {
  ADVERTISING_INFO,
  MY_INFO,
  SHOW_ADVERTISING_POPUP,
  SET_SCREEN_LOGIN_INFO,
  SET_HAS_NEW_NOTIFICATION,
  UPDATE_HOME_DATA,
  RESET_HOME_DATA,
  SET_PAGE_OFFSET,
} from 'src/store/home/action';
import { LOCALSTORAGE_USER_INFO } from 'src/constants/const';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import { EnumScreenLoginMode } from 'src/constants/enum';
import {
  IBookingProcessResponse,
  ICourseListResponse,
  IEventBannerListResponse,
  IGdrInfoResponse,
  IGsInfoResponse,
  IMyPracticeResponse,
  IMyRankingDataResponse,
  IMyRankingStoreDataResponse,
  IMyRankingTournamentResponse,
  INewsListResponse,
  ITournamentsResponse,
} from 'src/requests/api/home/prop-state.type';
import { IStoreDetail } from 'src/models';
import { IGdrNasmoClubResponse, IGsNasmoClubResponse } from 'src/requests/api/record/prop-state.type';

export interface UserInfoState {
  offset: number;
  userInfo: IMyInfoResponse | null;
  screenLoginMode: EnumScreenLoginMode;
  advertisingInfo: IEventBannerListResponse[];
  showAdvertPopup: boolean;
  advertIsHidden: boolean;
  hasNewNotification: boolean;
  dataBookingProcess: IBookingProcessResponse[];
  loadingBookingProcess: boolean;
  dataGs?: IGsInfoResponse;
  loadingGs: boolean;
  dataGsNasmo?: IGsNasmoClubResponse;
  loadingGsNasmo: boolean;
  dataGdr?: IGdrInfoResponse;
  loadingGdr: boolean;
  dataGdrNasmo?: IGdrNasmoClubResponse;
  loadingGdrNasmo: boolean;
  dataMyPractice?: IMyPracticeResponse;
  loadingMyPractice: boolean;
  dataCourse?: ICourseListResponse;
  loadingCourse: boolean;
  dataSubCountryRanking?: IMyRankingDataResponse | null;
  dataCountryRanking?: IMyRankingDataResponse | null;
  dataGlobalLevelRanking?: IMyRankingDataResponse | null;
  dataStoreRanking?: IMyRankingStoreDataResponse | null;
  loadingMyRanking: boolean;
  dataMyRankingTournament?: IMyRankingTournamentResponse[];
  loadingMyRankingTournament: boolean;
  dataTournament?: ITournamentsResponse;
  loadingTournament: boolean;
  dataStore: IStoreDetail[];
  loadingStore: boolean;
  dataNews: INewsListResponse[];
  loadingNews: boolean;
}

const initialState: UserInfoState = {
  offset: 0,
  userInfo: null,
  screenLoginMode: EnumScreenLoginMode.NONE,
  advertisingInfo: [],
  showAdvertPopup: false,
  advertIsHidden: false,
  hasNewNotification: false,
  dataBookingProcess: [],
  loadingBookingProcess: true,
  loadingGs: true,
  loadingGsNasmo: true,
  loadingGdr: true,
  loadingGdrNasmo: true,
  loadingMyPractice: true,
  loadingCourse: true,
  dataSubCountryRanking: null,
  dataCountryRanking: null,
  dataGlobalLevelRanking: null,
  dataStoreRanking: null,
  loadingMyRanking: true,
  dataMyRankingTournament: [],
  loadingMyRankingTournament: true,
  loadingTournament: true,
  dataStore: [],
  loadingStore: true,
  dataNews: [],
  loadingNews: true,
};

export function mainInfoReducer(state: UserInfoState = initialState, action: any) {
  state.userInfo = getObjectLocalStorage(LOCALSTORAGE_USER_INFO);
  switch (action.type) {
    case MY_INFO:
      saveObjectLocalStorage(LOCALSTORAGE_USER_INFO, action.payload.userInfo);
      return {
        ...state,
        userInfo: action.payload.userInfo,
      };
    case ADVERTISING_INFO: {
      return {
        ...state,
        advertisingInfo: action.payload.advertisingInfo,
      };
    }
    case SHOW_ADVERTISING_POPUP: {
      return {
        ...state,
        showAdvertPopup: action.payload.showAdvertPopup,
        advertIsHidden: action.payload.advertIsHidden || false,
      };
    }
    case SET_SCREEN_LOGIN_INFO: {
      return {
        ...state,
        screenLoginMode: action.payload.screenLoginMode,
      };
    }
    case SET_HAS_NEW_NOTIFICATION: {
      return {
        ...state,
        hasNewNotification: action.payload.value,
      };
    }
    case UPDATE_HOME_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_PAGE_OFFSET: {
      return {
        ...state,
        offset: action.payload,
      };
    }
    case RESET_HOME_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}

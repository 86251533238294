export const enum EnumPlusItemDuration {
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTH = 'THREE_MONTH',
  SIX_MONTH = 'SIX_MONTH',
}

export const enum EnumPlusItemBallType {
  BALL = 'BALL',
  BALL_TAIL = 'BALL_TAIL',
}

import { EnumRequestCode } from 'src/constants/enum';
import { trainingModeRecord } from 'src/requests/api/record/training-mode';
import { TrainingModeDetailState } from 'src/store/training-mode-detail/reducer';

export const UPDATE_TRAINING_MODE_DETAIL = 'UPDATE_TRAINING_MODE_DETAIL';
export const RESET_TRAINING_MODE_DETAIL = 'RESET_TRAINING_MODE_DETAIL';

export const trainingModeDetail = {
  update: (payload: TrainingModeDetailState) => ({
    type: UPDATE_TRAINING_MODE_DETAIL,
    payload,
  }),

  reset: () => ({
    type: RESET_TRAINING_MODE_DETAIL,
  }),
};

const getDrivingRangeDetail = (tsCode: string) => async (dispatch: any) => {
  let data;
  try {
    const result = await trainingModeRecord.getDrivingRangeDetail(tsCode);
    if (result.code === EnumRequestCode.FAILED) {
      throw new Error('get driving range failed');
    }
    data = result.entities;
  } catch (error) {
  } finally {
    dispatch(
      trainingModeDetail.update({
        loadingDrivingRange: false,
        drivingRange: data,
      }),
    );
  }
};

const getApproachDetail = (tsCode: string) => async (dispatch: any) => {
  let data;
  try {
    const result = await trainingModeRecord.getApproachDetail(tsCode);
    if (result.code === EnumRequestCode.FAILED) {
      throw new Error('get approach failed');
    }
    data = result.entities;
  } catch (error) {
  } finally {
    dispatch(
      trainingModeDetail.update({
        loadingApproach: false,
        approach: data,
      }),
    );
  }
};

const getPuttingDetail = (tsCode: string) => async (dispatch: any) => {
  let data;
  try {
    const result = await trainingModeRecord.getPuttingDetail(tsCode);
    if (result.code === EnumRequestCode.FAILED) {
      throw new Error('get putting failed');
    }
    data = result.entities;
  } catch (error) {
  } finally {
    dispatch(
      trainingModeDetail.update({
        loadingPutting: false,
        putting: data,
      }),
    );
  }
};

const getSwingVideoDetail = (tsCode: string) => async (dispatch: any) => {
  let data;
  try {
    const result = await trainingModeRecord.getSwingVideoDetail(tsCode);
    if (result.code === EnumRequestCode.FAILED) {
      throw new Error('get swing video failed');
    }
    data = result.entities;
  } catch (error) {
  } finally {
    dispatch(
      trainingModeDetail.update({
        loadingSwingVideo: false,
        swingVideo: data,
      }),
    );
  }
};

export const trainingModeDetailActions = {
  getDrivingRangeDetail,
  getApproachDetail,
  getPuttingDetail,
  getSwingVideoDetail,
};

import axios from 'src/requests';
import { IDetailID, IPageRowParam, IParamDateRequest } from 'src/requests/api/common/prop-state.type';
import {
  ICompareInfoRequest,
  IPracticeClubDetailRequest,
  ISearchDateRequest,
} from 'src/requests/api/record/prop-state.type';

const getDrivingClubRequest = async (params?: IParamDateRequest) => {
  const repsonse = await axios.get('/gdr/getDrivingClublist', {
    params,
  });
  return repsonse.data.data;
};

const getMiniRoundlistRequest = async (params?: IPageRowParam) => {
  const repsonse = await axios.get('/gdr/getMiniRoundlist', {
    params,
  });
  return repsonse.data.data;
};

const getDetailVideo = async (params?: IDetailID) => {
  const repsonse = await axios.get('/gdr/getNasmoDetailInfo', {
    params,
  });
  return repsonse.data.data;
};

const getListNasmo = async (params?: IPageRowParam) => {
  const repsonse = await axios.get('/gdr/getNasmolist', {
    params,
  });
  return repsonse.data.data;
};

const deleteVideo = async (body?: IDetailID) => {
  const repsonse = await axios.post('/gdr/deleteMyNasmo', body);
  return repsonse.data.data;
};

const getMiniRoundDetailInfoRequest = async (params?: IDetailID) => {
  const repsonse = await axios.get('/gdr/getMiniRoundDetailInfo', {
    params,
  });
  return repsonse.data.data;
};

const getMiniRoundCompareInfoRequest = async (params?: ICompareInfoRequest) => {
  const repsonse = await axios.get('/gdr/getMiniRoundCompareInfo', {
    params,
  });
  return repsonse.data.data;
};

const getPracticeClubGroup = async (params?: ISearchDateRequest) => {
  const repsonse = await axios.get('/gdr/getPracticeClubGroup', {
    params,
  });
  return repsonse.data.data;
};

const getPracticeClubDetailInfo = async (params?: IPracticeClubDetailRequest) => {
  const repsonse = await axios.get('/gdr/getPracticeClubDetailInfo', {
    params,
  });
  return repsonse.data.data;
};

export const listGDR = {
  getDrivingClubRequest,
  getMiniRoundlistRequest,
  getDetailVideo,
  getListNasmo,
  deleteVideo,
  getMiniRoundDetailInfoRequest,
  getMiniRoundCompareInfoRequest,
  getPracticeClubGroup,
  getPracticeClubDetailInfo,
};

import axios from 'src/requests';
import { IPageRowParam } from 'src/requests/api/common/prop-state.type';

const getPar3ChallengeLeaderboard = async (params?: IPageRowParam) => {
  const response = await axios.get('gs/getPar3ChallengeLeaderboard', {
    params,
  });
  return response.data.data;
};

const getPar3ChallengePlayedRoundNum = async (params?: IPageRowParam) => {
  const response = await axios.get('gs/getPar3ChallengePlayedRoundNum', {
    params,
  });
  return response.data.data;
};

export const nxRequests = {
  getPar3ChallengeLeaderboard,
  getPar3ChallengePlayedRoundNum,
};

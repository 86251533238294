import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EnumTypeTableRanking } from 'src/constants/enum';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import { profileRequests } from 'src/requests/api/profile';
import { IDataRankingDetailResponse, IStoreRankingResponse } from 'src/requests/api/profile/prop-state.type';
import { RootState } from 'src/store';
import Filter from 'src/view/pages/profile/ranking-detail/common/Filter';
import RankingTable from 'src/view/pages/profile/ranking-detail/common/RankingTable';
import { useTranslation } from 'react-i18next';
import { notificationActions } from 'src/store/notification';
import { NotificationType } from 'src/models';
import { twMerge } from 'tailwind-merge';

interface IProps {
  type: EnumTypeTableRanking;
}

export default function RankingDetailPage({ type }: IProps) {
  const translate = useTranslation().t;

  const userInfo = useSelector<RootState, IMyInfoResponse | null>((state) => state.mainInfoReducer.userInfo);
  const dispatch = useDispatch();

  const [dataRanking, setRanking] = useState<IDataRankingDetailResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (!!filter && !!Object.keys(filter).length) {
        try {
          setLoading(true);
          const data = await fetchDataByType();
          setRanking(
            (type === EnumTypeTableRanking.STORE
              ? data?.entities?.map((item: IStoreRankingResponse) => convertFormat(item))
              : data?.entities) || [],
          );
        } catch {
          dispatch(notificationActions.addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [filter]);

  const fetchDataByType = () => {
    const params = { ...filter, usrNo: userInfo?.usrNo };
    if (type === EnumTypeTableRanking.SUB_COUNTRY) return profileRequests.getDataSubCountryRankingDetail(params);
    if (type === EnumTypeTableRanking.COUNTRY) return profileRequests.getDataCountryRankingDetail(params);
    if (type === EnumTypeTableRanking.GLOBAL) return profileRequests.getDataGlobalRankingDetail(params);
    if (type === EnumTypeTableRanking.LEVEL) return profileRequests.getDataLevelRankingDetail(params);
    if (type === EnumTypeTableRanking.STORE) return profileRequests.getDataStoreRankingDetail(filter);
    return;
  };

  const isHasMyRanking = useMemo(() => dataRanking?.[0]?.usrNo === Number(userInfo?.usrNo), [dataRanking, userInfo]);

  const onChangeFilter = (value: any) => {
    setFilter(value);
  };

  function convertFormat(original: IStoreRankingResponse): IDataRankingDetailResponse {
    return {
      id: original?.id,
      usrNickName: original?.nickname,
      usrNickImage: original?.nickImage,
      usrCountry: original?.country,
      sex: original?.gender,
      gradeCode: original?.gradeCode,
      rankChange: original?.rankLevelChange,
      levelChangeStatus: original?.levelChangeStatus,
      cityRank: original?.rankOrder,
      ugGHandy: original?.rankingScore,
      usrNo: original?.usrNo,
      gradetcode: original?.gradeCode?.trim(),
    };
  }

  return (
    <div className={twMerge(!isHasMyRanking && 'pb-[85px]')}>
      <Filter type={type} onChangeFilter={onChangeFilter} isHasMyRanking={!!isHasMyRanking} loading={loading} />
      <RankingTable type={type} data={dataRanking} filter={filter} isHasMyRanking={isHasMyRanking} loading={loading} />
    </div>
  );
}

export const searchParamsContants = {
  REDIRECT_URL: 'redirect_url',
  IDX: 'idx',
  PREV_IDX: 'prev_idx',
  PAGE_REFERRER: 'page_referrer',
  THEME_MODE: 'theme',
  LANGUAGE: 'lang',
  REDIRECT_TYPE: 'redirect_type',
  NO_HEADER: 'no_header',
  SESSION_ID: 'sessionId',
};

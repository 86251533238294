import React, { CSSProperties, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { noImage } from 'src/assets/images';
import { twMerge } from 'tailwind-merge';

interface IImageCustomProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  className?: string;
  imgPath?: string;
  imgAlt?: string;
  imgNotFound?: string;
  emptyTag?: boolean;
  style?: CSSProperties;
}

const ImageCustom: React.FC<IImageCustomProps> = React.memo(
  ({ className, imgPath, imgAlt, imgNotFound, emptyTag, ...rest }) => {
    const [srcPic, setSrcPic] = useState<string>('invalid-link');
    const [notTag, setNotTag] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (imgPath) {
        setSrcPic(imgPath);
      }
    }, [imgPath]);

    const handleOnError = ({ currentTarget }: any) => {
      currentTarget.onerror = null; // prevents looping
      if (emptyTag && !isMobile) setNotTag(true); // bug safari mobile sometimes call onError
      currentTarget.src = imgNotFound ? imgNotFound : noImage;
    };

    const imageLoaded = () => {
      setLoading(false);
    };

    const imageLoader = () => {
      return (
        <div className={twMerge(className, 'animate-pulse bg-disable', loading ? '' : '!hidden')}>
          <div className={twMerge(className, 'flex h-full w-full items-center justify-center bg-disable')} />
        </div>
      );
    };

    const image = () => {
      if (imgPath) {
        return (
          <img
            className={twMerge(!loading ? '' : '!hidden', className)}
            src={srcPic}
            onError={handleOnError}
            alt={imgAlt ? imgAlt : 'picture'}
            {...rest}
            onLoad={imageLoaded}
          />
        );
      }
      return (
        <img
          className={twMerge(!loading ? '' : '!hidden', className)}
          src={imgNotFound ? imgNotFound : noImage}
          alt="no_picture"
          {...rest}
          onLoad={imageLoaded}
        />
      );
    };

    if (notTag) {
      return <></>;
    }

    return (
      <>
        {imageLoader()}
        {image()}
      </>
    );
  },
);

export default ImageCustom;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LOCAL_STORAGE_IS_NATIVE_APP } from 'src/constants/const';
import { firebaseInstance } from 'src/init-firebase';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';

export function useFirebase() {
  const userInfo: IMyInfoResponse | null = useSelector((state: any) => state.mainInfoReducer.userInfo);

  useEffect(() => {
    const isNativeApp = !!localStorage.getItem(LOCAL_STORAGE_IS_NATIVE_APP);
    // Do NOT initialize firebase when it's the mobile app or undefined
    if (!isNativeApp) {
      firebaseInstance.initialize();
    } else {
      // add smart login log
      firebaseInstance.initialFireStoreForNativeApp();
    }
  }, []);

  useEffect(() => {
    if (userInfo?.usrNo != null) {
      firebaseInstance.setUser(userInfo.usrNo.toString());
    }
  }, [userInfo?.usrNo]);
}

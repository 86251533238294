import React from 'react';
import { history } from 'src/services/history';
import { IGDRMiniRoundResponse } from 'src/requests/api/record/prop-state.type';
import RoundRecordInformation from 'src/view/pages/record/commons/RoundRecordInformation';
import { pathConstants } from 'src/constants/const';

interface IDrivingItemProps {
  item?: IGDRMiniRoundResponse;
}
const ItemPractice: React.FC<IDrivingItemProps> = React.memo(({ item }) => {
  return (
    <>
      {item && (
        <div onClick={() => history.push(pathConstants.RECORD_DETAIL_GDR_PRACTICE(item.tmCode))}>
          <RoundRecordInformation
            recordScore={{
              ccName: item.ccName,
              playMode: item.playMode,
              holeCnt: item.playHole,
              totalHoleCnt: item.totalHole,
              score: item.score,
              tags: item.tags,
              tagsCnt: item.tagsCnt,
              totalScore: item.totalScore,
              matchTotal: item.matchTotal,
            }}
          />
        </div>
      )}
    </>
  );
});

export default ItemPractice;

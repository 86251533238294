import { ITrendingStore } from 'src/requests/api/home/prop-state.type';
import { GET_TRENDING_STORES, RESET_TRENDING_STORES } from 'src/store/trending-stores/action';

export interface ITrendingStoresState {
  data: ITrendingStore[];
  loading: boolean;
  loaded: boolean;
}

const initialState: ITrendingStoresState = {
  data: [],
  loading: true,
  loaded: false,
};

export function trendingStoresReducer(state: ITrendingStoresState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case GET_TRENDING_STORES:
      return {
        ...state,
        ...payload,
      };
    case RESET_TRENDING_STORES:
      return initialState;
    default:
      return state;
  }
}

import { useTranslation } from 'react-i18next';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { IconAngleLeft } from 'src/assets/svg';
import { twMerge } from 'tailwind-merge';
import { ICountry, ICountryGroup } from 'src/requests/api/account/prop-state.type';
import { getCountries } from 'src/constants/const/countries.constants';
import { isMobile } from 'react-device-detect';

interface CountryCodeModalProps {
  isPage?: boolean;
  isModalOpened?: boolean;
  classWrapper?: string;
  defaultCode?: string;
  onClose: () => void;
  onChange: (country: ICountry) => void;
  onAnchorClick?: () => void;
}

function CountrySelection({
  isPage,
  isModalOpened = false,
  classWrapper,
  defaultCode = 'USA',
  onClose,
  onChange,
  onAnchorClick,
}: CountryCodeModalProps): JSX.Element {
  const translate = useTranslation().t;
  const [selectedCode, setSelectedCode] = useState(defaultCode);
  const alphabet = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  const countries: ICountryGroup[] = useMemo(() => getCountries(translate), [translate]);

  // Scroll to selected country after open modal or page
  useEffect(() => {
    if (isModalOpened || isPage) {
      const elementToView = document.getElementById(`country-${selectedCode}`);
      elementToView?.scrollIntoView({ block: 'center', inline: 'start' });
    }
  }, [isModalOpened, isPage]);

  const onClickAnChorLink = (e: React.MouseEvent<HTMLElement>, item: string) => {
    e && e.preventDefault();
    const elementToView = document.getElementById(`group-country-${item}`);
    elementToView?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    onAnchorClick?.call(null);
  };

  const changeCountry = (country: ICountry) => {
    onChange(country);
    setSelectedCode(country.countryCd);
  };

  return (
    <>
      {!isPage ? (
        <div className="flex h-[44px] items-center border-b border-bg-disable">
          <div className="ml-2 cursor-pointer rounded-[50%] p-2 active:bg-light-gray" onClick={onClose}>
            <IconAngleLeft className="h-4 w-4 stroke-title" />
          </div>
          <div className="gz-text-xl ml-2 mt-[3px] font-medium text-title">
            {translate('commons.customs.national_selection.TITLE')}
          </div>
        </div>
      ) : null}
      <div className={twMerge('relative h-[calc(100vh_-_44px)]', classWrapper)}>
        <div className="scroll-hidden h-full overflow-auto">
          {countries.map((item: ICountryGroup, index: number) => (
            <Fragment key={`country-group-${index}`}>
              <div id={`group-country-${item.idGroup}`} />
              {item.idGroup && (
                <div className="gz-text-xs sticky top-0 bg-gray-10 px-6 py-1.5 font-bold text-sub-body">
                  <span className="mx-2">{item.idGroup}</span>
                </div>
              )}
              {item.valueGroup.map((country: ICountry, subindex: number) => (
                <div
                  key={country.countryCd}
                  id={`country-${country.countryCd}`}
                  className={twMerge(
                    'flex cursor-pointer items-center justify-between px-6 ',
                    isMobile ? 'active:bg-secondary-20' : 'hover:bg-secondary-20',
                    subindex < countries.length - 1 ? 'border-b border-bg-disable' : '',
                    country.countryCd === selectedCode ? 'bg-secondary-20' : '',
                  )}
                  onClick={() => changeCountry(country)}
                >
                  <div className="flex items-center py-[0.75rem]">
                    {country.flag && (
                      <img src={country.flag} className="h-[1.5rem] w-[1.5rem]" alt={country.countryNm} />
                    )}
                    <div className={twMerge('gz-text-xs ml-2', !item.idGroup && 'font-bold')}>{country.countryNm}</div>
                  </div>
                  <div className="gz-text-xs pr-6">{country.countryNum}</div>
                </div>
              ))}
            </Fragment>
          ))}
        </div>
        <div className="absolute right-0 top-0 flex h-full flex-col justify-between bg-light-gray-25">
          {alphabet.map((item: string, index: number) => {
            const isAvailableGroup = countries.findIndex((country) => country.idGroup === item) > -1;
            return (
              <div
                key={`anchor-${index}`}
                className={twMerge(
                  'flex h-full items-center justify-center',
                  'outline-visible px-2.5 font-medium text-white',
                  'border-b-[1px] border-solid border-white  text-[13.5px]',
                  isMobile ? 'active:no-underline' : 'hover:no-underline',
                  isAvailableGroup ? 'cursor-pointer bg-text-disable' : 'bg-light-gray-20',
                )}
                onClick={(e) => isAvailableGroup && onClickAnChorLink(e, item)}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default CountrySelection;

import { IGroupTreeView } from 'src/view/commons/customs/TreeViewSelection';
import { COURSE_ACTION_TYPES } from './action';
import {
  CourseInfo,
  FavoriteInformation,
  InfoListCourseResponse,
  IFilterListCourse,
} from 'src/requests/api/course/prop-state.type';

export interface CourseState {
  courseList: InfoListCourseResponse;
  filterListCourse: IFilterListCourse;
  searchList: Array<CourseInfo>;
  listCourse: Array<CourseInfo>;
  searchTotalCount: number;
  offsetCourseListRecord: number;
  continentList: IGroupTreeView[];
  favoriteInfo: FavoriteInformation;
  loadingCourseList: boolean;
  isLastCourse: boolean;
  loadingSearchList: boolean;
  loadingFavorite: boolean;
}

export const initialState: CourseState = {
  isLastCourse: false,
  loadingCourseList: true,
  loadingSearchList: false,
  loadingFavorite: false,
  courseList: {
    newCourseList: [],
    loadingNewCourseList: true,
    roundCourseList: [],
    totalRoundCourse: 0,
    recommendCourseList: [],
  },
  filterListCourse: {
    countryCode: [],
    page: 1,
    versionSelected: [],
    searchTxt: '',
    totalCourse: 0,
  },
  searchList: [],
  listCourse: [],
  searchTotalCount: 0,
  offsetCourseListRecord: 0,
  continentList: [],
  favoriteInfo: {
    listFavorite: [],
    total: 0,
  },
};

export function courseReducer(state: CourseState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case COURSE_ACTION_TYPES.COURSE_LIST: {
      return {
        ...state,
        listCourse: action.payload.data,
      };
    }
    case COURSE_ACTION_TYPES.COURSE_FILTERS: {
      return {
        ...state,
        filterListCourse: action.payload.data,
      };
    }
    case COURSE_ACTION_TYPES.IS_LAST: {
      return {
        ...state,
        isLastCourse: action.payload.data,
      };
    }
    case COURSE_ACTION_TYPES.COURSE_LIST_OFFSET: {
      return {
        ...state,
        offsetCourseListRecord: action.payload.data,
      };
    }
    case COURSE_ACTION_TYPES.GET_INFO_LIST_REQUEST: {
      return {
        ...state,
        loadingCourseList: true,
      };
    }
    case COURSE_ACTION_TYPES.GET_INFO_LIST_FAILED: {
      return {
        ...state,
        loadingCourseList: false,
      };
    }
    case COURSE_ACTION_TYPES.GET_INFO_LIST_SUCCESS: {
      return {
        ...state,
        courseList: { ...state.courseList, ...payload.courseList },
        loadingCourseList: payload.loading ?? state.loadingCourseList,
      };
    }

    case COURSE_ACTION_TYPES.GET_SEARCH_LIST_REQUEST: {
      return {
        ...state,
        loadingSearchList: true,
      };
    }
    case COURSE_ACTION_TYPES.GET_SEARCH_LIST_FAILED: {
      return {
        ...state,
        loadingSearchList: false,
      };
    }
    case COURSE_ACTION_TYPES.GET_SEARCH_LIST_SUCCESS: {
      return {
        ...state,
        searchList: payload.searchList,
        searchTotalCount: payload.totalCount,
        loadingSearchList: false,
      };
    }
    case COURSE_ACTION_TYPES.INSERT_CONTINENT_INTO_LIST: {
      return {
        ...state,
        continentList: [...state.continentList, ...payload.continentList],
      };
    }
    case COURSE_ACTION_TYPES.UPDATE_CONTINENT_LIST: {
      return {
        ...state,
        continentList: [...payload.continentList],
      };
    }
    case COURSE_ACTION_TYPES.GET_FAVORITE_LIST_REQUEST: {
      return {
        ...state,
        loadingFavorite: true,
      };
    }
    case COURSE_ACTION_TYPES.GET_FAVORITE_LIST_FAILED: {
      return {
        ...state,
        loadingFavorite: false,
      };
    }
    case COURSE_ACTION_TYPES.GET_FAVORITE_LIST_SUCCESS: {
      return {
        ...state,
        favoriteInfo: payload.favoriteInfo,
        loadingFavorite: false,
      };
    }
    case COURSE_ACTION_TYPES.RESET_COURSE_LIST: {
      return initialState;
    }
    default:
      return state;
  }
}

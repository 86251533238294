import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useCallRequestForGuest = (
  callback: (isLoggedIn: boolean, countryCd: string, distUnit: string) => void,
  loading?: boolean,
) => {
  const settingInfo = useSelector((state: any) => state.settingInfoReducer.settingInfo);
  const isLoggedIn = useSelector((state: any) => state.authentication.isLoggedIn);

  useEffect(() => {
    if (!loading) return;
    if (!isLoggedIn && (!settingInfo.countryCd || !settingInfo.distUnit)) return;
    callback.call(null, isLoggedIn, settingInfo.countryCd, settingInfo.distUnit);
  }, [settingInfo.countryCd, settingInfo.distUnit, loading]);
};

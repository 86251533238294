import { EnumRequestCode, EnumVerifyTokenCode } from 'src/constants/enum';
import { loginMiddleware } from 'src/requests/api/account/login';
import { store } from 'src/store';
import { authActions } from 'src/store/account/login';

export const verifyToken = async () => {
  const verifyRes = await loginMiddleware.verifySession();
  const tokenIsAlive = verifyRes?.code === EnumRequestCode.SUCCESS && verifyRes?.status === EnumVerifyTokenCode.SUCCESS;
  if (!tokenIsAlive) {
    store.dispatch(authActions.logout());
  }
};

import { VERIFY_EMAIL, VERIFY_USERNAME } from 'src/store/account/signup/action';

export interface SignupState {
  issetUserName: boolean;
  issetUserEmail: boolean;
}

const initialState: SignupState = {
  issetUserName: false,
  issetUserEmail: false,
};

export function registration(state: SignupState = initialState, action: any) {
  switch (action.type) {
    case VERIFY_USERNAME:
      return {
        ...state,
        issetUserName: action.payload.isIsset,
      };
    case VERIFY_EMAIL:
      return {
        ...state,
        issetUserEmail: action.payload.isIsset,
      };
    default:
      return state;
  }
}

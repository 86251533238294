import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { DARK_MODE_ATTRIBUTE, DARK_MODE_ATTRIBUTE_NAME, searchParamsContants } from 'src/constants/const';
import { globalActions } from 'src/store/global';

interface Props {
  children?: JSX.Element;
}

function EmbeddedLayout({ children }: Props): JSX.Element {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const themeParam = params.get(searchParamsContants.THEME_MODE);

  useLayoutEffect(() => {
    if (themeParam === DARK_MODE_ATTRIBUTE) {
      document.documentElement.setAttribute(DARK_MODE_ATTRIBUTE_NAME, DARK_MODE_ATTRIBUTE);
    }
    updateSize();
    setTimeout(() => updateSize(), 500);
    window.addEventListener('resize', updateSize);
    return () => {
      if (themeParam === DARK_MODE_ATTRIBUTE) {
        document.documentElement.removeAttribute(DARK_MODE_ATTRIBUTE_NAME);
      }
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  const updateSize = () => {
    const mainElement = document.getElementById('main');
    dispatch(globalActions.updateMainWidth(mainElement?.offsetWidth ?? 'auto'));
  };

  return (
    <div>
      <div className="min-h-screen dark:bg-black">
        <div id="main" className="mx-auto max-w-[1024px]">
          {children}
        </div>
      </div>
    </div>
  );
}

export default EmbeddedLayout;

import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { IObjectData } from 'src/requests/api/common/prop-state.type';
import InfiniteScrollWrapper from 'src/view/commons/customs/InfiniteScroll';
import Timeline from 'src/view/commons/customs/Timeline';

interface IDrivingItemProps {
  children: JSX.Element;
  fieldDate: string;
  dataObject: IObjectData<any[]>;
  lastPage?: boolean;
  loading?: boolean;
  fetchData?: (value: boolean) => void;
}
const ListGroupMonth: React.FC<IDrivingItemProps> = React.memo(
  ({ children, fieldDate, dataObject, lastPage, loading, fetchData }) => {
    const translate = useTranslation().t;

    return (
      <>
        <InfiniteScrollWrapper loadMore={fetchData} isLastPage={lastPage} loading={loading}>
          <>
            {dataObject.keySort?.map((month: string, idx: number) => {
              const dateInfo = month.split('-');
              return (
                <div key={`month-group-${idx}`} className="mb-[8px] [&:not(:last-child)]:mb-[24px]">
                  <h3 className="gz-text-xl mb-[24px] font-bold">
                    {translate('date.TEXT_YEAR_AND_MONTH', {
                      year: dateInfo[0],
                      month: `${translate('date.month.' + dateInfo[1])}`,
                    })}
                  </h3>
                  {dataObject?.values[month].map((item: any, index: number) => {
                    const date = dayjs(item[fieldDate]).get('date');
                    const day = dayjs(item[fieldDate]).get('day');
                    return (
                      <Timeline key={`list-group-${index}`}>
                        <Timeline.Item className="-ml-[14px]">
                          <Timeline.Time day={translate(`date.${day}`)} date={date} />
                          <Timeline.Content>{React.cloneElement(children, { item: item })}</Timeline.Content>
                        </Timeline.Item>
                      </Timeline>
                    );
                  })}
                </div>
              );
            })}
          </>
        </InfiniteScrollWrapper>
      </>
    );
  },
);

export default ListGroupMonth;

import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { pathConstants, searchParamsContants } from 'src/constants/const';
import { history } from 'src/services/history';

/* 
  When users go to Guest route (Login, Signup, Find ID,...), check sessionId in redux before
  If sessionId null -> stay current page
     sessionId exists -> redirect Main page
*/
function GuestGuard({ children }: { children?: JSX.Element }): JSX.Element {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state: any) => state.authentication.isLoggedIn);
  const needUpdatePhone = useSelector((state: any) => state.authentication.needUpdatePhone);
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get(searchParamsContants.REDIRECT_URL);
  const idx = searchParams.get(searchParamsContants.IDX) as any;
  const prevIdx = searchParams.get(searchParamsContants.PREV_IDX) as any;

  useEffect(() => {
    // Redirect to Update Phone Number page if it hasn't been registered
    if (isLoggedIn && needUpdatePhone) {
      if (idx != null) {
        history.replaceWithSearch(pathConstants.UPDATE_NUMBER, undefined, {
          additionalParams: { prev_idx: idx - 1 },
        });
      } else {
        history.replaceWithSearch(pathConstants.UPDATE_NUMBER);
      }
      return;
    }
    if (isLoggedIn && prevIdx != null && window.history?.state?.idx != null && window.history.state.idx > prevIdx) {
      navigate(prevIdx - window.history.state.idx);
      return;
    }
    if (isLoggedIn && idx != null && window.history?.state?.idx != null && window.history.state.idx > idx) {
      navigate(idx - window.history.state.idx);
      return;
    }
    // Redirect to home page or url that defined in search params if it hasn't been registered
    if (isLoggedIn) {
      history.replace(redirectUrl ?? pathConstants.HOME_PAGE);
      return;
    }
  }, [isLoggedIn, needUpdatePhone]);

  return children ?? <Fragment />;
}

export default GuestGuard;

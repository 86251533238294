import { lazy } from 'react';
import { pathConstants } from 'src/constants/const';
import { IComponentRouteGroup } from 'src/models/route';
import GlobalLayout from 'src/view/layouts/global/Layout';
import LeaderBoardPar3 from 'src/view/pages/nx/leaderboard-par-3';
import PlusItemsPage from 'src/view/pages/plus-items';
import MyTrainingDetailHeader from 'src/view/pages/record/detail/training-mode/commons/Header';

const WithDrawSetting = lazy(() => import('src/view/pages/setting/withdraw'));
const EditProfile = lazy(() => import('src/view/pages/setting/user-profile'));
const ChangePassword = lazy(() => import('src/view/pages/setting/password'));
const ChangeEmail = lazy(() => import('src/view/pages/setting/email'));
const FeedScoreCard = lazy(() => import('src/view/pages/feed/post-feeds/score-card'));
const FeedGsSwing = lazy(() => import('src/view/pages/feed/post-feeds/swing-video/GsSwingVideoFeed'));
const FeedGdrSwing = lazy(() => import('src/view/pages/feed/post-feeds/swing-video/GdrSwingVideoFeed'));
const FeedTmSwing = lazy(() => import('src/view/pages/feed/post-feeds/swing-video/TmSwingVideoFeed'));
const CreateFeeds = lazy(() => import('src/view/pages/feed/create-edit/create'));
const CreateTrainingModeFeed = lazy(() => import('src/view/pages/feed/create-edit/create-training-mode'));
const EditFeeds = lazy(() => import('src/view/pages/feed/create-edit/edit'));
const ProfilePage = lazy(() => import('src/view/pages/profile/about'));
const AllLevelTagsPage = lazy(() => import('src/view/pages/profile/all-level-tags'));
const UserFeedPage = lazy(() => import('src/view/pages/profile/feed-list'));
const IndividualFeedDetailPage = lazy(() => import('src/view/pages/feed/individual-feed-detail'));
const PartnerRoundComparisonPage = lazy(() => import('src/view/pages/profile/partner-round-comparison'));
const DetailRecordGDR = lazy(() => import('src/view/pages/record/detail/GDR/driving'));
const DetailRecordPracticeGDR = lazy(() => import('src/view/pages/record/detail/GDR/practice'));
const DetailRecordGS = lazy(() => import('src/view/pages/record/detail/GS'));
const TmDrivingRangeDetail = lazy(() => import('src/view/pages/record/detail/training-mode/driving-range'));
const TmApproachDetail = lazy(() => import('src/view/pages/record/detail/training-mode/approach'));
const TmPuttingDetail = lazy(() => import('src/view/pages/record/detail/training-mode/putting'));
const TmSwingVideosDetail = lazy(() => import('src/view/pages/record/detail/training-mode/swing-videos'));
const ScoreCardGS = lazy(() => import('src/view/pages/record/score-card'));
const AllParticipatedTournamentsPage = lazy(
  () => import('src/view/pages/events/all-participated-events/ParticipatedTournaments'),
);
const AllParticipatedLeaguesPage = lazy(
  () => import('src/view/pages/events/all-participated-events/ParticipatedLeagues'),
);
const ListFavoriteCourse = lazy(() => import('src/view/pages/course/favorite'));
const ListMyCourse = lazy(() => import('src/view/pages/course/my-course'));
const FavouriteStoresPage = lazy(() => import('src/view/pages/store-news/favourite-stores'));
const ScreenSettingsPage = lazy(() => import('src/view/pages/setting/screen-settings'));
const AIAnalysis = lazy(() => import('src/view/pages/AI/analysis'));
const AIVideo = lazy(() => import('src/view/pages/AI/video'));

export const authRoutesGroup: IComponentRouteGroup[] = [
  {
    layout: <GlobalLayout showMobileSidebar={false} />,
    components: [
      { path: pathConstants.FAVORITE_COURSE, component: <ListFavoriteCourse /> },
      { path: pathConstants.MY_ROUND_COURSE, component: <ListMyCourse /> },
      { path: pathConstants.FAVOURITE_STORES, component: <FavouriteStoresPage /> },
      { path: pathConstants.FEED_CREATE_ITEM(), component: <CreateFeeds /> },
      { path: pathConstants.FEED_TRAINING_MODE_CREATE, component: <CreateTrainingModeFeed /> },
      { path: pathConstants.FEED_DETAIL_EDIT(), component: <EditFeeds /> },
      { path: pathConstants.FEED_SCORECARD, component: <FeedScoreCard /> },
      { path: pathConstants.FEED_GS_SWING, component: <FeedGsSwing /> },
      { path: pathConstants.FEED_GDR_SWING, component: <FeedGdrSwing /> },
      { path: pathConstants.FEED_TM_SWING, component: <FeedTmSwing /> },
      { path: pathConstants.PROFILE(), component: <ProfilePage /> },
      { path: pathConstants.PROFILE_LEVEL_TAGS(), component: <AllLevelTagsPage /> },
      { path: pathConstants.PROFILE_FEED(), component: <UserFeedPage /> },
      { path: pathConstants.PROFILE_FEED_DETAIL(), component: <IndividualFeedDetailPage /> },
      { path: pathConstants.PROFILE_PARTNER_ROUND_COMPARISON(), component: <PartnerRoundComparisonPage /> },
      { path: pathConstants.RECORD_DETAIL_GS(), component: <DetailRecordGS /> },
      { path: pathConstants.RECORD_SCORE_CARD_GS(), component: <ScoreCardGS /> },
      { path: pathConstants.RECORD_DETAIL_GDR_DRIVING(), component: <DetailRecordGDR /> },
      { path: pathConstants.RECORD_DETAIL_GDR_PRACTICE(), component: <DetailRecordPracticeGDR /> },
      { path: pathConstants.RECORD_SWING_AI_ANALYSIS(), component: <AIAnalysis /> },
      { path: pathConstants.RECORD_SWING_AI_ANALYSIS_DETAIL(), component: <AIVideo /> },
      { path: pathConstants.ALL_PARTICIPATED_TOURNAMENTS, component: <AllParticipatedTournamentsPage /> },
      { path: pathConstants.ALL_PARTICIPATED_TOURNAMENTS_ENDED, component: <AllParticipatedTournamentsPage /> },
      { path: pathConstants.ALL_PARTICIPATED_LEAGUES, component: <AllParticipatedLeaguesPage /> },
      { path: pathConstants.ALL_PARTICIPATED_LEAGUES_ENDED, component: <AllParticipatedLeaguesPage /> },
      { path: pathConstants.SETTING_EDIT_PROFILE, component: <EditProfile /> },
      { path: pathConstants.SETTING_CHANGE_PASSWORD, component: <ChangePassword /> },
      { path: pathConstants.SETTING_CHANGE_EMAIL, component: <ChangeEmail /> },
      { path: pathConstants.SETTING_WITH_DRAW, component: <WithDrawSetting /> },
      { path: pathConstants.PLUS_ITEMS(), component: <PlusItemsPage /> },
      { path: pathConstants.SETTING_SCREEN_SETTINGS, component: <ScreenSettingsPage /> },
      { path: pathConstants.LEADER_BOARD_PAR_3, component: <LeaderBoardPar3 /> },
    ],
  },
  {
    layout: <GlobalLayout showMobileSidebar={false} />,
    header: <MyTrainingDetailHeader />,
    components: [
      { path: pathConstants.RECORD_DETAIL_TM_DRIVING_RANGE(), component: <TmDrivingRangeDetail /> },
      { path: pathConstants.RECORD_DETAIL_TM_APPROACH(), component: <TmApproachDetail /> },
      { path: pathConstants.RECORD_DETAIL_TM_PUTTING(), component: <TmPuttingDetail /> },
      { path: pathConstants.RECORD_DETAIL_TM_SWING_VIDEOS(), component: <TmSwingVideosDetail /> },
    ],
  },
];

import { EnumTopTenPlayersType } from 'src/constants/enum/tournament.enum';
import axios from 'src/requests';
import { IDetailID, IPageRowParam } from 'src/requests/api/common/prop-state.type';
import { ICompareInfoRequest, IGetRoundShotRequest } from 'src/requests/api/record/prop-state.type';

const getScoreList = async (params?: IPageRowParam) => {
  const repsonse = await axios.get('/gs/getScoreList', {
    params,
  });
  return repsonse.data.data;
};

const getNasmoClubList = async (params?: IPageRowParam) => {
  const repsonse = await axios.get('gs/getSwingVideos', {
    params,
  });
  return repsonse.data.data;
};

const deleteNasmoClub = async (body: IDetailID) => {
  const repsonse = await axios.post('/gs/deleteMyNasmo', body);
  return repsonse.data.data;
};

const getRoundDetail = async (params: IDetailID) => {
  const repsonse = await axios.get('/gs/getRoundDetail', {
    params,
  });
  return repsonse.data.data;
};

const getRoundReport = async (params: IDetailID) => {
  const repsonse = await axios.get('/gs/getRoundReport', {
    params,
  });
  return repsonse.data.data;
};

const getRoundCompareInfo = async (params: ICompareInfoRequest) => {
  const repsonse = await axios.get('/gs/getRoundCompareInfo', {
    params,
  });
  return repsonse.data.data;
};

const getRoundShotGroup = async (params?: IGetRoundShotRequest) => {
  const response = await axios.get('/gs/getRoundShotGroup', {
    params,
  });
  return response.data.data;
};

/*
  "usrNo" will be deleted from API in the future
  This param be used to test
*/
const getScoreCard = async (params: IDetailID, usrNo?: string) => {
  const repsonse = await axios.get('/gs/getScoreCard', {
    params: {
      ...params,
      usrNo, // Delete this param in the future
    },
  });
  return repsonse.data;
};

const getTournamentNewPerioScoreCard = async (detailNo: number, usrNo?: number) => {
  const repsonse = await axios.get('/gs/getScoreCard/newperio', {
    params: {
      detailNo,
      usrNo,
    },
  });
  return repsonse.data.data;
};

const getNetworkPlayRoomInfo = async (params: IDetailID) => {
  const repsonse = await axios.get('/gs/getNetworkPlayRoomInfo', {
    params,
  });
  return repsonse.data.data;
};

const getNetworkPlayRanking = async (params: IDetailID) => {
  const repsonse = await axios.get('/gs/getNetworkPlayRanking', {
    params,
  });
  return repsonse.data.data;
};

const getNetworkPlayBestScore = async (params: IDetailID) => {
  const repsonse = await axios.get('/gs/getNetworkPlayBestScore', {
    params,
  });
  return repsonse.data.data;
};

const getNetworkPlayTop10ScoreList = async (params: IDetailID & { orderType: EnumTopTenPlayersType }) => {
  const repsonse = await axios.get('/gs/getNetworkPlayTop10ScoreList', {
    params,
  });
  return repsonse.data.data;
};

const getNetworkPlayTeamLongLeaderboard = async (params: IDetailID) => {
  const repsonse = await axios.get('/gs/getTeamLongLeaderBoard', {
    params,
  });
  return repsonse.data.data;
};

const getNetworkPlayTeamNearLeaderboard = async (params: IDetailID) => {
  const repsonse = await axios.get('/gs/getTeamNearLeaderBoard', {
    params,
  });
  return repsonse.data.data;
};

const getNetworkPlayTeamStrokeLeaderboard = async (params: IDetailID) => {
  const repsonse = await axios.get('/gs/getTeamStrokeLeaderBoard', {
    params,
  });
  return repsonse.data.data;
};

const getNetworkPlayTeamStrokeMemberRanking = async (params: IDetailID & { dataNo: number }) => {
  const repsonse = await axios.get('/gs/getNetworkPlayTeamStrokeMemberRanking', {
    params,
  });
  return repsonse.data.data;
};

const getNetworkPlayTeamLongMemberRanking = async (params: IDetailID & { dataNo: number }) => {
  const repsonse = await axios.get('/gs/getNetworkPlayTeamLongMemberRanking', {
    params,
  });
  return repsonse.data.data;
};

const getNetworkPlayTeamNearMemberRanking = async (params: IDetailID & { dataNo: number }) => {
  const repsonse = await axios.get('/gs/getNetworkPlayTeamNearMemberRanking', {
    params,
  });
  return repsonse.data.data;
};

const getScoreCardSingle = async (params: IDetailID & { usrNo2: number }) => {
  const repsonse = await axios.get('/gs/getScoreCardSingle', {
    params,
  });
  return repsonse.data.data;
};

const getSkillCompare = async (params: IDetailID & { usrNo2: number }) => {
  const repsonse = await axios.get('/gs/getSkillCompare', {
    params,
  });
  return repsonse.data.data;
};

const getFootWeightData = async (detailNo: number) => {
  const repsonse = await axios.get('/gs/getMovWeightData', {
    params: {
      detailNo,
    },
  });
  return repsonse.data.data;
};

export const GSrecord = {
  getScoreList,
  getNasmoClubList,
  deleteNasmoClub,
  getRoundDetail,
  getRoundReport,
  getRoundCompareInfo,
  getRoundShotGroup,
  getScoreCard,
  getTournamentNewPerioScoreCard,
  getNetworkPlayRoomInfo,
  getNetworkPlayRanking,
  getNetworkPlayBestScore,
  getNetworkPlayTop10ScoreList,
  getNetworkPlayTeamLongLeaderboard,
  getNetworkPlayTeamNearLeaderboard,
  getNetworkPlayTeamStrokeLeaderboard,
  getNetworkPlayTeamStrokeMemberRanking,
  getNetworkPlayTeamLongMemberRanking,
  getNetworkPlayTeamNearMemberRanking,
  getScoreCardSingle,
  getSkillCompare,
  getFootWeightData,
};

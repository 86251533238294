import i18n from 'src/locale';
import { IStoreDetail, IStoreOnMap } from 'src/models';
import { NotificationType } from 'src/models/notification';
import { notificationActions } from 'src/store/notification';
import { findStoreRequests } from 'src/requests/api/store-news/find-store';
import {
  EnumRequestCode,
  EnumResultTypeStoreMap,
  EnumSortTypeStoreMap,
  EnumValueBoolean,
  pageSize,
} from 'src/constants/enum';
import {
  ISearchStoreGetAutoFillParams,
  ISearchStoreSuggestionHistory,
} from 'src/requests/api/store-news/prop-state.type';
import { history } from 'src/services/history';
import { pathConstants } from 'src/constants/const';
import { IAutoFillState, IFilteringStoresState, IMapLocation } from 'src/store/store-map/type';
import { DEFAULT_DISTANCE_AWAY } from 'src/constants/const/store-map.constants';
import { calculateDistance, isLocationInsideMap } from 'src/services/google-map';
import minBy from 'lodash/minBy';
import dayjs from 'dayjs';

export const STORE_NEWS_ACTION_TYPES = {
  STORE_MAP_UPDATE_ALL_STORE_LIST_ON_MAP: 'STORE_MAP_UPDATE_ALL_STORE_LIST_ON_MAP',
  STORE_MAP_UPDATE_VIEWING_STORE_LIST_ON_MAP: 'STORE_MAP_UPDATE_VIEWING_STORE_LIST_ON_MAP',
  STORE_MAP_UPDATE_DETAIL_STORES_LIST_ON_MAP: 'STORE_MAP_UPDATE_DETAIL_STORES_LIST_ON_MAP',
  STORE_MAP_UPDATE_DETAIL_STORE_ON_MAP: 'STORE_MAP_UPDATE_DETAIL_STORE_ON_MAP',
  STORE_MAP_SET_SHOW_MAP: 'STORE_MAP_SET_SHOW_MAP',
  STORE_MAP_UPDATE_SUGGESTION_HISTORY: 'STORE_MAP_UPDATE_SUGGESTION_HISTORY',
  STORE_MAP_UPDATE_FILTERING_STORES: 'STORE_MAP_UPDATE_FILTERING_STORES',
  STORE_MAP_UPDATE_AUTO_FILL: 'STORE_MAP_UPDATE_AUTO_FILL',
  STORE_MAP_UPDATE_MAP_INFO: 'STORE_MAP_UPDATE_MAP_INFO',
  STORE_MAP_UPDATE_PAGE_OFFSET: 'STORE_MAP_UPDATE_PAGE_OFFSET',
  RESET_MAP: 'RESET_MAP',
  SET_SHOW_PERMISSION_ALERT: 'SET_SHOW_PERMISSION_ALERT',
};

export interface IAllStoreListOnMapParams {
  data?: IStoreOnMap[];
  total?: number;
  loading?: boolean;
  loaded?: boolean;
  firstLoad?: boolean;
  lastUpdated?: string;
}

export interface IViewingStoresOnMapParams {
  data?: IStoreOnMap[];
  total?: number;
  page?: number;
}

export interface ISuggestionHistoryParams {
  data?: ISearchStoreSuggestionHistory[];
  loading?: boolean;
  loaded?: boolean;
}

export interface IFilteringStoresStateParams {
  versions?: string[];
  facility?: string[];
  anyDistance?: boolean;
  distance?: number;
  latitude?: number;
  longitude?: number;
  countryCode?: string;
  regionCode?: string;
  sortType?: EnumSortTypeStoreMap;
  latitudeSouthwest?: number;
  longitudeSouthwest?: number;
  latitudeNortheast?: number;
  longitudeNortheast?: number;
}

export interface IAutoFillParams {
  searchText?: string;
  data?: ISearchStoreSuggestionHistory[];
  loading?: boolean;
  loadingMore?: boolean;
  loaded?: number;
  hasNext?: boolean;
}

export interface IMapInfoParams {
  title?: string;
  focusedStore?: IStoreOnMap;
  hideFocusedStore?: boolean;
  firstLoad?: boolean;
  zoom?: number;
  center?: IMapLocation;
  address?: string;
  lockedMove?: boolean;
  nearby?: boolean;
  loadOnCurrentMapBtn?: boolean;
}

export const storeMapActions = {
  updateAllStoreListOnMap: (payload: IAllStoreListOnMapParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_ALL_STORE_LIST_ON_MAP,
    payload,
  }),
  updateViewingStoreListOnMap: (payload: IViewingStoresOnMapParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_VIEWING_STORE_LIST_ON_MAP,
    payload,
  }),
  updateDetailStoreListOnMap: (payload: IStoreDetail[]) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_DETAIL_STORES_LIST_ON_MAP,
    payload,
  }),
  updateDetailStoreOnMap: (payload: { rgnNo: number; interestYn: EnumValueBoolean }) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_DETAIL_STORE_ON_MAP,
    payload,
  }),
  setShowMap: (showMap: boolean = true, isSearching: boolean = true) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_SET_SHOW_MAP,
    payload: {
      showMap,
      isSearching,
    },
  }),
  updateSuggestionHistory: (payload: ISuggestionHistoryParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_SUGGESTION_HISTORY,
    payload,
  }),
  updateFilteringStores: (payload: IFilteringStoresStateParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_FILTERING_STORES,
    payload,
  }),
  updateAutoFill: (payload: IAutoFillParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_AUTO_FILL,
    payload,
  }),
  updateMapInfo: (payload: IMapInfoParams) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_MAP_INFO,
    payload,
  }),
  updatePageOffset: (payload: number) => ({
    type: STORE_NEWS_ACTION_TYPES.STORE_MAP_UPDATE_PAGE_OFFSET,
    payload,
  }),
  resetMap: () => ({
    type: STORE_NEWS_ACTION_TYPES.RESET_MAP,
  }),
  setShowPermissionAlert: (showAlert: boolean) => ({
    type: STORE_NEWS_ACTION_TYPES.SET_SHOW_PERMISSION_ALERT,
    payload: {
      showAlert,
    },
  }),
};

const getAllStoreList = () => async (dispatch: any) => {
  const payload: IAllStoreListOnMapParams = {
    data: [],
    total: 0,
    loading: false,
    loaded: true,
    lastUpdated: dayjs().toString(),
  };

  try {
    const response = await findStoreRequests.getAllStoreListOnMap();
    if (response.code !== EnumRequestCode.SUCCESS) throw new Error('cannot get search store list');
    payload.data = response.entities ?? [];
    payload.total = response.totalCount ?? 0;
  } catch {
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  } finally {
    dispatch(storeMapActions.updateAllStoreListOnMap(payload));
  }
};

let countryOfNearestStore: string | undefined;
const getViewingStoreList = () => async (dispatch: any, getState: any) => {
  const filteringStoresState: IFilteringStoresState = getState().storeMapReducer.filteringStores;
  const allStores: IStoreOnMap[] = getState().storeMapReducer.allStores.data;
  const viewingStores = allStores.filter((store) => {
    const isStoreInsideMap =
      filteringStoresState.anyDistance &&
      !filteringStoresState.countryCode &&
      !filteringStoresState.regionCode &&
      filteringStoresState.latitudeNortheast != null &&
      filteringStoresState.latitudeSouthwest != null &&
      filteringStoresState.longitudeNortheast != null &&
      filteringStoresState.longitudeSouthwest != null &&
      isLocationInsideMap(store, {
        latitudeSouthwest: filteringStoresState.latitudeSouthwest,
        latitudeNortheast: filteringStoresState.latitudeNortheast,
        longitudeNortheast: filteringStoresState.longitudeNortheast,
        longitudeSouthwest: filteringStoresState.longitudeSouthwest,
      });
    return (
      filteringStoresState.versions.some((version) => store.system[version.toLowerCase()] != null) &&
      filteringStoresState.facility.some((facility) => store.fac?.includes(facility)) &&
      // If only check long, lat when there're no country or region filtering
      (!filteringStoresState.anyDistance ||
        filteringStoresState.countryCode ||
        filteringStoresState.regionCode ||
        isStoreInsideMap) &&
      (!filteringStoresState.countryCode ||
        (filteringStoresState.countryCode && filteringStoresState.countryCode === store.country)) &&
      (!filteringStoresState.regionCode ||
        (filteringStoresState.regionCode && filteringStoresState.regionCode === store.rgnCd)) &&
      (filteringStoresState.anyDistance ||
        (!filteringStoresState.anyDistance &&
          (calculateDistance(
            { latitude: store.lat, longitude: store.longi },
            { latitude: filteringStoresState.latitude, longitude: filteringStoresState.longitude },
            undefined,
            false,
          ) as number) <= filteringStoresState.distance))
    );
  });

  if (!countryOfNearestStore && filteringStoresState.latitude && filteringStoresState.longitude) {
    countryOfNearestStore = minBy(
      allStores,
      (store) =>
        calculateDistance(
          { latitude: store.lat, longitude: store.longi },
          { latitude: filteringStoresState.latitude, longitude: filteringStoresState.longitude },
          undefined,
          false,
        ) as number,
    )?.country;
  }

  viewingStores.sort((store1, store2) => {
    const store1Distance = calculateDistance(
      { latitude: store1.lat, longitude: store1.longi },
      { latitude: filteringStoresState.latitude, longitude: filteringStoresState.longitude },
      undefined,
      false,
    ) as number;
    const store2Distance = calculateDistance(
      { latitude: store2.lat, longitude: store2.longi },
      { latitude: filteringStoresState.latitude, longitude: filteringStoresState.longitude },
      undefined,
      false,
    ) as number;
    if (filteringStoresState.sortType === EnumSortTypeStoreMap.CLOSEST) {
      if (store1Distance !== store2Distance) return store1Distance - store2Distance;
      if (store1.cntPop !== store2.cntPop) return store2.cntPop - store1.cntPop;
      if (store1.cntFav !== store2.cntFav) return store2.cntFav - store1.cntFav;
    } else if (filteringStoresState.sortType === EnumSortTypeStoreMap.POPULAR) {
      if (store1.cntPop !== store2.cntPop) return store2.cntPop - store1.cntPop;
      if (store1Distance !== store2Distance) return store1Distance - store2Distance;
      if (store1.cntFav !== store2.cntFav) return store2.cntFav - store1.cntFav;
    } else if (filteringStoresState.sortType === EnumSortTypeStoreMap.FAVORITED) {
      if (store1.cntFav !== store2.cntFav) return store2.cntFav - store1.cntFav;
      if (store1Distance !== store2Distance) return store1Distance - store2Distance;
      if (store1.cntPop !== store2.cntPop) return store2.cntPop - store1.cntPop;
    }

    if (store1.country === countryOfNearestStore) {
      return -1;
    } else if (store2.country === countryOfNearestStore) {
      return 1;
    }
    return (
      new Date(store2.date?.slice(0, 19).replace(/-|\./g, '/')).getTime() -
      new Date(store1.date?.slice(0, 19).replace(/-|\./g, '/')).getTime()
    );
  });

  dispatch(
    storeMapActions.updateViewingStoreListOnMap({
      page: 1,
      data: viewingStores ?? [],
      total: viewingStores?.length ?? 0,
    }),
  );
  dispatch(
    storeMapActions.updateAllStoreListOnMap({
      firstLoad: false,
    }),
  );
};

const getDetailStoreList = (rgnNos: number[]) => async (dispatch: any, getState: any) => {
  const detailStores: IStoreDetail[] = getState().storeMapReducer.detailStores;
  const unfetchingRgnNos = rgnNos.filter((rgnNo) => detailStores.findIndex((store) => store.rgnNo === rgnNo) === -1);
  if (!unfetchingRgnNos.length) return;
  try {
    const response = await findStoreRequests.getAllStoreDetailList(unfetchingRgnNos);
    if (response.code !== EnumRequestCode.SUCCESS) throw new Error('cannot get search store list');
    dispatch(storeMapActions.updateDetailStoreListOnMap(response.entities ?? []));
  } catch {}
};

let lastRequestIndex = 0;
const getSuggestionHistory = () => async (dispatch: any, getState: any) => {
  const isLoggedIn = getState().authentication.isLoggedIn;

  const currentLastRequestIndex = ++lastRequestIndex;
  const payload: ISuggestionHistoryParams = {
    data: [],
    loading: false,
    loaded: true,
  };
  try {
    if (!isLoggedIn) return;
    const response = await findStoreRequests.getSuggestionHistory();
    if (response.code !== EnumRequestCode.SUCCESS) throw new Error('cannot get suggestion history');
    payload.data = response.entities ?? [];
  } catch {
  } finally {
    if (currentLastRequestIndex === lastRequestIndex) {
      dispatch(storeMapActions.updateSuggestionHistory(payload));
    }
  }
};

const clickSuggestion =
  (suggestion: ISearchStoreSuggestionHistory, embedded: boolean) => async (dispatch: any, getState: any) => {
    const isLoggedIn = getState().authentication.isLoggedIn;
    const suggestionHistoryList: ISearchStoreSuggestionHistory[] =
      getState().storeMapReducer.suggestionHistory.data ?? [];
    if (isLoggedIn) {
      try {
        findStoreRequests.saveSuggestionHistory(suggestion);
        const newSuggestionHistory = [
          suggestion,
          ...suggestionHistoryList.filter(
            (suggestionHistory) =>
              !(
                suggestionHistory.rgnNo === suggestion.rgnNo &&
                suggestionHistory.rgnCd === suggestion.rgnCd &&
                suggestionHistory.countryCode === suggestion.countryCode
              ),
          ),
        ];
        dispatch(
          storeMapActions.updateSuggestionHistory({
            data: newSuggestionHistory.splice(0, 5),
          }),
        );
      } catch {
        // do nothing, only save
      }
    }

    if ([EnumResultTypeStoreMap.OTHER, EnumResultTypeStoreMap.LOCATION].includes(suggestion.typeResult)) {
      history.push(
        !embedded
          ? pathConstants.STORE_DETAIL(suggestion.rgnNo?.toString())
          : pathConstants.EMBEDDED_STORE_DETAIL(suggestion.rgnNo?.toString()),
      );
      return;
    }
    const params: any = {};
    if (suggestion.typeResult === EnumResultTypeStoreMap.COUNTRY) {
      params.countryCode = suggestion.countryCode;
    } else {
      params.regionCode = suggestion.rgnCd;
    }
    dispatch(
      storeMapActions.updateAllStoreListOnMap({
        loading: true,
      }),
    );
    dispatch(
      storeMapActions.updateFilteringStores({
        anyDistance: true,
        distance: DEFAULT_DISTANCE_AWAY,
        latitudeNortheast: undefined,
        latitudeSouthwest: undefined,
        longitudeNortheast: undefined,
        longitudeSouthwest: undefined,
        ...params,
      }),
    );
    dispatch(storeMapMiddleware.getViewingStoreList());
    dispatch(storeMapActions.setShowMap(true));
    dispatch(
      storeMapActions.updateMapInfo({
        lockedMove: true,
        title: suggestion.typeResult === EnumResultTypeStoreMap.COUNTRY ? suggestion.countryNm : suggestion.comNm,
      }),
    );
    setTimeout(() => {
      dispatch(
        storeMapActions.updateMapInfo({
          address: suggestion.typeResult === EnumResultTypeStoreMap.COUNTRY ? suggestion.countryNm : suggestion.comNm,
        }),
      );
    }, 500);
  };

const getAutoFill = (searchText: string) => async (dispatch: any) => {
  const payload: IAutoFillParams = {
    data: [],
    loading: false,
    loaded: 1,
    hasNext: false,
  };
  try {
    dispatch(
      storeMapActions.updateAutoFill({
        loading: true,
        searchText,
      }),
    );
    if (!searchText?.trim()) return;
    const params: ISearchStoreGetAutoFillParams = {
      searchName: searchText?.trim(),
      page: 1,
      rows: pageSize.MEDIUM,
    };
    const response = await findStoreRequests.getAutoFill(params);
    if (response.code !== EnumRequestCode.SUCCESS) throw new Error('cannot get auto fill');
    payload.data = response.entities ?? [];
    payload.hasNext =
      !!response.entities?.length &&
      response.entities?.length === params.rows &&
      payload.data &&
      payload.data?.length < response.totalCount;
  } catch {
  } finally {
    dispatch(storeMapActions.updateAutoFill(payload));
  }
};

const loadMoreAutoFill = () => async (dispatch: any, getState: any) => {
  const autoFill: IAutoFillState = getState().storeMapReducer.autoFill;
  const payload: IAutoFillParams = {
    loadingMore: false,
    loaded: autoFill.loaded + 1,
    hasNext: false,
  };
  try {
    dispatch(
      storeMapActions.updateAutoFill({
        loadingMore: true,
      }),
    );
    const params: ISearchStoreGetAutoFillParams = {
      searchName: autoFill.searchText,
      page: autoFill.loaded + 1,
      rows: pageSize.MEDIUM,
    };
    const response = await findStoreRequests.getAutoFill(params);
    if (response.code !== EnumRequestCode.SUCCESS) throw new Error('cannot get auto fill');
    payload.data = [...autoFill.data, ...response.entities];
    payload.hasNext =
      !!response.entities?.length &&
      response.entities?.length === params.rows &&
      payload.data &&
      payload.data?.length < response.totalCount;
  } catch {
  } finally {
    dispatch(storeMapActions.updateAutoFill(payload));
  }
};

const updateInterestStore = (rgnNo: number, interestYn: EnumValueBoolean) => async (dispatch: any, getState: any) => {
  dispatch(
    storeMapActions.updateDetailStoreOnMap({
      rgnNo,
      interestYn,
    }),
  );
};

export const storeMapMiddleware = {
  getAllStoreList,
  getViewingStoreList,
  getDetailStoreList,
  getSuggestionHistory,
  clickSuggestion,
  getAutoFill,
  loadMoreAutoFill,
  updateInterestStore,
};

import { lineInfo } from './../../../constants/const/global.constants';
import { snsType } from 'src/constants/enum';
import axiosBasic from 'axios';
import axios from 'src/requests';
import { CT_WECHAT_INFO } from 'src/constants/const';
import qs from 'qs';

const loginWithIdAndPassword = async (
  username: string,
  password: string,
  loginPlatform?: string,
  pushToken?: string,
): Promise<any> => {
  const body = {
    usrId: username,
    password,
    loginPlatform,
    pushToken,
  };
  const response = await axios.post('/login/auth', body);
  return response.data.data;
};

const verifySession = async (): Promise<any> => {
  const response = await axios.post('/login/verifySession');
  return response.data.data;
};

const getUserInfoGoogle = async (googleResponse: any): Promise<any> => {
  const result = await axiosBasic.get('https://www.googleapis.com/oauth2/v3/userinfo', {
    headers: {
      Authorization: `Bearer ${googleResponse.access_token}`,
    },
  });
  return result.data;
};

// https://developers.google.com/people/api/rest/v1/people/get
const getUserGoogleDetail = async ({
  userId,
  access_token,
}: {
  userId: string;
  access_token: string;
}): Promise<any> => {
  const result = await axiosBasic.get(
    `https://people.googleapis.com/v1/people/${userId}?personFields=genders,phoneNumbers,nicknames,birthdays`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    },
  );
  return result?.data;
};

const getUserLINEDetail = async ({ code, isLogin = true }: { code: string; isLogin?: boolean }): Promise<any> => {
  const BASE_URL = 'https://api.line.me/oauth2/v2.1';
  const bodyGetToken = qs.stringify({
    grant_type: lineInfo.grantType,
    code,
    client_id: lineInfo.clientId,
    client_secret: lineInfo.clientSecret,
    redirect_uri: isLogin ? lineInfo.loginRedirectUri : lineInfo.settingRedirectUri,
  });
  const resToken = await axiosBasic.post(`${BASE_URL}/token`, bodyGetToken);
  const lineToken = resToken?.data?.id_token;

  if (!!lineToken) {
    const bodyVerify = qs.stringify({
      client_id: lineInfo.clientId,
      id_token: lineToken,
    });
    const resInfo = await axiosBasic.post(`${BASE_URL}/verify`, bodyVerify);
    return {
      accessToken: lineToken,
      snsId: resInfo?.data?.sub,
      email: resInfo?.data?.email,
      nicknames: resInfo?.data?.name,
    };
  }
};

const loginWithSNS = async (body: any): Promise<any> => {
  const response = await axios.post('/login/sns/auth', body);
  return response.data.data;
};

/* Reader Document: 
            https://developers.weixin.qq.com/doc/oplatform/en/Website_App/WeChat_Login/Wechat_Login.html
            https://developers.weixin.qq.com/doc/offiaccount/en/OA_Web_Apps/Wechat_webpage_authorization.html
            https://wohugb.gitbooks.io/wechat/content/authorize/step_one.html
            https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html
*/
const loginWithWeChat = async (codeInfo: string): Promise<any> => {
  const responseToken = await obtainCode(codeInfo);
  const body = {
    snsId: responseToken.data.unionid,
    type: snsType.Wechat,
    accessToken: responseToken.data.access_token,
    email: '',
  };
  const response = await axios.post('/login/sns/auth', body);
  return response.data.unionid;
};
export const obtainCode = (code: any) => {
  const paramsInfo = {
    appid: CT_WECHAT_INFO.AppID,
    secret: CT_WECHAT_INFO.AppSecret,
    code: code,
    grant_type: 'authorization_code',
  };
  return axios({
    method: 'GET',
    url: '//api.weixin.qq.com/sns/oauth2/access_token',
    params: paramsInfo,
  });
};

export const loginMiddleware = {
  loginWithIdAndPassword,
  getUserInfoGoogle,
  getUserGoogleDetail,
  loginWithSNS,
  loginWithWeChat,
  verifySession,
  getUserLINEDetail,
};

import axios from 'src/requests';
import { IPageRowParam } from 'src/requests/api/common/prop-state.type';
import {
  IEvenBannerListRequest,
  IGdrCheckLoginNumberRequest,
  IGetCoursesRequest,
  IGetNewsRequest,
  IGetStoresRequest,
  IGetTournamentsRequest,
  IMyInfoRequest,
  IUpdateBannerRequest,
} from 'src/requests/api/home/prop-state.type';
import { IGuestParams } from 'src/requests/api/prop-state.type';
import gdrRequest from 'src/requests/gdr-request';

const getMyInfo = async (params: IMyInfoRequest) => {
  const response = await axios.get('/main/myInfo', { params });
  return response.data.data;
};

const getBookingProcess = async () => {
  const response = await axios.get('/main/booking/process');
  return response.data.data;
};

const getNewCourseList = async (params?: IGetCoursesRequest) => {
  const response = await axios.get('/main/getNewCourseList', { params });
  return response.data.data;
};

const getRecommendCourseList = async (params?: IGetCoursesRequest) => {
  const response = await axios.get('/main/getRecommendCourseList', { params });
  return response.data.data;
};

const getMyRankingTournamentList = async () => {
  const response = await axios.get('/main/getMyTournament');
  return response.data.data;
};

const getSubCountryRanking = async (usrNo?: number) => {
  const response = await axios.get('/main/ranking/sub-country', {
    params: {
      usrNo,
    },
  });
  return response.data.data;
};

const getCountryRanking = async (usrNo?: number) => {
  const response = await axios.get('/main/ranking/country', {
    params: {
      usrNo,
    },
  });
  return response.data.data;
};

const getGlobalLevelRanking = async (usrNo?: number) => {
  const response = await axios.get('/main/ranking/globalLevel', {
    params: {
      usrNo,
    },
  });
  return response.data.data;
};

const getStoreRanking = async (usrNo?: number) => {
  const response = await axios.get('/main/ranking/store', {
    params: {
      usrNo,
    },
  });
  return response.data.data;
};

const getTournamentList = async (params?: IGetTournamentsRequest) => {
  const response = await axios.get('/main/getTournaments', { params });
  return response.data.data;
};

const getStoreList = async (params?: IGetStoresRequest) => {
  const response = await axios.get('/site/getNearbyStore', { params });
  return response.data.data;
};

const getNewsList = async (params?: IGetNewsRequest) => {
  const response = await axios.get('/main/noticeList', { params });
  return response.data.data;
};

const getEventBannerList = async (params: IEvenBannerListRequest) => {
  const response = await axios.get('/main/eventBannerList', { params });
  return response.data.data;
};

const getGsInfo = async () => {
  const response = await axios.get('/main/gsInfo');
  return response.data.data;
};

const getGsNasmoInfo = async () => {
  const response = await axios.get('/main/getNasmoClubListMain');
  return response.data.data;
};

const getGdrInfo = async () => {
  const response = await axios.get('/main/gdrInfo');
  return response.data.data;
};

const getGdrNasmoInfo = async (params: IPageRowParam) => {
  const response = await axios.get('gdr/getNasmolist', { params });
  return response.data.data;
};

const getMyPractice = async () => {
  const response = await axios.get('/main/myPractice');
  return response.data.data;
};

const checkGdrLoginNumber = async (params: IGdrCheckLoginNumberRequest) => {
  const response = await gdrRequest.post('/subscribe', params);
  return response.data;
};
const getPageColumn = async (params?: IGuestParams) => {
  const response = await axios.get('/main/trendingSiteList', { params });
  return response.data.data;
};

const getAlarmList = async () => {
  const response = await axios.get('/main/alarmList');
  return response.data.data;
};

const readAlarm = async () => {
  const response = await axios.post('/main/alarmRead');
  return response.data.data;
};

const countActionBanner = async (body: IUpdateBannerRequest) => {
  const response = await axios.post('/main/updateBannerCount', body);
  return response.data.data;
};

const countActionPopup = async (body: IUpdateBannerRequest) => {
  const response = await axios.post('/main/updatePopupCount', body);
  return response.data.data;
};

const getWaveEncryptToken = async () => {
  const response = await axios.post('/main/redirectWave');
  return response.data.data;
};

export const infoAccount = {
  getMyInfo,
  getBookingProcess,
  getNewCourseList,
  getRecommendCourseList,
  getSubCountryRanking,
  getCountryRanking,
  getGlobalLevelRanking,
  getStoreRanking,
  getMyRankingTournamentList,
  getTournamentList,
  getStoreList,
  getNewsList,
  getEventBannerList,
  getGsInfo,
  getGsNasmoInfo,
  getGdrInfo,
  getGdrNasmoInfo,
  getMyPractice,
  checkGdrLoginNumber,
  getPageColumn,
  getAlarmList,
  readAlarm,
  countActionBanner,
  countActionPopup,
  getWaveEncryptToken,
};

import { AnyAction } from 'redux';
import { LOCALSTORAGE_SNS_TYPE, pathConstants } from 'src/constants/const';
import { snsType } from 'src/constants/enum';
import { getSessionId, saveSessionId } from 'src/requests/token';
import { sendDataToApp } from 'src/hooks/webview';
import { LOGIN_FAILED, LOGIN_START, LOGIN_SUCCESS, LOGIN_WITH_SESSION_ID, LOGOUT_SUCCESS } from './action';
import { IAuthentication } from 'src/requests/api/account/prop-state.type';
import { booleanValue } from 'src/services/utils';
import { history } from 'src/services/history';

const initialState = (): IAuthentication => ({
  sessionId: getSessionId(),
  snsType: localStorage.getItem(LOCALSTORAGE_SNS_TYPE) as snsType | null,
  isLoggingIn: false,
  isLoggedIn: !!getSessionId(),
});

export const authentication = (state = initialState(), action: AnyAction) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        isLoggingIn: true,
      };
    case LOGIN_SUCCESS:
      saveSessionId(action.payload.sessionData.sessionId);
      sendDataToApp({ sessionId: action.payload.sessionData.sessionId });
      localStorage.setItem(LOCALSTORAGE_SNS_TYPE, action.payload.snsType);
      // Login again, when update and choose phone number have existing account
      return {
        ...state,
        sessionId: action.payload.sessionData.sessionId,
        snsType: action.payload.snsType,
        isLoggingIn: false,
        isLoggedIn: true,
        needUpdatePhone: !booleanValue(action.payload.sessionData.smsAuthYn),
      };
    case LOGIN_WITH_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload.sessionId,
        snsType: action.payload.snsType,
        isLoggingIn: false,
        isLoggedIn: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        isLoggingIn: false,
      };
    case LOGOUT_SUCCESS:
      const redirectPath = action.payload?.redirectPath || pathConstants.HOME_PAGE;
      if (action.payload?.replace) {
        history.replaceWithSearch(redirectPath, action.payload?.redirectState, {
          clearedSearchParams: action.payload?.clearedSearchParams,
        });
      } else {
        history.pushWithSearch(redirectPath, action.payload?.redirectState, {
          clearedSearchParams: action.payload?.clearedSearchParams,
        });
      }
      return {
        ...state,
        sessionId: null,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

import { flagUSA } from 'src/assets/svg';
import { CT_LINE_INFO } from 'src/constants/const/env.constants';

export const MAP_ZOOM_DEFAULT = 15;
export const DARK_MODE_ATTRIBUTE = 'dark';
export const DARK_MODE_ATTRIBUTE_NAME = 'data-mode';
export const API_TIMEZONE = 'EST';
export const TUNING_TIME_TIMEZONE = 'America/New_York';
export const JAPAN_TIMEZONE = 'Asia/Tokyo';
export const LOCALE_TIMEZONE = '';
export const VERIFY_CODE_SECONDS = 5;
export const VERIFY_CODE_LIFETIME = 300;
export const DEFAULT_DEBOUNCE_TIME = 700;
export const DEFAULT_SLIDE_INDEX = 0;
export const JAPAN_REGION_VALUE = '2';
export const MIN_DATE = '1970-01-01T00:00:00';
export const RATIO_KM_TO_MILES = 0.6214;
export const RUSSIA_COUNTRY_CODE = 'RUS';
export const RUSSIA_COUNTRY_NAME = 'Russia';

export const constUnit: Record<string, string> = {
  RPM: 'rpm',
  DEGREE: 'º',
  PERCENT: '%',
  CENTIMETER: 'cm',
  PUTTS: 'putts',
};

export const typeVideo: Record<string, string> = {
  MP4: 'mp4',
  M4A: 'm4a',
  M4B: 'm4b',
  F4V: 'f4v',
  MOV: 'mov',
};

export const languageCode: Record<string, string> = {
  KOREA: 'ko',
  USA: 'en',
  CHINA: 'zh',
  JAPAN: 'ja',
  VIETNAM: 'vi',
};
export const countryCode: Record<string, string> = {
  KOREA: 'kr',
  USA: 'en',
  CHINA: 'cn',
  JAPAN: 'ja',
  VIETNAM: 'vn',
};

export const mapGlobalLangToInappLang: Record<string, string> = {
  en: 'en',
  vn: 'vi',
  cn: 'zh',
  ja: 'ja',
  kr: 'ko',
};

export const locales = [
  { title: '한국어', value: countryCode.KOREA },
  { title: 'English', value: countryCode.USA },
  { title: '中文(简体)', value: countryCode.CHINA },
  { title: '日本語', value: countryCode.JAPAN },
  { title: 'Tiếng Việt', value: countryCode.VIETNAM },
];

export const localesLeaderBoard = [
  { title: 'English', value: countryCode.USA },
  { title: '日本語', value: countryCode.JAPAN },
];

export const initialCountry = { countryNum: '+1', countryCd: 'USA', flag: flagUSA };

export const mobileMessage = {
  LOGIN_SNS: 'loginSNS',
  DEVICE: 'deviceMobile',
  NATIVEAPP_PERMISSION: 'mobilePermission',
  NATIVEAPP_LOCATION: 'mobileLocation',
  STORE_DETAIL: 'storeDeTail',
  MOBILE_FILE_INFO: 'mobileFileInfo',
  SNS_CONNECTED: 'snsConnected',
  WIFI_ENABLE: 'wifiEnable',
  NOTIFICATION_ENABLE: 'notificationEnable',
  RES_MOB_START_DATA: 'resMobStartData',
  RES_CHECK_IN_DATA: 'resCheckInData',
  SAVE_MEDIA_INFO: 'saveMediaInfo',
  ON_RECEIVE_MESSAGE: 'onReceiveMessage',
  ON_BACK_BTN_PRESS: 'onBackBtnPress',
  UPLOAD_AVATAR_INFO: 'uploadAvatarInfo',
  RELOAD_SMART_LOGIN: 'reloadSmartLogin',
  SHOW_TOAST_AFTER_SIGN_UP: 'showToastAfterSignUp',
  ON_FINISH_AI_ANALYSIS: 'ON_FINISH_AI_ANALYSIS',
};

export const mobileAction = {
  WEBVIEW_LOADED: 'webviewLoaded',
  LOGIN_SUCCESS: 'loginSuccess',
  LOGOUT_SUCCESS: 'logoutSuccess',
  SHARE_LINK: 'shareLink',
  GET_PERMISSION: 'getPermission',
  REQUEST_PERMISSION: 'requestPermission',
  GET_LOCATION: 'getLocation',
  OPEN_GOOGLE_MAPS: 'openGoogleMaps',
  OPEN_SETTING_LOCATION: 'openSettingLocation',
  EDIT_VIDEO: 'editVideo',
  SAVE_SCORE_CARD: 'saveScoreCard',
  DOWNLOAD_VIDEO: 'downloadVideo',
  VIEW_NASMO_LIST: 'viewNasmoList',
  PICK_MOBILE_FILE: 'pickMobileFile',
  CONNECT_SNS: 'connectSns',
  UPDATE_AUTO_PLAY_SETTING: 'updateAutoPlaySetting',
  OPEN_APP_STORE: 'openAppStore',
  OPEN_SETTING_NOTIFICATION: 'openSettingNotification',
  GET_NOTIFICATION_ENABLE: 'getNotificationEnable',
  REQ_MOB_START_LISTENER: 'reqMobStartListener',
  REQ_CHECK_IN_LISTENER: 'reqCheckInListener',
  REMOVE_SOCKET_LISTENER: 'removeSocketListener',
  HANDLE_AVOIDING_VIEW: 'handleAvoidingView',
  CLIP_BOARD: 'clipboard',
  UPDATE_SCREEN_LOGIN_VISIBLE_INFO: 'updateScreenLoginVisibleInfo',
  CHANGE_LANGUAGE: 'changeLanguage',
  FIREBASE_TRACKING_EVENTS: 'firebaseTrackingEvents',
  FIREBASE_LOG_ERRORS: 'firebaseLogErrors',
  OPEN_IN_APP: 'openInApp',
  HANDLE_AFTER_SIGN_UP: 'handleAfterSignUp',
  REQUEST_SWING_AI: 'requestSwingAi',
  VIEW_SWING_AI_ANALYSIS: 'viewSwingAiAnalysis',
};

export const mobilePermissionResults = {
  UNAVAILABLE: 'unavailable',
  BLOCKED: 'blocked',
  DENIED: 'denied',
  GRANTED: 'granted',
  LIMITED: 'limited',
};

export const lineInfo = {
  clientId: CT_LINE_INFO.appID,
  clientSecret: CT_LINE_INFO.appSecret,
  loginRedirectUri: CT_LINE_INFO.redirectLoginURI,
  settingRedirectUri: CT_LINE_INFO.redirectSettingURI,
  baseApiURL: 'https://access.line.me/oauth2/v2.1/',
  scope: 'profile%20openid%20email',
  grantType: 'authorization_code',
};

/*
  formatDate starting with "T" are formatDate based on language
  They must match with format in locale json
*/
export const formatDate = {
  T_NORMAL_FULL_DATE: 'NORMAL_FULL_DATE',
  T_NORMAL_YEAR_AND_MONTH: 'NORMAL_YEAR_AND_MONTH',
  T_NORMAL_MONTH_AND_DATE: 'NORMAL_MONTH_AND_DATE',
  T_TEXT_FULL_DATE: 'TEXT_FULL_DATE',
  T_TEXT_YEAR_AND_MONTH: 'TEXT_YEAR_AND_MONTH',
  T_TEXT_MONTH_AND_DATE: 'TEXT_MONTH_AND_DATE',
  T_TEXT_MONTH_DATE_AND_TIME: 'TEXT_MONTH_DATE_AND_TIME',
  T_TEXT_FULL_DATE_TIME: 'TEXT_FULL_DATE_TIME',
  T_TEXT_FULL_DATE_TIME_AND_SECONDS: 'TEXT_FULL_DATE_TIME_AND_SECONDS',
  NORMAL: 'YYYY.MM.DD',
  SIMPLE: 'YYYY-M-D',
  BASIC: 'YYYY-MM-DD',
  SHORT: 'YYYY-M',
  YEAR_AND_MONTH: 'YYYY-MM',
  MONTH_AND_DAY: 'MM.DD',
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME_NORMAL: 'YYYY-MM-DD HH:mm',
  API_DATETIME: 'YYYYMMDD',
  HOURS_MINUTES: 'HH:mm',
  LEAGUE_DATE: 'DD/MM/YYYY',
  GS_DATE: 'YYYY-MM-DD HH:mm:ss.SSS',
  HOUR_SHORT: 'H',
};

/* The first element of privacyPolicyVersions MUST be the latest version */
export const privacyPolicyVersions = [
  {
    version: 'THIRD_VERSION',
    date: '2024-10-22',
  },
  {
    version: 'SECOND_VERSION',
    date: '2023-04-25',
  },
  {
    version: 'FIRST_VERSION',
    date: '2023-01-04',
  },
];

export const maxSizeMb = {
  IMAGE: 5000000, //5MB
  PICTURE: 50000000, //50MB
  VIDEO: 500000000, //500MB
};

export const distanceRatio = {
  METER_TO_YARD: 0.9144,
  YARD_TO_METER: 1.09361,
};

export const MILISECONDS_PER_SECOND = 1000;
export const SECONDS_PER_YEAR = 31536000;
export const SECONDS_PER_MONTH = 86400;
export const SECONDS_PER_HOUR = 3600;
export const SECONDS_PER_MINUTE = 60;
export const SINGULAR_NUMBER = 1;
export const OTP_MAX_LENGTH = 5;

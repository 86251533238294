import domtoimage from 'dom-to-image';
import saveAs from 'file-saver';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconSave } from 'src/assets/svg';
import { mobileAction } from 'src/constants/const';
import { eventName } from 'src/constants/const/firebase.constants';
import { RANKING_SCORE_TYPE, gHandyOptions } from 'src/constants/const/profile.constants';
import { EnumTypeTableRanking } from 'src/constants/enum';
import { sendDataToApp } from 'src/hooks/webview';
import { firebaseInstance } from 'src/init-firebase';
import { NotificationType } from 'src/models';
import { IDataRankingDetailResponse } from 'src/requests/api/profile/prop-state.type';
import { getDownloadRanking } from 'src/services/download';
import { globalActions } from 'src/store/global';
import { notificationActions } from 'src/store/notification';
import NotFoundItem from 'src/view/commons/customs/NotFoundItem';
import NoteTooltip from 'src/view/commons/customs/NoteTooltip';
import { RankingRow } from 'src/view/pages/profile/ranking-detail/common/RankingRow';
import { twMerge } from 'tailwind-merge';

interface IRankingTableProps {
  data?: IDataRankingDetailResponse[];
  top?: number;
  isHasMyRanking?: boolean;
  type: EnumTypeTableRanking;
  loading?: boolean;
  filter?: any;
}

export default function RankingTable({ data, top, isHasMyRanking, type, loading, filter }: IRankingTableProps) {
  const translate = useTranslation().t;
  const isNativeApp = useSelector((state: any) => state.webviewReducer.isNativeApp);
  const dispatch = useDispatch();

  const mappingTypeToRankingField = {
    [EnumTypeTableRanking.COUNTRY]: {
      rankingField: 'countryRank',
      nameFile: 'country-ranking',
    },
    [EnumTypeTableRanking.GLOBAL]: {
      rankingField: 'globalRank',
      nameFile: 'global-ranking',
    },
    [EnumTypeTableRanking.SUB_COUNTRY]: {
      rankingField: 'cityRank',
      nameFile: 'city-ranking',
    },
    [EnumTypeTableRanking.LEVEL]: {
      rankingField: 'levelRank',
      nameFile: 'level-ranking',
    },
    [EnumTypeTableRanking.STORE]: {
      rankingField: 'cityRank',
      nameFile: 'store-ranking',
    },
  };

  const renderGHandyColumn = () => {
    if (filter?.rankingScoreType === RANKING_SCORE_TYPE.BEST_SCORE_TYPE) return gHandyOptions[1].title;
    if (filter?.rankingScoreType === RANKING_SCORE_TYPE.LONGEST_TYPE) return gHandyOptions[2].title;
    return gHandyOptions[0].title;
  };

  const downloadRankingTable = () => {
    firebaseInstance.trackEvent(eventName.RANKING_DETAIL_SAVE_AS_IMAGE);

    dispatch(globalActions.updateLoading(true));
    const element = document.getElementById('download-ranking');
    if (element == null) {
      notificationActions.addNotification(
        translate('pages.record.score_card.SAVE_SCORECARD_FAILED'),
        NotificationType.DANGER,
      );
      dispatch(globalActions.updateLoading(false));
      return;
    }
    const cloneElement = getDownloadRanking(element);
    if (isNativeApp) {
      domtoimage
        .toPng(cloneElement.element, cloneElement.params)
        .then(function (dataUrl: any) {
          sendDataToApp({ action: mobileAction.SAVE_SCORE_CARD, info: dataUrl });
        })
        .catch((error: any) => {
          console.warn(error);
          notificationActions.addNotification(
            translate('pages.record.score_card.SAVE_SCORECARD_FAILED'),
            NotificationType.DANGER,
          );
        })
        .finally(() => {
          dispatch(globalActions.updateLoading(false));
          cloneElement.element.remove();
        });
      return;
    }
    domtoimage
      .toBlob(cloneElement.element, cloneElement.params)
      .then(function (blob: any) {
        saveAs(blob, `${mappingTypeToRankingField[type]?.nameFile}.png`);
        notificationActions.addNotification(
          translate('pages.record.score_card.SAVE_SCORECARD_SUCCESSFULLY'),
          NotificationType.SUCCESS,
        );
      })
      .catch((error: any) => {
        console.warn(error);
        notificationActions.addNotification(
          translate('pages.record.score_card.SAVE_SCORECARD_FAILED'),
          NotificationType.DANGER,
        );
      })
      .finally(() => {
        cloneElement.element.remove();
        dispatch(globalActions.updateLoading(false));
      });
  };

  const skeleton = (key: string) => {
    return (
      <tr className="animate-pulse shadow-sm" key={key}>
        <td className="w-[60px] py-[8px] pl-[16px]">
          <div className="flex justify-center">
            <div className="h-[24px] w-[40px] rounded-[4px] bg-disable" />
          </div>
        </td>
        <td className="py-[8px]">
          <div className="flex justify-center">
            <div className="h-[24px] w-[60%] rounded-[4px] bg-disable" />
          </div>
        </td>
        <td className="w-[60px] py-[8px]">
          <div className="flex justify-center">
            <div className="h-[24px] w-[80%] rounded-[4px] bg-disable" />
          </div>
        </td>
        <td className="w-[80px] py-[8px]">
          <div className="flex justify-center">
            <div className="h-[24px] w-[60%] rounded-[4px] bg-disable" />
          </div>
        </td>
      </tr>
    );
  };

  const table = () => (
    <table className="w-full border-collapse">
      <thead>
        <tr className="gz-text-xsm bg-gray-5 text-text">
          <th className="w-[60px] py-[8px] pl-[16px] text-center font-normal">
            {translate('pages.home.my_ranking.RANK')}
          </th>
          <th className="py-[8px] text-center font-normal">{translate('pages.home.my_ranking.PLAYER')}</th>
          <th className="w-[60px] py-[8px] text-center font-normal">{translate('pages.home.my_ranking.UP/DOWN')}</th>
          <th className="w-[80px] py-[8px] pr-[16px] text-right font-normal">{translate(renderGHandyColumn())}</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <>{[...Array(5)].map((_, i) => skeleton(i.toString()))}</>
        ) : (
          <>
            {!!data?.length ? (
              <>
                {data?.map((entry, index) => (
                  <RankingRow
                    key={entry.id}
                    isBestScore={filter?.rankingScoreType === RANKING_SCORE_TYPE.BEST_SCORE_TYPE}
                    isMyDataRanking={isHasMyRanking && index === 0}
                    data={entry}
                    rankingField={entry?.[mappingTypeToRankingField[type]?.rankingField]}
                    sameRank={
                      data &&
                      data.findIndex(
                        (data, dataIndex) =>
                          dataIndex !== index &&
                          entry?.[mappingTypeToRankingField[type]?.rankingField] ===
                            data?.[mappingTypeToRankingField[type]?.rankingField] &&
                          data.usrNo !== entry.usrNo,
                      ) > -1
                    }
                  />
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={4}>
                  <NotFoundItem text={translate('pages.home.my_ranking.NO_DATA')} />
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  );

  return (
    <div>
      <div className="flex items-center justify-between p-[16px]">
        <NoteTooltip
          title={translate('pages.home.my_ranking.TOP_PLAYER', { top: top ?? 100 })}
          content={
            <div className="mr-[20px] leading-6 tracking-[.01em]">{translate('pages.home.my_ranking.TOOLTIP')}</div>
          }
          classTitle={twMerge('gz-text-md font-medium text-black')}
          classIconNote={twMerge('w-[20px] h-[20px] ml-[4px]', 'stroke-text')}
        />
        {!!data?.length && (
          <div className={twMerge('flex cursor-pointer items-center justify-center')}>
            <span
              className={twMerge(
                'gz-text-xsm mr-[8px] overflow-hidden text-ellipsis whitespace-nowrap',
                isMobile ? 'active:opacity-[0.5]' : 'hover:opacity-[0.5]',
              )}
              onClick={downloadRankingTable}
            >
              {translate('pages.home.my_ranking.SAVE_AS_IMAGE')}
            </span>
            <IconSave className="stroke-text" />
          </div>
        )}
      </div>
      <div id="download-ranking" className="bg-gz-white">
        {table()}
      </div>
    </div>
  );
}

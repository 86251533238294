import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { EnumRequestCode, EnumValueBoolean } from 'src/constants/enum';
import { NotificationType } from 'src/models';
import { IMyInfoResponse } from 'src/requests/api/account/prop-state.type';
import { policyMiddleware } from 'src/requests/api/home/policy';
import { DateTime } from 'src/services/datetime';
import { booleanValue } from 'src/services/utils';
import { account } from 'src/store/home';
import { notificationActions } from 'src/store/notification';
import MarketingPolicy from 'src/view/commons/customs/marketing-policy';
import Button from 'src/view/commons/elements/Button';

interface IMarketingPolicyForOldMembersProps {
  userInfo: IMyInfoResponse | null;
  updateInfo: (dataUser: IMyInfoResponse | null) => void;
  addNotification: (message: string, type: NotificationType) => void;
}

function MarketingPolicyForOldMembers({
  userInfo,
  updateInfo,
  addNotification,
}: IMarketingPolicyForOldMembersProps): JSX.Element {
  const translate = useTranslation().t;
  const [isMarketingPolicyOpen, setIsMarketingPolicyOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsMarketingPolicyOpen(userInfo?.usrId != null && userInfo?.marketingOptInAgreementYn == null);
  }, [userInfo?.marketingOptInAgreementYn]);

  useEffect(() => {
    if (isMarketingPolicyOpen) {
      document.body.classList.add('open-modal');
    } else {
      document.body.classList.remove('open-modal');
    }
  }, [isMarketingPolicyOpen]);

  const handleAgreePolicy = async (agree: EnumValueBoolean) => {
    try {
      setLoading(true);
      const data = await policyMiddleware.agreeMarketingPolicy({
        marketingOptInAgreementYn: agree,
      });
      if (data.code !== EnumRequestCode.SUCCESS) {
        throw new Error('Confirm marketing policy unsuccessfully');
      }
      updateInfo({
        ...userInfo!,
        marketingOptInAgreementYn: agree,
      });
      addNotification(
        translate(`layouts.auth.marketing_policy_modal.${booleanValue(agree) ? 'NOTICE_AGREE' : 'NOTICE_DISAGREE'}`, {
          date: DateTime.formatDate(Date.now(), translate),
        }),
        NotificationType.WARNING,
      );
    } catch (err) {
      addNotification(translate('error.UNKNOWN_ERROR'), NotificationType.DANGER);
    } finally {
      setLoading(false);
      setIsMarketingPolicyOpen(false);
    }
  };

  if (!isMarketingPolicyOpen) return <></>;

  return (
    <div className="fixed inset-0 z-[100] h-full overflow-hidden bg-gz-white">
      <div className="mx-auto flex h-full max-w-[576px] flex-col">
        <div className="flex h-[44px] items-center border-b-[1px] border-gray-20" />
        <div className="flex-1 overflow-y-auto px-[24px]">
          <div className="gz-text-md mt-[40px] font-medium text-title">
            {translate('layouts.terms_and_privacy.header.TITLE_MARKETING_POLICY')}
          </div>
          <div className="gz-text-xs mt-[24px]">{translate('layouts.auth.marketing_policy_modal.NOTICE')}</div>
          <MarketingPolicy className="mt-[16px]" />
        </div>
        <div className="flex px-[24px] pb-[5%] pt-[5%]">
          <Button
            color="secondary-gray"
            className="mr-[6px] bg-gray-10"
            onClick={() => handleAgreePolicy(EnumValueBoolean.FALSE)}
            disabled={loading}
          >
            {translate('commons.customs.button.NOT_AGREE')}
          </Button>
          <Button className="ml-[6px]" onClick={() => handleAgreePolicy(EnumValueBoolean.TRUE)} disabled={loading}>
            {translate('commons.customs.button.AGREE')}
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  userInfo: state.mainInfoReducer.userInfo,
});

const mapDispatchToProps = {
  updateInfo: account.info,
  addNotification: notificationActions.addNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketingPolicyForOldMembers);

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { CupertinoLoadingIndicator } from 'src/view/commons/elements/LoadingIndicator';
import { twMerge } from 'tailwind-merge';
export default function FullPageLoading(): JSX.Element {
  const loading: boolean = useSelector((state: RootState) => state.globalReducer.loading);

  useEffect(() => {
    document.documentElement.style['overflow'] = loading ? 'hidden' : '';
  }, [loading]);

  return (
    <div className={twMerge('fixed z-[9999] h-screen w-screen bg-dark-33/70', !loading && 'hidden')}>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <CupertinoLoadingIndicator />
      </div>
    </div>
  );
}

import { STORE_NEWS_ACTION_TYPES, UPDATE_LOADING, UPDATE_MAIN_WIDTH } from './action';
import { LOCALSTORAGE_GEOLOCATION_USER } from 'src/constants/const';
import { getObjectLocalStorage, saveObjectLocalStorage } from 'src/services/storage';

export interface ILocation {
  latitude?: number;
  longitude?: number;
}
export interface IGlobalState {
  websiteLocation: ILocation | null;
  mainWidth: number | string;
  loading: boolean;
}

const haveLocation = getObjectLocalStorage(LOCALSTORAGE_GEOLOCATION_USER)?.latitude;

const initialState: IGlobalState = {
  websiteLocation: !!haveLocation ? getObjectLocalStorage(LOCALSTORAGE_GEOLOCATION_USER) : null,
  mainWidth: 'auto',
  loading: false,
};

const updateYourLocation = (state: IGlobalState, payload: any) => {
  if (JSON.stringify(state.websiteLocation) === JSON.stringify(payload.websiteLocation)) return state;
  saveObjectLocalStorage(LOCALSTORAGE_GEOLOCATION_USER, payload.websiteLocation);
  return {
    ...state,
    websiteLocation: payload.websiteLocation,
  };
};

export function globalReducer(state: IGlobalState = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case STORE_NEWS_ACTION_TYPES.GET_LOCATION_CURRENT: {
      return updateYourLocation(state, payload);
    }
    case UPDATE_MAIN_WIDTH: {
      return {
        ...state,
        mainWidth: payload,
      };
    }
    case UPDATE_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    default:
      return state;
  }
}

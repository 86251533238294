import { NotificationType } from 'src/models/notification';
import { notificationActions } from 'src/store/notification';
import { courseMiddleware } from 'src/requests/api/course';
import i18n from 'src/locale';
import {
  CourseInfo,
  IFilterListCourse,
  FavoriteInformation,
  InfoListCourseResponse,
  ICourseContinentResponse,
} from 'src/requests/api/course/prop-state.type';
import { commonMiddleware } from 'src/requests/api/common';
import { EnumMyCourseFilter, EnumRequestCode } from 'src/constants/enum';
import { ICountryRequest } from 'src/requests/api/common/prop-state.type';
import { pathConstants, RUSSIA_COUNTRY_NAME } from 'src/constants/const';
import { history } from 'src/services/history';
import { IGroupTreeView } from 'src/view/commons/customs/TreeViewSelection';
import { flagGolfzon } from 'src/assets/svg';

export const COURSE_ACTION_TYPES = {
  GET_INFO_LIST_REQUEST: 'COURSE_GET_INFO_LIST_REQUEST',
  GET_INFO_LIST_SUCCESS: 'COURSE_GET_INFO_LIST_SUCCESS',
  GET_INFO_LIST_FAILED: 'COURSE_GET_INFO_LIST_FAILED',

  COURSE_FILTERS: 'COURSE_FILTERS',
  COURSE_LIST: 'COURSE_LIST',
  NEW_COURSE_LIST: 'NEW_COURSE_LIST',
  IS_LAST: 'COURSE_IS_LAST',
  COURSE_LIST_OFFSET: 'COURSE_LIST_OFFSET',

  GET_SEARCH_LIST_REQUEST: 'COURSE_GET_SEARCH_LIST_REQUEST',
  GET_SEARCH_LIST_SUCCESS: 'COURSE_GET_SEARCH_LIST_SUCCESS',
  GET_SEARCH_LIST_FAILED: 'COURSE_GET_SEARCH_LIST_FAILED',

  GET_FAVORITE_LIST_REQUEST: 'COURSE_GET_FAVORITE_LIST_REQUEST',
  GET_FAVORITE_LIST_SUCCESS: 'COURSE_GET_FAVORITE_LIST_SUCCESS',
  GET_FAVORITE_LIST_FAILED: 'COURSE_GET_FAVORITE_LIST_FAILED',

  INSERT_CONTINENT_INTO_LIST: 'COURSE_INSERT_CONTINENT_INTO_LIST',
  UPDATE_CONTINENT_LIST: 'COURSE_UPDATE_CONTINENT_LIST',

  RESET_COURSE_LIST: 'COURSE_RESET_LIST',
};

export const course = {
  setOffsetCourse: (data: number) => ({
    type: COURSE_ACTION_TYPES.COURSE_LIST_OFFSET,
    payload: {
      data,
    },
  }),

  setListCourse: (data: Array<CourseInfo>) => ({
    type: COURSE_ACTION_TYPES.COURSE_LIST,
    payload: {
      data,
    },
  }),

  setFilterListCourse: (data: IFilterListCourse) => ({
    type: COURSE_ACTION_TYPES.COURSE_FILTERS,
    payload: {
      data,
    },
  }),

  setIsLastCourse: (data: boolean) => ({
    type: COURSE_ACTION_TYPES.IS_LAST,
    payload: {
      data,
    },
  }),

  getCourseListRequest: () => ({
    type: COURSE_ACTION_TYPES.GET_INFO_LIST_REQUEST,
  }),

  getCourseListSuccess: (courseList: InfoListCourseResponse, loading?: boolean) => ({
    type: COURSE_ACTION_TYPES.GET_INFO_LIST_SUCCESS,
    payload: {
      courseList,
      loading,
    },
  }),

  getCourseListFailed: () => ({
    type: COURSE_ACTION_TYPES.GET_INFO_LIST_FAILED,
  }),

  getSearchListRequest: () => ({
    type: COURSE_ACTION_TYPES.GET_SEARCH_LIST_REQUEST,
  }),

  getSearchListSuccess: (searchList: Array<CourseInfo>, totalCount: number) => ({
    type: COURSE_ACTION_TYPES.GET_SEARCH_LIST_SUCCESS,
    payload: {
      searchList,
      totalCount,
    },
  }),

  getSearchListFailed: () => ({
    type: COURSE_ACTION_TYPES.GET_SEARCH_LIST_FAILED,
  }),

  insertContinentIntoList: (continentList: IGroupTreeView[]) => ({
    type: COURSE_ACTION_TYPES.INSERT_CONTINENT_INTO_LIST,
    payload: {
      continentList,
    },
  }),

  updateContinentList: (continentList: IGroupTreeView[]) => ({
    type: COURSE_ACTION_TYPES.UPDATE_CONTINENT_LIST,
    payload: {
      continentList,
    },
  }),

  getFavoriteRequest: () => ({
    type: COURSE_ACTION_TYPES.GET_FAVORITE_LIST_REQUEST,
  }),

  getFavoriteListSuccess: (favoriteInfo: FavoriteInformation) => ({
    type: COURSE_ACTION_TYPES.GET_FAVORITE_LIST_SUCCESS,
    payload: {
      favoriteInfo,
    },
  }),

  getFavoriteFailed: () => ({
    type: COURSE_ACTION_TYPES.GET_FAVORITE_LIST_FAILED,
  }),
};

const getCourseList = () => async (dispatch: any, getState: any) => {
  const isLoggedIn = getState().authentication.isLoggedIn;
  const settingInfo = getState().settingInfoReducer.settingInfo;

  try {
    dispatch(course.getCourseListRequest());
    if (isLoggedIn) {
      const [responseRecommendCourses, responseRoundCourses, responseTotal] = await Promise.all([
        courseMiddleware.getRecommendCourseList(),
        courseMiddleware.getRoundCourseList(),
        courseMiddleware.getMyCourse({ orderType: EnumMyCourseFilter.NEW, page: 1, rows: 1 }),
      ]);
      dispatch(
        course.getCourseListSuccess(
          {
            recommendCourseList: responseRecommendCourses?.entities?.recommendCourseList || [],
            roundCourseList: responseRoundCourses?.entities?.roundCourseList || [],
            totalRoundCourse: responseTotal?.totalCount || 0,
          },
          false,
        ),
      );
    } else {
      const params = {
        guestCountryCode: settingInfo.countryCd,
        distanceUnit: settingInfo.distUnit,
      };
      const responseRecommendCourses = await courseMiddleware.getRecommendCourseList(params);
      dispatch(
        course.getCourseListSuccess(
          {
            recommendCourseList: responseRecommendCourses?.entities?.recommendCourseList || [],
            roundCourseList: [],
            totalRoundCourse: 0,
          },
          false,
        ),
      );
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(course.getCourseListFailed());
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const getSearchCourseList = (courses: CourseInfo[]) => async (dispatch: any, getState: any) => {
  const loadingNewCourseList = getState().courseReducer.courseList.loadingNewCourseList;
  if (loadingNewCourseList) {
    dispatch(
      course.getCourseListSuccess({
        loadingNewCourseList: false,
        newCourseList: (courses || []).slice(0, 4),
      }),
    );
  }
  dispatch(course.setListCourse(courses));
};

const getContinentList = (params?: ICountryRequest) => async (dispatch: any) => {
  try {
    const response = await commonMiddleware.getCountryList(params);

    if (response.code !== EnumRequestCode.SUCCESS) {
      throw new Error('get continent list failed');
    }
    const continentList: IGroupTreeView[] = (response?.entities ?? []).map((continent: ICourseContinentResponse) => ({
      name: `commons.customs.continent_selection.${continent.continent.toUpperCase()}`,
      value: continent.continent,
      checked: false,
      items: (continent.country ?? []).map((country) => ({
        name:
          country.countryNm === RUSSIA_COUNTRY_NAME ? 'commons.customs.national_selection.OTHER' : country.countryNm,
        value: country.uiCountry,
        icon: ['Golfzon', RUSSIA_COUNTRY_NAME].includes(country.countryNm) ? flagGolfzon : country.flagImage,
        checked: false,
        translate: country.countryNm === RUSSIA_COUNTRY_NAME,
      })),
    }));
    dispatch(course.insertContinentIntoList(continentList));
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
  }
};

const getFavoriteCourse = () => async (dispatch: any) => {
  try {
    dispatch(course.getFavoriteRequest());
    const response = await courseMiddleware.getFavoriteCourse();
    if (response.code === EnumRequestCode.SUCCESS) {
      dispatch(
        course.getFavoriteListSuccess({
          listFavorite: response?.entities,
          total: response?.totalCount,
        }),
      );
    } else {
      dispatch(course.getFavoriteFailed());
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(error.message, NotificationType.DANGER));
  }
};

const favoriteCourse = (typeNo: string) => async (dispatch: any) => {
  try {
    const response = await courseMiddleware.favoriteCourse(typeNo);
    if (response.code === EnumRequestCode.SUCCESS && response.entities.flag) {
      const additional = {
        textAction: {
          title: i18n.t('mess.VIEW'),
          onClick: () => history.push(pathConstants.FAVORITE_COURSE),
        },
      };
      dispatch(notificationActions.addNotification(i18n.t('mess.BOOKMARK_MESS'), NotificationType.SUCCESS, additional));
      return Promise.resolve(true);
    } else if (response.code === EnumRequestCode.SUCCESS) {
      dispatch(notificationActions.addNotification(i18n.t('mess.UN_BOOKMARK_MESS'), NotificationType.INFO));
      return Promise.resolve(false);
    } else {
      throw new Error("Can't set Favorite Course");
    }
  } catch (error: any) {
    console.warn(error);
    dispatch(notificationActions.addNotification(error.message, NotificationType.DANGER));
    return Promise.reject();
  }
};

const resetCourseList = () => ({
  type: COURSE_ACTION_TYPES.RESET_COURSE_LIST,
});

export const courseActions = {
  getCourseList,
  getSearchCourseList,
  getContinentList,
  favoriteCourse,
  getFavoriteCourse,
  resetCourseList,
};

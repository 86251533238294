export const downloadFile = async (urlFile: string, fileName: string = 'video-nasmo.mp4') => {
  await fetch(urlFile, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Origin: window.location.origin,
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;
      link.click();
    });
};

export const getDownloadScoreCard = (element: HTMLElement) => {
  const clone: any = element.cloneNode(true);
  clone.setAttribute('style', 'width: 350px; position: absolute; top: -9999px; left: -9999px');
  if (clone.getElementsByClassName('score-notes')) {
    const scoreNotesElement = clone.getElementsByClassName('score-notes')[0];
    scoreNotesElement?.setAttribute('style', 'white-space: nowrap; justify-content: flex-start');
  }
  if (clone.getElementsByClassName('score-note-group')) {
    const scoreNotesElements = clone.getElementsByClassName('score-note-group');
    Array.prototype.forEach.call(document.getElementsByClassName('score-note-group'), function (_, index) {
      if (index === scoreNotesElements.length - 1) return;
      if (scoreNotesElements.length <= 3) {
        scoreNotesElements[index]?.setAttribute('style', 'margin-right: 20px');
      } else {
        scoreNotesElements[index]?.setAttribute('style', 'margin-right: 14px');
      }
    });
  }
  document.body.appendChild(clone);
  const imageScale = 2;
  const domtoimageParams = {
    style: {
      left: '0',
      right: '0',
      bottom: '0',
      top: '0',
      transform: 'scale(' + imageScale + ')',
      transformOrigin: 'top left',
    },
    width: clone.clientWidth * imageScale,
    height: clone.clientHeight * imageScale,
  };
  return {
    element: clone,
    params: domtoimageParams,
  };
};

export const getDownloadRanking = (element: HTMLElement) => {
  const clone: any = element.cloneNode(true);
  clone.setAttribute('style', 'position: absolute; top: -9999px; left: -9999px');
  document.body.appendChild(clone);
  const imageScale = 2;
  const domtoimageParams = {
    style: {
      left: '0',
      right: '0',
      bottom: '0',
      top: '0',
      transform: 'scale(' + imageScale + ')',
      transformOrigin: 'top left',
    },
    width: clone.clientWidth * imageScale,
    height: clone.clientHeight * imageScale,
    cacheBust: true,
  };
  return {
    element: clone,
    params: domtoimageParams,
  };
};

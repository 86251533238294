import React from 'react';
import { useTranslation } from 'react-i18next';
import { cupHoldInOne } from 'src/assets/images';
import { IPar3ChallengeUser } from 'src/models';
import { booleanValue, getFlagByCountryCd } from 'src/services/utils';
import AvatarImage from 'src/view/commons/customs/AvatarImage';
import RankLevel from 'src/view/pages/profile/commons/RankLevel';

interface IRankingItem {
  topPlayer: IPar3ChallengeUser;
}

const RankingItem = ({ topPlayer }: IRankingItem) => {
  const translate = useTranslation().t;

  return (
    <div className="flex items-center justify-between border-b border-dark-10 py-[12px] last:border-b-0">
      <div className="flex min-w-0 items-center">
        <div className="gz-text-xs w-[48px] shrink-0 text-center font-bold">{topPlayer?.ranking || ''}</div>
        <div className="mr-[8px] shrink-0">
          <AvatarImage src={topPlayer?.usrNickImg} gradetcode={topPlayer?.grade} classImg="w-[40px] h-[40px]" />
        </div>
        {topPlayer?.countryCd && (
          <img src={getFlagByCountryCd(topPlayer?.countryCd)} alt="flag" className="mr-[8px] w-[20px]" />
        )}
        <div className="min-w-0">
          <div className="flex items-end">
            <span className="gz-text-xs overflow-hidden text-ellipsis whitespace-nowrap font-bold">
              {topPlayer.usrNickNm || ''}
            </span>
            {booleanValue(topPlayer?.isMyRank) && (
              <div className="gz-text-txsm ml-[8px] mb-[2px] flex h-[16px] shrink-0 items-center rounded-[4px] bg-primary-gradient px-[4px] font-bold text-gz-white">
                {translate('pages.nx.par_3_leaderboard.MY')}
              </div>
            )}
          </div>
          <RankLevel code={topPlayer?.grade} className="gz-text-xsm text-sub-body" />
        </div>
      </div>
      <div className="ml-[16px] mr-[16px] flex shrink-0 items-center">
        <div className="mr-[16px] h-[12px] w-[1px] bg-gray-42" />
        <div className="gz-text-xs mr-[8px] font-bold">{topPlayer?.holeInOneCnt || ''}</div>
        <img src={cupHoldInOne} alt="cup" className="w-[24px]" />
      </div>
    </div>
  );
};

export default RankingItem;

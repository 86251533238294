import { getObjectLocalStorage, saveObjectLocalStorage } from 'src/services/storage';
import { LOCALSTORAGE_SETTING_INFO } from 'src/constants/const';
import { MY_SETTING_INFO, UPDATE_WIFI_INFO } from 'src/store/setting/action';
import { IMySettingInfoResponse } from 'src/requests/api/setting/prop-state.type';
import { EnumStoreDistance, EnumWifiAction, TypeDistance, TypeSpeed } from 'src/constants/enum';

export interface UserInfoState {
  settingInfo: IMySettingInfoResponse | null;
  wifiEnable: boolean;
}

const initialState = (): UserInfoState => ({
  settingInfo: {
    distUnit: TypeDistance.IMPERIAL,
    greenUnit: TypeDistance.IMPERIAL,
    speedUnit: TypeSpeed.MPH,
    storeDistanceUnit: EnumStoreDistance.KILOMETER,
    onlyWifiPlayYn: EnumWifiAction.NEVER_AUTO_PLAY,
    ...getObjectLocalStorage(LOCALSTORAGE_SETTING_INFO),
  },
  wifiEnable: false,
});

export function settingInfoReducer(state = initialState(), action: any) {
  switch (action.type) {
    case MY_SETTING_INFO:
      const newSettingInfo = {
        ...state.settingInfo,
        ...action.payload.settingInfo,
      };
      saveObjectLocalStorage(LOCALSTORAGE_SETTING_INFO, newSettingInfo);
      return {
        ...state,
        settingInfo: newSettingInfo,
      };
    case UPDATE_WIFI_INFO:
      return {
        ...state,
        wifiEnable: action.payload.wifiEnable,
      };
    default:
      return state;
  }
}

export const colors = {
  transparent: 'transparent',
  black: '#000000',
  white: '#ffffff',
  gray_20: '#E6E6E6',
  primary: '#007BDC',
  primary_10: '#EBF5FE',
  primary_20: '#DAEFFF',
  primary_75: '#40ABFF',
  secondary_20: '#D9F5FF',
  secondary_75: '#33CAFF',
  blue_10: '#EFF6FF',
  blue_20: '#E8F3FF',
  blue_50: '#70C0FF',
  blue_75: '#0179E6',
  dark_blue_20: '#3654A0',
  green: '#00CC99',
  green_50: '#00B050',
  green_20: '#D3FFDE',
  green_10: '#EEFEF2',
  yellow_70: '#FFC700',
  orange_75: '#F1733A',
  orange_80: '#FF9052',
  txt_black: '#3F3E4E',
  txt_gray: '#8A8A8A',
  danger: '#FF3640',
};

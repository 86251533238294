export enum EnumBookingStatus {
  APPROVED = 1,
  REQUESTING = 0,
}

export enum EnumFeeType {
  ALL = '',
  ROOM = 'ROOM',
  NINE_HOLE = 'NINE_HOLE',
  EIGHTEEN_HOLE = 'EIGHTEEN_HOLE',
}

export enum EnumDayType {
  ALL = '',
  ALL_DAY = 'ALL_DAY',
  WEEKDAYS = 'WEEKDAYS',
  WEEKEND = 'WEEKEND',
}

export enum EnumGenderFeeType {
  ALL = 'All',
  ELDER = 'Elder',
  FEMALE = 'Female',
  JUNIOR = 'Junior',
  MALE = 'Male',
}

export enum snsType {
  Google = 'google',
  Facebook = 'facebook',
  Apple = 'apple',
  Wechat = 'wechat',
  Line = 'line',
}

export enum EnuGender {
  ALL = '',
  MALE = 'M',
  FEMALE = 'F',
}

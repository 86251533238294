import { EnumRequestCode } from 'src/constants/enum';
import axios from 'axios';
import { store } from 'src/store';
import { CT_BASE_URL } from 'src/constants/const';
import { getSessionId } from 'src/requests/token';
import { authActions } from 'src/store/account/login';
import { verifyToken } from 'src/requests/verify-token';

const instance = axios.create({
  baseURL: CT_BASE_URL,
  // baseURL: 'https://15d4-118-70-131-70.ngrok-free.app',
  timeout: 70000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// For GET requests
instance.interceptors.request.use(
  (req) => {
    const token = getSessionId();
    if (token && req.headers) {
      req.headers['gz_session_id'] = token;
    }
    return req;
  },
  (err) => Promise.reject(err),
);

// For POST requests
instance.interceptors.response.use(
  (res) => {
    // verify token when data null
    if (
      !!res?.config?.headers?.gz_session_id &&
      (res?.data?.data === null ||
        (res?.data?.data?.status === null && res?.data?.data?.code === EnumRequestCode.FAILED))
    ) {
      verifyToken();
    }
    if (!!res.data?.data?.withdraw) {
      store.dispatch(authActions.logout());
    }
    return res;
  },
  (err) => Promise.reject(err),
);

export default instance;

import { IFeed } from 'src/models';
import { storeFeed } from 'src/requests/api/feed/store-news';

export const getFeedThumbnailUrl = (feed: IFeed) => {
  if (feed.nasmoImgUrl) return feed.nasmoImgUrl;
  if (feed.imageList && feed.imageList.length > 0) {
    return feed.imageList[0];
  }
  return '';
};

export const deleteFeedAfterDeletingSwingVideo = async (feedNo?: number) => {
  if (!feedNo || feedNo <= 0) return;
  try {
    await storeFeed.deleteFeed({
      typeNo: feedNo,
    });
  } catch (error) {
    throw error;
  }
};

export const getFileNameFromUrl = (url?: string) => {
  if (!url) return '';
  return url.split('/').pop() ?? '';
};

export const getTmImageFromUrl = (url?: string) => {
  if (!url) return '';
  const imageName = RegExp(/new\/tm\/.+/g).exec(url);
  if (!imageName) return '';
  return imageName[0];
};

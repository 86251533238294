import React, { Fragment, Suspense } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import NotificationContainer from 'src/view/commons/customs/alert-mess/NotificationContainer';
import ButtonScrollToTop from 'src/view/commons/elements/ButtonScollToTop';
import { authRoutesGroup } from 'src/routes/auth/AuthenticatedRoutes';
import GuestGuard from 'src/routes/guest/GuestGuard';
import { guestRoutesGroup } from 'src/routes/guest/GuestRoutes';
import { globalRoutesGroup } from 'src/routes/global/GlobalRoutes';
import AuthenticatedGuard from 'src/routes/auth/AuthenticatedGuard';
import AuthGuestView from 'src/view/commons/customs/guest-mode/AuthGuestView';
import { useInitializeApp } from 'src/hooks/use-initialize-app';
import ErrorBoundary from 'src/ErrorBoundary';
import FullPageLoading from 'src/view/commons/customs/FullPageLoading';
import ForceUpdateModal from 'src/view/commons/elements/ForceUpdateModal';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

function App(): JSX.Element {
  useInitializeApp();
  const mainWidth = useSelector((state: RootState) => state.globalReducer.mainWidth);

  function withLayoutRoute(layout: JSX.Element, header?: JSX.Element) {
    return React.cloneElement(
      layout,
      {},
      <Suspense fallback={<></>}>
        {header}
        <Outlet />
      </Suspense>,
    );
  }

  const routes = [
    {
      id: 'auth',
      guard: <AuthenticatedGuard />,
      groupList: authRoutesGroup,
    },
    {
      id: 'guest',
      guard: <GuestGuard />,
      groupList: guestRoutesGroup,
    },
    {
      id: 'global',
      guard: <Fragment />,
      groupList: globalRoutesGroup,
    },
  ];

  return (
    <>
      <Helmet>
        {!!mainWidth && mainWidth <= 375 ? (
          <meta
            name="viewport"
            content="width=375, maximum-scale=5.0, user-scalable=no, viewport-fit=cover, target-density-dpi=device-dpi"
          />
        ) : (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=no, viewport-fit=cover"
          />
        )}
      </Helmet>
      <NotificationContainer />
      <ButtonScrollToTop />
      <FullPageLoading />
      <ErrorBoundary>
        <Routes>
          {routes.map((route) => (
            <Route key={route.id} element={withLayoutRoute(route.guard)}>
              {route.groupList.map((group, index) => (
                <Route key={index} element={withLayoutRoute(group.layout, group.header)}>
                  {group.components.map((component) => (
                    <Route
                      key={component.path}
                      path={component.path}
                      element={
                        <AuthGuestView>
                          <AuthGuestView.Global>{component.component}</AuthGuestView.Global>
                          <AuthGuestView.Auth>{component.authComponent}</AuthGuestView.Auth>
                          <AuthGuestView.Guest>{component.guestComponent}</AuthGuestView.Guest>
                        </AuthGuestView>
                      }
                    />
                  ))}
                </Route>
              ))}
            </Route>
          ))}
        </Routes>
      </ErrorBoundary>
      <ForceUpdateModal />
    </>
  );
}

export default App;

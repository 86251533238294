import dayjs from 'dayjs';
import { Component } from 'react';
import { LOCAL_STORAGE_WINDOW_RELOAD_DATETIME, pathConstants } from 'src/constants/const';
import { EnumFireStoreCollectionName } from 'src/constants/enum';
import { firebaseInstance } from 'src/init-firebase';

// ErrorBoundary Component
export default class ErrorBoundary extends Component {
  componentDidCatch(error: Error) {
    this.setState(null);
    const errorStr = 'Stack: ' + error?.stack + ' Message: ' + error?.message;
    if (window.location.pathname === pathConstants.SIGNUP) {
      firebaseInstance.logCrashlytics(errorStr, EnumFireStoreCollectionName.CRASHLYITC_ERRORS);
    } else {
      firebaseInstance.logError(errorStr);
    }
    if ((error?.stack ?? error.message)?.includes('ChunkLoadError')) {
      const oldDate = localStorage.getItem(LOCAL_STORAGE_WINDOW_RELOAD_DATETIME); // prevent from loop reload
      if (!oldDate || dayjs().diff(dayjs(oldDate), 'hour') > 1) {
        window.location.reload();
        localStorage.setItem(LOCAL_STORAGE_WINDOW_RELOAD_DATETIME, dayjs().toString());
      }
    }
  }

  render() {
    return (this.props as any).children;
  }
}

import { NotificationType } from 'src/models';
import { AIRequest } from 'src/requests/api/AI';
import { IAIAnalysisResult } from 'src/requests/api/AI/prop-state.type';
import { notificationActions } from 'src/store/notification';
import i18n from 'src/locale';
import { EnumRequestCode } from 'src/constants/enum';

export const UPDATE_AI_ANALYSIS_DATA = 'UPDATE_AI_ANALYSIS_DATA';
export const CLEAR_AI_ANALYSIS_DATA = 'CLEAR_AI_ANALYSIS_DATA';
export const UPDATE_AI_ANALYSIS_OFFSET = 'UPDATE_AI_ANALYSIS_OFFSET';

export interface IUpdateAIAnalysisPayload {
  loading?: boolean;
  type?: string;
  analysisId?: string;
  lang?: string;
  data?: IAIAnalysisResult;
}

export const AIDispatch = {
  updateAIAnalysisData: (payload: IUpdateAIAnalysisPayload) => ({
    type: UPDATE_AI_ANALYSIS_DATA,
    payload,
  }),

  clearAIAnalysisData: () => ({
    type: CLEAR_AI_ANALYSIS_DATA,
  }),

  updateAIAnalysisOffset: (payload: number) => ({
    type: UPDATE_AI_ANALYSIS_OFFSET,
    payload,
  }),
};

let lastRequestIndex = 0;
const getAIAnalysisData =
  (analysisId?: string, type?: string, lang?: string) => async (dispatch: any, getState: any) => {
    const currentState = getState().AIReducer;
    const currentAnalysisId = currentState.analysisId;
    const currentType = currentState.type;
    const currentLang = currentState.lang;
    try {
      const currentLastRequestIndex = ++lastRequestIndex;
      if (!type || !analysisId) throw new Error('invalid request body');
      if (currentAnalysisId === analysisId && currentType === type && !!lang && currentLang === lang) return;
      dispatch(AIDispatch.updateAIAnalysisData({ loading: true }));
      const result = await AIRequest.getAIAnalysisResult({
        analysisId,
        type: type.toUpperCase(),
      });
      if (result.code !== EnumRequestCode.SUCCESS) throw new Error('get AI analysis result failed');
      if (currentLastRequestIndex !== lastRequestIndex) return;
      const payload: IUpdateAIAnalysisPayload = {
        analysisId,
        type,
        lang,
        data: result.entities,
      };
      dispatch(AIDispatch.updateAIAnalysisData(payload));
    } catch (err) {
      dispatch(AIDispatch.clearAIAnalysisData());
      dispatch(notificationActions.addNotification(i18n.t('error.UNKNOWN_ERROR'), NotificationType.DANGER));
    } finally {
      dispatch(AIDispatch.updateAIAnalysisData({ loading: false }));
    }
  };

export const AIActions = {
  getAIAnalysisData,
};

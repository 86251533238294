import AvatarImage from 'src/view/commons/customs/AvatarImage';
import { twMerge } from 'tailwind-merge';

interface IProps {
  images?: string[];
  maxImage?: number;
  count?: number;
}

export default function ImageGroup({ images = [], maxImage = 3, count = 0 }: IProps) {
  if (count === 0) return <></>;

  const displayedImageCount = count > maxImage ? maxImage - 1 : maxImage;
  const displayedImageList = images.slice(0, displayedImageCount);

  return (
    <div className="flex items-center">
      {displayedImageList &&
        displayedImageList.length > 0 &&
        displayedImageList.map((image, index: number) => (
          <AvatarImage
            classContainer="self-start flex-shrink-0 [&:not(:first-child)]:-ml-[4px]"
            key={`nick-img-${index}`}
            classImg="w-[24px] h-[24px] ring-[1.5px] ring-gz-white"
            src={image}
          />
        ))}
      {count > displayedImageList.length && (
        <div
          className={twMerge(
            'z-1 flex items-center justify-center px-[2px]',
            'h-[24px] min-w-[24px] flex-shrink-0 self-start [&:not(:first-child)]:-ml-[4px]',
            'gz-text-txsm rounded-full bg-primary-10 leading-[16px] text-primary-75',
          )}
        >
          +{count - displayedImageList.length}
        </div>
      )}
    </div>
  );
}

import _ from 'lodash';
import { useEffect } from 'react';
import { isOpeningKeyboard } from 'src/services/mobile-keyboard';

export const useStickyHeaderOnSafari = () => {
  useEffect(() => {
    let fixPosition = 0; // the fix
    const toolbarWrap = document.getElementById('ios-toolbar-wrap'); // the toolbar wrap
    const toolbar = document.getElementById('ios-toolbar'); // the toolbar
    if (!toolbarWrap || !toolbar) return;

    // function to set the margin to show the toolbar if hidden
    const setMargin = function () {
      // if toolbar wrap is hidden
      const newPosition = toolbarWrap!.getBoundingClientRect().top;
      if (newPosition < -1 && isOpeningKeyboard()) {
        // add a margin to show the toolbar
        toolbar!.classList.add('down'); // add class so toolbar can be animated
        fixPosition = Math.abs(newPosition); // this is new position we need to fix the toolbar in the display
        // if at the bottom of the page take a couple of pixels off due to gap
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
          fixPosition -= 2;
        }
        // set the margin to the new fixed position
        toolbar!.style['margin-top'] = fixPosition + 'px';
      }
    };

    // use lodash debounce to stop flicker
    const debounceMargin = _.debounce(setMargin, 150);

    // function to run on scroll and blur
    const showToolbar = function () {
      // remove animation and put toolbar back in default position
      if (fixPosition > 0 || !isOpeningKeyboard()) {
        toolbar!.classList.remove('down');
        fixPosition = 0;
        toolbar!.style['margin-top'] = 0 + 'px';
      }
      // will check if toolbar needs to be fixed
      debounceMargin();
    };

    // add an event listener to scroll to check if
    // toolbar position has moved off the page
    window.addEventListener('scroll', showToolbar);
    // add an event listener to blur as iOS keyboard may have closed
    // and toolbar postition needs to be checked again
    window.visualViewport!.addEventListener('resize', showToolbar);

    return () => {
      window.removeEventListener('scroll', showToolbar);
      window.visualViewport!.removeEventListener('resize', showToolbar);
    };
  }, []);
};

import { EnumPlatform } from 'src/constants/enum';
import { ILocation } from 'src/store/global';
import { WEBVIEW_BROWSER, IS_NATIVEAPP_PERMISSION, UPDATE_NATIVEAPP_LOCATION } from 'src/store/webview/action';

export interface IPermission {
  nativeAppPermission?: boolean;
  isLocationEnabled?: boolean;
}
export interface IWebviewData {
  isNativeApp: boolean;
  typeNativeApp: string;
  fcmToken?: string;
  isPermissionNativeApp?: boolean;
  isLocationEnabled?: boolean;
  nativeAppLocation?: ILocation | null;
  keyboardShow?: boolean;
  language?: string;
  country?: string;
  isLoggedIn?: boolean; // check first time login mobile app
  needUpdate?: boolean;
  appVersion?: string;
  appsInstalled?: string[];
}

const initialState: IWebviewData = {
  isNativeApp: false,
  typeNativeApp: EnumPlatform.Web,
  isPermissionNativeApp: true,
  nativeAppLocation: undefined,
  isLocationEnabled: false,
  fcmToken: '',
  keyboardShow: false,
  language: '',
  country: '',
  isLoggedIn: false,
  needUpdate: true,
  appVersion: '',
  appsInstalled: [],
};

export const webviewReducer = (state: IWebviewData = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case WEBVIEW_BROWSER:
      return {
        ...state,
        ...action.payload,
      };
    case IS_NATIVEAPP_PERMISSION:
      return {
        ...state,
        isPermissionNativeApp: payload.nativeAppPermission,
        isLocationEnabled: payload.isLocationEnabled,
      };
    case UPDATE_NATIVEAPP_LOCATION:
      if (JSON.stringify(state.nativeAppLocation) === JSON.stringify(payload.nativeAppLocation)) return state;
      return {
        ...state,
        nativeAppLocation: payload.nativeAppLocation,
      };
    default:
      return state;
  }
};

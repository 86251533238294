export enum EnumFilteringModalStoreMap {
  ALL = 'ALL_FILTERS',
  SYSTEMS = 'FILTER_SYSTEMS',
  FACILITY = 'FILTER_FACILITIES',
  DISTANCE_AWAY = 'FILTER_DISTANCE_AWAY',
}

export enum EnumResultTypeStoreMap {
  LOCATION = 'Location',
  REGION = 'Region',
  COUNTRY = 'Country',
  OTHER = 'Other',
}

export enum EnumSortTypeStoreMap {
  CLOSEST = 'CLOSEST',
  POPULAR = 'POPULAR',
  FAVORITED = 'FAVORITED',
}

export enum EnumSiteImageType {
  STORE,
  MAINSTORE,
  FINDSTORE,
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { ITmDrivingRangeClubResponse, ITmResponse } from 'src/requests/api/record/prop-state.type';
import BallDirection from 'src/view/commons/customs/BallDirection';
import { constUnit, pathConstants } from 'src/constants/const';
import { history } from 'src/services/history';
import { getBallPathBySwingPositon } from 'src/services/training-mode';

interface IProps {
  classWrapper?: string;
  item?: ITmResponse;
  isDetail?: boolean;
}
const ApproachItem: React.FC<IProps> = React.memo(({ item, isDetail, classWrapper }) => {
  const translate = useTranslation().t;

  const renderItem = (arrayData: ITmDrivingRangeClubResponse[]) => {
    const itemMax = Math.max(...arrayData.map((el: ITmDrivingRangeClubResponse) => el.shotDistance));
    const totalShotCnt = arrayData.reduce((currentValue, item) => currentValue + Number(item.shotDistance), 0);
    return arrayData.map((element: ITmDrivingRangeClubResponse, index: number) => {
      const percent = (Number(element.shotDistance) * 100) / totalShotCnt;
      return (
        <div
          key={`club-shot-${index}`}
          className="gz-text-xsm flex items-center justify-between [&:not(:last-child)]:mb-[12px]"
        >
          <div className="w-[32px] shrink-0 uppercase">{element.club}</div>
          <div className="ml-[12px] mr-[14px] w-full">
            <div
              className={twMerge(
                'h-[8px] rounded-r-[8px]',
                itemMax === element.shotDistance ? 'bg-primary-75' : 'bg-gray-20',
              )}
              style={{ width: `${percent}%` }}
            />
          </div>
          <div className="flex w-[70px] flex-shrink-0 items-center justify-end">
            <span>
              {element.shotDistance} {item?.distanceUnit}
            </span>
            <span className="ml-[4px]">
              <BallDirection
                tmBallPathCode={getBallPathBySwingPositon(element.shotType, item?.tsSwingPosition)}
                classIcon={twMerge('w-[12px] h-[12px] w-auto')}
                hideText
              />
            </span>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className={twMerge('w-full rounded-[16px] p-[18px] shadow-xsm', !isDetail ? 'cursor-pointer' : '', classWrapper)}
      onClick={() => history.push(pathConstants.RECORD_DETAIL_TM_APPROACH(item?.tsCode))}
    >
      <div className="mb-[24px] flex justify-around">
        <div className="text-center">
          <div className="gz-text-md mb-[8px] font-bold">{item?.tsApproach?.practiceBallCount ?? 0}</div>
          <div className="gz-text-xsm text-sub-body">{translate('pages.record.type.training_mode.PRACTICE_BALLS')}</div>
        </div>
        <div className="text-center">
          <div className="gz-text-md mb-[8px] font-bold">
            {item?.tsApproach?.onGreenRate ?? 0}
            {constUnit.PERCENT}
          </div>
          <div className="gz-text-xsm text-sub-body">{translate('pages.record.type.training_mode.ON_GREEN_RATE')}</div>
        </div>
      </div>
      {item?.tsApproach?.approaches &&
        item?.tsApproach?.approaches.length > 0 &&
        renderItem(item.tsApproach.approaches)}
    </div>
  );
});

export default ApproachItem;
